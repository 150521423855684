var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import classnames from "classnames";
import { scaleLinear, scaleSequential } from "d3-scale";
import { interpolateSpectral } from "d3-scale-chromatic";
import { interpolateHcl } from "d3-interpolate";
import Node, { NumberOfChildrenPlacement } from "../Node";
import Breadcrumb from "../Breadcrumb";
import { ColorModel } from "./ITreeMapProps";
import TooltipProvider from "../Tooltip/TooltipProvider";
import { getColorDomainFn, getHighContrastColorFromString, getTopParent, getTopSubParentId, getValueFormatFn, } from "./helpers";
import { useTreeMap } from "./hooks";
var TreeMap = (function (_super) {
    __extends(TreeMap, _super);
    function TreeMap(props) {
        var _this = _super.call(this, props) || this;
        _this._onBreadcrumbItemClicked = function (ev) {
            _this._zoomTo(Number(ev.currentTarget.id));
        };
        _this._onNodeClick = function (ev) {
            _this._zoomTo(parseInt(ev.currentTarget.id));
        };
        var width = props.width, height = props.height, data = props.data, namePropInData = props.namePropInData, valuePropInData = props.valuePropInData, paddingOuter = props.paddingOuter;
        var topNode = useTreeMap({
            width: width,
            height: height,
            data: data,
            valuePropInData: valuePropInData,
            paddingOuter: paddingOuter,
        });
        _this.state = {
            height: height,
            width: width,
            xScaleFunction: scaleLinear()
                .range([0, width])
                .domain([topNode.x0, topNode.x1]),
            yScaleFunction: scaleLinear()
                .range([0, height])
                .domain([topNode.y0, topNode.y1]),
            breadcrumbItems: [{ text: data[namePropInData], key: 0 }],
            selectedNode: topNode,
        };
        return _this;
    }
    TreeMap.prototype.componentDidMount = function () {
        var onTreeMapDidMount = this.props.onTreeMapDidMount;
        this._zoomTo(0);
        if (onTreeMapDidMount) {
            onTreeMapDidMount(this);
        }
    };
    TreeMap.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var width = nextProps.width, height = nextProps.height;
        if (height !== this.props.height || width !== this.props.width) {
            var selectedNode = this.state.selectedNode;
            this.setState({
                width: width,
                height: height,
                xScaleFunction: scaleLinear()
                    .range([0, width])
                    .domain([selectedNode.x0, selectedNode.x1]),
                yScaleFunction: scaleLinear()
                    .range([0, height])
                    .domain([selectedNode.y0, selectedNode.y1]),
            });
        }
    };
    TreeMap.prototype.render = function () {
        var _this = this;
        var _a = this.state, width = _a.width, height = _a.height, breadcrumbItems = _a.breadcrumbItems, selectedNode = _a.selectedNode;
        var _b = this.props, svgClassName = _b.svgClassName, svgStyle = _b.svgStyle, className = _b.className, childrenPropInData = _b.childrenPropInData, breadCrumbClassName = _b.breadCrumbClassName, disableBreadcrumb = _b.disableBreadcrumb, tooltipPlacement = _b.tooltipPlacement, tooltipClassName = _b.tooltipClassName, disableTooltip = _b.disableTooltip, tooltipOffsetX = _b.tooltipOffsetX, tooltipOffsetY = _b.tooltipOffsetY, levelsToDisplay = _b.levelsToDisplay;
        var reactNodes = [];
        var maxLevel = selectedNode.depth === 0 ? levelsToDisplay : 1;
        var iterateAllChildren = function (mainNode, level) {
            reactNodes = reactNodes.concat(_this._getNode(mainNode));
            if (level < maxLevel) {
                if (childrenPropInData in mainNode &&
                    mainNode[childrenPropInData].length > 0) {
                    mainNode[childrenPropInData].forEach(function (element) {
                        iterateAllChildren(element, level + 1);
                    });
                }
            }
        };
        iterateAllChildren(selectedNode, 0);
        return (React.createElement(TooltipProvider, { tooltipPlacement: tooltipPlacement, tooltipClassName: tooltipClassName, disableTooltip: disableTooltip, tooltipOffsetX: tooltipOffsetX, tooltipOffsetY: tooltipOffsetY },
            React.createElement("div", { className: className },
                disableBreadcrumb === false ? (React.createElement(Breadcrumb, { items: breadcrumbItems, className: breadCrumbClassName })) : null,
                React.createElement("svg", { className: classnames(svgClassName), height: height, width: width, style: __assign({}, svgStyle) }, reactNodes))));
    };
    TreeMap.prototype._getNode = function (node) {
        var _a = this.props, treemapId = _a.id, valueUnit = _a.valueUnit, hideValue = _a.hideValue, hideNumberOfChildren = _a.hideNumberOfChildren, nodeStyle = _a.nodeStyle, nodeClassName = _a.nodeClassName, valuePropInData = _a.valuePropInData, childrenPropInData = _a.childrenPropInData, namePropInData = _a.namePropInData, linkPropInData = _a.linkPropInData, numberOfChildrenPlacement = _a.numberOfChildrenPlacement, darkNodeTextColor = _a.darkNodeTextColor, darkNodeBorderColor = _a.darkNodeBorderColor, lightNodeTextColor = _a.lightNodeTextColor, lightNodeBorderColor = _a.lightNodeBorderColor, paddingInner = _a.paddingInner, valueFn = _a.valueFn, valueFormat = _a.valueFormat, splitRegExp = _a.splitRegExp;
        var _b = this.state, xScaleFunction = _b.xScaleFunction, yScaleFunction = _b.yScaleFunction, selectedNode = _b.selectedNode;
        var customId = node.customId, data = node.data, x0 = node.x0, x1 = node.x1, y0 = node.y0, y1 = node.y1;
        var name = data[namePropInData];
        var url = data[linkPropInData];
        var nodeClassNameFromData = data["className"];
        var hasChildren = node[childrenPropInData] && node[childrenPropInData].length > 0
            ? true
            : false;
        var formatted = node[valuePropInData];
        try {
            formatted = getValueFormatFn(valueFn, valueFormat)(node[valuePropInData]);
        }
        catch (e) {
            console.warn(e);
        }
        var formattedValue = "(" + formatted + (valueUnit ? " " + valueUnit : "") + ")";
        var nodeTotalNodes = node.descendants().length - 1;
        var _c = this._getColorsFromNode(node, nodeTotalNodes, {
            darkNodeTextColor: darkNodeTextColor,
            darkNodeBorderColor: darkNodeBorderColor,
            lightNodeTextColor: lightNodeTextColor,
            lightNodeBorderColor: lightNodeBorderColor,
        }), bgColor = _c.bgColor, textColor = _c.textColor, borderColor = _c.borderColor;
        var isSelectedNode = customId === selectedNode.customId;
        return (React.createElement(Node, { bgColor: bgColor, textColor: textColor, borderColor: borderColor, className: classnames(nodeClassName, nodeClassNameFromData), style: __assign({ fontVariant: "normal", fontWeight: "normal", fontSize: 14, fontFamily: "Arial" }, nodeStyle), hasChildren: hasChildren, hideNumberOfChildren: hideNumberOfChildren, id: customId, isSelectedNode: isSelectedNode, key: customId, label: name, nodeTotalNodes: nodeTotalNodes, onClick: !isSelectedNode ? this._onNodeClick : undefined, treemapId: treemapId, url: url, value: !hideValue && formattedValue, x0: x0, x1: x1, xScaleFunction: xScaleFunction, y0: y0, y1: y1, yScaleFunction: yScaleFunction, numberOfChildrenPlacement: numberOfChildrenPlacement, paddingInner: paddingInner, splitRegExp: splitRegExp }));
    };
    TreeMap.prototype._getColorsFromNode = function (node, nodeTotalNodes, _a) {
        var darkNodeTextColor = _a.darkNodeTextColor, darkNodeBorderColor = _a.darkNodeBorderColor, lightNodeTextColor = _a.lightNodeTextColor, lightNodeBorderColor = _a.lightNodeBorderColor;
        var _b = this.props, colorModel = _b.colorModel, valuePropInData = _b.valuePropInData, customD3ColorScale = _b.customD3ColorScale, data = _b.data, childrenPropInData = _b.childrenPropInData;
        var colorDomainFn = getColorDomainFn(getTopParent(node), data, colorModel, childrenPropInData, valuePropInData, customD3ColorScale);
        var backgroundColor;
        switch (colorModel) {
            case ColorModel.Depth:
                backgroundColor = colorDomainFn(node.depth);
                if (node.parent === null) {
                    backgroundColor = colorDomainFn(0);
                }
                break;
            case ColorModel.Value:
                backgroundColor = colorDomainFn(node[valuePropInData]);
                if (node.parent === null) {
                    backgroundColor = colorDomainFn(1);
                }
                break;
            case ColorModel.NumberOfChildren:
                backgroundColor = colorDomainFn(nodeTotalNodes);
                if (node.parent === null) {
                    backgroundColor = colorDomainFn(1);
                }
                break;
            case ColorModel.OneEachChildren: {
                var originalBackgroundColor = colorDomainFn(getTopSubParentId(node));
                if (node.depth > 1) {
                    backgroundColor = scaleLinear()
                        .domain([0, node && node.children ? node.children.length : 0])
                        .interpolate(interpolateHcl)
                        .range(["white", originalBackgroundColor])(getTopSubParentId(node));
                }
                else {
                    backgroundColor = originalBackgroundColor;
                }
                if (node.parent === null) {
                    backgroundColor = colorDomainFn(0);
                }
            }
        }
        return {
            bgColor: backgroundColor,
            textColor: getHighContrastColorFromString(backgroundColor) === "dark"
                ? darkNodeTextColor
                : lightNodeTextColor,
            borderColor: getHighContrastColorFromString(backgroundColor) === "dark"
                ? darkNodeBorderColor
                : lightNodeBorderColor,
        };
    };
    TreeMap.prototype._zoomTo = function (nodeId) {
        var _this = this;
        var _a = this.state, xScaleFunction = _a.xScaleFunction, yScaleFunction = _a.yScaleFunction, selectedNode = _a.selectedNode;
        var onZoom = this.props.onZoom;
        var currentNode = getTopParent(selectedNode)
            .descendants()
            .filter(function (item) {
            return item.customId.toString() === nodeId.toString();
        })
            .pop();
        if (currentNode) {
            var breadcrumbItems = getTopParent(currentNode)
                .path(currentNode)
                .map(function (_a) {
                var data = _a.data, customId = _a.customId;
                return {
                    text: data["name"],
                    key: customId,
                    onClick: customId !== nodeId ? _this._onBreadcrumbItemClicked : undefined,
                };
            });
            if (onZoom) {
                onZoom(currentNode.depth, nodeId, breadcrumbItems);
            }
            this.setState({
                xScaleFunction: xScaleFunction.domain([currentNode.x0, currentNode.x1]),
                yScaleFunction: yScaleFunction.domain([currentNode.y0, currentNode.y1]),
                selectedNode: currentNode,
                breadcrumbItems: breadcrumbItems,
            });
        }
        else {
            console.warn("No node found for " + nodeId);
        }
    };
    TreeMap.prototype.resetZoom = function () {
        this._zoomTo(0);
    };
    TreeMap.prototype.zoomOut = function () {
        var selectedNode = this.state.selectedNode;
        if (selectedNode &&
            selectedNode.parent &&
            selectedNode.parent.customId !== undefined) {
            this._zoomTo(selectedNode.parent.customId);
        }
    };
    TreeMap.prototype.getZoomLevel = function () {
        var selectedNode = this.state.selectedNode;
        return selectedNode.depth;
    };
    TreeMap.defaultProps = {
        id: "myTreeMap",
        data: null,
        height: 600,
        width: 600,
        valueFormat: ",d",
        disableBreadcrumb: false,
        colorModel: ColorModel.OneEachChildren,
        paddingInner: 4,
        paddingOuter: 4,
        customD3ColorScale: scaleSequential(interpolateSpectral),
        namePropInData: "name",
        linkPropInData: "link",
        valuePropInData: "value",
        childrenPropInData: "children",
        numberOfChildrenPlacement: NumberOfChildrenPlacement.BottomRight,
        darkNodeTextColor: "white",
        darkNodeBorderColor: "white",
        lightNodeTextColor: "black",
        lightNodeBorderColor: "black",
        disableTooltip: false,
        tooltipOffsetX: 0,
        tooltipOffsetY: 0,
        levelsToDisplay: 1,
    };
    return TreeMap;
}(React.Component));
export default TreeMap;
