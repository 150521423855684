import React from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import 'scss/index.scss';
import Router from './Router';
import { PortalDiv } from 'components/Portal';

export default function App() {
  return (
    <>
      <Router />
      <ToastContainer pauseOnFocusLoss={false} />
      <PortalDiv />
    </>
  );
}

