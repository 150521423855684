import { isEqual } from 'lodash';
import React, { useState, useEffect, useLayoutEffect, useMemo, useCallback, useRef } from 'react';
import {
    Card,
    Container, 
    Row, 
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
  } from 'reactstrap';
import { Portalable } from 'components/Portal';
import { getAutomationPotentialByActivity, getActivityCountByUnitAndActivity } from 'lib/metrics';
import { transposeObject } from 'lib/helper';
import KpiCard from 'components/KpiCard';
import DropdownMenu from 'components/DropdownMenu';
import EmployeeCard from './EmployeeCard';
import { FTE_HOURS_PER_MONTH } from 'lib/constants';
import Button from 'components/Button';
import Loading from 'components/Loading';

import { FloatingTooltip, InfoCircle } from 'components/FloatingTooltip';

import { postSearchApi } from 'api/chat';
import { set } from 'lodash';

function listReducer(state , action) {
    /*
    * Updates the state of the array
    * @param {Array} state - the current state of the array
    * @param {Object} action - the action to be performed
    *   action.type: 'overwrite' | 'extend' | 'prepend' | 'replace' | 'remove' | 'update'
    *   action.payload: activity | activities
    * @returns {Array} - the new state of the activities array
    */
    switch (action.type) {
      case 'overwrite':
        return action.payload;
      case 'extend':
        return [...state, ...action.payload];
      case 'prepend':
        return [ ...action.payload, ...state ];
      case 'replace':
        if (state.findIndex(activity => activity.id === action.payload.oldActivity.id) !== -1) {
          return [
            ...state.slice(0, state.findIndex(activity => activity.id === action.payload.oldActivity.id)),
            ...action.payload.newActivities,
            ...state.slice(state.findIndex(activity => activity.id === action.payload.oldActivity.id) + 1)
          ];
        }
        return state;
      case 'remove':
        return state.filter((a) => a !== action.payload);
      case 'update':
        return state.map((a) => (a.id === action.payload.id ? action.payload : a));
      default:
        return state;
    }
  }

const GenAIAgentDesignTab = (props) => {
    const {agentTeam, setAgentTeam, agentActivity, setAgentActivity, employees, time_utilization, addEmployee, updateEmployee, activities, relationships, groupMapping, termFilter, isMaximized} = props;
    const data = props;
    
    const [size, setSize] = useState([0, 0]);
    const [highestImpactedUnit, setHighestImpactedUnit] = useState({ name: '', workEffort: 0 });
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [isGeneratingAgent, setIsGeneratingAgent] = useState(false);
    const [isGeneratingSuggestions, setIsGeneratingSuggestions] = useState(false);
    const [isGeneratingEmployeeSuggestions, setIsGeneratingEmployeeSuggestions] = useState(false);
    const [activeSuggestionTab, setActiveSuggestionTab] = useState(1);
    const [uniqueSetOfFunctions, setUniqueSetOfFunctions] = useState([]);
    const [activitiesForSelectedFunction, setActivitiesForSelectedFunction] = useState([]);
    const [orgKpiData, setOrgKpiData] = useState([]);
    const [teamKpiData, setTeamKpiData] = useState([]);

    // Refs to store previous values
    const prevProp1Ref = useRef();
    const prevProp2Ref = useRef();
    const prevProp3Ref = useRef();
    const prevProp4Ref = useRef();
    const prevProp5Ref = useRef();
    const prevProp6Ref = useRef();
    const prevProp7Ref = useRef();
    const prevProp8Ref = useRef();
    const prevProp9Ref = useRef();
    const prevProp10Ref = useRef();

    const prevState1Ref = useRef();
    const prevState2Ref = useRef();
    const prevState3Ref = useRef();
    const prevState4Ref = useRef();
    const prevState5Ref = useRef();
    const prevState6Ref = useRef();
    const prevState7Ref = useRef();
    const prevState8Ref = useRef();
    const prevState9Ref = useRef();
    const prevState10Ref = useRef();
    
    useEffect(() => {
        // Log current values
        /*console.log('useEffect triggered');
        console.log('agentActivity:', agentActivity, 'prevProp1:', prevProp1Ref.current, 'has changed:', agentActivity !== prevProp1Ref.current);
        console.log('agentTeam:', agentTeam, 'prevProp2:', prevProp2Ref.current, 'has changed:', agentTeam !== prevProp2Ref.current);
        console.log('activities:', activities, 'prevProp3:', prevProp3Ref.current, 'has changed:', activities !== prevProp3Ref.current);
        console.log('agentTeam:', time_utilization, 'prevProp4:', prevProp4Ref.current, 'has changed:', time_utilization !== prevProp4Ref.current);
        console.log('setAgentTeam:', setAgentTeam, 'prevProp5:', prevProp5Ref.current, 'has changed:', setAgentTeam !== prevProp5Ref.current);
        console.log('selectedAgent:', selectedAgent, 'prevProp6:', prevProp6Ref.current, 'has changed:', selectedAgent !== prevProp6Ref.current);
        console.log('highestImpactedUnit:', highestImpactedUnit, 'prevProp7:', prevProp7Ref.current, 'has changed:', highestImpactedUnit !== prevProp7Ref.current);
        console.log('updateEmployee:', updateEmployee, 'prevProp8:', prevProp8Ref.current, 'has changed:', updateEmployee !== prevProp8Ref.current);
        console.log('data.automation_potential_per_activity:', data.automation_potential_per_activity, 'prevProp9:', prevProp9Ref.current, 'has changed:', data.automation_potential_per_activity !== prevProp9Ref.current);
        console.log('isMaximized:', isMaximized, 'prevProp10:', prevProp10Ref.current, 'has changed:', isMaximized !== prevProp10Ref.current);
        
        console.log('state highestImpactedUnit:', highestImpactedUnit, 'prevState1:', prevState1Ref.current, 'has changed:', highestImpactedUnit !== prevState1Ref.current);
        console.log('state selectedAgent:', selectedAgent, 'prevState2:', prevState2Ref.current, 'has changed:', selectedAgent !== prevState2Ref.current);
        console.log('state isGeneratingAgent:', isGeneratingAgent, 'prevState3:', prevState3Ref.current, 'has changed:', isGeneratingAgent !== prevState3Ref.current);
        console.log('state isGeneratingSuggestions:', isGeneratingSuggestions, 'prevState4:', prevState4Ref.current, 'has changed:', isGeneratingSuggestions !== prevState4Ref.current);
        console.log('state isGeneratingEmployeeSuggestions:', isGeneratingEmployeeSuggestions, 'prevState5:', prevState5Ref.current, 'has changed:', isGeneratingEmployeeSuggestions !== prevState5Ref.current);
        console.log('state activeSuggestionTab:', activeSuggestionTab, 'prevState6:', prevState6Ref.current, 'has changed:', activeSuggestionTab !== prevState6Ref.current);
        console.log('state uniqueSetOfFunctions:', uniqueSetOfFunctions, 'prevState7:', prevState7Ref.current, 'has changed:', uniqueSetOfFunctions !== prevState7Ref.current);
        console.log('state activitiesForSelectedFunction:', activitiesForSelectedFunction, 'prevState8:', prevState8Ref.current, 'has changed:', activitiesForSelectedFunction !== prevState8Ref.current);
        console.log('state orgKpiData:', orgKpiData, 'prevState9:', prevState9Ref.current, 'has changed:', orgKpiData !== prevState9Ref.current);
        console.log('state teamKpiData:', teamKpiData, 'prevState10:', prevState10Ref.current, 'has changed:', teamKpiData !== prevState10Ref.current);*/
    
        // Update refs with current values after logging
        prevProp1Ref.current = agentActivity;
        prevProp2Ref.current = agentTeam;
        prevProp3Ref.current = activities;
        prevProp4Ref.current = time_utilization;
        prevProp5Ref.current = setAgentTeam;
        prevProp6Ref.current = selectedAgent;
        prevProp7Ref.current = highestImpactedUnit;
        prevProp8Ref.current = updateEmployee;
        prevProp9Ref.current = data.automation_potential_per_activity;
        prevProp10Ref.current = isMaximized;
        
        prevState1Ref.current = highestImpactedUnit;
        prevState2Ref.current = selectedAgent;
        prevState3Ref.current = isGeneratingAgent;
        prevState4Ref.current = isGeneratingSuggestions;
        prevState5Ref.current = isGeneratingEmployeeSuggestions;
        prevState6Ref.current = activeSuggestionTab;
        prevState7Ref.current = uniqueSetOfFunctions;
        prevState8Ref.current = activitiesForSelectedFunction;
        prevState9Ref.current = orgKpiData;
        prevState10Ref.current = teamKpiData;
            
      }, [agentActivity, agentTeam, activities, time_utilization, setAgentTeam, selectedAgent, highestImpactedUnit, updateEmployee, data.automation_potential_per_activity, 
        isMaximized, uniqueSetOfFunctions, activitiesForSelectedFunction, orgKpiData, teamKpiData, isGeneratingAgent, isGeneratingSuggestions, isGeneratingEmployeeSuggestions, activeSuggestionTab]);

    useEffect(() => {
        console.log('Selected Agent updated:', selectedAgent);
    }, [selectedAgent]);

    useEffect(() => {
        // This should not cause a loop
        console.log('Looping');
    }, [selectedAgent, agentActivity, agentTeam, activities, time_utilization, employees]);
    
        
    const calcTotalWorkEffort = useCallback((teamMembers) => {
        const timeUtilizationByActivity = transposeObject(time_utilization);
        let total_work_effort = 0;
        let activityId = agentActivity.id;
        if (!(activityId in timeUtilizationByActivity)) {
            activityId = agentActivity.groupId;
        }

        const timeUtilizationForSingleActivity = timeUtilizationByActivity[activityId];
        if (timeUtilizationForSingleActivity !== undefined) {
            total_work_effort = Object.entries(timeUtilizationForSingleActivity).reduce((acc, [key, value]) => {
                if (teamMembers.includes(String(key))) {
                    let numericValue = parseFloat(value);
                    if (!isNaN(numericValue)) {
                        const employee = employees.find(node => String(node.id) === String(key));
                        if (employee && employee.fte) {
                            numericValue = numericValue * employee.fte;  // If employee does not have FTE we assume FTE 1
                        }
                        return acc + (numericValue / 100);
                    }
                }
                return acc;
            }, 0);

            total_work_effort = total_work_effort * FTE_HOURS_PER_MONTH;
        }
        return total_work_effort;
    }, [time_utilization, agentActivity, employees]);

    const calcKpiData = useCallback((agentActivity, agentTeam, activities, nodes, time_utilization) => {
        if (!agentActivity || activities.length === 0 || nodes.length === 0 || !time_utilization) {
            return [];
        }
        const timeUtilizationByActivity = transposeObject(time_utilization);  // TODO: costly computation. Eliminate once we phase out functionId and groupId entirely
        const teamMembers = filterEmployees(agentTeam, nodes, true, false).map(employee => String(employee.id));

        let activityId = agentActivity.id;
        if (!(activityId in timeUtilizationByActivity)) {
            activityId = agentActivity.groupId;
        }

        let total_work_effort = calcTotalWorkEffort(teamMembers);

        const workFreedUp = total_work_effort * (parseFloat(agentActivity.automation_potential) / 100) || 0;
        const agentCostSavings = workFreedUp * 60;  // TODO: Update with actual cost per hour
        if (selectedAgent && agentTeam.id !== '' && selectedAgent.genAIAgentData.activity === activityId && parseFloat(selectedAgent.genAIAgentData.workFreedUp) !== parseFloat(workFreedUp)) {
            console.log('Updating workFreedUp for agent:', selectedAgent, 'from:', selectedAgent.genAIAgentData.workFreedUp, 'to:', workFreedUp);
            updateEmployee({ ...selectedAgent, genAIAgentData: { ...selectedAgent.genAIAgentData, workFreedUp: workFreedUp } });
        }

        return [
            { title: 'Total work', rawValue: total_work_effort, value: total_work_effort.toLocaleString('no-NO'), icon: '🔧', unit: 'hrs/mth' },
            { title: 'Automation potential', value: agentActivity.automation_potential || 0, icon: '✨', unit: '' },
            { title: 'work freed up', rawValue: workFreedUp, value: workFreedUp.toLocaleString('no-NO') || 0, icon: '⏳', unit: 'hrs/mth' },
            { title: 'AI agent salary cost saving', rawValue: (agentCostSavings || 0), value: (agentCostSavings || 0).toLocaleString('no-NO'), icon: '💵', unit: '€/mth' },
        ];
    }, [calcTotalWorkEffort, selectedAgent, updateEmployee]);

    useEffect(() => {
        if (activities.length === 0) {
            return;
        }

        const uniqueFunctionNames = [...new Set(activities.map(activity => activity.function))];
        if (uniqueSetOfFunctions.length !== uniqueFunctionNames.length && uniqueFunctionNames.length !== 0) {
            console.log('Updating unique functions');
            const newUniqueFunctions = activities.filter((activity, index, self) => self.findIndex(t => t.functionLabel === activity.functionLabel && t.function === activity.function) === index).map(activity => ({ label: activity.function, id: activity.id }));
            if (!isEqual(uniqueSetOfFunctions, newUniqueFunctions)) {
                console.log('Setting unique functions:', newUniqueFunctions);
                setUniqueSetOfFunctions(Array.from(newUniqueFunctions));
            }
        }
    }, [activities, uniqueSetOfFunctions]);
    
    useEffect(() => {
        if ( employees.length === 0 || activities.length === 0 ) {
            return;
        }
        let activity = agentActivity;
        
        if (!activity && uniqueSetOfFunctions.length > 0) {
            console.log('Setting activity to first unique function');
            activity = activities.find(activity => activity.id === uniqueSetOfFunctions[0].id);
            setAgentActivity(activity);
        }

        if (activity) {
            const comparativeActivity = activities.find(a => a.id === activity.id);
            if (!isEqual(comparativeActivity, agentActivity)) { 
                console.log('Setting agent activity to:', comparativeActivity);
                setAgentActivity(comparativeActivity);
                activity = comparativeActivity;
            }
            const { highestAutomationPotentialActivity, automation_potential_per_activity } = getAutomationPotentialByActivity(activities, time_utilization, employees);
            const _highestImpactedUnit = getHighestImpactedUnit(activity, automation_potential_per_activity, groupMapping);
            if (!isEqual(highestImpactedUnit, _highestImpactedUnit)) {
                console.log('Setting highest impacted unit:', _highestImpactedUnit);
                setHighestImpactedUnit(_highestImpactedUnit);
            }
        }

        const newActivitiesForSelectedFunction = agentActivity ? activities.filter(a => a.function === agentActivity.function).map(a => ({ label: a.activity, id: a.id })) : [];
        if (!isEqual(activitiesForSelectedFunction, newActivitiesForSelectedFunction)) {
            console.log('Setting activities for selected function:', newActivitiesForSelectedFunction);
            setActivitiesForSelectedFunction(newActivitiesForSelectedFunction);
        }

        const allTeams = {'name': 'Whole organization dummy team', 'id': ''};
        const newOrgKpiData = activity ? calcKpiData(activity, allTeams, activities, employees, time_utilization) : []
        if (!isEqual(orgKpiData, newOrgKpiData)) {
            console.log('Setting org KPI data:', newOrgKpiData);
            setOrgKpiData(newOrgKpiData);
        }

        const newTeamKpiData = activity ? calcKpiData(activity, agentTeam, activities, employees, time_utilization) : [];
        if (!isEqual(teamKpiData, newTeamKpiData)) {
            console.log('Setting team KPI data:', newTeamKpiData);
            setTeamKpiData(newTeamKpiData);
        }
    }, [agentActivity, agentTeam, selectedAgent, activities, time_utilization, employees, updateEmployee, groupMapping, setAgentActivity, highestImpactedUnit, 
        setHighestImpactedUnit, activitiesForSelectedFunction, setActivitiesForSelectedFunction, uniqueSetOfFunctions, calcKpiData, orgKpiData, teamKpiData]);

    useEffect(() => {
        if ( employees.length === 0 ) {
            return;
        }

        if (!agentTeam) {
            setAgentTeam(groupMapping[0]);
            return;
        }

        if (!agentActivity) {
            return;
        }

        const teamAgents = filterEmployees(agentTeam, employees, false, true).map(employee => employee.id);
        const teamEmployees = filterEmployees(agentTeam, employees, true, false);
        let agentFound = false;
        for (const agentId of teamAgents) {
            const agent = employees.find(employee => String(employee.id) === String(agentId));
            
            if (agent.group === agentTeam.id && [agentActivity.id, agentActivity.groupId].includes(agent['genAIAgentData'].activity)) {
                let activityId = agent.genAIAgentData.activity;

                let timeUtilizationSum = 0;
                teamEmployees.forEach(employee => {
                    const employeeTimeUtilization = employee?.time_utilization?.[activityId] ?? null;
                    if (employeeTimeUtilization) {
                        timeUtilizationSum += parseFloat(employeeTimeUtilization) * (employee.fte || 0);
                    }
                });

                const workFreedUp = (timeUtilizationSum / 100) * FTE_HOURS_PER_MONTH * (parseFloat(agentActivity.automation_potential) / 100) || 0;
                console.log('workFreedUp:', workFreedUp, 'agent.genAIAgentData.workFreedUp:', agent.genAIAgentData.workFreedUp);
                console.log(parseFloat(agent.genAIAgentData.workFreedUp) !== parseFloat(workFreedUp));
                console.log(parseFloat(agent.genAIAgentData.workFreedUp), parseFloat(workFreedUp));
                if (parseFloat(agent.genAIAgentData.workFreedUp) !== parseFloat(workFreedUp)) {
                    updateEmployee(prevAgent => ({
                        ...prevAgent,
                        genAIAgentData: {
                            ...prevAgent.genAIAgentData,
                            workFreedUp: workFreedUp
                        }
                    }));
                }
                

                if (selectedAgent?.id !== agent.id) {
                    setSelectedAgent(agent);
                }
                agentFound = true;
                break;
            }
        }

        if (!agentFound) {
            setSelectedAgent(undefined);
        }

    }, [agentActivity, agentTeam, setAgentTeam, selectedAgent, employees, updateEmployee, groupMapping, highestImpactedUnit, activities, data.automation_potential_per_activity]);
    
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight - (isMaximized ? 150 : 350)]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [isMaximized]);

    const createAgent = () => {
        setIsGeneratingAgent(true);

        if (!agentTeam || !agentActivity) {
            console.log('No agent team or activity selected');
            return;
        }
        const group = groupMapping.find(group => group.id === agentTeam.id);
        const agentName = agentActivity.function + ' ' + agentActivity.name + ' ' + agentTeam.name + ' GenAI Agent';
        //const existingAgent = employees.find(employee => employee.name === agentName);
        const existingAgent = checkIfAgentExists();
        if (existingAgent) {
            setSelectedAgent(existingAgent);
            return;
        }

        
        if (!group) {
            console.log('Could not find group for agentTeam:', agentTeam);
            return;
        }

        const team_employees = filterEmployees(agentTeam, employees, true, false)
        let activityId = agentActivity.id;
        let activityFound = false;

        // First loop to check for agentActivity.id
        for (const employee of team_employees) {
            if (employee.time_utilization && employee.time_utilization[agentActivity.id]) {
                activityFound = true;
                break;
            }
        }

        // If agentActivity.id is not found, check for agentActivity.groupId
        if (!activityFound) {
            for (const employee of team_employees) {
                if (employee.time_utilization && employee.time_utilization[agentActivity.groupId]) {
                    activityId = agentActivity.groupId;
                    activityFound = true;
                    break;
                }
            }
        }

        // TODO: Create a relationship to each employee with this activity
        const agentID = Math.floor(1000000000 + Math.random() * 9000000000).toString();

        const relationships = employees.filter(employee => employee.time_utilization && employee.time_utilization[activityId])
                                        .map(employee => ({
                                        source: employee.id, 
                                        target: agentID, 
                                        frequency: 10, 
                                        duration: 1, 
                                        permanence: 3, 
                                        relationship: [agentActivity.id.toString()], 
                                        importance: 3, 
                                        value: 3, 
                                        value_new: 10, 
                                        num_fte_involved: 1, 
                                        reported_by: ['AI']
                                    }));



        const agent = {
            //id: `${Math.floor(1000000000 + Math.random() * 9000000000)}`,
            id: agentID,
            employee_number: Math.floor(1000000000 + Math.random() * 9000000000).toString(),
            reporting_to: team_employees[0].employee_number,
            title: 'Generative AI Agent',
            role: 'Generative AI Agent',
            department: group.name,
            division: '',
            group: group.id,
            groupName: group.name,
            email_address: agentName.replace(/ /g, '.') + '@agent.ai',
            employee_type: 'GenAI Agent',
            genAIAgentData: {activity: activityId,
                            automationSuggestions: [],
                            EmployeeActivitySuggestions: [],
                            workFreedUp: teamKpiData[2]?.rawValue ?? 0,
                            },
            //activityUtilization: {[activityId.id]: teamUtilization + '%'},
            //time_utilization: {[activityId.id]: teamUtilization + '%'},
            activityUtilization: {[activityId]: 0 + '%'},
            time_utilization: {[activityId]: 0 + '%'},
            name: agentName,
            first_name: agentActivity.function + ' ' + agentActivity.name,
            last_name: group.name + ' GenAI Agent',
            fte: 0,
            responseStatus: 'Complete',
            status: 'Active',
        }

        addEmployee(agent, relationships);
        setSelectedAgent(agent);
        console.log('Added agent:', agent);
        setIsGeneratingAgent(false);
    };

    const generateAutomationSuggestions = async () => {
        setIsGeneratingSuggestions(true);
    
        const apiPayload = {
            'prompt_template': 'generate_gen_ai_task_suggestions',
            'design_id': data.data.id,
            'selected_agent': selectedAgent,
            'team': agentTeam,
            'employees': employees,
            'activity': agentActivity,
            'functionName': agentActivity['function_label'],
            'activityName': agentActivity['activity_label'],
            'automationPotential': agentActivity.automationPotential,
            'activityDescription': agentActivity['description'],
            'activities': activities,
            'activityList': activities.filter(activity => activity.groupId === agentActivity.groupId).map(activity => activity.activity),
            'existing_gen_ai_agent_task_suggestions': selectedAgent.genAIAgentData.automationSuggestions,
        };
        console.log('API payload:', apiPayload);
    
        try {
          const apiResponse = await postSearchApi(apiPayload);
    
              try {
                  const apiMessage = apiResponse.message ?? []
                  setSelectedAgent(prevAgent => ({
                    ...prevAgent,
                    'genAIAgentData': {...prevAgent.genAIAgentData, 'automationSuggestions': [...(prevAgent.genAIAgentData.automationSuggestions ??[]), ...apiMessage]}
                  }))
                  /*updateEmployee(({
                    ...selectedAgent,
                    automationSuggestions: [...(selectedAgent.automationSuggestions ?? []), ...apiMessage]
                  }))*/
              } catch (error) {
                  console.error('Error parsing API response:', apiResponse.message);
                  console.error('Errr message: ', error);
              }
        } catch (error) {
            console.error('Error sending chat message:', error);
        }
        setIsGeneratingSuggestions(false);
    };

    const getEmployeeActivitySuggestions = async () => {
        setIsGeneratingEmployeeSuggestions(true);
    
        const apiPayload = {
            'prompt_template': 'generate_employee_activity_suggestions',
            'design_id': data.data.id,
            'selected_agent': selectedAgent,
            'team': agentTeam,
            'employees': employees,
            'activity': agentActivity,
            'functionName': agentActivity['function_label'],
            'activityName': agentActivity['activity_label'],
            'automationPotential': agentActivity.automationPotential,
            'activityDescription': agentActivity['description'],
            'activities': activities,
            'activityList': activities.filter(activity => activity.groupId === agentActivity.groupId).map(activity => activity.activity),
            'existing_automation_suggestions': selectedAgent.genAIAgentData.automationSuggestions,
            'existing_employee_activity_suggestions': selectedAgent.genAIAgentData.EmployeeActivitySuggestions,
        };
        console.log('API payload:', apiPayload);
    
        try {
          const apiResponse = await postSearchApi(apiPayload);
    
          if (selectedAgent.genAIAgentData.EmployeeActivitySuggestions) {
              try {
                  const existingSuggestions = selectedAgent.genAIAgentData.EmployeeActivitySuggestions;
                  const newSuggestions = apiResponse.message;
                  const updatedSuggestions = [ ...existingSuggestions, ...newSuggestions ];
                  setSelectedAgent(prevAgent => ({
                    ...prevAgent,
                    'genAIAgentData': {...prevAgent.genAIAgentData, 'EmployeeActivitySuggestions': updatedSuggestions}
                  }))
                  setIsGeneratingEmployeeSuggestions(false);
              } catch (error) {
                  console.error('Error parsing API response:', apiResponse.message);
                  console.error('Errr message: ', error);
                  setIsGeneratingEmployeeSuggestions(false);
              }
          } else {
            setSelectedAgent(prevAgent => ({
                ...prevAgent,
                'genAIAgentData': {...prevAgent.genAIAgentData, 'EmployeeActivitySuggestions': apiResponse.message}
              }))
              setIsGeneratingEmployeeSuggestions(false);
          }
        } catch (error) {
            console.error('Error generating suggestion:', error);
            setIsGeneratingEmployeeSuggestions(false);
        }
    };

    const deleteAutomationSuggestion = (indexToDelete) => {
        setTimeout(() => {
            const updatedSuggestions = selectedAgent.genAIAgentData.automationSuggestions.filter((_, index) => index !== indexToDelete);
            selectedAgent.genAIAgentData.automationSuggestions = updatedSuggestions;
            updateEmployee(selectedAgent);
        }, 500);
    }; 

    const deleteEmployeeActivitySuggestion = (indexToDelete) => {
        setTimeout(() => {
          const updatedSuggestions = selectedAgent.genAIAgentData.EmployeeActivitySuggestions.filter((_, index) => index !== indexToDelete);
          selectedAgent.genAIAgentData.EmployeeActivitySuggestions = updatedSuggestions;
          updateEmployee(selectedAgent);
        }, 500);
    }; 

    const implementationComplexityColor = (complexity) => {
        let color;
        if (complexity === 'Low') {
          color = 'rgba(52,227,159, 0.7)';
        } else if (complexity === 'Medium') {
          color = 'rgba(61,173,199, 0.7)';
        } else if (complexity === 'High') {
          color = 'rgba(73,99,254, 0.7)';
        } else {
          color = 'rgba(255, 255, 255, 0.7)';
        }
        return color;
      }

      const RenderAgentTaskRecommendationCards = () => {
        const suggestions = Array.isArray(selectedAgent?.genAIAgentData.automationSuggestions) ? selectedAgent.genAIAgentData.automationSuggestions : [];
    
        return (
            <>
                <div className="recommendations-list col-md-6">
                    {suggestions.length > 0 ? (
                        suggestions.map((item, index) => (
                            <div key={index} className="recommendation-item">
                                <button
                                    className="delete-suggestion-button"
                                    onClick={() => deleteAutomationSuggestion(index, suggestions)}
                                >
                                    x
                                </button>
                                <div className="description">
                                    <span className="icon" title={item.icon}>{item.icon}</span>
                                    <FloatingTooltip text={item.implementation_plan}>
                                        <span>
                                            <div className='kpi-label-title'>{item.title}<InfoCircle backgroundColor='white' color='black' borderColor='black' /></div>
                                            <p>{item.description}</p>
                                        </span>
                                    </FloatingTooltip>
                                </div>
                                <div className="recommendation-details">
                                    <div className="left">
                                        <FloatingTooltip text={item.impact_explanation}>
                                            <div className='kpi'>
                                                <div className='kpi-label'>
                                                    Expected impact:
                                                    <InfoCircle backgroundColor='white' color='black' borderColor='black' />
                                                </div>
                                                {item.impact}
                                            </div>
                                        </FloatingTooltip>
                                    </div>
                                    <div className="right">
                                        <FloatingTooltip text={item.risk_explanation}>
                                            <div className='kpi'>
                                                <div className='kpi-label'>
                                                    Risk:
                                                    <InfoCircle backgroundColor='white' color='black' borderColor='black' />
                                                </div>
                                                {item.risk}
                                            </div>
                                        </FloatingTooltip>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Generate your first suggestion for what task the agent can do for the team.</p>
                    )}
                </div>
                {isGeneratingSuggestions ? (
                    <div className="loading-spin">
                        <Loading text="Considering tasks the agent could learn..." />
                    </div>
                ) : (
                    <div>
                        <button onClick={() => generateAutomationSuggestions()} className="button ms-auto mt-4">
                            {selectedAgent?.genAIAgentData.EmployeeActivitySuggestions ? 'Generate more task suggestions' : 'Generate task suggestion'}
                            <span className="material-symbols-outlined">neurology</span>
                        </button>
                    </div>
                )}
            </>
        );
    };
    

    const RenderEmployeeRecommendationCards = () => {
        const suggestions = Array.isArray(selectedAgent?.genAIAgentData.EmployeeActivitySuggestions) ? selectedAgent.genAIAgentData.EmployeeActivitySuggestions : [];

        return (
            <>
                <div className="recommendations-list col-md-6">
                    {suggestions.length > 0 ? (
                        selectedAgent.genAIAgentData.EmployeeActivitySuggestions.map((item, index) => (
                        <div key={index} className="recommendation-item">
                            <button
                            className="delete-suggestion-button"
                            onClick={() => deleteEmployeeActivitySuggestion(index)}
                            >
                            x
                            </button>
                            <div className='description'>
                            <span className="icon" title={item.icon}>{item.icon}</span>
                            <FloatingTooltip text={item.implementation_plan}>
                                <span>
                                <div className='kpi-label-title'>{item.title}<InfoCircle backgroundColor='white' color='black' borderColor='black' /></div>
                                <p>{item.description}</p>
                                </span>
                            </FloatingTooltip>
                            </div>
                            <div className="recommendation-details">
                            <div className="left">
                                <FloatingTooltip text={item.impact_explanation}>
                                <div className='kpi'>
                                    <div className='kpi-label'>
                                        Expected impact: 
                                    <InfoCircle backgroundColor='white' color='black' borderColor='black' /></div>
                                    {item.impact}
                                </div>
                                </FloatingTooltip>
                            </div>
                            <div className="right">
                                <FloatingTooltip text={item.activity_scope_explanation}>
                                <div className='kpi'>
                                    <div className='kpi-label'>
                                    Risk:
                                    <InfoCircle backgroundColor='white' color='black' borderColor='black' />
                                    </div>
                                    <div className='complexity' style={{ backgroundColor: implementationComplexityColor(item.risk), boxShadow: `0px 0px 10px ${implementationComplexityColor(item.risk)}` }}>
                                    {item.activity_scope}
                                    </div>
                                </div>
                                </FloatingTooltip>
                            </div>
                        </div>
                        </div>
                        ))
                    ) : (
                        <p>Generate your first suggestion for what activities the team could take on given the extra time they get from the agent.</p>
                    )}
                </div>
                {isGeneratingEmployeeSuggestions ? (
                <div className="loading-spin">
                    <Loading text="Considering tasks the agent could learn..." />
                </div>
                ) : (
                <div>
                    <button onClick={() => getEmployeeActivitySuggestions()} className="button ms-auto mt-4" >
                        {selectedAgent?.genAIAgentData.EmployeeActivitySuggestions ? 'Generate more task suggestions' : 'Generate task suggestion'}
                        <span className="material-symbols-outlined">neurology</span>
                    </button>
                </div>
                )}
            </>
        );
    };

    const filterEmployees = (agentTeam, employees, return_employees=true, return_agents=false) => {
        if (agentTeam.id === '' ) {
            return employees;
        }

        if (return_agents && !return_employees) {
            return employees.filter(employee => employee.group === agentTeam.id && employee.employee_type === 'GenAI Agent');
        } else if (!return_agents && return_employees) {
            return employees.filter(employee => employee.group === agentTeam.id && employee.employee_type !== 'GenAI Agent');
        } else if (!return_agents && !return_employees) {
            return []
        }
        return employees.filter(employee => employee.group === agentTeam.id);
    };

    const onClickAgent = (employee) => {
        if (employee.employee_type !== 'GenAI Agent') {
            return;
        }
        console.log('Selected agent:', employee);
        let employeeActivity = activities.find(activity => activity.id === employee.genAIAgentData.activity);
        if (!employeeActivity) {
            employeeActivity = activities.find(activity => activity.function_id === employee.genAIAgentData.activity);
        }

        if (!employeeActivity) {
            return;
        }
        

        updateSelectedActivity({ label: employeeActivity.function, id: employeeActivity.id });
    };

    const compareTimeUtilization = (a, b) => {
        const activityField = (a?.time_utilization?.[agentActivity.id] !== undefined) || 
                          (b?.time_utilization?.[agentActivity.id] !== undefined) 
                          ? agentActivity.id 
                          : agentActivity.function_id;
        const aTimeUtilization = parseFloat(a.time_utilization?.[activityField] ?? 0) * a.fte;
        const bTimeUtilization = parseFloat(b.time_utilization?.[activityField] ?? 0) * b.fte;
        return bTimeUtilization - aTimeUtilization;
    };

    const renderTeamMemberCards = (employee_list) => {
        if (!employee_list) {
            return null;
        }
        if (employee_list.length === 0) {
            return (
            <div>
                <h3>No team members found</h3>
            </div>
            );
        }
        if (Object.keys(employee_list).length === 0) {
            return null;
        }

        const agents = employee_list.filter(employee => employee.employee_type === 'GenAI Agent');
        const teamMembers = employee_list.filter(employee => employee.employee_type !== 'GenAI Agent');

        const sorted_employee_list = [...agents, ...teamMembers.sort(compareTimeUtilization)];
        
        return sorted_employee_list.map(employee => (
            <Col key={employee.id} sm={12} md={6} lg={4} xl={2} >
                <EmployeeCard 
                    employee={employee} 
                    selectedAgent={selectedAgent}
                    activity={agentActivity}
                    fte_hour_month={FTE_HOURS_PER_MONTH}
                    onClick={onClickAgent}
                />
            </Col>
        ));
    };

    const setSelectedAgentFromActivity = (activity, team) => {
        if (!activity || !team) {
            return;
        }
        if (activity === agentActivity && team === agentTeam) {
            return;
        }
        const newAgentTeam = team.id || team.groupId;
        const activityAgent = employees.find(employee => employee.activity === activity.id && employee.group === newAgentTeam && employee.employee_type === 'GenAI Agent');
        if (activityAgent) {
            setSelectedAgent(activityAgent);
        } else {
            setSelectedAgent(undefined);
        }
    };

    const updateSelectedActivity = (item = agentActivity) => {
        let selected_activity = activities.find(activity => activity.id === item.id);
        if (selected_activity !== undefined) {
            setAgentActivity(selected_activity);
        } else {
            selected_activity = activities.find(activity => activity.function === item.label);
            setAgentActivity(selected_activity);
        }
    }

    const updateSelectedTeam = (item) => {
        const selected_team = groupMapping.find(group => group.id === item.id);
        setAgentTeam(selected_team);
    }

    const getHighestImpactedUnit = (activity, automation_potential_per_activity, unitNames) => {
        if (!activity || !automation_potential_per_activity || !unitNames) {
            return { name: '', workEffort: 0 };
        }
        const automation_potential_activity = automation_potential_per_activity.find(entry => entry.id === activity.id)
        if (!automation_potential_activity || !automation_potential_activity.workEffortByUnit) {
            return { name: '', workEffort: 0 };
        }
        const workEffortByUnit = Object.values(automation_potential_activity.workEffortByUnit);
        if (!workEffortByUnit || !Array.isArray(workEffortByUnit) || workEffortByUnit.length === 0) {
            return { name: '', workEffort: 0 };
        }
        
        
        workEffortByUnit.sort((a, b) => b.workEffort - a.workEffort); // Sort Desc
        const unit = unitNames?.find(unit => unit.id === workEffortByUnit[0].name);
        const unitName = unit ? unit.name : '';
        const highestWorkEffortUnit = { id: unit.id, name: unitName, workEffort: workEffortByUnit[0]?.workEffort || 0 };
        
        return highestWorkEffortUnit;
    };

    const checkIfAgentExists = () => {
        const agentName = agentActivity.function + ' ' + agentActivity.name + ' GenAI Agent';
        const agents = employees.filter(employee => employee.name === agentName)
        return agents.find(agent => agent.group === agentTeam.id) ? true : false;
    }

    return (
        <div className="tab automation-tab">
            <div className='automation-tab-header'>
                <h1>
                    Generative AI Agents
                </h1>
            </div>
            {( activities.length === 0 ? (
                <div>
                    <h3>
                        No activities found
                    </h3>
                </div>
            ) : ( employees.length === 0) ? (
                    <h3>
                        No employees found
                    </h3>
                ) : (
                <>
                <div className='full-width-row'>
                    <div className='input-with-label'>
                        <h3>Function:</h3>
                        {agentActivity && (
                        <DropdownMenu ClassName='automation-tab-title-dropdown'
                            key={agentActivity.id}
                            title={agentActivity.function}
                            items={uniqueSetOfFunctions}
                            onChange={updateSelectedActivity}
                        />
                        )}
                    </div>
                    <div className='input-with-label'>
                        <h3>Activity:</h3>
                        {agentActivity && (
                        <DropdownMenu className='automation-tab-title-dropdown'
                            key={agentActivity.id}
                            title={agentActivity.activity}
                            items={activitiesForSelectedFunction}
                            onChange={updateSelectedActivity}
                        />
                        )}
                    </div>
                </div>
                { agentActivity ? (
                    <>
                    <Container fluid>
                        <h3>Organization wide potential</h3>
                        <div className='container-wide-row'>
                            <KpiCard kpiData={orgKpiData} />
                        </div>
                        <div className='container-wide-row'>
                            <div className='input-with-label center' >
                                <h3>Team:</h3>
                                {agentTeam && (
                                <DropdownMenu className='automation-tab-title-dropdown'
                                    key={agentTeam.id}
                                    title={agentTeam.name}
                                    items={groupMapping}
                                    onChange={updateSelectedTeam}
                                />
                                )}
                            </div>
                        </div>
                        <div className='container-wide-row'>
                            <Col md={9}>
                                <Container fluid>
                                    
                                    <div className='container-wide-row employee-card-row-wrapper'>
                                        {renderTeamMemberCards(filterEmployees(agentTeam, employees, true, true))}
                                    </div>
                                </Container>
                            </Col>
                            <Col md={3} className="metric-card metrics-column" >
                                <div className="metric-card-header">
                                    <h3>Case for training a GenAI Agent to perform</h3>
                                    <h3 className='bold'>{agentActivity.function}</h3>
                                    <h3 className='bold'>{agentActivity.name}</h3>
                                    <h3> tasks for</h3>
                                    <h3 className='bold'>{agentTeam.name}</h3>    
                                </div>
                                
                                <div className="metric-card-body">
                                    <h3><span className="metric-icon material-symbols-outlined">group</span> Team members: {filterEmployees(agentTeam, employees, true, false).length}</h3>
                                    <h3><span className="metric-icon material-symbols-outlined">smart_toy</span> Team agents: {filterEmployees(agentTeam, employees, false, true).length}</h3>
                                    <h3>🔧 {teamKpiData[0]?.title}: {teamKpiData[0]?.value} {teamKpiData[0]?.unit}</h3>
                                    <h3>✨ {teamKpiData[1]?.title}: {teamKpiData[1]?.value || '?'}</h3>
                                    <h3>⏳ {teamKpiData[2]?.title}: {((teamKpiData[2]?.value) || 0)} {teamKpiData[2]?.unit}</h3>
                                    <h3>💵 {teamKpiData[3]?.title}: {teamKpiData[3]?.value || 0} {teamKpiData[3]?.unit}</h3>
                                    
                                    
                                    
                                    <div className="metric-card-footer">
                                        {(isGeneratingAgent ? (
                                            <div className="loading-spin">
                                                <Loading text="Creating generative AI Agent..." />
                                            </div>
                                            ) : (
                                                checkIfAgentExists() ? (
                                                    <button onClick={() => setSelectedAgentFromActivity(agentActivity, agentTeam)} className="button ms-auto mt-4" >
                                                        Select existing agent
                                                        <span className="material-symbols-outlined">neurology</span>
                                                    </button>
                                                ) : (
                                                    <button onClick={() => createAgent()} className="button ms-auto mt-4">
                                                        Create generative AI Agent
                                                        <span className="material-symbols-outlined">neurology</span>
                                                    </button>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Container>
                    </>
                ) : (
                    <div>Select an activity and team...</div>
                )}
                <h3>AI Agent suggestions</h3>
                { selectedAgent ? (
                <div className='container-wide-row'>
                    <Portalable portal={isMaximized} className="gen-ai-tabs">
                        <Nav tabs className="design-navs">
                            <NavItem>
                                <NavLink active={activeSuggestionTab === 1}
                                    onClick={() => setActiveSuggestionTab(1)}
                                    className="design-nav">
                                    Gen AI Agent
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeSuggestionTab === 2}
                                    onClick={() => setActiveSuggestionTab(2)}
                                    className="design-nav">
                                    Team
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeSuggestionTab}>
                            <TabPane tabId={1}>
                            {1 === activeSuggestionTab && 
                                <div className="row center_content">
                                    <RenderAgentTaskRecommendationCards />
                                </div>
                            }
                            </TabPane>
                            <TabPane tabId={2}>
                            {2 === activeSuggestionTab && 
                                <div className="row center_content">
                                <RenderEmployeeRecommendationCards />
                                </div>}
                            </TabPane>
                        </TabContent>
                    </Portalable>
                </div>
                ) : (
                    <div>Select an agent to create suggestions</div>
                                    
                )}
                </>
            ))}
        </div>
    );
}
  
export default GenAIAgentDesignTab;
