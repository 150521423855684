import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import _ from 'lib/lodashFunctions';

import CSVReader from 'components/CSVReader';
import AgTable from 'components/AgTable';
import Button from 'components/Button';
import { setSurvey } from 'redux/actions/survey';

const fullColumns = [
  { accessor: 'first_name', Header: 'First Name' },
  { accessor: 'last_name', Header: 'Last Name' },
  { accessor: 'title', Header: 'Job Title' },
  { accessor: 'fte', Header: 'FTE'},
  { accessor: 'department', Header: 'Department' },
  { accessor: 'location', Header: 'Location' },
  { accessor: 'email', Header: 'Email' },
];
const simpleColumns = [
  { accessor: 'first_name', Header: 'First Name' },
  { accessor: 'last_name', Header: 'Last Name' },
  { accessor: 'title', Header: 'Job Title' },
  { accessor: 'fte', Header: 'FTE'},
  { accessor: 'department', Header: 'Department' },
  { accessor: 'email', Header: 'Email' },
];
const SurveyParticipantsCreate = (props) => {
  const { isSimple = false } = props;
  const defaultColumns = isSimple ? simpleColumns : fullColumns;
  const onDataLoad = (data) => {
    let headers = [...defaultColumns];
    if (
      data[0][0].toLowerCase().includes('first') &&
      data[0][1].toLowerCase().includes('last')
    ) {
      if (!isSimple) {
        headers = headers.concat(
          data[0]
            .slice(defaultColumns.length, data[0].length)
            .map((u) => ({
              accessor: u.toLowerCase(),
              Header: u,
              custom: true,
            })),
        );
      }
      data = data.slice(1, data.length);
    } else {
      if (!isSimple) {
        headers = headers.concat(
          _.range(data[0].length - defaultColumns.length).map((idx) => ({
            accessor: `custom${idx + 1}`,
            Header: `Custom ${idx + 1}`,
            custom: true,
          })),
        );
      }
    }

    let error = data.some(
      (row) =>
        validation('first_name', row[0]) ||
        validation('fte', row[3]) ||
        validation('department', row[4]) ||
        validation('email', row[isSimple ? 5 : 6]),
    );
    props.setParticipants(data, headers, error);
  };

  const onUpdate = (data) => {
    const columns = props.participants_columns || defaultColumns;
    let dataList = data.map((row) => columns.map((c) => row[c.accessor]));
    let error = data.some(
      (row) =>
        validation('first_name', row.first_name) ||
        validation('fte', row.fte) ||
        validation('department', row.department) ||
        validation('email', row.email),
    );
    props.setParticipants(dataList, null, error);
  };

  const onDataError = (err) => {
    console.log(err);
  };

  const validation = (col, value) => {
    if (
      (col === 'first_name' || col === 'department' || col === 'email') &&
      !value
    ) {
      return 'This field can\'t be empty';
    }
    if ( col === 'fte') {
      const numValue = Number(value);
      const isValid = !isNaN(numValue) && numValue > 0;
      if ( !isValid ){
        return 'FTE needs to be a positive number';
      }
      
    }
    if (col === 'email') {
      let email_re =
        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        );
      if (!email_re) return 'Invalid email address.';

      if (getData().map((d) => d.email).filter((v) => v === value).length > 1)
        return 'Duplicated email.';
    }
    return null;
  };

  const setColumns = (columns) => {
    props.setParticipantsColumns(columns);
  };

  const getData = () => {
    const { participants, participants_columns } = props;
    const columns = participants_columns || defaultColumns;

    if (!Array.isArray(participants)) return [];

    return participants.map((row) =>
      Object.fromEntries(columns.map((col, i) => [col.accessor, row[i]])),
    );
  };

  let data = getData();
  let error = props.error;
  const columns = props.participants_columns || defaultColumns;

  return (
    <div className="card__content-wrapper">
      <div className="card__header row align-items-center">
        <div className="col-6">
          <h3>Who will be participating in the survey?</h3>
        </div>
        <div className="col-6 text-end">
          <Button
            className="button"
            text="Add New"
            onClick={() => onUpdate([{}, ...data])}
          />
          <CSVReader
            label="Upload csv"
            onFileLoaded={onDataLoad}
            onError={onDataError}
            parserOptions={{}}
          />
        </div>
        {error && <Alert color="danger">{error}</Alert>}
      </div>
      <div className="card-content">
        <AgTable
          data={data}
          columns={columns}
          editable={true}
          onUpdate={onUpdate}
          validation={validation}
          addColumn={props.addColumn}
          maxCustomColumnCount={10}
          setColumns={setColumns}
          emptyText="No participants"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  participants: (state.survey.data && state.survey.data.participants) || [],
  participants_columns:
    state.survey.data && state.survey.data.participants_columns,
  error: state.survey.errors && state.survey.errors.participants,
  inputs:
    state.survey.data &&
    state.survey.data.draft_inputs &&
    state.survey.data.draft_inputs.participants,
});

const mapDispatchToProps = (dispatch) => ({
  setParticipants: (participants, headers, error) => {
    const data = {
      participants: participants,
      participantsError: error,
    };
    if (headers) {
      data['participants_columns'] = headers;
    }
    dispatch(setSurvey(data));
  },
  setParticipantsColumns: (columns) => {
    dispatch(
      setSurvey({
        participants_columns: columns,
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyParticipantsCreate);
