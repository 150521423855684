import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import Select from 'react-select';
import { isEmptyObject } from 'lib/helper';
import HTMLEditor from 'components/HTMLEditor';
import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import Button from 'components/Button';
import IndustrySelect from 'components/IndustrySelect';
import React, { useEffect, useState, useRef } from 'react';
import CurrencySelect from 'components/CurrencySelectByCountry';

import { Col, Row } from 'reactstrap';
import Loading from 'components/Loading';
import { postSearchApi } from 'api/chat';
import { set } from 'lodash';

const missingCurrency = {currencySymbol: '', currencyCode: '', countryCode: ''};
const missingRevenue = {amount: 0, currency: missingCurrency}

export default function BusinessProfile({ name, setName, website, setWebsite, businessDetails, setBusinessDetails, businessDescription, setBusinessDescription, activities, setActivities, employees, setEmployees }) {
  const contentRef = useRef();  
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
  const [errors, setErrors] = useState({ name: false, website: false });
  const [revenueAmount, setRevenueAmount] = useState(businessDetails?.revenue?.amount || 0);
  const [selectedCurrency, setSelectedCurrency] = useState(businessDetails?.revenue?.currency?.currency || missingCurrency);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);

  useEffect(() => {
    setRevenueAmount(businessDetails?.revenue?.amount || 0);
    setSelectedCurrency(businessDetails?.revenue?.currency || missingCurrency);
  }, [businessDetails]);

  const updateBusinessDetails = (field, value) => {
    setBusinessDetails({ ...businessDetails, [field]: value });
  };

  const updateIndustryHierarchy = (field, value) => {
    setBusinessDetails(prevDetails => {
      return {
        ...prevDetails,
        industry: {
          ...prevDetails.industry,
          [field]: value
        }
      };
    });
    
  };

  const updateRevenueDetails = (newRevenue, newCurrency) => {
    setBusinessDetails(prevDetails => ({
      ...prevDetails,
      revenue: { 
        amount: newRevenue, 
        currency: newCurrency
      },
    }));
  };

  const handleRevenueChange = (e) => {
    const amount = e.target.value.replace(/[^\d.-]/g, '');
    setRevenueAmount(amount);
    updateRevenueDetails(amount, selectedCurrency);
  };

  const handleCurrencyChange = (selectedCurrency) => {
    setSelectedCurrency(selectedCurrency.target);
    updateRevenueDetails(revenueAmount, selectedCurrency.target);
  };
  
  const generateBusinessDescription = async () => {
    setWarningModalIsOpen(false);
    const newErrors = { name: !name, website: !website };
    setErrors(newErrors);
    if (newErrors.name || newErrors.website) {
      return;
    }
    
    setIsGeneratingDescription(true);

    const apiPayload = {
        'prompt_template': 'generate_business_description',
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'num_employees': businessDetails.employeeCount ? businessDetails.employeeCount : '',
        'revenue': businessDetails.revenue ? businessDetails.revenue : missingRevenue,
        'activities': activities ? activities : [],
        'employees': employees ? employees : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedBusinessDescription = apiResponse.message['business_description'][0]['description'];
      const generatedBusinessDetails = apiResponse.message['business_details'];

      try {
        setBusinessDescription(generatedBusinessDescription);
        setBusinessDetails(generatedBusinessDetails);
        setIsGeneratingDescription(false);
      } catch (error) {
          console.error('Error parsing API response:', error);
          setIsGeneratingDescription(false);
      }

    } catch (error) {
        console.error('Error generating business profile:', error);
    }
  };

  const checkIfEmptyBusinessDetails = () => {
    if (!businessDescription && isEmptyObject(businessDetails)) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <div className="function-tab-header">
      </div>
      <Row className="align-items-center gap-5">
        <Col sm="3">
          <div className="form-label">
            Name
          </div>
          <TextInput
            placeholder="Business name"
            onChange={(e) => setName(e.target.value)}
            name="title"
            value={name}
            autoFocus
            className={`${errors.name ? 'error-border' : ''}`}
          />
        </Col>
        <Col sm="4">
          <div className="form-label">
            Website
          </div>
          <TextInput
            placeholder="Business website"
            onChange={(e) => setWebsite(e.target.value)}
            name="website"
            value={website}
            className={`${errors.website ? 'error-border' : ''}`}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-left">
          <button onClick={() => checkIfEmptyBusinessDetails() ? generateBusinessDescription() : setWarningModalIsOpen(true)} disabled={ isGeneratingDescription } className="button me-3 mb-3 w-auto mt-4">
          Generate Business Profile
            <span className="material-symbols-outlined">
              neurology
            </span>
          </button>
        </Col>
      </Row>
      <div className="card-content mb-2">
        {isGeneratingDescription ? (
          <div className="loading-spin pb-5">
            <Loading text="Browsing the web to find your business details..." />
          </div>
        ) : (
        <span>
          <Row className="d-flex justify-left mt-5 gap-5">
            <Col sm="3" className='card__content-wrapper'>
              <div className="form-header">
                Business Details
              </div>
              <Row className="">
                <Row className="">
                  <div className="form-label">
                    Number of employees
                  </div>
                  <Col sm="7">  
                    <NumberInput
                      name="num_employees"
                      placeholder="Number of employees"
                      onChange={(e) => updateBusinessDetails('num_employees', e.target.value)}
                      value={businessDetails?.num_employees || 0}
                      className=""
                      min={0}
                      step={1}
                    />
                  </Col>
                </Row>
                <Row className="">
                  <div className="form-label">
                    Revenue
                  </div>
                  <Col sm="7">  
                    <NumberInput
                      name="revenue"
                      placeholder="Revenue"
                      value={revenueAmount || 0}
                      onChange={handleRevenueChange}
                      className=""
                      min={0}
                      step={1}
                    />
                  </Col>
                  <Col sm="5">
                    <CurrencySelect
                      name="currency"
                      value={selectedCurrency?.currencyCode || ''}
                      onChange={handleCurrencyChange}
                      className="auto-suggest-dropdown"
                    />
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col sm="4" className='card__content-wrapper'>
              <div className="form-header">
                Industry Hierarchy
              </div>
              <IndustrySelect
                industryHierarchy={businessDetails?.industry}
                updateIndustryHierarchy={updateIndustryHierarchy}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm="12" className='card__content-wrapper mb-3'>
              <div className='mb-5'>
                <div className="form-header">
                  Description
                </div>
                <HTMLEditor
                  ref={contentRef}
                  value={businessDescription}
                  editorHeight={500}
                  onChange={(newValue) => {
                    setBusinessDescription(newValue);
                  }}
                />
              </div>
            </Col>
          </Row>
        </span>
      )}
      {warningModalIsOpen && (
        <Modal isOpen={warningModalIsOpen} toggle={() => setWarningModalIsOpen(false)}>
          <ModalBody className='text-center p-3'>
            <div className="popup-warning-icon">
              <span className="material-symbols-outlined popup-warning-icon">
                warning
              </span>
            </div>
            <div className="popup-warning-header">
              Proceeding will overwrite the following:
            </div>
            <div className="popup-warning-message">
              Business details<br />industry hierarchy<br />business description
            </div>
            <div className="popup-warning-header">
              Are you certain you wish to continue?
            </div>
          </ModalBody>
          <ModalFooter>
            <button onClick={() => generateBusinessDescription()} className="button ms-auto">
              Generate Business Profile
              <span className="material-symbols-outlined">
                neurology
              </span>
            </button>
            <Button
              text="Cancel"
              onClick={() => setWarningModalIsOpen(false)}
              className="button ms-3"
            />
          </ModalFooter>
        </Modal>
      )}
      </div>
    </div>
  );
}
