import React from 'react';
import { Link } from 'react-router-dom';
import CircleProgressBar from 'components/CircleProgressBar';

const SurveyCard = (props) => {
  const { survey } = props;

  return (
    <Link
      className="survey_card-wrapper col-xl-4 col-lg-6 col-md-12 col-12"
      to={survey.draft ? `/discover/create/${survey.id}` : `/discover/${survey.id}`}
    >
      <div className="survey_card card">
        <h4 className="survey__name">{survey.title}</h4>
        <div className="progress-bar-donut">
          {survey.draft
            ? <div className="draft"> DRAFT </div>
            : (
              <CircleProgressBar
                percent={survey.completed_count * 100 / survey.participants_count}
              />
            )
          }
          {survey.closed && <div className="closed-survey">Survey closed</div>}
        </div>
        <p className="helper-text text-center mt-3 mb-4">
          {survey.end_date && (
            <>
              Due on{' '}
              <span className="fw-bold">
                {survey.end_date || 'Undefined'}
              </span>
            </>
          )}
        </p>
      </div>
    </Link>
  );
};

export default SurveyCard;
