import * as constants from 'redux/constants/survey'
import toast from 'lib/toast.js'

export const surveyCreateInitialState = {
  loading: false,
  survey: null,
  errors: null
}

export const surveyCreate = (state = surveyCreateInitialState, action) => {
  switch (action.type) {
    case constants.SURVEY_CREATE_REQUEST:
      return {
        loading: true
      }
    case constants.SURVEY_CREATE_SUCCESS:
      // toast("Success to create new survey")
      return {
        loading: false,
        survey: action.payload
      }
    case constants.SURVEY_CREATE_FAILURE:
      toast(
        'Failed to create new survey:',
        action.payload.response || {
          Error: action.payload.statusText
        }
      )
      return {
        loading: false,
        errors: action.payload.response || {
          Error: action.payload.statusText
        }
      }
    default:
      return state
  }
}

export const getSurveyMetaInitialState = {
  loading: false,
  data: null,
  errors: {}
}


export const getSurveyMeta = (state = getSurveyMetaInitialState, action) => {
  switch (action.type) {
    case constants.SURVEY_META_GET_REQUEST:
      return {
        loading: true
      }
    case constants.SURVEY_META_GET_SUCCESS:
      return {
        loading: false,
        data: {
          ...action.payload['meta'][0] || undefined,
          templates: action.payload['templates']
        }
      }
    case constants.SURVEY_META_GET_FAILURE:
      return {
        loading: false
      }
    default:
      return state
  }
}

export const getSurveyInitialState = {
  loading: false,
  data: null,
  errors: {}
}

export const getSurvey = (state = getSurveyInitialState, action) => {
  switch (action.type) {
    case constants.SURVEY_GET_REQUEST:
      return {
        loading: true
      }
    case constants.SURVEY_GET_SUCCESS:
      // toast("Success to get survey")
      return {
        loading: false,
        data: {
          ...action.payload
        }
      }
    case constants.SURVEY_GET_FAILURE:
      toast(
        'Failed to get survey:',
        action.payload.response || {
          Error: action.payload.statusText
        }
      )
      return {
        loading: false,
        errors: action.payload.response || {
          Error: action.payload.statusText
        }
      }
    case constants.SET_SURVEY_LOCAL:
      return {
        loading: false,
        data: {
          ...state.data,
          ...action.payload
        },
        changed: true
      }
    case constants.SET_SURVEY_ERROR:
      return {
        ...state,
        errors: action.payload
      }
    case constants.CLEAR_SURVEY:
      return getSurveyInitialState
    default:
      return state
  }
}

export const closeSurveyInitialState = {
  loading: false,
  data: null,
  errors: {}
}

export const closeSurvey = (state = closeSurveyInitialState, action) => {
  switch (action.type) {
    case constants.CLOSE_REQUEST:
      return {
        loading: true
      }
    case constants.CLOSE_SUCCESS:
      toast('Survey closed successfully!')
      return {
        loading: false,
        data: action.payload
      }
    case constants.CLOSE_FAILURE:
      toast(
        'Failed to close discover!',
        action.payload.response || {
          Error: action.payload.statusText
        }
      )
      return {
        loading: false,
        errors: action.payload.response || {
          Error: action.payload.statusText
        }
      }
    default:
      return state
  }
}

export const closeDiagnoseSurveyInitialState = {
  loading: false,
  data: null,
  errors: {}
}

export const closeDiagnoseSurvey = (state = closeDiagnoseSurveyInitialState, action) => {
  switch (action.type) {
    case constants.DIAGNOSE_CLOSE_REQUEST:
      return {
        loading: true
      }
    case constants.DIAGNOSE_CLOSE_SUCCESS:
      toast('Survey closed successfully!')
      return {
        loading: false,
        data: action.payload
      }
    case constants.DIAGNOSE_CLOSE_FAILURE:
      toast(
        'Failed to close discover!',
        action.payload.response || {
          Error: action.payload.statusText
        }
      )
      return {
        loading: false,
        errors: action.payload.response || {
          Error: action.payload.statusText
        }
      }
    default:
      return state
  }
}

export const deleteSurveyInitialState = {
  loading: false,
  data: null,
  errors: {}
}


export const deleteSurvey = (state = deleteSurveyInitialState, action) => {
  switch (action.type) {
    case constants.DELETE_REQUEST:
      return {
        loading: true
      }
    case constants.DELETE_SUCCESS:
      toast('Survey is removed')
      return {
        loading: false,
        data: action.payload
      }
    case constants.DELETE_FAILURE:
      toast(
        'Failed to delete survey'
      )
      return {
        loading: false,
        errors: action.payload.response || {
          Error: action.payload.statusText
        }
      }
    default:
      return state
  }
}

export const deleteDiagnoseSurveyInitialState = {
  loading: false,
  data: null,
  errors: {}
}


export const deleteDiagnoseSurvey = (state = deleteDiagnoseSurveyInitialState, action) => {
  switch (action.type) {
    case constants.DELETE_REQUEST:
      return {
        loading: true
      }
    case constants.DELETE_SUCCESS:
      toast('Survey is removed')
      return {
        loading: false,
        data: action.payload
      }
    case constants.DELETE_FAILURE:
      toast(
        'Failed to delete survey'
      )
      return {
        loading: false,
        errors: action.payload.response || {
          Error: action.payload.statusText
        }
      }
    default:
      return state
  }
}

export const sendSurveyInitialState = {
  loading: false
}

export const sendSurvey = (state = sendSurveyInitialState, action) => {
  switch (action.type) {
    case constants.SURVEY_SEND_REQUEST:
      return {
        loading: true
      }
    case constants.SURVEY_SEND_SUCCESS:
      toast('Survey is sent successfully!')
      return {
        loading: false,
        data: action.payload
      }
    case constants.SURVEY_SEND_FAILURE:
      toast(
        'Failed to send survey:',
        (action.payload.response && action.payload.response.message) || action.payload.response || {
          Error: action.payload.statusText
        }
      )
      return {
        loading: false,
        errors: (action.payload.response && action.payload.response.message) || action.payload.response || {
          Error: action.payload.statusText
        }
      }
    default:
      return state
  }
}
