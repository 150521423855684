import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

export const SortableItem = SortableElement(({value, renderChild}) =>{
  return renderChild(value)
})

export const SortableList = SortableContainer(({items, renderChild}) => {
  return (
    <div className="row">
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} renderChild={renderChild} />
      ))}
    </div>
  )
})

export const SortableComponent = (props) => {
  return (
    <SortableList
      distance={2}
      items={props.data}
      onSortEnd={props.onSortEnd}
      onSortStart={props.onSortStart}
      axis="xy"
      renderChild={props.renderChild}
      useWindowAsScrollContainer={true}
    />
  );
}
