import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Button from 'components/Button';
import _, { isEqual } from 'lodash';
import { generateuniqueIshId, transposeObject, sum } from 'lib/helper';
import { getAutomationPotentialByActivity, getTotalWorkEffort, calcTotalWorkEffortAcrossActivities } from 'lib/metrics';
import { postSearchApi } from 'api/chat';
import { FTE_HOURS_PER_MONTH } from 'lib/constants';
import PercentageCellEditor from 'components/DataTable/PercentageCellEditor';
import InlineTextCellEditor from 'components/InlineTextCellEditor';
import StrategicActivityClassificationEditor from 'components/StrategicActivityClassificationEditor';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import KpiCard from 'components/KpiCard';
import RatioBar from 'components/RatioBar';
import TreeMap from 'components/TreeMap';

const calcSpanOfControl = (employees) => {
  if (!employees) {
    return 0;
  }

  const directReportPerEmployee = employees.map((emp) => getDirectSubordinates(emp));
  const totalDirectReports = sum(directReportPerEmployee);
  const avgDirectReports = totalDirectReports / employees.length;
  return avgDirectReports;
}

const getEmployeeId = (employee) => {
  if (employee.employee_number) {
    return employee.employee_number;
  }
  return employee.email;
}

const getDirectSubordinates = (employee, employees) => {
  if (!employee || !employees) {
    return 0;
  }

  const directSubordinates = employees.filter((emp) => emp.reporting_to === getEmployeeId(employee));
  return directSubordinates.length + (employee.fte-1);
}

const DashboardTab = (props) => {
  const { data, employees, relationships, activities, isMaximized } = props;
  const { time_utilization } = data;
  const [baseMetrics, setBaseMetrics] = useState([]);
  const [automationMetrics, setAutomationMetrics] = useState([]);
  const [improvementMetrics, setImprovementMetrics] = useState([]);

  const employeeCount = useMemo(() => employees?.length || 0, [employees]);
  const fteCount = useMemo(() => employees?.reduce((total, node) => total + parseFloat(node.fte), 0) || 0, [employees]);
  const SpanOfControl = useMemo(() => calcSpanOfControl(employees) || 0, [employees]);
  const totalWorkEffort = useMemo(() => getTotalWorkEffort(employees) || 0, [employees]);
  const avgAutomationPotential = useMemo(() => activities.reduce((total, activity) => total + (parseFloat(activity.automation_potential)), 0) / activities.length || 0, [activities]);
  const automationPotentialByActivity = useMemo(() => getAutomationPotentialByActivity(activities, time_utilization, employees)?.automation_potential_per_activity, [activities, time_utilization, employees]);
  const TotalAutomationPotential = useMemo(() => automationPotentialByActivity?.reduce((total, activity) => total + activity.workeffortAutomationPotential, 0) || 0, [automationPotentialByActivity]);
  const TotalWorkEffortAfterAutomation = useMemo(() => totalWorkEffort - TotalAutomationPotential || 0, [totalWorkEffort, TotalAutomationPotential]);
  const totalSavingsPotential = useMemo(() => TotalAutomationPotential * 60 || 0, [TotalAutomationPotential]);
  const StrategicActivities = useMemo(() => activities?.filter(activity => activity.classification === 'Strategic') || [], [activities]);
  const SupportActivities = useMemo(() => activities?.filter(activity => activity.classification === 'Support') || [], [activities]);
  const timeSpentOnStrategicActivities = useMemo(() => Math.round(calcTotalWorkEffortAcrossActivities(time_utilization, employees, StrategicActivities)) || 0, [time_utilization, employees, StrategicActivities]);
  const timeSpentOnSupportActivities = useMemo(() => Math.round(calcTotalWorkEffortAcrossActivities(time_utilization, employees, SupportActivities)) || 0, [time_utilization, employees, SupportActivities]);
  const automationPotentialByStrategicActivity = useMemo(() => automationPotentialByActivity.filter(activity => activity.classification === 'Strategic') || [], [automationPotentialByActivity]);
  const automationPotentialBySupportActivity = useMemo(() => automationPotentialByActivity.filter(activity => activity.classification === 'Support') || [], [automationPotentialByActivity]);
  const ratioTimeSpentOnStrategicActivities = useMemo(() => timeSpentOnStrategicActivities / totalWorkEffort * 100 || 0, [timeSpentOnStrategicActivities, totalWorkEffort]);
  const strategicTreemapData = useMemo(() => ({
    name: 'Total work breakdown',
    children: [
      {
        name: 'Strategic',
        value: timeSpentOnStrategicActivities,
        children: automationPotentialByStrategicActivity.map((activity) => ({ name: activity.activityLabel, value: Math.round(activity.workeffort) }))
      },
      {
        name: 'Support',
        value: timeSpentOnSupportActivities,
        children: automationPotentialBySupportActivity.map((activity) => ({ name: activity.activityLabel, value: Math.round(activity.workeffort) }))
      }
    ]
  }), [timeSpentOnStrategicActivities, timeSpentOnSupportActivities, automationPotentialByStrategicActivity, automationPotentialBySupportActivity]);
  
  const CalcBaseMetrics = useCallback(() => {
    return [
      { title: 'Dataset size', value: employeeCount.toLocaleString('no-NO'), icon: <span className="metric-icon material-symbols-outlined">group</span>, unit: 'Employees' },
      { title: 'Organization size', value: fteCount.toLocaleString('no-NO'), icon: <span className="metric-icon material-symbols-outlined">Badge</span>, unit: 'FTE' },
      //{ title: 'Span of control', value: SpanOfControl.toFixed(0).toLocaleString('no-NO'), icon: '👥', unit: 'Employees'},
      { title: 'Total work capacity', value: totalWorkEffort.toLocaleString('no-NO'), icon: '🔧', unit: 'hrs/mth' },
    ];
  }, [employeeCount, fteCount, totalWorkEffort]);

  const CalcAutomationMetrics = useCallback(() => {
    return [
      { title: 'Average automation potential', value: avgAutomationPotential.toLocaleString('no-NO', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), icon: '✨', unit: ' %'},
      { title: 'Total automation potential', value: TotalAutomationPotential.toLocaleString('no-NO', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), icon: '⏳', unit: 'hrs/mth' },
      { title: 'Total potential salary cost savings', value: totalSavingsPotential.toLocaleString('no-NO', { minimumFractionDigits: 0, maximumFractionDigits: 0 }), icon: '💵', unit: '€/mth' },
    ];
  }, [avgAutomationPotential, TotalAutomationPotential, totalSavingsPotential]);

  const calcImprovementMetrics = useCallback(() => {
    return [
      { title: 'Strategic activities', value: StrategicActivities.length.toLocaleString('no-NO'), icon: '🎯', unit: '' },
      { title: 'Support activities', value: SupportActivities.length.toLocaleString('no-NO'), icon: '🔧', unit: '' },
      { title: 'Time spent on Strategic activities', value: ratioTimeSpentOnStrategicActivities.toFixed(0).toLocaleString('no-NO'), icon: '⏳', unit: '%' },
      /*{ title: 'Time spent on Strategic activities', value: timeSpentOnStrategicActivities.toLocaleString('no-NO'), icon: '🎯', unit: 'hrs/mth' },
      { title: 'Time spent on Support activities', value: timeSpentOnSupportActivities.toLocaleString('no-NO'), icon: '🔧', unit: 'hrs/mth' },*/
    ];
  }, [StrategicActivities, SupportActivities, ratioTimeSpentOnStrategicActivities]);

  useEffect(() => {
    const UpdatedBaseMetrics = CalcBaseMetrics();
    if (!isEqual(baseMetrics, UpdatedBaseMetrics)) {
      setBaseMetrics(UpdatedBaseMetrics);
    }
  }, [CalcBaseMetrics, baseMetrics]);

  useEffect(() => {
    const UpdatedAutomationMetrics = CalcAutomationMetrics();
    if (!isEqual(automationMetrics, UpdatedAutomationMetrics)) {
      setAutomationMetrics(UpdatedAutomationMetrics);
    }
  }, [CalcAutomationMetrics, automationMetrics]);

  useEffect(() => {
    const UpdatedImprovementMetrics = calcImprovementMetrics();
    if (!isEqual(improvementMetrics, UpdatedImprovementMetrics)) {
      setImprovementMetrics(UpdatedImprovementMetrics);
    }
  }, [calcImprovementMetrics, improvementMetrics]);

  return (
    <div className="tab automation-tab">
      <div>
        <div className='automation-tab-header'>
          <h1>Organization</h1>
        </div>
        <div className='container-wide-row'>
          <KpiCard kpiData={baseMetrics} />
        </div>
      </div>

      <div>
        <div>
          <h1>Strategic focus</h1>
        </div>
        <div className='container-wide-row'>
          <RatioBar label1='Strategic' label2="Support" value1={timeSpentOnStrategicActivities} value2={timeSpentOnSupportActivities} unit="hrs/mth" />
          <KpiCard kpiData={improvementMetrics} />
        </div>
        <div>
          <TreeMap hierarchyData={strategicTreemapData} />
        </div>
      </div>

      <div>
        <div className='automation-tab-header'>
          <h1>Automation Opportunity</h1>
        </div>
        <div className='container-wide-row'>
          <RatioBar label1='Required human effort' label2="Automation potentential" value1={TotalWorkEffortAfterAutomation} value2={TotalAutomationPotential} unit="hrs/mth" />
          <KpiCard kpiData={automationMetrics} />
          
        </div>
      </div>

      
    </div>
  );
};

export default DashboardTab;
