import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { createDiagnose } from 'api/diagnose';
import Button from 'components/Button';
import Loading from 'components/Loading';
import TextInput from 'components/TextInput';
import toast from 'lib/toast';
import SurveyParticipantsCreate
  from 'pages/Survey/components/SurveyParticipantsCreate';
import { clearSurvey } from 'redux/actions/survey';
import Explanation from './Explanation';

const NewDiagnose = (props) => {
  const mutation = useMutation({
    mutationFn: (payload) => {
      return createDiagnose(payload);
    },
  });
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [company_name, setCompanyName] = useState('');

  useEffect(() => {
    props.clearSurvey();
  }, []);

  if (mutation.isLoading) {
    return <Loading text="Creating diagnose survey..."/>;
  }

  if (mutation.isError) {
    toast('Request failed!');
  }

  if (mutation.data?.status === 201) {
    navigate('/diagnose');
  }

  function onClickSend() {
    const { data = {} } = props.survey;
    if (data.participants && !data.participantsError) {
      mutation.mutate({
        participants: data.participants,
        participants_columns: data.participants_columns,
        client: +props.currentClient,
        title,
        company_name,
      });
    } else {
      toast('Invalid Participants data!');
    }
  }

  return (
    <div className="diagnose-index">
      <div className="content__header py-4 d-lg-flex align-items-center">
        <h3 className="ellipsis">New Diagnose survey</h3>
        <div className="ms-lg-auto">
          <Button
            text="Send"
            className="button"
            onClick={onClickSend}
          />
        </div>
      </div>
      <div className="card__group">
        <div className="new-survey-name">
          <TextInput
            type="text"
            id="companyName"
            name="companyName"
            placeholder="Enter Company Name"
            required
            maxLength="60"
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
            defaultValue={company_name}
            error=""
          />
          <TextInput
            type="text"
            id="title"
            name="title"
            placeholder="Enter title"
            required
            maxLength="30"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            defaultValue={title}
            error=""
          />
        </div>
      </div>
      <div className="row">
        <div className="card">
          <SurveyParticipantsCreate
            addColumn={false}
            isSimple={true}
          />
        </div>
      </div>
      <Explanation/>
    </div>
  );
};

const mapDispatchToProps = { clearSurvey };
const mapStateToProps = (state) => ({
  survey: state?.survey,
  currentClient: state?.currentClient?.data,
});
export default connect(mapStateToProps, mapDispatchToProps)(NewDiagnose);
