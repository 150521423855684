import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import withRouter from 'lib/withRouter'
class SidebarItem extends Component {
  render() {
    const isActive = window.location.pathname.indexOf(this.props.data.link) > -1

    const iconActiveClassName = classNames({
      'nav__item-icon': true,
      active: isActive
    })
    const liActiveClassName = classNames({
      nav__item: true,
      active: isActive
    })



    return (
      <li className={liActiveClassName}>
        <Link to={this.props.data.link} className='sidebar-item-link'>
          <svg
            className={iconActiveClassName}
            width={`${this.props.data.width}px`}
            height={`${this.props.data.height}px`}
            viewBox={`-0.5 -0.5 ${this.props.data.boxWidth} ${this.props.data.boxHeight
              }`}
          >
            <use
              xlinkHref={this.props.data.icon}
              preserveAspectRatio="xMinYMid"
            />
          </svg>
          {this.props.data.text}
          {/*<span>({this.props.data.count})</span>*/}
        </Link>
      </li>
    )
  }
}

export default withRouter(SidebarItem)
