import React from 'react';
import { Col } from 'reactstrap';

export default function IndustrySelect({ industryHierarchy, updateIndustryHierarchy }) {
  const handleIndustryChange = (field, value) => {
    updateIndustryHierarchy(field, value);
  };

  const styles = {
    label: {
      maxWidth: '100%', // Ensures labels don't overflow their container
      whiteSpace: 'normal', // Allows text to wrap
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    input: {
      maxWidth: '100%' // Ensures input fields don't exceed their container's width
    }
  };

  return (
    <>
      <div className="mb-4">
        <span style={{display: 'inline'}}>Sector</span>
        <input
          type="text"
          placeholder="Sector"
          value={industryHierarchy?.sector || ''}
          onChange={(e) => handleIndustryChange('sector', e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4" style={{ marginLeft: '20px' }}>
        <span>Industry Group</span>
        <input
          type="text"
          placeholder="Industry Group"
          value={industryHierarchy?.industry_group || ''}
          onChange={(e) => handleIndustryChange('industry_group', e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4" style={{ marginLeft: '40px' }}>
        <span>Industry</span>
        <input
          type="text"
          placeholder="Industry"
          value={industryHierarchy?.industry || ''}
          onChange={(e) => handleIndustryChange('industry', e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4" style={{ marginLeft: '60px' }}>
        <span>Sub-Industry</span>
        <input
          type="text"
          placeholder="Sub-Industry"
          value={industryHierarchy?.sub_industry || ''}
          onChange={(e) => handleIndustryChange('sub_industry', e.target.value)}
          className="form-control"
        />
      </div>
    </>
  );
}
