import { createSlice } from '@reduxjs/toolkit';

import { getProfile } from 'api/user';

export const getProfileSlice = createSlice({
  name: 'profile',
  initialState: {
    loading: true,
    data: null,
    error: null,
  },
  reducers: {
    getProfileStart: state => {
      state.loading = true;
    },
    getProfileSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getProfileFailure: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const {
  getProfileStart,
  getProfileSuccess,
  getProfileFailure,
} = getProfileSlice.actions;

export default getProfileSlice.reducer;

export const getProfileData = () => async dispatch => {
  try {
    dispatch(getProfileStart());
    const profile = await getProfile();
    dispatch(getProfileSuccess(profile));
  } catch (err) {
    dispatch(getProfileFailure(err));
  }
};