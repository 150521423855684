import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';

import {
  deleteUser,
  editUser,
  getUserList,
  inviteUserList,
  updateProfile,
} from 'api/user';
import toast from 'lib/toast';
import { getProfileData } from './profileSlice';
import ConfirmModal from './ConfirmModal';

const ActionCell = (props) => {
  const onDelete = () => {
    props.context.select(props.data.id);
    props.context.delete();
  };

  const onEdit = () => {
    props.context.select(props.data.id);
    props.context.update();
  };

  if (props.data.isSelf) return <></>;

  return (
    <div style={{ display: 'inline-flex' }}>
      <button className="form-control" onClick={onEdit}>
        Edit
      </button>
      <button className="form-control" onClick={onDelete}>
        Delete
      </button>
    </div>
  );
};
const InviteModal = (props) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const payload = {
      first_name: form.get('first_name'),
      last_name: form.get('last_name'),
      email: form.get('email'),
      role: form.get('role'),
    }
    if (Object.values(payload).some(v => !v)) {
      toast('Please fill all fields');
      return;
    }
    props.onConfirm(payload);
  };
  return (
    <ConfirmModal {...props} hideButton>
      <Form onSubmit={onFormSubmit} className="text-start">
        <FormGroup>
          <label htmlFor="new_first_name">First Name</label>
          <input
            type="text"
            id="new_first_name"
            name="first_name"
            className="form-control"
            placeholder="First Name"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="new_last_name">Last Name</label>
          <input
            type="text"
            id="new_last_name"
            name="last_name"
            className="form-control"
            placeholder="Last Name"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="new_email">Email</label>
          <input
            type="text"
            id="new_email"
            name="email"
            className="form-control"
            placeholder="Email"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="new_role" className="form-label">Role</label>
          <select className="form-select" id="new_role" name="role">
            <option value="admin">Admin</option>
            <option value="guest">User</option>
          </select>
        </FormGroup>
        <div className="text-center">
          <button
            type="submit"
            className="button"
          >
            Send Invite
          </button>
        </div>
      </Form>
    </ConfirmModal>
  );
};
const UpdateModal = (props) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const payload = {
      role: form.get('role'),
    }
    props.onConfirm(payload);
  };
  const { data } = props;
  return (
    <ConfirmModal {...props} hideButton>
      <Form onSubmit={onFormSubmit} className="text-start">
        <p>Update role of <strong>{data.user__email}</strong></p>
        <FormGroup>
          <select
            className="form-select"
            id="new_role"
            name="role"
            defaultValue={data.is_admin ? 'admin' : 'guest'}
          >
            <option value="admin">Admin</option>
            <option value="guest">User</option>
          </select>
        </FormGroup>
        <div className="text-center">
          <button
            type="submit"
            className="button"
          >
            Update
          </button>
        </div>
      </Form>
    </ConfirmModal>
  );
};
const DeleteModal = (props) => {
  return (
    <ConfirmModal {...props}>
      <p>Are you sure to delete this user?</p>
    </ConfirmModal>
  );
};
const accessLevelFormatter = (params) => {
  return params.value ? 'Admin' : 'User';
};
const loginDateFormatter = (params) => {
  return params.value ? new Date(params.value).toDateString() : 'Never';
};
const UserColumnDefs = [
  {
    headerName: 'First Name',
    headerTooltip: 'user__first_name',
    field: 'user__first_name',
    flex: 1,
  },
  {
    headerName: 'Last Name',
    headerTooltip: 'user__last_name',
    field: 'user__last_name',
    flex: 1,
  },
  {
    headerName: 'Email',
    headerTooltip: 'user__email',
    field: 'user__email',
    flex: 1,
  },
  {
    headerName: 'Access Level',
    headerTooltip: 'is_admin',
    field: 'is_admin',
    flex: 1,
    valueFormatter: accessLevelFormatter,
  },
  {
    headerName: 'Last Login',
    headerTooltip: 'user__last_login',
    field: 'user__last_login',
    valueFormatter: loginDateFormatter,
    flex: 1,
  },
  {
    headerName: 'Actions',
    headerTooltip: 'action',
    field: 'action',
    flex: 1,
    cellRenderer: ActionCell,
  },
];

const Modal = (props) => {
  const { showModal, modalName, onClose, onConfirm, user } = props;
  if (!showModal) return <></>;

  if (modalName === 'Delete') {
    return (
      <DeleteModal
        onConfirm={onConfirm}
        onClose={onClose}
        showModal={showModal}
      />
    );
  } else if (modalName === 'Invite') {
    return (
      <InviteModal
        onConfirm={onConfirm}
        onClose={onClose}
        showModal={showModal}
      />
    );
  } else if (modalName === 'Update') {
    return (
      <UpdateModal
        onConfirm={onConfirm}
        onClose={onClose}
        showModal={showModal}
        data={user}
      />
    );
  } else {
    return <></>;
  }
};
const Profile = ({ ...props }) => {
  const { profile } = props;
  const [formData, setFormData] = useState({});
  const [userList, setUserList] = useState([]);
  const [selectedId, setSelectedId] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [modalName, setModalName] = useState('Delete');

  function onFormChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const onClickInvite = () => {
    setModalName('Invite');
    setShowModal(true);
  };
  const onSelect = (userId) => {
    setSelectedId(userId);
  };
  const onDelete = () => {
    setShowModal(true);
    setModalName('Delete');
  };
  const onUpdate = () => {
    setShowModal(true);
    setModalName('Update');
  };
  const onClose = () => {
    setShowModal(false);
  }
  const onConfirm = (data) => {
    if (modalName === 'Delete') {
      deleteUser({user_id: selectedId}).then(
        res => {
          setShowModal(false);
          refreshUserList();
        }
      ).catch(e => {
        toast('Failed to remove user', e.response?.data?.msg);
      });
    } else if (modalName === 'Invite') {
      inviteUserList(data).then(
        res => {
          setShowModal(false);
          refreshUserList();
        }
      ).catch(e => {
        toast('Failed to invite user', e.response?.data?.msg);
      });
    } else if (modalName === 'Update') {
      editUser({user_id: selectedId, ...data}).then(
        res => {
          setShowModal(false);
          refreshUserList();
        }
      ).catch(e => {
        toast('Failed to update user', e.response?.data?.msg);
      });
    }
  };

  function onFormSubmit(e) {
    e.preventDefault();
    updateProfile(formData)
      .then(data => {
        if (data.success) {
          toast('Updated profile successfully', data.msg);
        }
        props.getProfileData();
      })
      .catch(e => {
        toast('Failed to update profile', e.response?.data?.msg);
      })
      .finally(() => null);
  }

  const refreshUserList = () => {
    getUserList().then(
      data => {
        setUserList(data.map(user => {
          return {
            ...user,
            isSelf: user.user__id === profile.id,
          };
        }));
      },
    ).catch(e => {
      console.warn(e);
    });
  }

  useEffect(() => {
    const {
      first_name,
      last_name,
      email,
      company,
    } = profile || {};
    setFormData({
      first_name,
      last_name,
      email,
      company,
    });

    if (profile?.is_admin) {
      refreshUserList();
    }
  }, [profile]);
  
  const data = userList.filter(u => u.id === selectedId)[0];

  return (
    <>
      <div className="content__header pt-3">
        <h3>Welcome {profile?.first_name || ''}</h3>
      </div>
      <div className="card">
        <div className="card__content-wrapper">
          <div className="card-content">
            <Form onSubmit={onFormSubmit} className="login-form">
              <Row>
                <FormGroup className="col-md-6">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="form-control"
                    placeholder="First Name"
                    value={formData.first_name || ''}
                    onChange={onFormChange}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="form-control"
                    placeholder="Last Name"
                    value={formData.last_name || ''}
                    onChange={onFormChange}
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className="col-md-6">
                  <label htmlFor="company">Company</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    className="form-control"
                    placeholder="Company"
                    value={formData.company || ''}
                    onChange={onFormChange}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email || ''}
                    className="form-control"
                    placeholder="Email"
                    onChange={onFormChange}
                  />
                </FormGroup>
              </Row>
              <button
                type="submit"
                className="button login-button mb-3"
              >
                Update details
              </button>
            </Form>
          </div>
        </div>
      </div>
      {
        profile?.is_admin && (
          <div>
            <div className="card">
              <div className="card__content-wrapper">
                <div className="d-flex justify-content-between">
                  <h3>User Administration</h3>
                  <button
                    className="button"
                    onClick={onClickInvite}
                  >
                    New User
                  </button>
                </div>
                <div className="card-content">
                  <div
                    className="ag-theme-alpine table-bar"
                    style={{ height: 500, width: 'auto' }}
                  >
                    <AgGridReact
                      rowData={userList}
                      defaultColDef={{
                        width: 140,
                        minWidth: 140,
                        sortable: true,
                        resizable: true,
                      }}
                      columnDefs={UserColumnDefs}
                      enableBrowserTooltips={true}
                      context={{
                        delete: onDelete,
                        update: onUpdate,
                        select: onSelect,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Modal
              showModal={showModal}
              modalName={modalName}
              onClose={onClose}
              onConfirm={onConfirm}
              user={data}
            />
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.profile.data,
});
const mapDispatchToProps = {
  getProfileData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
