import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lib/lodashFunctions';
import Checkbox from './Checkbox';
import CollapseRow from './CollapseRow';
import TextInput from './TextInput';


export function TimeUtilizationSelector({
  value,
  setValue,
  activities,
}) {
  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(() => activities.filter(
    o => o.group?.toLowerCase().includes(search.toLocaleLowerCase()) || o.name?.toLowerCase().includes(search.toLowerCase()),
  ), [search, activities]);
  const activitiesByGroup = useMemo(() => {
    let grouped = _.groupBy(filteredOptions, 'group');
    return Object.values(grouped).map((items) => ({
      groupName: items[0].group,
      groupLabel: `${items[0].group || 'All'}`,
      activities: items.map((u) => ({
        name: u.name || u.group,
        id: u.id.toString(),
        groupId: u.groupId || u.id,
      })),
    }));
  }, [filteredOptions]);

  function getSum(activities) {
    return activities.map(a => value[a.groupId]).filter(u => !!u).map(v => +v.replace('%', '')).reduce((s, a) => s + a, 0);
  }


  const handleCheckActivity = (checked, activity) => {
    if (checked) {
      setValue({
        [activity.groupId]: `${100 - Math.min(100, getSum(activities))}%`,
        ...value,
      });
    } else {
      const v = { ...value };
      delete v[activity.groupId];
      setValue(v);
    }
  };

  const handleCheckedActivityGroup = (checked, groupName) => {
    let activityGroupIds = new Set();
    filteredOptions.forEach((a) => {
      if (a.group === groupName) {
        if (checked && !value[a.groupId]) {
          activityGroupIds.add(a.groupId);
        }
        if (!checked && value[a.groupId]) {
          activityGroupIds.add(a.groupId);
        }
      }
    });
    let v = { ...value };
    if (checked) {
      const ids = [...activityGroupIds].filter(id => !value[id]);
      if (ids.length) {
        const sum = Math.min(getSum(activities), 100);
        const percent = (100 - sum) / ids.length;
        ids.forEach(id => v[id] = `${percent}%`);
      }
    } else {
      [...activityGroupIds].forEach(id => delete v[id]);
    }
    setValue(v);
  };

  const isChecked = useCallback((activity) => {
    return !!value[activity.groupId];
  }, [value]);

  const allChecked = useMemo(() => filteredOptions.every(o => isChecked(o)), [filteredOptions, isChecked]);
  const allUnchecked = useMemo(() => filteredOptions.every(o => !isChecked(o)), [filteredOptions, isChecked]);

  function handleCheckedActivityAll(checked) {
    let activityGroupIds = new Set();
    filteredOptions.forEach((a) => {
      if (checked && !value[a.groupId]) {
        activityGroupIds.add(a.groupId);
      }
      if (!checked && value[a.groupId]) {
        activityGroupIds.add(a.groupId);
      }
    });
    let v = { ...value };
    if (checked) {
      const ids = [...activityGroupIds].filter(id => !value[id]);
      if (ids.length) {
        const sum = Math.min(getSum(activities), 100);
        const percent = ((100 - sum) / ids.length).toFixed(2);
        ids.forEach(id => v[id] = `${percent}%`);
      }
    } else {
      [...activityGroupIds].forEach(id => delete v[id]);
    }

    setValue(v);
  }

  function handleValueChanged(activity, v) {
    const validValue = Math.abs(parseFloat(v));
    if (!isNaN(validValue)) {
      setValue({
        ...value,
        [activity.groupId]: `${v}%`,
      });
    }
  }

  const sum = getSum(activities);

  return (
    <div className="activity-selector pt-2">
      <div className="activities-search px-2 py-1">
        <input
          type="search"
          placeholder="Activities search"
          className="activities-search-input"
          value={search} onChange={e => setSearch(e.target.value)}
        />
      </div>
      <div className="px-2 py-1 border-bottom d-flex justify-content-between align-items-center">
        <div className="form-check check-row activity-all-check">
          <Checkbox
            className="form-check-input"
            type="checkbox"
            onChange={() =>
              handleCheckedActivityAll(!allChecked)
            }
            checked={allChecked}
            indeterminate={!allChecked && !allUnchecked}
            id="selectAll"
          />
          <label
            className="form-check-label"
            htmlFor="selectAll"
          >
            All
          </label>
        </div>
        <div>{+sum.toFixed(6)}%/100%</div>
      </div>
      <div className="list-group activities">
        {activitiesByGroup.map(
          ({ groupName, groupLabel, activities }, groupIndex) => {

            const checked = activities.every((a) => isChecked(a));
            const unchecked = activities.every((a) => !isChecked(a));

            return (
              <CollapseRow
                key={groupName}
                defaultColapse={true}
                header={
                  <div className="form-check check-row">
                    <Checkbox
                      className="form-check-input"
                      type="checkbox"
                      onChange={() =>
                        handleCheckedActivityGroup(!checked, groupName)
                      }
                      checked={checked}
                      indeterminate={!checked && !unchecked}
                      id={`activity-group-${groupIndex}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`activity-group-${groupIndex}`}
                    >
                      {groupLabel}
                    </label>
                  </div>
                }
                content={activities.map((activity, index) => {
                  const checked = isChecked(activity);
                  return (
                    <div className="form-check ms-4 check-row d-flex" key={index}>
                      <Checkbox
                        checked={checked}
                        onChange={() =>
                          handleCheckActivity(!checked, activity)
                        }
                        className="form-check-input"
                        id={`activity-item-${groupIndex}-${index}`}
                      />
                      <label
                        className="form-check-label ellipsis ms-1"
                        htmlFor={`activity-item-${groupIndex}-${index}`}
                      >
                        {activity.name}
                      </label>
                      {checked && (
                        <div className="time-util-selector-value-input d-flex ms-auto">
                          <input
                            type="number"
                            value={value[activity.groupId].replace('%', '')}
                            onChange={(e) => handleValueChanged(activity, e.target.value)}
                          />
                          %
                        </div>
                      )}
                    </div>
                  );
                })}
              />
            );
          },
        )}
      </div>
    </div>

  );
}