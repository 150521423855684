import React from 'react';
import {
  getGraphNodeTitle,
  getResponseStatusClassname,
} from 'lib/helper';

const CellNode = (props) => {
  const { node, label } = props;

  if (node.ax) {
    return (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {label && <b>{label}:</b>}
        &nbsp;{getGraphNodeTitle(node)}
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {label && <b>{label}:</b>}
        &nbsp;
        <span className="indicator">
          <span className={getResponseStatusClassname(node?.responseStatus)} />
        </span>
        &nbsp;{node.name}
      </div>
    );
  }
};

export default CellNode;