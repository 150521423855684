import { useEffect } from 'react';

export function installClickOutside(className, callback) {
  const handler = (e) => {
    if (!e.target.classList.contains(className)) {
      callback(e)
    }
  }
  document.addEventListener('mousedown', handler)
  return handler
}

export function useDoubleClick({
  ref,
  latency = 300,
  onSingleClick = () => null,
  onDoubleClick = () => null
}) {
  useEffect(() => {
    const clickRef = ref.current;
    let clickCount = 0;
    const handleClick = e => {
      clickCount += 1;

      setTimeout(() => {
        if (clickCount === 1) onSingleClick(e);
        else if (clickCount === 2) onDoubleClick(e);

        clickCount = 0;
      }, latency);
    };

    // Add event listener for click events
    clickRef.addEventListener('click', handleClick);

    // Remove event listener
    return () => {
      clickRef.removeEventListener('click', handleClick);
    };
  });
}

