import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import CircleProgressBar from 'components/CircleProgressBar';
import DesignButtons from './DesignButtons';

export default function DesignContent({ design }) {
  const [toggle, setToggle] = useState(false);

  const navigate = useNavigate();

  const onClick = (e) => {
    const haveEdit = !!document.querySelector('.editable-text.onedit');
    e.preventDefault();
    if (!haveEdit) {
      navigate(`/design/org/${design.id}`);
    }
  };

  return (
    <div
      onClick={onClick}
      className="d-flex justify-content-center pt-4"
      style={{ cursor: 'pointer' }}
    >
      <div className="card-content">
        <div className="circle__wrapper position-relative mb-3">
          {
            design.run
              ? <CircleProgressBar percent={design.progress}/>
              : <DesignButtons
                design={design}
                toggle={() => setToggle(!toggle)}
              />
          }
        </div>
      </div>
    </div>
  );
}
