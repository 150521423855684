import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Button from 'components/Button';

export default class SurveyConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onClose = () => {
    this.toggle();
    this.props.onConfirm();
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog-mini modal-dialog-centered "
        >
          <ModalBody className="text-center p-5">
            <p>{this.props.message}</p>
            <Button text="Yes" onClick={this.onClose} className="button me-3" />
            <Button text="No" className="button ms-3" onClick={this.toggle} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
