import { createSlice } from '@reduxjs/toolkit';

import { getClients } from 'api/user';

export const getClientsSlice = createSlice({
  name: 'client',
  initialState: {
    loading: true,
    data: null,
    error: null,
  },
  reducers: {
    getClientsStart: state => {
      state.loading = true;
    },
    getClientsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getClientsFailure: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const setClientSlice = createSlice({
  name: 'client',
  initialState: { data: null },
  reducers: {
    setClient: (state, action) => {
      state.data = action.payload;
    },
  },
});

const {
  getClientsStart,
  getClientsSuccess,
  getClientsFailure,
} = getClientsSlice.actions;
const { setClient } = setClientSlice.actions;

export const getClientsData = () => async dispatch => {
  try {
    dispatch(getClientsStart());
    const clients = await getClients();
    dispatch(getClientsSuccess(clients));
  } catch (err) {
    dispatch(getClientsFailure(err));
  }
};

export const setCurrentClient = (payload) => async dispatch => {
  dispatch(setClient(payload));
};