import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { listSurveys } from 'api/survey';
import Loading from 'components/Loading';
import Error from 'components/Error';
import { SortableComponent } from 'lib/Sortable';
import toast from 'lib/toast';
import { sortSurveysApi } from 'redux/actions/survey';
import SurveyCard from './SurveyCard';
import { arrayMoveImmutable } from 'array-move';

const Surveys = (props) => {
  const [surveyList, setSurveyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const renderChild = (survey) => <SurveyCard key={survey.id} survey={survey}/>;
  const onSortStart = () => null;

  useEffect(
    () => {
      if (!props.currentClient?.data) return;
      setLoading(true);
      setError('');

      listSurveys(props.currentClient?.data).then(res => {
        const { data, surveys_order } = res.data;
        let sortedData = [];
        data?.forEach(survey => {
          const exist = surveys_order?.find(id => survey.id === id);
          if (!exist) {
            sortedData.push(survey);
          }
        });
        surveys_order?.forEach(id => {
          const survey = data?.find(item => item.id === id);
          if (survey) {
            sortedData.push(survey);
          }
        });
        setSurveyList(sortedData);
      }).catch(err => {
        toast(
          'Failed to get surveys:',
          err.response || err.toString(),
        );
        setError(err.response || err.toString());
      }).finally(() => {
        setLoading(false);
      });
    }, [props.currentClient],
  );

  const orderSurveys = ({ oldIndex, newIndex }) => {
    const surveyData = surveyList || [];
    const ids = surveyData.map(survey => survey.id);
    const changedList = arrayMoveImmutable(surveyData, oldIndex, newIndex);
    setSurveyList(changedList);
    props.sortSurveysApi(ids, oldIndex, newIndex);
  };

  if (loading) return <Loading text="Loading data..."/>;
  if (error) return <Error text={error}/>;

  return (
    <div className="all__surveys row">
      <svg style={{ height: 0, width: 0 }} className="gradient-pattern">
        <defs>
          <linearGradient id="circle_progressbar_gradient">
            <stop offset="0%" stopColor="#4767F9"/>
            <stop offset="100%" stopColor="#11C684"/>
          </linearGradient>
        </defs>
      </svg>
      <SortableComponent
        data={surveyList}
        renderChild={renderChild}
        onSortStart={onSortStart}
        onSortEnd={orderSurveys}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  currentClient: state.currentClient,
});

const mapDispatchToProps = {
  sortSurveysApi,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Surveys);
