import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createEmptyDesign } from 'api/design';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { SortableComponent } from 'lib/Sortable';
import {
  getDesigns,
  sortSurveyDesigns,
  sortSurveyDesignsApi,
} from 'redux/actions/design';
import DesignOrganizationCard from './components/DesignOrganizationCard';
import DesignTitleModal from './components/DesignTitleModal';
import FilterModal from './FilterModal';

const DesignIndex = (props) => {
  const { currentClient } = props;
  useEffect(
    () => {
      if (!currentClient) return;
      refreshData();
    }, [currentClient],
  );
  const { designs } = props;
  const navigate = useNavigate();
  const refreshData = useCallback(
    () => props.getDesigns(currentClient), [currentClient],
  );
  const [showModal, setShowModal] = useState(false);
  const [showOptimize, setShowOptimize] = useState(false);
  const onModalToggle = () => {
    setShowModal(!showModal);
  };
  const onOptimizeModalToggle = () => {
    setShowOptimize(!showOptimize);
  };

  const renderChild = (design) => {
    return (
      <DesignOrganizationCard
        key={design.id}
        data={design}
      />
    );
  };
  const onCreate = (title) => {
    if (title) {
      createEmptyDesign(title, currentClient).then((res) => {
        navigate('/design/org/' + res.id);
      });
      setShowModal(false);
    }
  };

  function onSortStart() {
  }

  function orderDesigns({ oldIndex, newIndex }) {
    const ids = (designs.data || []).map(design => design.id);
    props.sortSurveyDesigns(oldIndex, newIndex);
    props.sortSurveyDesignsApi(ids, oldIndex, newIndex);
  }

  if (designs.loading) return <Loading text="Loading data..."/>;

  return (
    <div>
      <svg style={{ height: 0, width: 0 }} className="gradient-pattern">
        <defs>
          <linearGradient id="circle_progressbar_gradient">
            <stop offset="0%" stopColor="#11C684"/>
            <stop offset="100%" stopColor="#4767F9"/>
          </linearGradient>
        </defs>
      </svg>
      <div className="content__header py-4 d-flex align-items-center">
        <h3 className="ellipsis">Design your organization</h3>
        <div className="ms-lg-auto me-lg-0">
          <Button
            className="button me-2"
            text="Let Reconfig Design"
            onClick={onOptimizeModalToggle}
            iconWidth={16}
            iconHeight={16}
            iconClassName="button__icon button__icon-blue"
          />
          <Button
            className="button"
            text="Create empty Design"
            onClick={onModalToggle}
            iconWidth={16}
            iconHeight={16}
            iconClassName="button__icon button__icon-blue"
          />
        </div>
      </div>
      <div className="all-designs">
        <SortableComponent
          data={designs?.data || []}
          renderChild={renderChild}
          onSortStart={onSortStart}
          onSortEnd={orderDesigns}
        />
      </div>
      <DesignTitleModal
        showModal={showModal}
        defaultTitle=""
        onConfirm={onCreate}
        onToggle={onModalToggle}
      />
      {showOptimize && (
        <FilterModal
          modal={showOptimize}
          toggle={onOptimizeModalToggle}
          designs={designs?.data || []}
          refresh={refreshData}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  designs: state.designs,
  currentClient: state.currentClient?.data,
});

const mapDispatchToProps = {
  getDesigns,
  sortSurveyDesigns,
  sortSurveyDesignsApi,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesignIndex);
