import * as React from "react";
import TooltipContainer from "./TooltipContainer";
import TooltipContext from "./TooltipContext";
var TooltipProvider = function (_a) {
    var tooltipPlacement = _a.tooltipPlacement, tooltipClassName = _a.tooltipClassName, tooltipOffsetX = _a.tooltipOffsetX, tooltipOffsetY = _a.tooltipOffsetY, disableTooltip = _a.disableTooltip, children = _a.children;
    var containerRef = React.useRef(null);
    var _b = React.useState(false), isVisible = _b[0], setIsVisible = _b[1];
    var _c = React.useState([null, null]), pos = _c[0], setPos = _c[1];
    var _d = React.useState(null), content = _d[0], setContent = _d[1];
    var _e = React.useState(tooltipPlacement), placement = _e[0], setPlacement = _e[1];
    var showTooltip = React.useCallback(function (content, _a) {
        var clientX = _a.clientX, clientY = _a.clientY;
        var _b = containerRef.current.getBoundingClientRect(), width = _b.width, height = _b.height, left = _b.left, top = _b.top;
        var x = clientX - left;
        var y = clientY - top;
        if (!placement) {
            var autoPlacement = x < width / 2
                ? y < height / 2
                    ? "bottomRight"
                    : "topRight"
                : y < height / 2
                    ? "bottomLeft"
                    : "topLeft";
            setPlacement(autoPlacement);
        }
        setIsVisible(true);
        setPos([x, y]);
        setContent(content);
    }, [containerRef, setContent, setIsVisible, setPos]);
    var hideTooltip = React.useCallback(function () {
        setIsVisible(false);
    }, [setIsVisible, setPos]);
    var tooltipContextValue = React.useMemo(function () { return ({
        showTooltip: showTooltip,
        hideTooltip: hideTooltip,
        tooltipClassName: tooltipClassName,
        disableTooltip: disableTooltip,
    }); }, [showTooltip, hideTooltip, tooltipClassName]);
    return (React.createElement(TooltipContext.Provider, { value: tooltipContextValue },
        React.createElement("div", { ref: containerRef },
            children,
            isVisible && (React.createElement(TooltipContainer, { position: pos, placement: placement, offsetX: tooltipOffsetX, offsetY: tooltipOffsetY }, content)))));
};
export default TooltipProvider;
