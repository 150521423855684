import React, { useState } from 'react';

const StrategicActivityClassificationEditor = (props) => {
  const [value, setValue] = useState(props.value);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    props.stopEditing(); // Stop editing immediately after value change

    // Update the global state
    props.node.setDataValue(props.column.colId, newValue);
  };

  return (
    <select value={value} onChange={handleChange} autoFocus>
      <option value="Strategic">Strategic</option>
      <option value="Support">Support</option>
    </select>
  );
};

export default StrategicActivityClassificationEditor;
