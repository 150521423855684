import * as React from "react";
import { getNumberItemsWidthByNumberOfChars } from "../helpers";
export var NumberOfChildrenPlacement;
(function (NumberOfChildrenPlacement) {
    NumberOfChildrenPlacement[NumberOfChildrenPlacement["TopRight"] = 0] = "TopRight";
    NumberOfChildrenPlacement[NumberOfChildrenPlacement["BottomRight"] = 1] = "BottomRight";
})(NumberOfChildrenPlacement || (NumberOfChildrenPlacement = {}));
var NumberOfChildren = function (_a) {
    var customId = _a.customId, width = _a.width, height = _a.height, textColor = _a.textColor, nodeTotalNodes = _a.nodeTotalNodes, isSelectedNode = _a.isSelectedNode, placement = _a.placement, style = _a.style;
    var realPlacement = isSelectedNode
        ? NumberOfChildrenPlacement.TopRight
        : placement;
    var fontSize = Number(style.fontSize);
    var itemsWidth = getNumberItemsWidthByNumberOfChars(fontSize, nodeTotalNodes.toString().length);
    var itemHeightFactor = 2;
    var itemsHeight = fontSize + itemHeightFactor;
    var strokeDasharrayTotal = itemsWidth + itemsHeight;
    if (width > itemsWidth && height > itemsHeight) {
        return (React.createElement("g", { transform: "translate(0, " + (realPlacement === NumberOfChildrenPlacement.BottomRight
                ? height - itemsHeight
                : 0) + ")" },
            React.createElement("rect", { id: "rectNumberItems-" + customId, x: width - itemsWidth, y: 0, width: itemsWidth, height: itemsHeight, fill: "none", stroke: textColor, strokeDasharray: (realPlacement === NumberOfChildrenPlacement.BottomRight
                    ? itemsWidth
                    : 0) + "," + strokeDasharrayTotal + "," + strokeDasharrayTotal }),
            React.createElement("text", { fill: textColor, x: width - itemsWidth + itemsWidth / 2, y: itemsHeight - itemHeightFactor, textAnchor: "middle", style: {
                    fontVariant: style.fontVariant,
                    fontWeight: style.fontWeight,
                    fontSize: style.fontSize,
                    fontFamily: style.fontFamily,
                } }, nodeTotalNodes)));
    }
    return null;
};
export default NumberOfChildren;
