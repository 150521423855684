import "./Breadcrumb.css";
import * as React from "react";
import classnames from "classnames";
export var Breadcrumb = function (_a) {
    var className = _a.className, items = _a.items;
    if (!items) {
        return null;
    }
    return (React.createElement("div", { className: classnames("TreeMap__breadcrumb", className) }, items.map(function (item, index) { return (React.createElement("div", { key: index },
        React.createElement("a", { className: "TreeMap__breadcrumbItem", key: item.key, id: "" + item.key, onClick: item.onClick ? item.onClick : undefined, style: { cursor: item.onClick ? "pointer" : "auto" } }, item.text),
        index < items.length - 1 ? (React.createElement("span", { className: "TreeMap__breadcrumbSeparator" }, "/")) : null)); })));
};
