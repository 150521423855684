import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

import Button from 'components/Button';
import SVG from 'components/SVG';

function generateNewKey(labels) {
  let idx = 0;
  while (labels[`custom-${idx}`] !== undefined) idx += 1;
  return `custom-${idx}`;
}

export default function CustomLabelEditor({
  open,
  defaultLabels,
  setLabels,
  toggle,
}) {

  const [currentDefaultLabels, setCurrentDefaultLabels] = useState(defaultLabels);
  function onSave() {
    setLabels(currentDefaultLabels);
    toggle();
  }

  const onAdd = () => {
    let newLabel = generateNewKey(currentDefaultLabels)
    setCurrentDefaultLabels({
      ...currentDefaultLabels,
      [newLabel]: newLabel,
    })
  }
  return (
    <Modal isOpen={open} toggle={toggle} className="design-label-edit-modal">
      <ModalBody>
        <h3>Edit Labels</h3>
        <div className="row p-2">
          <div className="d-flex flex-column">
            {Object.keys(currentDefaultLabels).map((c, idx) => (
              currentDefaultLabels?.[c] && (
                <div className="form-group row mt-1" key={`default-${idx}`}>
                  <div className="col-sm-10">
                    <input
                      placeholder=""
                      type="text"
                      className="form-control"
                      value={currentDefaultLabels?.[c] || ''}
                      onChange={(e) => {
                        setCurrentDefaultLabels({
                          ...currentDefaultLabels,
                          [c]: e.target.value,
                        })
                      }}
                      onBlur={(e) => {
                        setCurrentDefaultLabels({
                          ...currentDefaultLabels,
                          [c]: e.target.value || defaultLabels[c],
                        })
                      }}
                    />
                  </div>
                  <div className="col-sm-2 d-flex align-items-center">
                    <div
                      className="d-flexalign-items-center"
                      onClick={() => setCurrentDefaultLabels({
                        ...currentDefaultLabels,
                        [c]: '',
                      })}
                    >
                      <SVG
                        icon="delete.svg#delete"
                        iconWidth={24}
                        iconHeight={24}
                        iconClassName="button__icon button__icon-red"
                      />
                    </div>
                  </div>
                </div>
              )
            ))}
            <div className="form-group mt-1 d-flex">
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          text="Add label"
          onClick={onAdd}
          className="button ms-auto"
        />
        <Button text="Save" onClick={onSave} className="button ms-auto" />
        <Button text="Cancel" onClick={toggle} className="button ms-3 red" />
      </ModalFooter >
    </Modal >
  );
}
