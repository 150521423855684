import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Button from 'components/Button';
import TextInput from 'components/TextInput';

const DesignTitleModal = (props) => {
  const { onConfirm, defaultTitle = '', onToggle, showModal } = props;
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    // This will update the title state whenever the defaultTitle prop changes
    setTitle(defaultTitle);
  }, [defaultTitle]);

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const onModalToggle = () => {
    // Consider whether you want to reset the title to defaultTitle here
    // setTitle(defaultTitle);
    onToggle(!showModal);
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={onModalToggle}
      className="modal-dialog-mini modal-dialog-centered"
    >
      <ModalBody className="text-center p-5">
        <div className="filter-header">
          <TextInput
            type="text"
            placeholder="Enter title of design"
            onChange={onTitleChange}
            name="title"
            value={title}
          />
          <Button text="Create" onClick={() => onConfirm(title)} className="button me-3"/>
          <Button text="Cancel" className="button ms-3" onClick={onModalToggle}/>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DesignTitleModal;
