import React from 'react';

import LinkButton from 'components/LinkButton';
import Surveys from './components/Surveys';

const SurveyIndex = (props) => {
  return (
    <>
      <div className="content__header py-4 d-lg-flex align-items-center">
        <h3 className="ellipsis">Discover your strategic alignment</h3>
        <div className="ms-lg-auto">
          <LinkButton
            to="/discover/create"
            text="Create survey "
            iconWidth={16}
            iconHeight={16}
            icon="plus.svg#plus"
            iconClassName="button__icon button__icon-blue"
            className="button"
          />
        </div>
      </div>
      <Surveys/>
    </>
  );
};

export default SurveyIndex;
