import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ButtonGroup, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import ApiHelper from 'api/helper';
import { ActivitySelector } from 'components/ActivitySelector';
import Button from 'components/Button';
import Loading from 'components/Loading';
import TextInput from 'components/TextInput';
import ToggleButton from 'components/ToggleButton';
import MappingFilters from './components/MappingFilters';
import clsx from 'classnames';

const Header = (props) => {
  let errors = '';

  function onTitleChange(e) {
    props.setTitle(e.target.value);
  }

  return (
    <div className="filter-header">
      <div>
        <TextInput
          type="text"
          placeholder="Enter title of design"
          onChange={onTitleChange}
          name="title"
          value={props.title}
          error={errors && errors.title}
        />
      </div>
    </div>
  );
};

const FilterModal = (props) => {
  const { designs, toggle, modal, refresh, filters: rawFilters } = props;
  const [clusterCount, setClusterCount] = useState(10);
  const [optimizeType, setOptimizeType] = useState(2);
  const [selectDesignError, setSelectDesignError] = useState('');
  const [filters, setFilters] = useState([]);
  const [design, setDesign] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [defaultActivities, setDefaultActivities] = useState([]);
  const [title, setTitle] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setTitle('');
  }, [modal]);

  function handleChangeActivity(values) {
    if (!design) {
      return;
    }
    setActivities(values);
  }

  function onChangeClusterCount(event) {
    setClusterCount(event.target.value);
  }

  function onMappingFilterChange(ids) {
    setFilters(ids);
  }

  function onChangeOptimizeType(event, value, id) {
    setOptimizeType(id);
  }

  function optimizeDesign() {
    if (!design) {
      setSelectDesignError('Should select a design to run optimization');
      return;
    }
    setLoading(true);
    ApiHelper.post('/api/design-optimize/', {
      data: {
        design: design.value,
        client: +props.currentClient,
        title,
        filter_role: (activities || []).map((r) => r.value.toString()),
        filters,
        optimize_type: optimizeType,
        max_clusters: clusterCount,
      },
    }).then((res) => {
      toggle();
      refresh();
    }).catch((err) => {
      const { response } = err;
      if (response.status === 400) {
        setSelectDesignError(response.data.message);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  function onSelectDesign(design) {
    setSelectDesignError(false);
    setDesign(design);
    const rawDesign = designs.find(item => item.id === design.value);
    const parsedActivities = JSON.parse(rawDesign.activities);
    const activities = (parsedActivities || []).map(item => ({
      ...item,
      group: item.function,
      name: item.activity,
      value: item.id,
    }));
    setFilters(rawDesign.filters);
    setDefaultActivities(activities);
    setActivities(activities.map(item => ({
      value: String(item.id),
      label: item.activity,
    })));
    setTitle('AI designed: ' + rawDesign.title);
    setClusterCount(rawDesign.group_mapping?.length);
  }

  const BottomButtons = () => {
    if (loading) {
      return (
        <div className="row">
          <div className="col">
            <Loading size="sm" />
          </div>
          <div
            className="col my-auto"
            style={{ fontSize: '25px', color: '#aaa' }}
          >
            Creating optimized design now...
          </div>
        </div>
      );
    } else {
      return (
        <>
          <Button
            color="primary"
            onClick={toggle}
            text="Cancel"
            className="button"
          />
          <Button
            color="primary"
            text="Run Optimization"
            onClick={optimizeDesign}
            className="button"
          />
        </>
      );
    }

  };

  let designOptions = designs.map((design) => {
    return {
      value: design.id,
      label: design.title,
    };
  });

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      autoFocus={false}
      id="main-modal"
    >
      <ModalBody>
        <Header title={title} setTitle={setTitle} />
        <div className="card__content-wrapper pb-2 mt-4">
          <div className="card__header row align-items-center">
            <h5>Select Design to run optimization</h5>
          </div>
          <Select
            name="designs"
            placeholder="Select design"
            options={designOptions}
            onChange={onSelectDesign}
            value={design}
            autoFocus
            className={clsx('select-design', { 'is-invalid': selectDesignError })}
          />
          {selectDesignError && <FormFeedback>{selectDesignError}</FormFeedback>}
        </div>
        <div className="row mt-4">
          <div className="card col-12 col-md-6">
            <div className="p-2 filter-activities-content">
              <div className="card__header align-items-center">
                <h5 className="col-md-12">Activities</h5>
              </div>
              <div className="card-content filter-activities-list">
                <ActivitySelector
                  value={activities}
                  setValue={handleChangeActivity}
                  options={defaultActivities}
                />
              </div>
            </div>
          </div>

          <div className="card col-12 col-md-6">
            <MappingFilters
              disabled={!design}
              allFilters={rawFilters}
              selectedFilters={filters}
              onMappingFilterChange={onMappingFilterChange}
            />
            <div className="card__content-wrapper pb-2 mt-4">
              <div className="card__header row align-items-center">
                <h5>Optimize for organization type</h5>
              </div>
              <div className="card-content">
                <div
                  className="custom-checkbox--activities custom-checkbox row">
                  <ButtonGroup style={{ width: '100%' }}>
                    <ToggleButton
                      checked={optimizeType === 1}
                      disabled={!design}
                      onChange={onChangeOptimizeType}
                      value={1}
                      text="Lean"
                    />
                    <ToggleButton
                      checked={optimizeType === 2}
                      disabled={!design}
                      onChange={onChangeOptimizeType}
                      value={2}
                      text="Balanced"
                    />
                    <ToggleButton
                      checked={optimizeType === 3}
                      disabled={!design}
                      onChange={onChangeOptimizeType}
                      value={3}
                      text="Independent"
                    />
                  </ButtonGroup>
                </div>
              </div>
            </div>
            <div className="card__content-wrapper pb-2 mt-4">
              <div className="card__header row align-items-center">
                <h5>Maximum number of units</h5>
              </div>
              <div className="card-content">
                <div>
                  <TextInput
                    type="text"
                    value={clusterCount}
                    style={{ border: 'none' }}
                    onChange={onChangeClusterCount}
                    disabled={!design}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <BottomButtons />
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  filters: state.profile.data?.filters,
  currentClient: state.currentClient?.data,
});

export default connect(mapStateToProps, null)(FilterModal);
