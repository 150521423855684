import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';

import { getDiagnose } from 'api/diagnose';
import Loading from 'components/Loading';
import toast from 'lib/toast';
import withRouter from 'lib/withRouter';
import SurveyParticipants from '../Survey/components/SurveyParticipants';
import Explanation from './Explanation';
import Button from '../../components/Button';
import SurveyConfirmModal from '../Survey/components/SurveyConfirmModal';
import { closeDiagnoseSurvey, deleteDiagnoseSurvey } from '../../redux/actions/survey';
import { getDownloadUrl } from 'api/survey';


const Diagnoses = (props) => {
  const useDiagnose = (id, clientId) => {
    return useQuery({
      queryKey: ['diagnose', id, clientId],
      queryFn: () => getDiagnose(id, clientId),
      enabled: !!id,
      retry: 0,
    });
  };

  const deleteConfirmModalRef = useRef();
  const closeConfirmModalRef = useRef();
  const downloadConfirmModalRef = useRef();
  const [refreshTime, setRefreshTime] = useState(new Date());
  const { params } = props.match;
  let diagnosis = useDiagnose(params.id, +props.currentClient || undefined);
  const { data, isFetching, isError, refetch } = diagnosis;
  const interval  = 10000;

  useEffect(() => {
    if (((data?.closed === true) && (data?.pdf_name === null)) || ((props?.close?.data?.closed_id === data?.id.toString()) && (data?.pdf_name === null))) {
      const intervalId = setInterval(() => {
        
          refetch();
        
      }, interval);

      return () => clearInterval(intervalId);
    }
  }, [refetch, data, props?.close?.data?.closed_id, interval]);

  if (props?.close?.loading) return <Loading text="Closing survey..."/>;
  if (props?.delete_diagnose?.loading) return <Loading text="Deleting diagnose..."/>;
  if (isFetching) {
    return <Loading text="Loading data..."/>;
  }

  if (isError) {
    if (diagnosis.error.response.status === 404) {
      toast('Diagnosis not found');
    } else {
      toast('Failed to load Diagnosis');
    }
    return null;
  }

  function deleteDiagnoseSurvey() {
    props.deleteDiagnoseSurvey(params?.id, +props.currentClient);
  }
  function closeDiagnoseSurvey() {
    if (props?.close?.data?.closed_id !== data?.id.toString() || data?.close) {
      props?.closeDiagnoseSurvey(params?.id);
    }
  }

  function downloadReportPdf() {
    getDownloadUrl(params?.id).then(res => {
      let url = res?.data?.url;
      const aTag = document.createElement('a');
      aTag.href = url;
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    }).catch(err => {
      toast('Download failed');
    }).finally(() => {
      toast('Download Successful');
    });
  }

  const customColumns = (data.participants_columns || []).filter(col => col.custom);

  return (
    <div className="diagnose-index">
      <div className="content__header pt-3 d-flex justify-content-between">
        <div>
          <div className="ellipsis">
            <span className="fs-3">Diagnose survey</span>
            {((data?.closed) || (props?.close?.data?.closed_id === data?.id.toString())) &&
              <span className="closed-survey ms-1">Survey closed</span>}
          </div>
          <div className="ellipsis text-black fs-3">{data?.title}</div>
          <div className="ellipsis text-black font_24">Company: {data?.company_name}</div>
        </div>
        <div>
          <div className="mx-auto">
            {(data?.pdf_name !== null && data?.closed === true) &&
              <Button
                className="button mx-auto"
                text="Download report"
                onClick={() => downloadConfirmModalRef.current.toggle()}
                iconWidth={16}
                iconHeight={16}
                icon="close.svg#close"
                iconClassName="button__icon button__icon-blue"
              />
            }
            {(((data?.closed === true) && (data?.pdf_name === null)) || ((props?.close?.data?.closed_id === data?.id.toString()) && (data?.pdf_name === null))) &&
              <a>Your report is being generated by Reconfigs AI. Please check back in a few minutes.</a>
            }
          </div>
        </div>
        <div>
          {((data?.closed) || (props?.close?.data?.closed_id === data?.id.toString())) ?
            (<></>) :
            (<Button
              className="button me-lg-3"
              text="End survey"
              onClick={() => closeConfirmModalRef.current.toggle()}
              iconWidth={16}
              iconHeight={16}
              icon="close.svg#close"
              iconClassName="button__icon button__icon-blue"
            />)
          }
          <Button
            className="button me-lg-0"
            text="Delete survey"
            onClick={() => deleteConfirmModalRef.current.toggle()}
            iconWidth={16}
            iconHeight={16}
            icon="close.svg#close"
            iconClassName="button__icon button__icon-blue"
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="card">
          <SurveyParticipants
            data={data?.participants}
            customColumns={[]}
            hideExport
            isSimple
          />
        </div>
      </div>
      <Explanation/>
      <SurveyConfirmModal
        ref={deleteConfirmModalRef}
        message="Are you sure to delete this survey?"
        onConfirm={deleteDiagnoseSurvey}
      />
      <SurveyConfirmModal
        ref={closeConfirmModalRef}
        message="Are you sure to close this survey?"
        onConfirm={closeDiagnoseSurvey}
      />
      <SurveyConfirmModal
        ref={downloadConfirmModalRef}
        message="Do you want to download generated report PDF?"
        onConfirm={downloadReportPdf}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentClient: state.currentClient?.data,
  close: state.closeDiagnoseSurvey,
  delete_diagnose: state.deleteDiagnoseSurvey,
});

const mapDispatchToProps = {
  closeDiagnoseSurvey,
  deleteDiagnoseSurvey,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Diagnoses));
