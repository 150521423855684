import ApiHelper from './helper';

export async function getChatHistory() {
  const url = '/api/chat/';

  const { data } = await ApiHelper.get(url);
  return data;
}

export async function sendChatMessage(params) {
    const url = '/api/chat/';
  
    const { data } = await ApiHelper.post(url, { data: params });
    return data;
  }

  export async function postSearchApi(params) {
    const url = '/api/search/';
  
    const { data } = await ApiHelper.post(url, { data: params });
    return data;
  }
