import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { getOptions, getOptionsSorted } from './util';
import classNames from 'classnames';
import { ActivitySelector } from 'components/ActivitySelector';

function MultiSelect({ column, options, value, setValue, setValid }) {
  const toggleValue = useCallback(
    (v) => {
      let newValue = [];
      if (
        value?.findIndex &&
        value.findIndex((o) => o.value === v.value) > -1
      ) {
        newValue = value.filter((vv) => vv.value !== v.value);
      } else if (value?.length) {
        newValue = [...value, v];
      } else {
        newValue = [v];
      }
      setValue(newValue);
    },
    [setValue, value]
  );

  useEffect(() => {
    setValid(value?.length > 0);
  }, [value, setValid]);


  return (
    <ul className="list-group mt-2">
      {options.map((o) => (
        <li
          className={classNames('list-group-item', {
            active:
              value?.indexOf &&
              value.findIndex((v) => v.value === o.value) > -1,
          })}
          key={o.value}
          onClick={() => toggleValue(o)}
        >
          {o.label || 'None'}
        </li>
      ))}
    </ul>
  );
}

function MinMaxSelect({ value, setValue, setValid, max }) {
  const lower = value?.lower || 1;
  const upper = value?.upper || max;
  const setBound = (key, v) => {
    const newValue = {
      upper,
      lower,
      [key]: parseInt(v),
    };
    setValue(newValue);
    setValid(newValue.upper >= newValue.lower);
  };


  useEffect(() => {
    setValid(upper > lower);
    if (!value?.upper) {
      setValue({
        upper,
        lower,
      })
    }
  }, [value, setValid, upper, lower, setValue]);

  return (
    <div className="mt-2">
      <div className="input">
        <label>Lower bounds</label>
        <input
          type="number"
          min={1}
          max={max}
          onChange={(e) => setBound('lower', e.target.value)}
          value={lower}
        />
      </div>
      <div className="input">
        <label>Upper bounds</label>
        <input
          type="number"
          min={1}
          max={max}
          onChange={(e) => setBound('upper', e.target.value)}
          value={upper}
        />
      </div>
    </div>
  );
}

export default function FilterValues({
  column,
  data,
  value,
  setValue,
  setValid,
  shouldSort = true,
}) {
  const options = useMemo(() => {
    return shouldSort ? getOptionsSorted(column.key, data, value, column.resource) : getOptions(column.key, data, column.resource);
  }, [column, data, value, shouldSort]);

  if (column.resource) {
    return (
      <MultiSelect
        column={column}
        options={options}
        value={value}
        setValue={setValue}
        setValid={setValid}
      />
    );
  }

  switch (column.key) {
    case 'permanence':
    case 'reported_by':
      return (
        <MultiSelect
          column={column}
          options={options}
          value={value}
          setValue={setValue}
          setValid={setValid}
        />
      );
    case 'weight_new':
    case 'group_length':
      return (
        <MinMaxSelect
          options={options}
          value={value}
          setValue={setValue}
          setValid={setValid}
          max={options.length}
        />
      );
    case 'activity':
      return (
        <ActivitySelector
          options={options}
          value={value}
          setValue={setValue}
          setValid={setValid}
        />
      );
    default:
      return (
        <MultiSelect
          column={column}
          options={options}
          value={value}
          setValue={setValue}
          setValid={setValid}
        />
      );
  }
}

function MultiTerm({ filter }) {
  return (
    <div className="header-term-value">
      {filter.value?.map((v) => v.label).join(',')}
    </div>
  );
}

function MinMaxTerm({ filter }) {
  return (
    <div className="header-term-value">{`${filter.value.lower}-${filter.value.upper}`}</div>
  );
}

export function FilterTerm({ filter }) {
  if (filter.resource) {
    return <MultiTerm filter={filter} />;
  }
  switch (filter.key) {
    case 'activity':
    case 'permanence':
    case 'reported_by':
      return <MultiTerm filter={filter} />;
    case 'weight_new':
    case 'group_length':
      return <MinMaxTerm filter={filter} />;
    default:
      return <MultiTerm filter={filter} />;
  }
}
