import React, { Component } from 'react'
import { ReactComponent as LogoutSVG } from '../../../img/icons/logout.svg'
import { logout } from 'redux/actions/auth'
import { connect } from 'react-redux'
import withRouter from 'lib/withRouter'
class Logout extends Component {
  logout = () => {
    this.props.logout()
  }
  render() {
    return (
      <div className="logout cursor-pointer" onClick={this.logout}>
        <LogoutSVG />
      </div>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = {
  logout
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Logout))


