import React, { Component } from 'react';
import CSVReader from 'components/CSVReader';
import { setSurvey } from 'redux/actions/survey';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import AgTable from 'components/AgTable';
import Button from 'components/Button';

class SurveyActivitiesCreate extends Component {
  onDataLoad = (data) => {
    this.props.setActivities(data);
  };

  onDataError = (err) => {
    console.log(err);
  };

  onUpdate = (data, error) => {
    let dataList = data.map((row) => [row.group, row.activity]);
    this.props.setActivities(dataList, error);
  };

  getData = () => {
    const { activities } = this.props;

    if (!Array.isArray(activities)) return [];

    if (activities.length) {
      return activities.map((row) => ({
        group: row[0],
        activity: row[1],
      }));
    }
  };

  validation = (col, value, data) => {
    const otherColumn = col === 'activity' ? 'group' : 'activity';
    const duplicatedRows = this.getData().filter(
      v => v[col] === value && v[otherColumn] === data[otherColumn]
    );

    if (duplicatedRows.length > 1) return 'Duplicate is not allowed.';

    return value ? '' : 'This field can\'t be empty.';
  };

  render() {
    let data = this.getData();
    let { error } = this.props;
    return (
      <div className="card__content-wrapper">
        <div className="card__header row align-items-center">
          <div className="col-12 mb-4">
            <h3>Which functions and activities should participants report on?</h3>
          </div>
          <div className="col-12">
            <Button
              className="button"
              text="Add New"
              onClick={() => this.onUpdate([{}, ...(data || [])])}
            />
            <CSVReader
              label="Upload csv"
              onFileLoaded={this.onDataLoad}
              onError={this.onDataError}
              parserOptions={{}}
            />
          </div>
          {error && <Alert color="danger mt-4">{error}</Alert>}
        </div>
        <div className="card-content">
          <AgTable
            data={data}
            columns={[
              { Header: 'Function', accessor: 'group' },
              { Header: 'Activity', accessor: 'activity' },
            ]}
            editable={true}
            onUpdate={this.onUpdate}
            validation={this.validation}
            emptyText="No activities"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activities: (state.survey.data && state.survey.data.activities) || [],
  error: state.survey.errors && state.survey.errors.activities,
});

const mapDispatchToProps = (dispatch) => ({
  setActivities: (activities, error) => {
    dispatch(
      setSurvey({
        activities: activities,
        activitiesError: error,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyActivitiesCreate);
