import React from 'react';

/* 
A simple text editor that can be used to edit the cell value in AG-Grid
It is used in the AG-Grid component to edit the cell value
This component is used in the AG-Grid component to edit the cell value
*/

class InlineTextCellEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
        this.refInput = React.createRef(); // Reference to the text area to focus
    }
  
    componentDidMount() {
        setTimeout(() => {
            this.refInput.current.focus();
            this.adjustTextAreaHeight();
        }, 100);
    }

    adjustTextAreaHeight = () => {
        // Assuming autoHeight is set to true for the column
        // Call resetRowHeights to resize the row to fit the content
        this.props.api.resetRowHeights();
    };
  
    getValue() {
        return this.state.value;
    }
  
    onChange = (event) => {
        this.setState({ value: event.target.value }, () => {
            // Resize the row height after the state is updated
            this.adjustTextAreaHeight();
        });
    };
  
    // Optional: Implement to control when the editor should stop editing
    isCancelBeforeStart() {
      // Return true if you want to cancel editing before it starts
      return false;
    }
  
    // Optional: Implement to control when the editor should stop editing after it has started
    isCancelAfterEnd() {
      // Return true if you want to cancel editing after it has started
      return false;
    }

    render() {  
        const height = this.props.eGridCell.clientHeight;
        const width = this.props.eGridCell.clientwidth;
        const textAreaStyle = { 
            width: '100%', 
            height: '100%', 
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: 0,
            margin: 0,
            resize: 'none',
            border: 'none',
        }
      
        return (
            <textarea
            ref={this.refInput}
            style={textAreaStyle}
            value={this.state.value}
            onChange={this.onChange}
            onBlur={() => this.props.stopEditing()} // Close editor on blur
            />
        );
    }
  }
  
export default InlineTextCellEditor;
