import React, { useState } from 'react';
import classNames from 'classnames';

import GaugeChart from 'components/Gauge';
import { FloatingTooltip, InfoCircle } from 'components/FloatingTooltip';

const coordinationLoadTooltipText = `Reconfig uses three factors to calculate Coordination Load:
A = coordination between departments is inefficient
B = missing coordination within a department is inefficient
C = larger departments are inefficient
Coordination Load = A + B + C
Lower Coordination Load means a more efficient organization`;

const Coordination = (props) => {
  const { employeeCount, fteCount, spanOfControl, totalWorkCapacity, TotalAutomationPotential, interfaces, coordinationLoad } = props;
  const [active, setActive] = useState(1);
  const formattedTotalCapapacity = Number((totalWorkCapacity || 0).toFixed(0)).toLocaleString('no-NO');
  const formattedAutomationPotential = Number((TotalAutomationPotential || 0).toFixed(0)).toLocaleString('no-NO')
  const formattedCoordinationLoad = Number((coordinationLoad.value || 0).toFixed(0)).toLocaleString('no-NO');

  return (
    <div className="coord-load">
      <div
        className="text-center btn btn-unselected w-100"
        style={{ width: '100%', marginBottom: '0.5rem' }}
      >
        Employees: {(employeeCount || 0).toLocaleString('no-NO')}
      </div>
      <div
        className="text-center btn btn-unselected w-100"
        style={{ width: '100%', marginBottom: '0.5rem' }}
      >
        Total FTE: {(fteCount || 0).toLocaleString('no-NO')}
      </div>
      <div
        className="text-center btn btn-unselected w-100"
        style={{ width: '100%', marginBottom: '0.5rem' }}
      >
        Span of control: {(spanOfControl || 0).toFixed(2)}
      </div>
      {(Number(interfaces) > 0) ? <div
        className="text-center btn btn-unselected w-100"
        style={{ width: '100%', marginBottom: '0.5rem' }}
      >
        Inter-unit relationships: {(interfaces || 0).toLocaleString('no-NO')}
      </div>
      : null}
      <div
        className="text-center btn btn-unselected w-100"
        style={{ width: '100%', marginBottom: '0.5rem' }}
      >
        Total work capacity: {formattedTotalCapapacity} h/mth
      </div>
      {(Number(formattedAutomationPotential) !== 0) ? <div
        className="text-center btn btn-unselected w-100"
        style={{ width: '100%', marginBottom: '0.5rem' }}
      >
        Total AI potential: {formattedAutomationPotential} h/mth
      </div>
      : null}
      {(Number(formattedCoordinationLoad) !== 0) ? <div>
        <FloatingTooltip text={coordinationLoadTooltipText}>
          <button
            className={
              classNames({
                'text-center': true,
                'sidebar': true,
                'btn': true,
                'btn-selected': active === 1,
                'btn-unselected': active !== 1,
              })
            }
            style={{ width: '100%' }}
            onClick={() => {
              setActive(1);
            }}
          >
            Coordination Load: {formattedCoordinationLoad}
          </button>
        </FloatingTooltip>
        <GaugeChart value={
            coordinationLoad.percent
          || 0
        }>
          Vs current organization
        </GaugeChart>
      </div>
      : null}
    </div>
  );
};

/*const Coordination = (props) => {
  const { productivityGain, coordinationLoad, interfaces, groupCollaboration, spanOfControl } = props;
  const [active, setActive] = useState(1);

  return (
    <div className="coord-load">
      <div
        className="text-center btn btn-unselected w-100"
        style={{ width: '100%', marginBottom: '0.5rem' }}
      >
        Unit relationships: {interfaces || 0}
      </div>
      <div
        className="text-center btn btn-unselected w-100"
        style={{ width: '100%', marginBottom: '0.5rem' }}
      >
        Unit cohesion: {(groupCollaboration || 0).toFixed(2)} %
      </div>
      <div
        className="text-center btn btn-unselected w-100"
        style={{ width: '100%', marginBottom: '0.5rem' }}
      >
        Span of control: {(spanOfControl || 0).toFixed(2)}
      </div>
      <button
        className={
          classNames({
            'text-center': true,
            'sidebar': true,
            'btn': true,
            'btn-selected': active === 0,
            'btn-unselected': active !== 0,
          })
        }
        style={{ width: '100%', marginBottom: '0.5rem' }}
        onClick={() => {
          setActive(0);
        }}
      >
        Productivity gain: {(productivityGain.value || 0).toFixed(2)} h/mth
      </button>
      <FloatingTooltip text={coordinationLoadTooltipText}>
        <button
          className={
            classNames({
              'text-center': true,
              'sidebar': true,
              'btn': true,
              'btn-selected': active === 1,
              'btn-unselected': active !== 1,
            })
          }
          style={{ width: '100%' }}
          onClick={() => {
            setActive(1);
          }}
        >
          Coordination Load: {(coordinationLoad.value || 0).toFixed(2)}
        </button>
      </FloatingTooltip>
      <GaugeChart value={
        (active === 1
          ? coordinationLoad.percent
          : productivityGain.percent)
        || 0
      }>
        Vs current organization
      </GaugeChart>
    </div>
  );
};*/

export default Coordination;
