import Button from 'components/Button';
import React, { useEffect, useState } from 'react'
import FilterModal from './FilterModal';
import Term from './Term';

export default function DesignsTermFilter({ data, addFilter, delFilter, resetFilter, filters }) {
  const [openAddFilterModal, setOpenAddFilterModal] = useState(false);
  const [filter, setFilter] = useState(null);
  const editFilter = (filter) => {
    setOpenAddFilterModal(true);
    setFilter(filter);
  }
  const showAddFilterModal = () => {
    setFilter(null);
    setOpenAddFilterModal(true);
  }
  useEffect(() => {
    return () => {
      resetFilter();
    }
  }, [])

  return !!data && (
    <div className="d-flex filter-header">
      {filters.map((f) => (
        <Term filter={f} key={f.id} delFilter={delFilter} editFilter={editFilter} />
      ))}
      <Button
        color="primary"
        onClick={showAddFilterModal}
        text="Add filter"
        className="button"
        icon="plus.svg#plus"
      />
      {openAddFilterModal && (<FilterModal
        data={data}
        modal={openAddFilterModal}
        toggle={() => setOpenAddFilterModal(!openAddFilterModal)}
        addFilter={addFilter}
        filter={filter}
      />)}
    </div>
  )
}
