import React, { Component } from 'react';

import AgTable from 'components/AgTable';
import { parseActivities } from 'lib/helper';

export default class SurveyActivities extends Component {
  constructor(props) {
    super(props);
    const { raw, hidden } = props.data;
    this.state = {
      data: (raw || []).filter(
        (row) => !row.properties || row.properties.disabled !== true,
      ),
      hidden,
    };
  }

  render() {
    const { data, hidden } = this.state;
    let columnDefs = [
      { Header: 'Function', accessor: 'group' },
      { Header: 'Activity', accessor: 'name' },
    ];
    const activities = parseActivities(data, hidden);

    if (!hidden) {
      columnDefs = [{ Header: 'Activity', accessor: 'name' }];
    }

    return (
      <div className="card__content-wrapper">
        <div className="card__header row align-items-center">
          <h3>Functions and activities</h3>
        </div>
        <div className="card-content">
          <AgTable
            data={activities}
            columns={columnDefs}
            emptyText="No activities"
          />
        </div>
      </div>
    );
  }
}
