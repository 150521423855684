import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router'
import LoginForm from './LoginForm'
import { login } from 'redux/actions/auth'
import { authErrors, isAuthenticated } from 'redux/reducers/index'
import Particles from 'react-particles-js'
import LoginParticleParam from './LoginParticleParam'
import faker from 'faker'

const Login = props => {
  const params = LoginParticleParam

  let images = []

  for (let i = 0; i < 100; i++) {
    let name = faker.name.findName();
    let color = '#' + ((1 << 24) * Math.random() | 0).toString(16)
    if (name.length < 30) {
      images.push({
        src: URL.createObjectURL(new Blob([`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
              <text x="50%" y="50%" dy="-0.3em" text-anchor="middle" fill="${color}">${name}</text>
              </svg>`], { type: 'image/svg+xml' })),
        width: 150,
        height: 150
      })
    }
  }

  params.particles.shape.images = images;

  const redirect = props.location?.state ? props.location.state.from.pathname : '/'
  if (props.isAuthenticated) {
    return <Navigate to={redirect} />
  } else {
    return (
      <div>
        <div className="login-container d-flex justify-content-center align-items-center">
          <LoginForm {...props} />
        </div>
        <Particles
          className="particles-js"
          params={params}
          width={window.innerWidth}
          height={window.innerHeight}
          canvasClassName="particle-canvas"
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  errors: authErrors(state),
  isAuthenticated: isAuthenticated(state)
})

const mapDispatchToProps = dispatch => ({
  onSubmit: (username, password) => {
    dispatch(login(username, password))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)


