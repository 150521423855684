import React, { useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

export const InfoCircle = ({ color = 'black', backgroundColor = 'white', borderColor = 'black' }) => {
  return (
    <div style={{
      display: 'inline-grid',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      border: `1px solid ${borderColor}`,
      backgroundColor: backgroundColor,
      color: color,
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '10px',
      verticalAlign: 'middle',
      marginRight: '4px',
    }}>
      i
    </div>
  );
};

const formatBoldBeforeColon = (line) => {
  const parts = line.split(/:(.+)/);
  if (parts.length > 1) {
    return [<strong key="bold">{parts[0]}:</strong>, parts[1]];
  }
  return [line];
};

const formatNumberedList = (line) => {
  const match = line.match(/^(\d+)\. (.*)/);
  if (match) {
    return [<strong key="number">{match[1]}.</strong>, ' ', match[2]];
  }
  return [line];
};

const formatLinks = (line) => {
  return line.split(/(https?:\/\/[^\s]+)/g).map((part, idx) => 
    /https?:\/\//.test(part) ? <a href={part} target="_blank" rel="noopener noreferrer" key={idx}>{part}</a> : part
  );
};

const applyAllFormats = (line) => {
  let formattedLine = formatBoldBeforeColon(line);
  
  
  if (formattedLine.length === 1) { // if it wasn't a numbered list
    formattedLine = formatNumberedList(formattedLine[0]);  
  }

  // Now apply link formatting, taking into account that the line might have multiple parts
  return formattedLine.map((part, idx) => (
    typeof part === 'string' ? formatLinks(part) : part
  ));
};

export const FloatingTooltip = ({ children, header, text }) => {
  const processText = (text) => {
    if (!text) {
      return '';
    }
    if (!text.includes('\n')) {
      return applyAllFormats(text);
    }
    return text.split('\n').map((line, index) => (
      <span key={index}>
        {applyAllFormats(line)}
        <br />
      </span>
    ));
  };
  
  const formattedText = processText(text);

  const [show, setShow] = useState(false);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  const popover = (
    <Popover id="popover-basic" className='popover'>
      <Popover.Header as="h3">
        {header}
      </Popover.Header>
      <Popover.Body>
        {formattedText}
      </Popover.Body>
    </Popover>
  );

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <OverlayTrigger
          placement="top"
          show={show}
          overlay={popover}
        >
        {children}
      </OverlayTrigger>
    </div>
  );
};

