import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { getClientsSlice, setClientSlice } from 'pages/Client/clientSlice';
import profileReducer from 'pages/Profile/profileSlice';
import sidebarReducer from './sidebarReducer';
import auth, * as fromAuth from './auth.js';
import * as surveyReducers from './survey';
import * as designReducers from './design';

export const isAuthenticated = state => fromAuth.isAuthenticated(state.auth);
export const authErrors = state => fromAuth.errors(state.auth);

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: auth,
  sidebar: sidebarReducer,
  profile: profileReducer,
  client: getClientsSlice.reducer,
  currentClient: setClientSlice.reducer,

  surveyCreate: surveyReducers.surveyCreate,
  survey: surveyReducers.getSurvey,
  surveyMeta: surveyReducers.getSurveyMeta,
  closeSurvey: surveyReducers.closeSurvey,
  closeDiagnoseSurvey: surveyReducers.closeDiagnoseSurvey,
  deleteDiagnoseSurvey: surveyReducers.deleteDiagnoseSurvey,
  deleteSurvey: surveyReducers.deleteSurvey,
  sendSurvey: surveyReducers.sendSurvey,
  designs: designReducers.getDesigns,
  designOrg: designReducers.getDesignOrg,
  designExport: designReducers.designExport,
  savedDesign: designReducers.savedDesign,
  designGraphFilter: designReducers.graphFilter,
  designMatrixFilter: designReducers.matrixFilter,
  designMatrixTermFilter: designReducers.matrixTermFilter,
  designCoefficient: designReducers.efficiencyCoefficient,
});
export default createRootReducer;
