import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import CircleProgressBar from 'components/CircleProgressBar';

const ItemCard = (props) => {
  const { data } = props;
  return (
    <Link
      className="col-xl-4 col-lg-6 col-md-12 col-12"
      to={`/diagnose/${data.id}`}
    >
      <Card>
        <CardBody className="card__content-wrapper">
          <h4 style={{
            paddingBottom: 15,
            textAlign: 'center',
          }}>{data.title}</h4>
          <div className="progress-bar-donut" style={{ margin: 'auto' }}>
            <CircleProgressBar
              percent={data.completed_count * 100 / data.participants_count}
            />
            {data.closed && <div className="closed-survey m-2">Survey closed</div>}
          </div>
        </CardBody>
      </Card>
    </Link>
  );
};

export default ItemCard;
