import jwtDecode from 'jwt-decode'
import * as auth from 'redux/constants/auth'

let accessToken = localStorage.getItem('accessToken')
let refreshToken = localStorage.getItem('refreshToken')

const initialState = {
  access: accessToken ? {
    token: accessToken,
    ...jwtDecode(accessToken)
  } : null,
  refresh: refreshToken ? {
    token: refreshToken,
    ...jwtDecode(refreshToken)
  } : null,
  errors: {}
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case auth.LOGIN_SUCCESS:
      localStorage.setItem('accessToken', action.payload.access)
      localStorage.setItem('refreshToken', action.payload.refresh)
      return {
        access: {
          token: action.payload.access,
          ...jwtDecode(action.payload.access)
        },
        refresh: {
          token: action.payload.refresh,
          ...jwtDecode(action.payload.refresh)
        },
        errors: {}
      }
    case auth.TOKEN_RECEIVED:
      return {
        ...state,
        access: {
          token: action.payload.access,
          ...jwtDecode(action.payload.access)
        }
      }
    case auth.LOGIN_FAILURE:
    case auth.TOKEN_FAILURE:
      return {
        access: undefined,
        refresh: undefined,
        errors: {
          ...action.payload.response,
          non_field_errors: action.payload.response.detail || action.payload.statusText
        }
      }
    case auth.LOGOUT:
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      return {
        error: null
      }
    default:
      return state
  }
}

export function isRefreshTokenExpired(state) {
  if (state && state.access && state.access.exp) {
    return state.access.exp < Date.now() / 1000
  }
  return true
}

export function isAuthenticated(state) {
  return !isRefreshTokenExpired(state)
}

export function errors(state) {
  return state.errors
}
