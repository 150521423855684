import React, { Component } from 'react'
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react'

export default class HTMLEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editorValue: props.value
    }
  }

  onChange = (editorValue, editor) => {
    this.setState({ editorValue }, () => { this.props.onChange(editorValue) })
  };

  getContent = () => {
    return this.state.editorValue
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.value !== nextProps.value) {
      this.setState({
        editorValue: nextProps.value,
      })
      return false;
    }
    return true;
  }

  render() {
    const { editorHeight, onChange, value, ...rest } = this.props
    const { editorValue } = this.state

    return (
      <TinyMCEEditor
        apiKey="l47peuat6250icozda4mg7yxwvhl119wbw3fpvkn19g8obqd"
        {...rest}
        value={editorValue}
        init={{
          height: editorHeight || 300,
          menubar: true,
          branding: false,
          paste_data_images: true,
          plugins: [
            'lists link image charmap preview anchor',
            'fullscreen',
            'insertdatetime paste code help'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
          save_onsavecallback: () => { },
        }}
        onEditorChange={this.onChange}
      />
    )
  }
}