import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import {
  ButtonGroup,
  InputGroup,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import SVG from 'components/SVG';
import ToggleButton from 'components/ToggleButton';
import { toImportance } from 'lib/helper';
import Loading from 'components/Loading';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { setEfficiencyCoefficient } from 'redux/actions/design';
import { postSearchApi } from 'api/chat';

const ImportRelationships = (props) => {
  const {
    showModal,
    activityOptions,
    activityFormat,
    toggleImportRelationship,
    handleImportRelationships,
    setActivityFormat,
    resources,
    threshold
  } = props;
  const activities = activityOptions.map(c => c.name);
  const hasDuplicatedActivity = new Set(activities).size !== activities.length;
  const hasError = activityFormat === 'Activity' && hasDuplicatedActivity;
  return (
    <Modal isOpen={showModal}
           toggle={toggleImportRelationship}>
      <ModalHeader>Import relationships</ModalHeader>
      <CSVWizard
        toggle={toggleImportRelationship}
        handleSubmit={handleImportRelationships}
        specifyColumns={[
          {
            key: 'from',
            label: 'From resource',
            placeholder: 'From',
            required: true,
          },
          {
            key: 'to',
            label: 'To resource',
            placeholder: 'To',
            required: true,
          },
          {
            key: 'value',
            label: 'Relationship importance',
            placeholder: 'Importance',
            required: true,
          },
          {
            key: 'permanence',
            label: 'Relationship stability',
            placeholder: 'Stability',
            required: true,
          },
          {
            key: 'frequency',
            label: 'Interaction frequency',
            placeholder: 'Frequency',
            required: true,
          },
          {
            key: 'duration',
            label: 'Interaction duration',
            placeholder: 'Duration',
            required: true,
          },
          {
            key: 'twoway',
            label: 'Two-way relationship',
            placeholder: 'Direction',
            required: true,
          },
          {
            key: 'relationship',
            label: activityFormat,
            placeholder: 'Activities',
            required: true,
          },
        ]}
        activityOption={activityFormat}
        hasActivityError={hasError}
        setActivityFormat={setActivityFormat}
        resources={resources}
        activities={activityOptions}
        threshold={threshold}
        height={750}
      />
    </Modal>
  );
};

const MatrixFilter = (props) => {
  const { scenarioOptions, data, getDesignOrg } = props;
  const { setMatrixFilter, matrixFilter } = props;
  const { nodes, links, defaultLabels, activityOptions, custom_columns } = data;
  const [efficiencyCoefficient, setEfficiencyCoefficient] = useState(props.coefficient);
  const [activityFormat, setActivityFormat] = useState('Activity');
  const [showImportModal, setShowImportModal] = useState(false);
  const [generateRelationshipsModal, setGenerateRelationshipsModal] = useState(false);
  const [generatingRelationshipsLoading, setGeneratingRelationshipsLoading] = useState(false);
  const debounceSave = useCallback(debounce(setCoefficient, 1000), []);
  useEffect(() => {
    debounceSave(efficiencyCoefficient);
  }, [efficiencyCoefficient]);

  function setCoefficient(value) {
    props.setEfficiencyCoefficient(value);
  }

  const orderByOptions = [
    ...Object.keys(defaultLabels).map((key) => (
      defaultLabels[key] && nodes.some(n => n[key]) && {
        label: defaultLabels[key],
        value: key,
        asc: 1,
      }
    )),
    ...scenarioOptions.map(item => ({
      label: item.name,
      value: item.id,
    })),
    { label: 'Coordination work effort', value: 'sumValue', asc: -1 },
    { label: 'Number of relationships', value: 'count', asc: -1 },
  ]
    .filter((u) => u)
    .concat(
      (custom_columns || []).map((column) => ({
        label: column.Header,
        value: column.accessor,
        asc: 1,
      })),
    );
  const colorIntensityOptions = [
    { label: 'Global', value: 'global' },
    ...Object.keys(defaultLabels).map((key) => (
      defaultLabels[key] && nodes.some(n => n[key]) && {
        label: defaultLabels[key],
        value: key,
      }
    )),

  ].filter((u) => u);
  const colorRelationshipOptions = [
    links.some(l => l.value_new) && {
      label: 'Relationship work effort',
      value: 'value_new',
    },
    links.some(l => l.value) && {
      label: 'Relationship importance',
      value: 'value',
    },
    links.some(l => l.permanence) && {
      label: 'Relationship stability',
      value: 'permanence',
    },
    links.some(l => l.frequency) && {
      label: 'Interaction frequency',
      value: 'frequency',
    },
    links.some(l => l.duration) && {
      label: 'Interaction duration',
      value: 'duration',
    },
  ].filter(u => !!u);

  const onOrderByChange = (options) => {
    setMatrixFilter({
      ...matrixFilter,
      order: options,
    });
  };
  const setColorIntensityOption = (option) => {
    setMatrixFilter({
      ...matrixFilter,
      colorIntensityOption: option,
    });
  };
  const setColorRelationshipOption = (option) => {
    setMatrixFilter({
      ...matrixFilter,
      colorRelationshipOption: option,
    });
  };
  const handleChangeAxisLabel = (event, value) => {
    let ax = event.target.getAttribute('id');
    let axis = matrixFilter.axis;
    if (value) {
      axis = [ax, ...axis];
    } else {
      let idx = axis.indexOf(ax);
      axis.splice(idx, 1);
    }
    setMatrixFilter({
      ...matrixFilter,
      axis: [...axis],
    });
  };
  const onCoefficientChange = (event) => {
    setEfficiencyCoefficient(event.target.value);
  };
  const toggleImportRelationship = () => {
    setShowImportModal(!showImportModal);
  };
  const handleImportRelationships = (data) => {
    toggleImportRelationship();
    const { nodes, threshold } = props.data;
    const links = data.rows.map((row) => {
      const from = nodes.find(n => n.name === row[data.from]);
      const to = nodes.find(n => n.name === row[data.to]);
      if (from && to) {
        const weight = toImportance(row[data.value], threshold, ['weight', 'weight_new']);
        const permanence = toImportance(row[data.permanence], threshold, ['permanence']);
        const frequency = toImportance(row[data.frequency], threshold, ['frequency']);
        const duration = toImportance(row[data.duration], threshold, ['duration']);
        const activityNames = row[data.relationship].split(';').map(u => u.trim());
        let twoWay = row[data.twoway].trim().toLowerCase();
        twoWay = twoWay === 'true' || twoWay === 'yes';
        let activities = [];
        if (activityFormat === 'Activity') {
          activities = activityNames.map(a => activityOptions.find(b => b.name === a)).filter(u => !!u).map(o => o.id);
        } else if (activityFormat === 'Function-Activity') {
          activities = activityNames.map(a => {
            let splited = a.split('-');
            let f = splited[0];
            let c = splited[1];
            return activityOptions.find(b => b.group === f && b.name === c);
          }).filter(u => !!u).map(o => o.id);
        }
        const relationship = activities.map(a => String(a));
        let link = {
          source: from.id,
          target: to.id,
          value: weight,
          value_new: frequency * duration,
          permanence,
          frequency,
          duration,
          relationship,
        };

        if (twoWay) {
          return [link, {
            ...link,
            source: link.target,
            target: link.source,
          }];
        } else {
          return link;
        }
      }
      return null;
    });

    props.data.links = links.filter(l => !!l).flat();
    props.onSave();
  };

  const toggleGenerateRelationships = () => {
    setGenerateRelationshipsModal(!generateRelationshipsModal);
  };

  const renderGenerateRelationshipsModal = () => {
    return (
      <Modal isOpen={generateRelationshipsModal} toggle={toggleGenerateRelationships}>
        <ModalHeader>Do you want to generate synthetic relationships between employees?</ModalHeader>
        {generatingRelationshipsLoading && (
        <div className="loading-spin">
          <Loading text="Simulating synthetic relationships between employees..." />
        </div>
        )}
        
        <ModalFooter>
          <button onClick={generateRelationships} className="button" disabled={generatingRelationshipsLoading}>
            overwrite existing data
            <span className="material-symbols-outlined">neurology</span>
          </button>
         {/* <button onClick={generateRelationships} className="button">
            Extend with more data
            <span className="material-symbols-outlined">neurology</span>
          </button>*/}
        </ModalFooter>

      </Modal>
    );
  };

  const generateRelationships = async () => {
    const { nodes, activities } = props.data;
    setGeneratingRelationshipsLoading(true);
    
    const apiPayload = {
        'prompt_template': 'generate_relationships',
        'design_id': props.data.id,
        'employees': nodes,
        'activities': activities,
    };

    try {
        const apiResponse = await postSearchApi(apiPayload);
        getDesignOrg(props.data.id);
        setGeneratingRelationshipsLoading(false)
        setGenerateRelationshipsModal(false);
    } catch (error) {
        console.error('Errr message: ', error);
        setGeneratingRelationshipsLoading(false)
        setGenerateRelationshipsModal(false);
    }
  }

  const { axis } = matrixFilter;
  let orderBys = matrixFilter.order.map(c => ({
    ...c,
    label: orderByOptions.find(d => d.value === c.value)?.label,
  })).filter(c => c.label);

  return (
    <div className="d-flex matrix-filter">
      <div>
        <h5>Employee labels</h5>
        <ButtonGroup vertical>
          <ButtonGroup className="col-md-12">
            {Object.keys(defaultLabels).map(c => (
              defaultLabels[c] ? (
                <ToggleButton
                  value={defaultLabels[c]}
                  key={c}
                  id={c}
                  checked={axis.indexOf(c) > -1}
                  onChange={handleChangeAxisLabel}
                />
              ) : null
            ))}
          </ButtonGroup>
          {custom_columns && custom_columns.length ? <ButtonGroup>
            {custom_columns.map((column) => (
              <ToggleButton
                value={column.Header}
                key={column.accessor}
                id={column.accessor}
                checked={axis.indexOf(column.accessor) > -1}
                onChange={handleChangeAxisLabel}
              />
            ))}
          </ButtonGroup> : null}
        </ButtonGroup>
        <h5 className="mt-2">Unit Labels</h5>
        <ButtonGroup>
          {scenarioOptions.map(option => (
            <ToggleButton
              value={option.name}
              id={option.id}
              key={option.id}
              onChange={handleChangeAxisLabel}
              checked={axis.indexOf(option.id) > -1}
            />
          ))}
        </ButtonGroup>
      </div>
      <div className="filter-select filter ms-1">
        <h5>Order by</h5>
        <Select
          name="filterDivisions"
          placeholder="Select order"
          options={orderByOptions}
          onChange={onOrderByChange}
          value={orderBys}
          closeMenuOnSelect={false}
          classNamePrefix="filter"
          isMulti
        />
      </div>

      <div className="filter-select">
        <h5>Color intensity grouped by</h5>
        <Select
          name="filterColorIntensityGroup"
          options={colorIntensityOptions}
          onChange={setColorIntensityOption}
          value={
            matrixFilter.colorIntensityOption ||
            colorIntensityOptions[0]
          }
        />
        <h5 className="mt-2">Color relationship by</h5>
        <Select
          name="filterColorIntensityGroup"
          options={colorRelationshipOptions}
          onChange={setColorRelationshipOption}
          value={
            matrixFilter.colorRelationshipOption ||
            colorRelationshipOptions[0]
          }
        />
      </div>
      <div className="filter-select">
        <h5>Expected productivity gain from coorganization</h5>
        <InputGroup>
          <Input
            placeholder="Efficiency percent"
            className="form-control text-end efficiency"
            type="number"
            name="coefficient"
            value={efficiencyCoefficient}
            onChange={onCoefficientChange}
          />
          <InputGroupText>%</InputGroupText>
        </InputGroup>
        <button
          onClick={toggleImportRelationship}
          className="button mt-3"
          style={{ width: '100%', marginBottom: '0.5rem' }}
        >
          Import relationships&nbsp;
          <SVG
            icon="import.svg#file-upload"
            iconWidth={18}
            iconHeight={20}
            iconViewWidth={100}
            iconViewHeight={123}
            iconClassName="button__icon button__icon-blue"
          />
        </button>
        <ImportRelationships
          handleImportRelationships={handleImportRelationships}
          showModal={showImportModal}
          setActivityFormat={setActivityFormat}
          activityOptions={activityOptions}
          activityFormat={activityFormat}
          toggleImportRelationship={toggleImportRelationship}
          resources={data.resources}
          threshold={data.threshold}
        />
        <button onClick={toggleGenerateRelationships} className="button" style={{ width: '100%', marginBottom: '0.5rem'}}>
                Generate synthetic relationships
                <span className="material-symbols-outlined">neurology</span>
              </button>
              {renderGenerateRelationshipsModal()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  coefficient: state.designCoefficient?.data,
});
const mapDispatchToProps = {
  setEfficiencyCoefficient,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatrixFilter);
