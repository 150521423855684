import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import store, { history } from './app/store';
import App from 'app/App';

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { cacheTime: 5000, refetchOnWindowFocus: false },
  },
});
const myFallback = <FallbackComponent/>;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

render(
  <Sentry.ErrorBoundary fallback={myFallback} showDialog>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <App/>
        </Router>
        <ReactQueryDevtools initialIsOpen={false}/>
      </QueryClientProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
