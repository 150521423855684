import React, { Component } from 'react';
import LineProgressBar from 'components/LineProgressBar';

export default class SurveyTopPanel extends Component {
  constructor(props) {
    super(props);
    let dateProgress = 0;
    if (props.data.end_date) {
      let endDate = new Date(props.data.end_date);
      endDate.setDate(endDate.getDate() + 1);
      dateProgress =
        (100 * (new Date() - new Date(props.data.created)) /
          (endDate - new Date(props.data.created)));
      dateProgress = Math.min(100, Math.floor(dateProgress));
    }
    this.state = {
      surveyProgress: props.data.completed_count * 100 / props.data.participants_count,
      dateProgress: dateProgress,
    };
  }

  render() {
    const { data } = this.props;
    const { dateProgress, surveyProgress } = this.state;

    return (
      <div className="card__content-wrapper">
        <div className="card__header row align-items-center">
          <h3>Discovery progress</h3>
        </div>
        <div className="card-content">
          <div className="row">
            <div className="col-6 position-relative mt-4 mb-3">
              <div className="progress-bar-line mb-2">
                <h4 className="ellipsis">Date progress</h4>
                <LineProgressBar
                  percent={dateProgress}
                  containerClassName="progress-bar-line-survey-top"
                />
              </div>
              <p className="progress-bar-description">
                Survey end date: <span>{data.end_date}</span>
              </p>
            </div>
            <div className="col-6 position-relative mt-4 mb-3">
              <div className="progress-bar-line mb-2">
                <h4 className="ellipsis">Response progress</h4>
                <LineProgressBar
                  percent={surveyProgress.toFixed(0)}
                  containerClassName="progress-bar-line-survey-top"
                />
              </div>
              <p className="progress-bar-description">
                Responded / Total participants:&nbsp;
                <span>{data.completed_count} / {data.participants_count}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
