import { RSAA } from 'redux-api-middleware';
import ls from 'local-storage';

export const apiAuthInjector = () => {
  return function (next) {
    return function (action) {
      const callApi = action[RSAA];

      // Check if this action is a redux-api-middleware action.
      if (callApi
        // && ['api/token/', ].indexOf(callApi.endpoint) >= 0
      ) {
        // Inject the Authorization header from localStorage.
        callApi.headers = Object.assign({}, callApi.headers, {
          Authorization: ls.get('accessToken') && `Bearer ${ls.get('accessToken')}`,
        });
      }

      // Pass the FSA to the next action.
      return next(action);
    };
  };
};

export const apiAuthFailureInjector = () => {
  return function (next) {
    return function (action) {
      if (action && action.payload && action.payload.status === 401) {
        action.type = '@@auth/TOKEN_FAILURE';
      }
      return next(action);
    };
  };
};
