import SVG from 'components/SVG';
import React from 'react';
import clsx from 'classnames';

export default function Toolbar({ selected, setSelected }) {
  return (
    <div className="matrix-toolbar">
      <div
        className={clsx('matrix-toolbar-button', { active: selected === 'select' })}
        onClick={() => setSelected('select')}
      >
        <SVG
          icon={
            selected === 'select'
              ? 'select.svg#selected'
              : 'select.svg#deselected'
          }
          iconWidth={24}
          iconHeight={24}
          iconViewWidth={42}
          iconViewHeight={42}
          iconClassName="button__icon"
        />
      </div>
      <div
        className={clsx('matrix-toolbar-button', { active: selected === 'search' })}
        onClick={() => setSelected('search')}
      >
        <SVG
          icon={
            selected === 'search'
              ? 'search.svg#selected'
              : 'search.svg#deselected'
          }
          iconWidth={24}
          iconHeight={24}
          iconViewWidth={42}
          iconViewHeight={42}
          iconClassName="button__icon"
        />
      </div>
      <div
        className={clsx('matrix-toolbar-button', { active: selected === 'highlight' })}
        onClick={() => setSelected('highlight')}
      >
        <SVG
          icon={
            selected === 'highlight'
              ? 'highlight.svg#selected'
              : 'highlight.svg#deselected'
          }
          iconWidth={24}
          iconHeight={24}
          iconViewWidth={42}
          iconViewHeight={42}
          iconClassName="button__icon"
        />
      </div>
      <div
        className={clsx('matrix-toolbar-button', { active: selected === 'edit' })}
        onClick={() => setSelected('edit')}
      >
        <SVG
          icon={
            selected === 'edit' ? 'edit.svg#selected' : 'edit.svg#deselected'
          }
          iconWidth={24}
          iconHeight={24}
          iconViewWidth={42}
          iconViewHeight={42}
          iconClassName="button__icon"
        />
      </div>
      <div
        className={clsx('matrix-toolbar-button', { active: selected === 'stamp' })}
        onClick={() => setSelected('stamp')}
      >
        <SVG
          icon={
            selected === 'stamp' ? 'stamp.svg#active' : 'stamp.svg#inactive'
          }
          iconWidth={24}
          iconHeight={24}
          iconViewWidth={42}
          iconViewHeight={42}
          iconClassName="button__icon"
        />
      </div>
    </div>
  );
}
