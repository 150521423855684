import { rgb } from "d3-color";
import { ColorModel } from "./ITreeMapProps";
import { extent } from "d3-array";
import { format } from "d3-format";
export var getTopSubParentId = function (node) {
    if (node.parent && node.parent.parent) {
        return getTopSubParentId(node.parent);
    }
    return node.customId;
};
export var getTopParent = function (node) {
    if (node.parent) {
        return getTopParent(node.parent);
    }
    return node;
};
export var getDepth = function (obj, childrenPropInData) {
    var depth = 0;
    if (obj[childrenPropInData]) {
        obj[childrenPropInData].forEach(function (d) {
            var tmpDepth = getDepth(d, childrenPropInData);
            if (tmpDepth > depth) {
                depth = tmpDepth;
            }
        });
    }
    return 1 + depth;
};
export var getHighContrastColor = function (r, g, b) {
    var c = [r / 255, g / 255, b / 255];
    for (var i = 0; i < c.length; ++i) {
        if (c[i] <= 0.03928) {
            c[i] = c[i] / 12.92;
        }
        else {
            c[i] = Math.pow((c[i] + 0.055) / 1.055, 2.4);
        }
    }
    var luminance = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return luminance > 0.4 ? "light" : "dark";
};
export var getHighContrastColorFromString = function (backgroundColor) {
    if (!backgroundColor) {
        return "dark";
    }
    var rgbColor = rgb(backgroundColor);
    if (rgbColor) {
        return getHighContrastColor(rgbColor.r, rgbColor.g, rgbColor.b);
    }
};
export var getValueFormatFn = function (valueFn, valueFormat) {
    var valueFormatFn = function (value) { return "" + value; };
    try {
        valueFormatFn = valueFn ? valueFn : format(valueFormat);
    }
    catch (e) {
        console.warn(e);
    }
    return valueFormatFn;
};
export var getColorDomainFn = function (topNode, data, colorModel, childrenPropInData, valuePropInData, customD3ColorScale) {
    var nodes = topNode.descendants();
    var d;
    switch (colorModel) {
        case ColorModel.Depth:
            d = [0, getDepth(data, childrenPropInData) - 1];
            break;
        case ColorModel.Value:
            d = extent(nodes, function (n) {
                if (n.parent !== null) {
                    return n[valuePropInData];
                }
            });
            break;
        case ColorModel.NumberOfChildren:
            d = extent(nodes, function (n) {
                return n.parent !== null ? n.descendants().length : 1;
            });
            break;
        case ColorModel.OneEachChildren:
            d = [data[childrenPropInData] ? data[childrenPropInData].length : 0, 0];
            break;
        default:
            break;
    }
    return customD3ColorScale.domain(d);
};
