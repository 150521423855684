import ToggleButton from 'components/ToggleButton';
import React, { useState } from 'react';
import { ButtonGroup } from 'reactstrap';

export default function OptimizeTypes({ data, handleOptimizeTypeChange }) {
  const [optimizeType, setOptimizeType] = useState(data.optimize_type);
  const onChange = (event, value, id) => {
    setOptimizeType(id);
    handleOptimizeTypeChange(id);
  };
  return (
    <div className="pb-2">
      <div className="card__header row align-items-center">
        <h4>Evaluate as organization type</h4>
      </div>
      <div className="card-content">
        <div className="custom-checkbox--activities custom-checkbox row design-label-selector">
          <ButtonGroup>
            <ToggleButton
              checked={optimizeType === 1}
              disabled={false}
              onChange={onChange}
              value={1}
              text="Lean"
            />
            <ToggleButton
              checked={optimizeType === 2}
              disabled={false}
              onChange={onChange}
              value={2}
              text="Balanced"
            />
            <ToggleButton
              checked={optimizeType === 3}
              disabled={false}
              onChange={onChange}
              value={3}
              text="Independent"
            />
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
}
