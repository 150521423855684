import { getEmployeeId, transposeObject, sum } from './helper';
import { FTE_HOURS_PER_MONTH } from './constants';
import { link } from 'd3';

// lean, balanced, independent
const profileAlpha = [0.2, 0.4, 0.6];
const profileBeta = [0.6, 0.3, 0.2];

export function getRelationshipWorkEffort(links, nodeFrom, nodeTo, fteInvolvedTemp) {
  let workEffort = null;
  if (nodeFrom?.id !== nodeTo?.id && !nodeFrom?.ax) {
    //If nodeFrom is not a group, return work effort for the link
    workEffort = links.filter(
      (th) => th.sourceId === nodeFrom.id && th.targetId === nodeTo.id
    ).map(
      (t) => t.workEffort * (fteInvolvedTemp || t.num_fte_involved)
    )[0];
  } else {
    // If nodeFrom is a group, return work effort for the group including all sub members
    const sourceGroupResources = new Set(getSubMembers(nodeFrom));
    const targetGroupResources = new Set(getSubMembers(nodeTo));
    // find unique links between source and target group so we don't count work effort twice for bidirectional links
    // Also ensures we take the highest work effort for a link between the two directions
    const uniqueLinks = links.filter(
      link => sourceGroupResources.has(link.sourceId)
        && targetGroupResources.has(link.targetId)
    ).reduce((acc, link) => {
      const sortedIds = [link.sourceId, link.targetId].sort();
      const linkId = sortedIds.join('-');
      if (!acc[linkId] || acc[linkId].workEffort < link.workEffort) {
        acc[linkId] = link;
      }
      return acc;
    }, {});
    workEffort = Object.values(uniqueLinks)
      .map(link => link.workEffort * link.num_fte_involved)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }
  return workEffort || 0;
}

export function getSubMembers(nodeFrom) {
  if(!nodeFrom) return null;
  if(!nodeFrom.childNodes) return [nodeFrom.id];
  const subMembers = [nodeFrom.id, ...nodeFrom.childNodes];
  return subMembers;
}

export function getProductivityGain(
  links, newNodes, baseResult, coefficient, members = []
) {
  let gain = 0;
  let totalWorkEffort = 0;
  const workEffortMap = {};

  if (!links?.length) return { percent: 0, value: 0 };

  (links || []).forEach(link => {
    const tmp = typeof (link.source) === 'object';
    const sourceId = tmp ? link.sourceId : link.source;
    const targetId = tmp ? link.targetId : link.target;
    const key = sourceId > targetId
      ? `${targetId}-${sourceId}`
      : `${sourceId}-${targetId}`;
    if (!workEffortMap[key]) {
      workEffortMap[key] = [];
    }
    workEffortMap[key].push(
      link.num_fte_involved
        ? link.value_new * link.num_fte_involved
        : link.value_new
    )
  });

  (links || []).forEach(link => {
    const tmp = typeof (link.source) === 'object';
    const sourceId = tmp ? link.sourceId : link.source;
    const targetId = tmp ? link.targetId : link.target;
    const key = sourceId > targetId
      ? `${targetId}-${sourceId}`
      : `${sourceId}-${targetId}`;
    if (members && members.length > 0) {
      if (members.indexOf(sourceId) === -1 && members.indexOf(targetId) === -1) {
        return;
      }
    }
    let oldSourceGroup, newSourceGroup, oldTargetGroup, newTargetGroup;
    let workEffort = 0;
    if (workEffortMap[key]) {
      workEffort = Math.max(...workEffortMap[key]) / workEffortMap[key].length;
    }
    totalWorkEffort += workEffort;
    newSourceGroup = newNodes.find(item => item.id === sourceId)?.group;
    newTargetGroup = newNodes.find(item => item.id === targetId)?.group;
    oldSourceGroup = baseResult?.nodes?.find(item => item.id === sourceId)?.group;
    oldTargetGroup = baseResult?.nodes?.find(item => item.id === targetId)?.group;

    if ((oldSourceGroup === oldTargetGroup) && (newSourceGroup !== newTargetGroup)) {
      gain -= workEffort;
    } else if ((oldSourceGroup !== oldTargetGroup) && (newSourceGroup === newTargetGroup)) {
      gain += workEffort;
    }
  });

  const productivityGain = (gain || 0) * coefficient / 100;
  const percent = (totalWorkEffort + productivityGain) / totalWorkEffort * 100;

  return { percent: percent, value: productivityGain };
}

export function getCoordinationLoadByCluster(nodeCount, linkCount, optimizeType) {
  /*
  * nodeCount: number of nodes
  * linkCount: number of links
  * optimizeType: 1 - lean, 2 - balanced, 3 - independent
  * 
  * return coordination load for the cluster
  * */
  const nc = 1;
  const nn = nodeCount;
  const s1 = 0;
  const s2 = nodeCount ** 2 - linkCount - nn;
  const alpha = profileAlpha[optimizeType - 1];
  const beta = profileBeta[optimizeType - 1];
  const mdl_weight = 1 - alpha - beta;
  const mdl = (nc + nn) * Math.log(nn) / Math.log(2);
  const type_error = alpha * (s1 * (2 * Math.log(nn + 1) / Math.log(2))) +
    beta * (s2 * (2 * Math.log(nn + 1) / Math.log(2)));
  const fitness = mdl * mdl_weight + type_error;
  return fitness / nodeCount;
}

export function getCoordinationLoad(data, nodeCount) {
  let coordinationLoadInPercent;
  let coordinationLoad = 0;
  let fitness;

  if (data?.result) {
    const { base_fitness, result, optimize_type } = data;
    let optKey = 'Fitness-' + ['Lean', 'Balanced', 'Independent'][optimize_type - 1];
    fitness = result[optKey];
    if (!fitness) fitness = result.Fitness;
    coordinationLoadInPercent = (fitness / base_fitness[optKey]) * 100;
    if (nodeCount) {
      coordinationLoad = fitness / nodeCount;
    } else {
      coordinationLoad = 0;
    }

    return { value: coordinationLoad, percent: coordinationLoadInPercent };
  }

  return {}
}

export function getActivityCountByUnit(data) {
  if (!data) {
    return;
  }
  let numInterUnitInterfaces = 0;
  const interUnitInterfacesPerTeam = {};
  data.links.forEach(link => {
    if (interUnitInterfacesPerTeam[link.sourceId] && interUnitInterfacesPerTeam[link.sourceId][link.targetId]) {
      return;
    }
    interUnitInterfacesPerTeam[link.sourceId] = interUnitInterfacesPerTeam[link.sourceId] || {};
    interUnitInterfacesPerTeam[link.targetId] = interUnitInterfacesPerTeam[link.targetId] || {};
    interUnitInterfacesPerTeam[link.sourceId][link.targetId] = interUnitInterfacesPerTeam[link.targetId][link.sourceId] = 1;
    if (link.source?.group !== link.target?.group) {
      numInterUnitInterfaces++;
    }
  });

  return { numInterUnitInterfaces, interUnitInterfacesPerTeam: interUnitInterfacesPerTeam };
}

export function getActivityCountByUnitAndActivity(groups, links, activities) {
  if (!groups || !links || !activities) {
    return;
  }

  const interUnitInterfacesPerTeam = {};
  const activityCountByUnitAndActivity = {};

  // Initialize activityCounts for each group
  groups.forEach(group => {
    activityCountByUnitAndActivity[group] = {};
  });

  links.forEach(link => {
    link.relationship.forEach(activityId => {
      const activity = activities.find(act => act.id.toString() === activityId);
      const sourceGroup = link.source.group;
      const targetGroup = link.target.group;
      if (activity && sourceGroup && targetGroup) {
        // We only count activities for relationships where both sides of the relationship are confirmed to exist
        if (!activityCountByUnitAndActivity[sourceGroup]) {
          activityCountByUnitAndActivity[sourceGroup] = {};
        }
        if (!activityCountByUnitAndActivity[sourceGroup][activityId]) {
          activityCountByUnitAndActivity[sourceGroup][activityId] = 0;
        }

        if (!activityCountByUnitAndActivity[targetGroup]) {
          activityCountByUnitAndActivity[targetGroup] = {};
        }
        if (!activityCountByUnitAndActivity[targetGroup][activityId]) {
          activityCountByUnitAndActivity[targetGroup][activityId] = 0;
        }

        activityCountByUnitAndActivity[sourceGroup][activityId]++;
        activityCountByUnitAndActivity[targetGroup][activityId]++;
      }
    });
  });

  return activityCountByUnitAndActivity;
}


export function getGroupCollaboration(data) {
  if (!data) {
    return;
  }
  let groupScore = {};
  let groupLength = {};
  data.links.forEach(link => {
    if (link.source.group === link.target.group) {
      groupScore[link.source.group] = (groupScore[link.source.group] || 0) + 1;
    }
  });

  data.nodes.forEach(node => {
    groupLength[node.group] = (groupLength[node.group] || 0) + 1;
  })

  let result = 0;
  let count = 0;

  for (let key in groupLength) {
    if (groupLength[key] && groupLength[key] > 1) {
      result += (groupScore[key] || 0) / (groupLength[key] * (groupLength[key] - 1)) * 100;
    }
    count++;
  }

  return result / count;
}


export function getSpanOfControl(data) {
  if (!data) {
    return;
  }
  let groupLength = {};

  data.nodes.forEach(node => {
    groupLength[node.group] = (groupLength[node.group] || 0) + 1;
  })

  let result = 0;
  let count = 0;

  for (let key in groupLength) {
    result += groupLength[key];
    count++;
  }

  return result / count;

}


function calculateWorkEffort(nodes, activityIds, groupNames, FTE_HOURS_PER_MONTH) {
  /**
   * Calculate the work effort per group per activity.
   * 
   * @param {Array} nodes - Array of node objects. Each node represents an entity and contains data like `activityUtilization` and `fte`.
   * @param {Array} activityIds - Array of activity IDs to consider for the calculation.
   * @param {Array} groupNames - Array of group names to calculate the work effort for.
   * @param {Number} FTE_HOURS_PER_MONTH - Constant representing the number of full-time equivalent hours per month.
   * @returns {Object} - An object with work effort calculated for each group and activity.
   */
  let relationCount = {};

  // Initialize relationCount with activity IDs and group names
  activityIds.forEach((id) => {
    relationCount[id] = {};
    groupNames.forEach((group) => {
      relationCount[id][group] = [];
    });
  });

  // Calculate work effort
  nodes.forEach(node => {
    Object.keys(node.activityUtilization || {}).forEach(activity => {
      const fte = +node.fte;
      const percent = +node.activityUtilization[activity].replace('%', '') * fte; // Multiplying by FTE
      if (relationCount[activity]?.[node.groupName] && percent > 0) {
        relationCount[activity][node.groupName].push(percent);
      }
    });
  });

  // Summarize the work efforts
  activityIds.forEach((id) => {
    groupNames.forEach((group) => {
      let percents = relationCount[id][group] || [];
      relationCount[id][group] = percents.reduce((a, b) => a + b, 0) * FTE_HOURS_PER_MONTH / 100;
    });
  });

  return relationCount;
}

export function getTotalWorkEffort(employees) {
  if (!employees) {
    return 0;
  }
  let totalWorkEffort = employees.reduce((total, node) => {
    return total + (parseFloat(node.fte) * FTE_HOURS_PER_MONTH);
  }, 0);

  return totalWorkEffort;
}

export function getTotalAutomationPotential(
  activities, time_utilization, nodes
) {
  if(!activities || !time_utilization || !nodes) {
    return 0;
  }
  const transposed_time_utilization = transposeObject(time_utilization);
  let totalAutomationPotential = Object.values(transposed_time_utilization).reduce((total, utilization) => {
    const arrayUtil = Object.values(utilization);
    const mappedUtil = arrayUtil.map(value => parseFloat(value) / 100)
    const utilSum = sum(mappedUtil) ;
    const autPot = (parseFloat(activities[0].automation_potential || 0) / 100);
    return total + (utilSum * autPot);
  }, 0);

  return totalAutomationPotential;
}

export function getAutomationPotentialByActivity(
  activities, time_utilization, nodes
) {
  if(!activities || !time_utilization || !nodes) {
    return {highestAutomationPotentialActivity: {},automation_potential_per_activity: []};
  }
  const automation_potential_per_activity = [];
  const transposed_time_utilization = transposeObject(time_utilization);
  activities.forEach((activity) => {
    let totalFteLocal = 0.0;
    let activityId = activity.id;
    if (typeof transposed_time_utilization[activityId] === 'undefined') {
      activityId = activity.groupId;
    }
    if (typeof transposed_time_utilization[activityId] !== 'undefined') {
      const actvitity_utilization_values = Object.values(transposed_time_utilization[activityId]).map(value => parseFloat(value) / 100); // pct number, FTE is calculated at print time
      const unitTimeUtilization = Object.keys(transposed_time_utilization[activityId])
      totalFteLocal = actvitity_utilization_values.reduce((total, utilization, index) => {
        const nodeId = unitTimeUtilization[index];
        const node = nodes.find(node => String(node.id) === String(nodeId));
        const fte = parseFloat(node?.fte || 0);
        return total + (utilization * fte);
      }, 0);
    }

    let workEffortByUnit = {};    
    nodes.forEach((node) => {
      try { 
        const activityUtilizationValue =  node.activityUtilization[activity.id]
        if (activityUtilizationValue === undefined) {
          return;
        }
        const timeUtilization = parseFloat(activityUtilizationValue) / 100 || 0;
        if (!(node.group in workEffortByUnit)) {
          workEffortByUnit[node.group] = {workEffort: 0, name: node.group, fte: 0};
        }
        const nodeFte = parseFloat(node.fte);
        const fte = timeUtilization * nodeFte;
        workEffortByUnit[node.group].workEffort += fte * FTE_HOURS_PER_MONTH;
        workEffortByUnit[node.group].fte += fte;
      } catch (e) {
        //console.error('No time utilization by node for activity:', e);
      }
    });
    
    const automation_potential = parseFloat(activity.automation_potential) / 100 || 0;
    const AutomationPotentialActivity = {
      id: activity.id,
      activityLabel: activity.name,
      groupId: activity.groupId,
      functionLabel: activity.group,
      description: activity.description,
      classification: activity.classification,
      totalFte: totalFteLocal,
      automationPotential: automation_potential,
      automationSuggestions: activity.automationSuggestions,
      workeffort: totalFteLocal * FTE_HOURS_PER_MONTH,
      workeffortAutomationPotential: totalFteLocal * FTE_HOURS_PER_MONTH * automation_potential,
      workEffortByUnit: workEffortByUnit,
    };

    /*
    console.log('__________________________________________________________');
    //console.log('actvitity_utilization_values', actvitity_utilization_values);
    console.log('AutomationPotentialActivity', AutomationPotentialActivity);
    console.log('transposed_time_utilization', transposed_time_utilization)
    console.log('transposed_time_utilization[activity.groupId]', transposed_time_utilization[activity.groupId]);
    console.log('activity.groupId', activity.groupId);
    console.log('activity', activity);
    console.log('nodes', nodes);
    console.log('workEffortByUnit', workEffortByUnit);
    console.log('totalFte', totalFteLocal);
    console.log('automation_potential', automation_potential);
    console.log('AutomationPotentialActivity', AutomationPotentialActivity);
    console.log('__________________________________________________________');
    */
    
    
    automation_potential_per_activity.push({ ...AutomationPotentialActivity });
  });
  
  const descSortedIdsByWorkEffortAutomated = Object.keys(automation_potential_per_activity).sort((a, b) => {
    const a_value = automation_potential_per_activity[a].workeffortAutomationPotential; 
    const b_value = automation_potential_per_activity[b].workeffortAutomationPotential;
    const diff = a_value < b_value ? 1 : -1;
    
    return diff;
  });

  const highestAutomationPotentialActivity = automation_potential_per_activity[descSortedIdsByWorkEffortAutomated[0]];
  
  return {
    highestAutomationPotentialActivity: highestAutomationPotentialActivity || {},
    automation_potential_per_activity: automation_potential_per_activity || []
  };
}

export const calcTotalWorkEffortAcrossActivities = (time_utilization, employees, activities) => {
  if (!time_utilization || !employees || !activities) {
    return 0;
  }
  const timeUtilizationByActivity = transposeObject(time_utilization);
  const activityIds = activities.map(activity => activity.id);
  
  const timeUtilizationForRelevantActivities = Object.keys(timeUtilizationByActivity)
    .filter(key => activityIds.includes(+key))
    .reduce((obj, key) => {
        obj[key] = timeUtilizationByActivity[key];
        return obj;
    }, {});

  let total_work_effort = 0;
  if (timeUtilizationForRelevantActivities !== undefined) {
    

    Object.values(timeUtilizationForRelevantActivities).forEach(timeUtilizationSingleActivity => {
        Object.entries(timeUtilizationSingleActivity).forEach(([key, value]) => {
            if (employees.some(employee => String(employee.id) === String(key))) {
                let numericValue = parseFloat(value);
                if (!isNaN(numericValue)) {
                    const employee = employees.find(node => String(node.id) === String(key));
                    if (employee) {
                        numericValue = numericValue * (employee.fte || 1);
                        total_work_effort += (numericValue / 100);
                    }
                }
            }
        });
    });

    total_work_effort = total_work_effort * FTE_HOURS_PER_MONTH;
  }

  return total_work_effort;
};
