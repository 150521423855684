var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import "./Node.css";
import * as React from "react";
import classnames from "classnames";
import useTooltip from "../Tooltip/useTooltip";
import { Tooltip } from "../Tooltip/Tooltip";
import NumberOfChildren, { NumberOfChildrenPlacement, } from "./NumberOfChildren";
import { getNumberItemsWidthByNumberOfChars } from "./helpers";
import LabelNewLine from "./LabelNewLine";
var Node = function (_a) {
    var bgColor = _a.bgColor, borderColor = _a.borderColor, className = _a.className, hasChildren = _a.hasChildren, hideNumberOfChildren = _a.hideNumberOfChildren, id = _a.id, isSelectedNode = _a.isSelectedNode, label = _a.label, nodeTotalNodes = _a.nodeTotalNodes, onClick = _a.onClick, textColor = _a.textColor, treemapId = _a.treemapId, url = _a.url, value = _a.value, x0 = _a.x0, x1 = _a.x1, xScaleFunction = _a.xScaleFunction, y0 = _a.y0, y1 = _a.y1, yScaleFunction = _a.yScaleFunction, style = _a.style, numberOfChildrenPlacement = _a.numberOfChildrenPlacement, paddingInner = _a.paddingInner, splitRegExp = _a.splitRegExp;
    var currentXTranslated = Math.max(0, xScaleFunction(x0) + paddingInner);
    var currentYTranslated = Math.max(0, yScaleFunction(y0) + paddingInner);
    var currentWidth = Math.max(0, xScaleFunction(x1) - xScaleFunction(x0) - paddingInner);
    var currentHeight = Math.max(0, yScaleFunction(y1) - yScaleFunction(y0) - paddingInner);
    var cursor = hasChildren === true && isSelectedNode === false ? "pointer" : "auto";
    var fontSize = Number(style.fontSize);
    var itemsWidth = getNumberItemsWidthByNumberOfChars(fontSize, nodeTotalNodes.toString().length);
    var showNumberOfItems = !hideNumberOfChildren && hasChildren;
    var paddedCurrentWidth = currentWidth -
        (Number(style.paddingLeft) || 0) -
        (Number(style.paddingRight) || 4);
    var clipWidth = Math.max(0, showNumberOfItems &&
        numberOfChildrenPlacement === NumberOfChildrenPlacement.TopRight
        ? paddedCurrentWidth - itemsWidth
        : paddedCurrentWidth);
    var _b = useTooltip(), hideTooltip = _b.hideTooltip, showTooltip = _b.showTooltip, disableTooltip = _b.disableTooltip;
    var handleMouseMove = React.useCallback(function (ev) {
        showTooltip(React.createElement(Tooltip, { label: label, value: value }), ev);
    }, [showTooltip]);
    var handleMouseLeave = React.useCallback(function () {
        hideTooltip();
    }, [hideTooltip]);
    return (React.createElement("g", { onMouseEnter: disableTooltip ? undefined : handleMouseMove, onMouseLeave: disableTooltip ? undefined : handleMouseLeave, onMouseMove: disableTooltip ? undefined : handleMouseMove, transform: "translate(" + currentXTranslated + "," + currentYTranslated + ")", id: "" + id, onClick: hasChildren ? onClick : null, style: { cursor: cursor } },
        React.createElement("rect", { id: "rect-" + id, width: currentWidth, height: currentHeight, className: classnames("Node", className), style: __assign({ fill: bgColor, stroke: borderColor }, style) }),
        React.createElement("clipPath", { id: "clip-" + treemapId + "-" + id },
            React.createElement("rect", { width: clipWidth, height: currentHeight })),
        React.createElement("a", { className: classnames({ Node__link: !!url }), href: url, target: "_blank" },
            React.createElement("text", { clipPath: "url(#clip-" + treemapId + "-" + id + ")", transform: "translate(" + (style.paddingLeft || 0) + "," + (style.paddingTop || 0) + ")", style: {
                    fontVariant: style.fontVariant,
                    fontWeight: style.fontWeight,
                    fontSize: style.fontSize,
                    fontFamily: style.fontFamily,
                } },
                React.createElement(LabelNewLine, { label: label, textColor: textColor, value: value, hasChildren: hasChildren, containerWidth: clipWidth, containerHeight: currentHeight, style: style, splitRegExp: splitRegExp }))),
        showNumberOfItems && (React.createElement(NumberOfChildren, { customId: id, width: currentWidth, height: currentHeight, style: style, textColor: textColor, nodeTotalNodes: nodeTotalNodes, isSelectedNode: isSelectedNode, placement: numberOfChildrenPlacement }))));
};
export default Node;
