import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'components/Button';
import CustomLabelEditor from './CustomLabelEditor';
import { TimeUtilizationSelector } from 'components/TimeUtilizationSelector';
import { getResponseStatusClassname } from 'lib/helper';

export default function ItemEditModal({
  item,
  setItem,
  toggle,
  activityOptions,
  deleteItem,
  defaultLabels,
  setLabels,
}) {
  const [utilizations, setUtilizations] = useState(item.time_utilization || {});
  const [currentItem, setCurrentItem] = useState({ ...item });
  const [showEditLabel, setShowEditLabel] = useState(false);

  const onSave = () => {
    setItem({
      ...item,
      ...currentItem,
      time_utilization: utilizations,
    });
    toggle();
  };

  const onDelete = () => {
    deleteItem(item);
    toggle();
  };

  return (
    <Modal isOpen={!!item} toggle={toggle}>
      <ModalBody className="design-item-edit-modal">
        <h3 className="d-flex">
          Edit Resource
          {item.responseStatus !== undefined && (
            <span className="indicator">
              <span
                className={getResponseStatusClassname(item.responseStatus)} />
            </span>
          )}
        </h3>

        <div className="row p-2">
          <div className="col-md-5 d-flex flex-column">
            {Object.keys(defaultLabels).map(c => (
              defaultLabels[c] && (
                <div className="form-group row mb-2" key={c}>
                  <label
                    htmlFor={`item-edit-modal-${c}`}
                    className="col-sm-4 col-form-label"
                  >
                    {defaultLabels[c]}
                  </label>
                  <div className="col-sm-8">
                    <input
                      placeholder={defaultLabels[c]}
                      type="text"
                      className="form-control"
                      id={`item-edit-modal-${c}`}
                      value={currentItem[c] || ''}
                      onChange={(e) =>
                        setCurrentItem({
                          ...currentItem,
                          [c]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              )
            ))}
            <div className="form-group mt-1 d-flex">
              <Button
                text="Edit Labels"
                onClick={() => setShowEditLabel(true)}
                className="button ms-auto"
              />
            </div>
            <div className="form-group mt-1 flex-fill d-flex flex-column">
              <label htmlFor="name" className="col-sm-11 col-form-label">
                Role description:
              </label>
              <div className="col-sm-12 flex-fill d-flex flex-column">
                <textarea
                  placeholder="Role description"
                  className="form-control flex-fill mh-20"
                  id="staticEmail"
                  value={currentItem.role || ''}
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      role: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <h4>Time Utilization</h4>
            <TimeUtilizationSelector
              activities={activityOptions}
              value={utilizations}
              setValue={setUtilizations}
            />
          </div>
        </div>
        {showEditLabel && (
          <CustomLabelEditor
            open={showEditLabel}
            toggle={() => setShowEditLabel(!showEditLabel)}
            defaultLabels={defaultLabels}
            setLabels={setLabels}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button text="Delete" onClick={onDelete} className="button red" />
        <Button text="Save" onClick={onSave} className="button ms-auto" />
        <Button text="Cancel" onClick={toggle} className="button ms-3" />
      </ModalFooter>
    </Modal>
  );
}
