import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'lib/withRouter';
import classNames from 'classnames';

import surveyIcon from 'img/icons/ear.svg';
import designIcon from 'img/icons/design.svg';
import StrategyIcon from 'img/icons/strategy.svg';
import DiagnoseIcon from 'img/icons/diagnose.svg';
import { getProductivityGain, getCoordinationLoad, getActivityCountByUnit, getGroupCollaboration, getSpanOfControl } from 'lib/metrics';
import SidebarItem from './SidebarItem';
import Coordination from './Coordination';
import { getTotalWorkEffort, getTotalAutomationPotential, getAutomationPotentialByActivity } from 'lib/metrics';
import { FTE_HOURS_PER_MONTH } from 'lib/constants';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeGauge: 0,
    };
  }

  render() {
    const url = this.props.history.location.pathname;
    const items = [
      {
        icon: DiagnoseIcon + '#main',
        width: 25,
        height: 24,
        boxWidth: 24,
        boxHeight: 24,
        text: 'Diagnose',
        count: 5,
        link: '/diagnose',
      },
      {
        icon: StrategyIcon + '#main',
        width: 25,
        height: 25,
        boxWidth: 41,
        boxHeight: 41,
        text: 'Define',
        count: 5,
        link: '/define',
      },
      {
        icon: surveyIcon + '#ear',
        width: 25,
        height: 25,
        boxWidth: 20,
        boxHeight: 26,
        text: 'Discover',
        count: 5,
        link: '/discover',
      },
      {
        icon: designIcon + '#pen',
        width: 25,
        height: 24,
        boxWidth: 25,
        boxHeight: 24,
        text: 'Design',
        count: 3,
        link: '/design',
      },
    ];
    const sidebarClasses = classNames({
      'side__navbar-wrapper': true,
      'toggle-off': !this.props.sidebar.collapse,
      'toggle-on': this.props.sidebar.collapse,
    });

    return (
      <div className={sidebarClasses}>
        <ul className="side__navbar">
          {items.map(item => (
            <SidebarItem key={item.text} data={item} />
          ))}
        </ul>
        {
          !this.props.sidebar.collapse && url.includes('design/org/') &&
          this.renderDesignCoordination()
        }
      </div>
    );
  }

  renderDesignCoordination() {
    const { designData, coefficient } = this.props;
    const { base_result, nodes, links, activities, time_utilization } = designData || {};
    const coordinationLoad = getCoordinationLoad(
      designData, designData?.nodes?.length,
    );
    const productivityGain = getProductivityGain(
      links, nodes, base_result, coefficient,
    );
    const interfaces = getActivityCountByUnit(designData);
    const groupCollaboration = getGroupCollaboration(designData);
    const spanOfControl = getSpanOfControl(designData);

    const employeeCount = nodes?.length || 0;
    const fteCount = nodes?.reduce((total, node) => total + parseFloat(node.fte), 0) || 0;
    
    const automationPotentialByActivity = getAutomationPotentialByActivity(activities, time_utilization, nodes)?.automation_potential_per_activity;
    //const totelWorkEffort = getTotalWorkEffort(nodes) || 0;
    const totalWorkEffort = automationPotentialByActivity?.reduce((total, activity) => total + activity.workeffort, 0) || 0;
    const totalWorkCapacity = fteCount * FTE_HOURS_PER_MONTH;
    const TotalAutomationPotential = automationPotentialByActivity?.reduce((total, activity) => total + activity.workeffortAutomationPotential, 0) || 0;

    return (
      <Coordination
        employeeCount={employeeCount}
        fteCount={fteCount}
        spanOfControl={spanOfControl}
        totalWorkCapacity={totalWorkCapacity}
        TotalAutomationPotential={TotalAutomationPotential}
        interfaces={interfaces?.numInterUnitInterfaces}
        coordinationLoad={coordinationLoad}
      />
      /*<Coordination
        productivityGain={productivityGain}
        coordinationLoad={coordinationLoad}
        interfaces={interfaces?.numInterUnitInterfaces}
        groupCollaboration={groupCollaboration}
        spanOfControl={spanOfControl}
      />*/
    );
  }
}

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
  designData: state.designOrg && state.designOrg.data,
  coefficient: state.designCoefficient?.data,
}))(Sidebar));
