import React from 'react';
import { Cell, PieChart, Pie } from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
	cx, cy, midAngle, innerRadius, outerRadius, name, index,
}) => {
	const radius = outerRadius + 15;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="black" textAnchor={Math.abs(x - cx) < 1 ? 'middle' : x > cx ? 'start' : 'end'} dominantBaseline="central">
			{name}
		</text>
	);
};


const GaugeChart = ({ value, children }) => {
	const chartValue = value;
	const width = 250;
	const colorData = [
		{
			value: 1,
			name: '',
			fill: 'f033ff'
		},
		{
			value: 99,
			name: ' ',
			stroke: 'url(#circle_progressbar_gradient1)'
		},
		{
			value: 1,
			name: '100%',
			fill: 'f033ff'
		},
		{
			value: 99,
			name: ' ',
			stroke: 'url(#circle_progressbar_gradient2)'
		},
		{
			value: 1,
			name: '',
			fill: 'f033ff'
		},
	];

	const sumValues = colorData
		.map(cur => cur.value)
		.reduce((a, b) => a + b);

	const arrowData = [
		{ value: chartValue },
		{ value: 0 },
		{ value: sumValues - chartValue }
	];

	const pieProps = {
		startAngle: 180,
		endAngle: 0,
		cx: width / 2,
		cy: width / 2
	};

	const pieRadius = {
		innerRadius: (width / 2) * 0.4,
		outerRadius: (width / 2) * 0.4,
		strokeWidth: 7
	};

	const Arrow = ({ cx, cy, midAngle, outerRadius }) => { //eslint-disable-line react/no-multi-comp
		let angle = Math.max(-180, Math.min(-midAngle, 0))
		const RADIAN = Math.PI / 180;
		const sin = Math.sin(RADIAN * angle);
		const cos = Math.cos(RADIAN * angle);
		const mx = cx + (outerRadius + width * 0.03) * cos;
		const my = cy + (outerRadius + width * 0.03) * sin;
		return (
			<g>
				<circle cx={cx} cy={cy} r={width * 0.03} fill="#666" stroke="none" />
				<path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="6" stroke="#666" fill="none" strokeLinecap="round" />
			</g>
		);
	};

	return (
		<div>
			<svg style={{ height: 0, width: 0 }} className="gradient-pattern">
				<defs>
					<linearGradient id="circle_progressbar_gradient1">
						<stop offset="0%" stopColor="#34e39f" />
						<stop offset="100%" stopColor="#2D95C0" />
					</linearGradient>
					<linearGradient id="circle_progressbar_gradient2">
						<stop offset="0%" stopColor="#2D95C0" />
						<stop offset="100%" stopColor="#4767F9" />
					</linearGradient>
				</defs>
			</svg>
			<div style={{
				left: '5px',
				bottom: '-15px',
				position: 'absolute',
				textAlign: 'center',
				width: '100%',
			}}
			>
				{value.toFixed(1)}%
			</div>
			<div style={{
				left: '5px',
				bottom: (pieRadius.outerRadius + 55) + 'px',
				position: 'absolute',
				textAlign: 'center',
				width: '100%'
			}}>{children}</div>
			<PieChart width={width} height={(width / 2) + 30}>
				<Pie
					dataKey="value"
					data={colorData}
					isAnimationActive={false}
					{...pieRadius}
					{...pieProps}
					label={renderCustomizedLabel}
				>
					{
						colorData.map((entry, index) => (
							<Cell key={`cell-${index}`} stroke={colorData[index].stroke} fill={colorData[index].fill} />
						))
					}
				</Pie>
				<Pie
					dataKey="value"
					stroke="none"
					activeIndex={1}
					activeShape={Arrow}
					data={arrowData}
					outerRadius={pieRadius.innerRadius * 0.7}
					fill="none"
					{...pieProps}
				/>
			</PieChart>
		</div>

	);
};

export default GaugeChart;