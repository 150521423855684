import _ from 'lib/lodashFunctions';
import { getRelationshipWorkEffort } from 'lib/metrics';

export function getOptions(key, data, resource = false) {
  const maxGroupCount = Math.max(
    ...Object.values(_.groupBy(data.nodes, (node) => node.group)).map(
      (v) => v.length
    )
  );

  if (resource) {
    const values = [...new Set(data?.nodes.map(node => node[key]).filter(u => !!u) || [])];
    return values.map(value => ({
      value,
      label: value,
    }))
  }

  switch (key) {
    case 'groupName':
      return data?.groupOptions || [];
    case 'name':
      return (
        data?.nodes.map((node) => ({
          value: node.id,
          label: node.name,
        })) || []
      );
    case 'activity':
      return data.activityOptions;
    case 'weight_new': {
      // links don't have work effort set at this point. Track and see if we can change the order of filter generation and data enrichments
      const maxWorkEffort = 100; // = Math.max(...data.links.map((link) => getRelationshipWorkEffort(data.links, link.source, link.target)));
      return _.range(1, maxWorkEffort + 1).map((v) => ({
        label: '',
        value: v,
      }));
      //return data.workEffortFilters
    }

    case 'permanence':
      return data.threshold.filter(u => u.field === 'permanence').map(u => ({
        ...u,
        label: u.title,
        value: u.limit > 0 ? u.limit : 3,
      }))
    case 'duration':
    case 'frequency':
    case 'weight':
      return data.threshold.filter(u => u.field === key).map(u => ({
        ...u,
        label: u.title,
        value: u.limit,
      }))
    case 'reported_by':
      return [
        { label: 'One participant', value: 1 },
        { label: 'Two participants', value: 2 },
      ];
    case 'group_length':
      return _.range(1, maxGroupCount + 1).map((v) => ({
        label: '',
        value: v,
      }));

    default: // custom columns
      return [...new Set(data?.nodes.map(u => u[key]))].map(u => ({
        label: u,
        value: u,
      }));
  }
}

export function getOptionsSorted(key, data, value) {
  const options = getOptions(key, data);
  const optionsSorted = options.slice(0, options.length).sort((a, b) => {
    const xa =
      value?.indexOf && value.findIndex((v) => v.value === a.value) > -1
        ? 1
        : 0;
    const xb =
      value?.indexOf && value.findIndex((v) => v.value === b.value) > -1
        ? 1
        : 0;
    if (xa !== xb) return xb - xa;
    if (a.value !== b.value && typeof (a.value) === 'number' && typeof (b.value) == 'number') {
      return a.value - b.value;
    }
    return String(a.label).localeCompare(String(b.label));
  });

  return optionsSorted;
}
