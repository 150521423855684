import React from 'react'
import clsx from 'classnames';
export default class EditableText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
      oldValue: props.value
    }
  }
  onClick = (e) => {
    if (e.detail > 1 && this.state.disabled) {
      e.target.select()
      this.setState({
        disabled: false
      })
    } else {
      if (!this.props.selected && this.props.onClick)
        this.props.onClick();
    }
  }
  onBlur = (e) => {
    const { setValue } = this.props
    const result = setValue(this.props.value, true)
    this.setState({
      disabled: true
    })
    if (!result) {
      this.setState({
        oldValue: this.props.value
      })

    } else {
      setValue(this.state.oldValue, true)
      alert(result)
    }
  }

  setValue = (value) => {
    const { setValue } = this.props
    setValue(value)
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      this.onBlur()
    }
    if (e.keyCode === 27) {
      this.setState({
        disabled: true
      })
      const { setValue } = this.props
      setValue(this.state.oldValue, true)
    }
  }

  render() {
    const { value } = this.props
    const { disabled } = this.state
    return (
      <input
        className={clsx('editable-text', { onedit: !disabled })}
        onMouseUp={this.onClick}
        type="text"
        value={value}
        onBlur={this.onBlur}
        readOnly={disabled}
        draggable={disabled}
        onKeyDown={this.keyDown}
        onChange={e => this.setValue(e.target.value)}
      />
    )
  }
}

