import React, { useRef, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { getColorByName, toServerLinks } from 'lib/helper';
import { setMatrixFilter, saveDesignOrg } from 'redux/actions/design';
import EditCellModal from 'components/AdjacentMatrix/EditCellModal';
import MatrixFilter from 'components/AdjacentMatrix/MatrixFilter';
import Matrix from 'components/AdjacentMatrix/Matrix';
import Toolbar from 'components/AdjacentMatrix/Toolbar';
import CommunciationMetricsComponent from './components/CommunciationMetricsComponent';

const DesignMatrixView = (props) => {
  const matrixRef = useRef();
  const editCellModelRef = useRef();
  const [setNodes, setNodesProc] = useState();
  const [selectedTool, setSelectedTool] = useState('select');
  const colorOptions = {};
  const {
    data,
    matrixFilter,
    matrixTermFilter,
    notCollapse,
    width,
    height,
    getDesignOrg,
  } = props;
  const {
    threshold,
    result,
    activityMap,
    activityOptions,
    survey_type,
    defaultLabels,
    custom_columns: customColumns,
    scenarios,
  } = data;

  props.data.nodes.forEach((node) => {
    colorOptions[node.group] = getColorByName(node.groupName);
  });

  // Scale relationship work effort by FTE * connection density for both source and target of link
  props.data.links.forEach((link) => {
    link.workEffort = link.value_new;
  });

  const showEditModal = (row, col, link, data) => {
    editCellModelRef.current.showModal(
      row,
      col,
      link,
      selectedTool === 'edit',
      data,
    );
  };

  useEffect(() => {
    if (setNodes) setNodes.proc(matrixRef.current.totalNodes);
  }, [setNodes]);

  const saveDesign = () => {
    const { nodes, links, groupOptions, result, ...rest } = data;
    const payload = {
      ...rest,
      group_mapping: groupOptions.map(item => ({
        id: item.value,
        name: item.label,
      })),
      ...toServerLinks({
        ...result,
        nodes,
        links,
      }),
    };

    props.saveDesignOrg(
      data.id,
      payload,
      true,
    );
  };

  const selectedCursor = useMemo(() => {
    switch (selectedTool) {
      case 'search':
        return {
          url: require('../../img/icons/search-cursor.svg').default,
          x: 15,
          y: 15,
        };
      case 'select':
        return {
          url: require('../../img/icons/select-cursor.svg').default,
          x: 0,
          y: 0,
        };
      case 'highlight':
        return {
          url: require('../../img/icons/highlight-cursor.svg').default,
          x: 0,
          y: 35,
        };
      case 'edit':
        return {
          url: require('../../img/icons/edit-cursor.svg').default,
          x: 0,
          y: 35,
        };
      case 'stamp':
        return {
          url: require('../../img/icons/stamp-unfilled.svg').default,
          x: 18,
          y: 35,
        };
      default:
        return null;
    }
  }, [selectedTool]);
  const axisLabels = { ...defaultLabels };
  let scenarioOptions = [];

  (customColumns || []).forEach(item => {
    axisLabels[item.accessor] = item.Header;
  });
  scenarios.forEach((item, index) => {
    const groupId = 'groupName' + index;
    axisLabels[groupId] = item.name;
    scenarioOptions.push({
      id: groupId,
      name: item.name,
    });
  });

  return (
    <div className="tab">
      <div className="row">
        <CommunciationMetricsComponent
          data={data}
          employees={data.nodes}
          relationships={data.links}
          activities={data.activities}
          isMaximized
        />
      </div>
      <div className="row">
        <MatrixFilter
          data={data}
          colorOptions={colorOptions}
          scenarioOptions={scenarioOptions}
          matrixFilter={matrixFilter}
          matrixTermFilter={matrixTermFilter}
          setMatrixFilter={props.setMatrixFilter}
          onSave={saveDesign}
          getDesignOrg={getDesignOrg}
        />
      </div>
      <Matrix
        ref={matrixRef}
        {...props}
        showEditModal={showEditModal}
        colorOptions={colorOptions || colorOptions}
        activityOptions={activityOptions}
        notCollapse={notCollapse}
        width={width}
        height={height}
        tool={selectedTool}
        cursor={selectedCursor}
        axisLabels={axisLabels}
        saveDesign={saveDesign}
      />

      <Toolbar
        selected={selectedTool}
        setSelected={setSelectedTool}
      />
      {result && (
        <EditCellModal
          ref={editCellModelRef}
          onConfirm={saveDesign}
          {...{
            data,
            threshold,
            activityOptions,
            activityMap,
            survey_type,
            coefficient: props.coefficient,
          }}
          filters={matrixTermFilter}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  matrixFilter: state.designMatrixFilter,
  matrixTermFilter: state.designMatrixTermFilter,
  coefficient: state.designCoefficient?.data,
});

const mapDispatchToProps = {
  setMatrixFilter,
  saveDesignOrg,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignMatrixView);
