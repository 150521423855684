import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lib/lodashFunctions';
import Checkbox from './Checkbox';
import CollapseRow from './CollapseRow';

export function ActivitySelector({
  enabled = true,
  value,
  setValue,
  options,
  setValid,
}) {

  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(() => options.filter(o => o.group?.toLowerCase().includes(search.toLocaleLowerCase()) || o.name?.toLowerCase().includes(search.toLowerCase())), [search, options])
  const activitiesByGroup = useMemo(() => {
    let grouped = _.groupBy(filteredOptions, 'group');

    return Object.values(grouped).map((items) => ({
      groupName: items[0].group,
      groupLabel: `${items[0].group || 'All'}`,
      activities: items.map((u) => ({
        name: u.name || u.group,
        id: u.id.toString(),
      })),
    }));
  }, [filteredOptions]);

  const activityNameMap = useMemo(
    () => _.mapValues(_.keyBy(filteredOptions, 'id'), (u) => u.name || u.group),
    [filteredOptions]
  );

  const allActivities = useMemo(
    () => options.map((a) => a.id.toString()),
    [options]
  );

  const handleChangeActivity = (checked, activity) => {
    if (checked) {
      setValue([
        ...value,
        {
          value: activity,
          label: activityNameMap[activity],
        },
      ]);
      setValid && setValid(true);
    } else {
      setValue(
        (
          value ||
          allActivities.map((r) => ({
            value: r,
            label: activityNameMap[r],
          }))
        ).filter((v) => v.value !== activity)
      );
      setValid && setValid(true);
    }
  };

  const handleChangeActivityGroup = (checked, groupName) => {
    let roles = new Set((value && value.map((v) => v.value)) || allActivities);
    filteredOptions.forEach((a) => {
      if (a.group === groupName) {
        if (checked) {
          roles.add(a.id.toString());
        } else {
          roles.delete(a.id.toString());
        }
      }
    });
    setValid && setValid(true);
    setValue(
      [...roles].map((r) => ({
        value: r,
        label: activityNameMap[r],
      }))
    );
  };

  const isChecked = useCallback((activity) => {
    return !!(
      (value &&
        value.map((v) => v.value.toString()).indexOf(activity.id.toString()) >
        -1) ||
      !value
    );
  }, [value]);

  const allChecked = useMemo(() => filteredOptions.every(o => isChecked(o)), [filteredOptions, isChecked])
  const allUnchecked = useMemo(() => filteredOptions.every(o => !isChecked(o)), [filteredOptions, isChecked])

  function handleChangeActivityAll(checked) {
    let roles = new Set((value && value.map((v) => v.value)) || allActivities);
    filteredOptions.forEach((a) => {
      if (checked) {
        roles.add(a.id.toString());
      } else {
        roles.delete(a.id.toString());
      }
    });
    setValid && setValid(true);

    setValue(
      [...roles].map((r) => ({
        value: r,
        label: activityNameMap[r],
      }))
    );
  }


  return (
    <div className="activity-selector pt-2">
      <div className="activities-search px-2 py-1">
        <input type="search" placeholder="Activities search" className='activities-search-input' value={search} onChange={e => setSearch(e.target.value)} />
      </div>
      <div className='px-2 py-1 border-bottom'>
        <div className="form-check check-row activity-all-check">
          <Checkbox
            className="form-check-input"
            type="checkbox"
            onChange={() =>
              handleChangeActivityAll(!allChecked)
            }
            checked={allChecked}
            indeterminate={!allChecked && !allUnchecked}
            id="selectAll"
          />
          <label
            className="form-check-label"
            htmlFor="selectAll"
          >
            All
          </label>
        </div>

      </div>
      <div className="list-group activities">
        {activitiesByGroup.map(
          ({ groupName, groupLabel, activities }, groupIndex) => {

            const checked = activities.every((a) => isChecked(a));
            const unchecked = activities.every((a) => !isChecked(a));

            return (
              <CollapseRow
                key={groupName}
                defaultColapse={true}
                header={
                  <div className="form-check check-row">
                    <Checkbox
                      className="form-check-input"
                      type="checkbox"
                      onChange={() =>
                        handleChangeActivityGroup(!checked, groupName)
                      }
                      checked={checked}
                      indeterminate={!checked && !unchecked}
                      id={`activity-group-${groupIndex}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`activity-group-${groupIndex}`}
                    >
                      {groupLabel}
                    </label>
                  </div>
                }
                content={activities.map((activity, index) => {
                  const checked = isChecked(activity);
                  return (
                    <div className="form-check ms-4 check-row" key={index}>
                      <Checkbox
                        checked={checked}
                        onChange={() =>
                          handleChangeActivity(!checked, activity.id)
                        }
                        className="form-check-input"
                        id={`activity-item-${groupIndex}-${index}`}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`activity-item-${groupIndex}-${index}`}
                      >
                        {activity.name}
                      </label>
                    </div>
                  );
                })}
              />
            );
          }
        )}
      </div>
    </div>

  );
}

export function ActivitySelected({ value, options, filters }) {
  const filteredOptions = useMemo(() => {
    if (!filters) {
      return options;
    }
    let filterActivitiesValues = filters.filter(
      (f) => f.label === 'Activity'
    );

    return options.filter(o => filterActivitiesValues.every((filter) => {
      if (filter.flag === 'include') {
        return !!filter.value.find(v => v.value.toString() === o.id.toString());
      } else {
        return !filter.value.find(v => v.value.toString() === o.id.toString());
      }
    }));

  }, [options, filters])
  const activitiesByGroup = useMemo(() => {
    let grouped = _.groupBy(
      filteredOptions.filter(
        (o) =>
          value.findIndex((v) => v.value.toString() === o.id.toString()) > -1
      ),
      'group'
    );
    return Object.values(grouped).map((items) => ({
      groupName: items[0].group,
      groupLabel: `${items[0].group || 'All'}`,
      activities: items.map((u) => ({
        name: u.name || u.group,
        id: u.id.toString(),
      })),
    }));
  }, [value, filteredOptions]);

  return (
    <div className="list-group activities">
      {activitiesByGroup.length === 1 && activitiesByGroup[0].name === 'All' ?
        activitiesByGroup[0].activities.map((activity, index) => {
          return (
            <div className="form-check ms-1 check-row" key={index}>
              <span className="form-check-label">{activity.name}</span>
            </div>
          );
        }) : activitiesByGroup.map(
          ({ groupName, groupLabel, activities }, groupIndex) => {
            return (
              <CollapseRow
                key={groupName}
                defaultColapse={true}
                header={
                  <div className="form-check check-row">
                    <span className="form-check-label">{groupLabel}</span>
                  </div>
                }
                content={activities.map((activity, index) => {
                  return (
                    <div className="form-check ms-4 check-row" key={index}>
                      <span className="form-check-label">{activity.name}</span>
                    </div>
                  );
                })}
              />
            );
          }
        )}
    </div>
  );
}

export function ActivitySelectedInTooltip({ value, options }) {
  const activitiesByGroup = useMemo(() => {
    let grouped = _.groupBy(
      options.filter(
        (o) =>
          value.findIndex((v) => v.value.toString() === o.id.toString()) > -1
      ),
      'group'
    );
    return Object.values(grouped).map((items) => ({
      groupName: items[0].group,
      groupLabel: `${items[0].group || 'All'}`,
      activities: items.map((u) => ({
        name: u.name || u.group,
        id: u.id.toString(),
      })),
    }));
  }, [value, options]);

  return (
    <div className="list-group activities">
      {activitiesByGroup.map(
        ({ groupName, groupLabel, activities }, groupIndex) => {
          return (
            <div key={groupName}>
              <div className="tooltip-groupname">{groupName}</div>
              {activities.map((activity, index) => (
                <div key={index} className="tooltip-activityname">
                  {activity.name}
                </div>
              ))}
            </div>
          );
        }
      )}
    </div>
  );
}


