import React from 'react';
import { ButtonGroup } from 'reactstrap';
import ToggleButton from 'components/ToggleButton';

const ButtonWrapper = (props) => {
  const { children } = props;
  let expanded = [];
  let firstRow = [];
  let secondRow = [];
  let charLength = 0;

  children.forEach(item => {
    if (Array.isArray(item)) {
      expanded = expanded.concat(item.filter(c => !!c));
    } else if (item) {
      expanded.push(item);
    }
  });

  charLength = expanded.map(item => item.props.value.length).reduce((a, b) => a + b, 0);

  if (expanded.length < 6) {
    firstRow = expanded;
  } else {
    expanded.forEach(item => {
      if (firstRow.map(item => item.props.value.length).reduce((a, b) => a + b, 0) * 2 > charLength) {
        secondRow.push(item);
      } else {
        firstRow.push(item);
      }
    });
  }

  return (
    <ButtonGroup vertical className={props.className}>
      <ButtonGroup>
        {firstRow}
      </ButtonGroup>
      {secondRow.length
        ? <ButtonGroup>
          {secondRow}
        </ButtonGroup>
        : null
      }
    </ButtonGroup>
  );
};

export default function LabelSelector({ data, labels, setLabels }) {
  const onChangeLabel = (event, value) => {
    let id = event.target.getAttribute('id');
    if (value) {
      setLabels([...labels, id]);
    } else {
      const newLabels = [...labels];
      let idx = newLabels.indexOf(id);
      newLabels.splice(idx, 1);
      if (newLabels.length > 0) {
        setLabels(newLabels);
      }
    }
  };

  return (
    <div>
      <div className="card__header row align-items-center">
        <h4>Resource labels</h4>
      </div>
      <ButtonWrapper className="card-content design-label-selector">
        {data.defaultLabels && Object.keys(data.defaultLabels).map(c => (
          data.defaultLabels[c] && (
            <ToggleButton
              key={c}
              value={data.defaultLabels[c]}
              id={c}
              checked={labels.indexOf(c) > -1}
              onChange={onChangeLabel}
            />
          )
        ))}
      </ButtonWrapper>
    </div>
  );
}
