import { treemap, hierarchy, treemapSquarify } from "d3-hierarchy";
export var useTreeMap = function (_a) {
    var width = _a.width, height = _a.height, data = _a.data, valuePropInData = _a.valuePropInData, paddingOuter = _a.paddingOuter;
    var d3TreeMap = treemap()
        .tile(treemapSquarify.ratio(1))
        .size([width, height])
        .round(true)
        .paddingOuter(function (node) {
        if (node.depth > 2) {
            return 1;
        }
        if (node.depth > 1) {
            return 2;
        }
        return paddingOuter;
    })
        .paddingTop(function (node) {
        if (node.depth > 2) {
            return 3;
        }
        if (node.depth > 1) {
            return 7;
        }
        return 19;
    })(hierarchy(data)
        .sum(function (s) { return s[valuePropInData]; })
        .sort(function (a, b) { return b[valuePropInData] - a[valuePropInData]; }));
    var numberItemId = 0;
    var customNodes = d3TreeMap.each(function (item) {
        item.customId = numberItemId++;
    });
    return customNodes;
};
