import React from 'react';
import { writeFile, utils } from 'xlsx';

const ExportToExcel = ({ buttonLabel, data, fileName, sheetName, fieldsToExport }) => {

  const exportToExcel = () => {
    // Create a new workbook and worksheet
    const filteredData = filterFields(data, fieldsToExport);
    const ws = utils.json_to_sheet(filteredData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    // Generate Excel file and trigger download
    writeFile(wb, `${fileName}.xlsx`);
  };

  const filterFields = (data, fields) => {
    return data.map(item => {
      let filteredItem = {};
      fields.forEach(field => {
        if (item[field] !== undefined && item[field] !== null && String(item[field]).trim() !== '') {
          filteredItem[field] = item[field];
        }
      });
      return filteredItem;
    });
  };

  return (
    <button onClick={exportToExcel} className="button ms-auto">
      {buttonLabel}
      <span className="material-symbols-outlined">
        download
      </span>
    </button>
  );
};

export default ExportToExcel;
