import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import SVG from './SVG';
import clsx from 'classnames';


export default function CollapseRow({ header, content, defaultColapse, headerClass }) {
  const [collapse, setCollapse] = useState(defaultColapse);
  return (
    <div className="collapse-wrapper">
      <div className={clsx('collpase-row-header', headerClass)}>
        <div className="collpase-row-header-content">{header}</div>
        <div
          onClick={() => setCollapse(!collapse)}
          className="collpase-row-button"
        >
          <SVG icon="arrowdown.svg#down" iconWidth={16} iconHeight={16} className={clsx('collapse-row-icon', { 'revert': collapse })} />
        </div>
      </div>
      <Collapse isOpen={collapse}>{content}</Collapse>
    </div>
  );
}
