import React, { Fragment } from 'react';
import {
  getGraphNodeTitle,
  parseImportance,
  getSize,
  getResponseStatusClassname,
} from 'lib/helper';
import {
  getCoordinationLoadByCluster,
  getProductivityGain,
} from 'lib/metrics';
import { ActivitySelected } from './ActivitySelector';
import CellNode from 'components/CellNode';
import { getRelationshipWorkEffort } from 'lib/metrics';

const getAmbiguity = (links) => {
  if (!links?.length) return 0;

  return `${(
    (links.filter((link) => link.reportedBy?.length === 1).length * 100) /
    links.length
  ).toFixed(1)}%`;
};
const ResourcesInvolved = ({ data }) => {
  if (!data?.link?.num_fte_involved || data?.link?.source?.ax) {
    return '';
  }

  return (
    <div>
      <b>Resources Involved:</b>&nbsp;
      {data.link.num_fte_involved}
    </div>
  );
};

export const Tooltip = ({ ...props }) => {
  const { data, position, threshold, activityOptions, 
    surveyType, filters, totalNodes, links } = props;

  if (!data) return <Fragment />;

  let from = getGraphNodeTitle(data.source);
  let to = getGraphNodeTitle(data.target);
  let nSource = getSize(data.source, totalNodes);
  let nTarget = getSize(data.target, totalNodes);
  if (data.source === data.target) nTarget--;

  let percent = (data.count * 100) / (nSource * nTarget);

  const importance = (data) => {
    if (surveyType === 0) {
      return (
        <div>
          <b>Importance:</b>
          {parseImportance(data.value, threshold, ['weight'])}
        </div>
      );
    } else {
      if (!data.value) return null;

      return (
        <div>
          <b>Relationship importance:</b>{' '}
          {parseImportance(data.value, threshold, ['weight'])}
        </div>
      );
    }
  };
  const workEffort = (data) => {
    if (surveyType === 1) {
      return (
        <div>
          <b>Relationship work effort :</b>{' '}
          {`${getRelationshipWorkEffort(links, data.source, data.target).toFixed(1)} h/mth`}  
        </div>
      );
    }
  };
  const reportedBy = (data) => {
    if (nSource * nTarget > 1) {
      return null;
    }
    const reporterIds = data.link?.reported_by || [];
    let reporterLabels = [];
    if (reporterIds[0] === 'stamp') {
      reporterLabels = ['Manual'];
    } else {
      if (reporterIds.indexOf(data.source.id) > -1) reporterLabels.push(from);
      if (reporterIds.indexOf(data.target.id) > -1) reporterLabels.push(to);
    }

    return (
      <div>
        <b>Reported by:</b> {reporterLabels.join(' & ')}
      </div>
    );
  };
  const ambiguityComponent = () => {
    if (nSource * nTarget > 1) {
      const ambiguity = getAmbiguity(data.links);

      if (!ambiguity) return null;

      return (
        <div>
          <b>Ambiguity:</b> {ambiguity}
        </div>
      );
    } else {
      return null;
    }
  };
  const connectionRatio = () => {
    if (nSource * nTarget > 1) {
      const ratio = `${data.count}/${nSource * nTarget} ${percent.toFixed(1)}%`;
      return (
        <div>
          <b>Connection ratio:</b> {ratio}
        </div>
      );
    } else {
      return null;
    }
  };
  const frequencySection = (data) => {
    if (!data.frequency) {
      return '';
    }

    return (
      <div>
        <b>Interaction frequency:</b>{' '}
        {parseImportance(data.frequency, threshold, ['frequency'])}
      </div>
    );
  };
  const durationSection = (data) => {
    if (!data.duration) {
      return '';
    }

    return (
      <div>
        <b>Interaction duration:</b>{' '}
        {parseImportance(data.duration, threshold, ['duration'])}
      </div>
    );
  };
  const stability = (data) => {
    if (!data.permanence || surveyType === 0) {
      return '';
    }
    return (
      <div>
        <b>Relationship stability:</b>{' '}
        {parseImportance(data.permanence, threshold, ['permanence'])}
      </div>
    );
  };

  const coordinationLoadAndGain = () => {
    const { source, target, baseResult, nodes, links } = data;
    let subMembers = null;
    let linkCountInGroup = 0;
    let coordinationLoad = null;
    if (source?.ax && source?.id === target?.id) {
      subMembers = [source.id, ...source.childNodes];
      links.forEach(link => {
        if (subMembers.indexOf(link.sourceId) > -1 && subMembers.indexOf(link.targetId) > -1) {
          linkCountInGroup += 1;
        }
      });
      coordinationLoad = getCoordinationLoadByCluster(
        subMembers.length, linkCountInGroup, data.optimizeType
      ).toFixed(2);
    } else {
      return null;
    }
    const productivityGain = getProductivityGain(
      links, nodes, baseResult, data.coefficient, subMembers
    ).value.toFixed(2);
    return (
      <>
        <div>
          <b>Coordination Load:</b> {coordinationLoad}
        </div>
        <div>
          <b>Productivity Gain:</b> {productivityGain} h/mth
        </div>
      </>
    );
  };
  return (
    <div
      className="tooltip tooltip__inner"
      style={{
        ...position,
      }}
    >
      <div>
        <CellNode node={data.source} label="From" />
      </div>
      <div>
        <CellNode node={data.target} label="To" />
      </div>
      {reportedBy(data)}
      {workEffort(data)}
      {importance(data)}
      {stability(data)}
      {frequencySection(data)}
      {durationSection(data)}
      <ResourcesInvolved data={data}/>
      {data.duration && surveyType === 0 ? (
        <div>
          <b>Calculated importance:</b> {data.value_new.toFixed(5)}
        </div>
      ) : (
        ''
      )}
      {connectionRatio(data)}
      {ambiguityComponent()}
      {coordinationLoadAndGain()}
      <b>Activities:</b> <br />
      <ActivitySelected
        value={[...data.relationship].map((d) => ({ value: d }))}
        options={activityOptions}
        filters={filters}
      />
    </div>
  );
};

export const TooltipAxis = (props) => {
  const { data, position } = props;
  let workEffort = 0;
  let existingPair = {};
  data.sumRelationshipWorkEffort.forEach((item) => {
    const pairKey = item[0] < item[1] ? `${item[0]}-${item[1]}` : `${item[1]}-${item[0]}`;
    if (!existingPair[pairKey]) {
      existingPair[pairKey] = 1;
      workEffort += item[2];
    }
  });

  if (!data) return <Fragment />;

  return (
    <div
      className="tooltip tooltip__inner"
      style={{
        ...position,
      }}
    >
      {
        !data?.ax && (
          <div style={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
          >
            <b>Name:</b>
            &nbsp;
            <span className="indicator">
              <span className={getResponseStatusClassname(data?.responseStatus)} />
            </span>
            &nbsp;{data.name}
          </div>
        )
      }

      <div>
        <b>Relationship work effort:</b> {workEffort.toFixed(1)} h/mth
      </div>
      <div>
        <b>Ambiguity of relationships receiving information:</b> &nbsp;
        {getAmbiguity(data.incomingLinks)}
      </div>
      <div>
        <b>Ambiguity of relationships providing information:</b> &nbsp;
        {getAmbiguity(data.outgoingLinks)}
      </div>
      <div>
        <b>Receives information from:</b> {data.incoming} resources
      </div>
      <div>
        <b>Provides information to:</b> {data.outgoing} resources
      </div>
      <div>
        <b>Number of two way relationships among these:</b> {data.both}
      </div>
    </div>
  );
};
