import React, { Component } from 'react'
import { ReactComponent as Logo } from '../../../img/logo.svg'
import { ReactComponent as NavbarToggler } from '../../../img/icons/menu.svg'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { connect } from 'react-redux'

import {
  changeSidebarVisibility
  //changeMobileSidebarVisibility
} from '../../../redux/actions/sidebarActions'

class HeaderSidebar extends Component {
  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility())
  }

  render() {
    let topSidebarClass = classNames({
      'logo-wrapper': true,
      'toggle-off': this.props.sidebar.show,
      'toggle-on': this.props.sidebar.collapse
    })
    return (
      <div className={topSidebarClass}>
        <NavLink className="logo" to='/'>
          <Logo style={{ width: '147px', cursor: 'pointer' }} />
        </NavLink>
        <div className="navbar-toggler" onClick={this.changeSidebarVisibility}>
          <NavbarToggler style={{ display: 'flex' }} />
        </div>
      </div>
    )
  }
}

export default connect(state => {
  return { sidebar: state.sidebar }
})(HeaderSidebar)
