import React, { Fragment } from 'react';
import { toast } from 'react-toastify';

let yaml = require('json2yaml');

const content = (msg, txt = null) => {
  return (
    <Fragment>
      <div>{msg}</div>
      {txt && <pre>{yaml.stringify(txt)}</pre>}
    </Fragment>
  );
};

export default ((msg, txt = null) => {
  toast(content(msg, txt), {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
});