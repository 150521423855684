import React, { Component } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowDown } from 'img/icons/arrowdown.svg';
import { FormFeedback } from 'reactstrap';

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      currentIndex: this.props.index ? this.props.index : 0,
    };
  }
  showMenu = (event) => {
    if (this.props.disabled) {
      return;
    }
    event.preventDefault();

    this.setState({ toggle: true }, () => {
      document.addEventListener('mouseup', this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ toggle: false }, () => {
      document.removeEventListener('mouseup', this.closeMenu);
    });
  };

  onChangeItem = (idx, item) => {
    this.setState({
      currentIndex: idx,
    });
    this.props.onChangeItem(idx, item);
  };

  renderMenu = () => {
    const { list } = this.props;
    if (this.props.disabled === true) return '';
    let dropdownClasses = classNames(this.props.menuClassName, {
      'dropdown-menu': true,

      show: this.state.toggle,
    });

    return (
      <div className={dropdownClasses}>
        {list.map((item, index) => (
          <div
            key={index}
            className="dropdown-item cursor-pointer"
            onClick={() => this.onChangeItem(index, item)}
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { list, className, disabled, error } = this.props;
    let pClasses = classNames({
      'cursor-pointer': true,
      show: this.state.toggle,
    });
    return (
      <>
        <div className={classNames(className, { 'is-invalid': error })}>
          <p onClick={this.showMenu} className={pClasses}>
            {list[this.state.currentIndex]}
            {!disabled && <ArrowDown />}
          </p>
          {!disabled && this.renderMenu()}
        </div>
      </>
    );
  }
}
