import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createDesign, getSurvey, remindSurvey } from 'api/survey';
import Error from 'components/Error';
import Loading from 'components/Loading';
import Button from 'components/Button';
import DesignTitleModal from 'pages/Design/components/DesignTitleModal';
import toast from 'lib/toast';
import withRouter from 'lib/withRouter';
import { closeSurvey, deleteSurvey } from 'redux/actions/survey';
import SurveyTopPanel from './components/SurveyTopPanel';
import SurveyParticipants from './components/SurveyParticipants';
import SurveyActivities from './components/SurveyActivities';
import SurveyConfirmModal from './components/SurveyConfirmModal';
import EmailEditor from './components/EmailEditor';

const SurveyShow = (props) => {
  const surveyId = props.match.params.survey;
  const [loading, setLoading] = useState({ status: false, message: '' });
  const [error, setError] = useState('');
  const [survey, setSurvey] = useState(null);
  const [remindEmail, setRemindEmail] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading({ status: true, message: 'Loading data...' });
    getSurvey(surveyId).then(res => {
      setSurvey(res.data);
      setRemindEmail(res.data.remind_email);
    }).catch(err => {
      setError(err);
    }).finally(() => {
      setLoading({ status: false, message: '' });
    });
  }, [surveyId]);

  const deleteConfirmModalRef = useRef();
  const closeConfirmModalRef = useRef();
  const inviteEmailRef = useRef();
  const remindEmailRef = useRef();

  function closeSurvey() {
    props.closeSurvey(surveyId);
    window.location.reload();
  }

  function onCreateDesign(title) {
    setLoading({ status: true, message: 'Creating Design...' });
    createDesign(surveyId, title)
      .then(res => {
        navigate(`/design/org/${res.data.id}`);
      })
      .catch(() => {
        toast('Failed to create design!');
      })
      .finally(() => {
        setLoading({ status: false, message: '' });
      });
  }

  function deleteSurvey() {
    props.deleteSurvey(surveyId);
  }

  function onRemind() {
    remindSurvey({surveyId, remindEmail}).then(
      () => {
        toast('Remind email sent successfully!');
      }
    ).catch(
      () => {
        toast('Failed to send remind email!');
      }
    )
  }

  const onModalToggle = () => {
    setShowModal(!showModal);
  }

  function onChangeEmail(data) {
    setRemindEmail(data);
  }

  // TODO fix loading section for close and deleting survey
  if (props.close.loading) return <Loading text="Closing survey..." />
  if (props.delete_survey.loading) return <Loading text="Deleting survey..." />
  if (error) {
    return <Error text={error} message={error} />;
  }

  if (!survey) return null;

  const custom_columns = (survey.participants_columns || []).filter(col => col.custom);
  return (
    <div>
      <div className="content__header py-4 d-lg-flex align-items-center">
        <div className="ellipsis fs-3">{survey.title}</div>
        {
          survey.closed &&
          <div className="closed-survey ms-3">Survey closed</div>
        }
        <div className="ms-lg-auto me-lg-0">
          {
            !survey.closed
              ? (
                <Button
                  className="button ms-3"
                  text="End survey"
                  onClick={() => closeConfirmModalRef.current.toggle()}
                  iconWidth={16}
                  iconHeight={16}
                  icon="close.svg#close"
                  iconClassName="button__icon button__icon-blue"
                />
              )
              : (
                <Button
                  className="button ms-3"
                  text="Create Design"
                  onClick={onModalToggle}
                  iconWidth={16}
                  iconHeight={16}
                  icon="close.svg#close"
                  iconClassName="button__icon button__icon-blue"
                />
              )
          }
          <Button
            className="button ms-3"
            text="Delete survey"
            onClick={() => deleteConfirmModalRef.current.toggle()}
            iconWidth={16}
            iconHeight={16}
            icon="close.svg#close"
            iconClassName="button__icon button__icon-blue"
          />
        </div>
      </div>
      <div className="card__group">
        <div className="card">
          <SurveyTopPanel data={survey} />
        </div>
        <div className="card">
          <SurveyParticipants
            data={survey.participants?.data || survey.participants}
            surveyId={survey.id}
            customColumns={custom_columns}
          />
        </div>
        {
          !survey.closed && (
            <div className="card">
              <div className="card__content-wrapper pb-3">
                <div className="card__header align-items-center d-flex justify-content-between">
                  <h3>Email message - Reminder</h3>
                  <Button
                    className="button button-ic-bg float-end"
                    text="Remind Participants"
                    onClick={onRemind}
                    icon="remind.svg#main"
                    iconWidth={20}
                    iconHeight={17}
                    position="right"
                    iconClassName="button__icon button__icon-blue"
                  />
                </div>
                <EmailEditor
                  ref={remindEmailRef}
                  data={survey && survey.remind_email}
                  onChange={onChangeEmail}
                  readOnly={false}
                />
              </div>
            </div>
          )
        }
        <div className="card">
          <div className="card__content-wrapper pb-3">
            <div className="card__header row align-items-center">
              <h3 className="me-3">Email message - Survey invitation</h3>
            </div>
            <EmailEditor
              ref={inviteEmailRef}
              data={survey && survey.invite_email}
              onChange={onChangeEmail}
              readOnly={true}
            />
          </div>
        </div>
        <div className="card">
          <div className="card">
            <div className="card__content-wrapper pb-3">
              <div className="card__header row align-items-center">
                <h3 className="me-3">
                  Survey introduction - First page of the survey
                </h3>
              </div>
              <div className="card-content mt-2 overflow-auto"
                dangerouslySetInnerHTML={{ __html: survey.introduction }} />
            </div>
          </div>
        </div>
        <div className="card">
          <SurveyActivities
            data={{
              raw: survey.activities.data,
              hidden: survey.tmp_activities?.data,
            }}
          />
        </div>
      </div>

      <SurveyConfirmModal
        ref={deleteConfirmModalRef}
        message="Are you sure you want to delete this survey?"
        onConfirm={deleteSurvey}
      />
      <SurveyConfirmModal
        ref={closeConfirmModalRef}
        message="Are you sure you want to close this survey?"
        onConfirm={closeSurvey}
      />
      <DesignTitleModal
        showModal={showModal}
        defaultTitle={survey.title}
        onConfirm={onCreateDesign}
        onToggle={onModalToggle}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  close: state.closeSurvey,
  delete_survey: state.deleteSurvey,
});

const mapDispatchToProps = {
  closeSurvey,
  deleteSurvey,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyShow));
