import ApiHelper from './helper';

export async function listDiagnoses(client) {
  const { data } = await ApiHelper.get('/api/diagnose/', {
    params: {
      client: +client ? client : undefined,
    }
  });
  return data;
}

export async function getDiagnose(id, clientId) {
  const { data } = await ApiHelper.get(`/api/diagnose/${id}/?client=${clientId}`);
  return data;
}

export async function createDiagnose(payload) {
  return ApiHelper.post('/api/diagnose/', { data: payload });
}
