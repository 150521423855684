import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import range from 'lodash/range';
import sum from 'lodash/sum';
import isArray from 'lodash/isArray';

export default {
  cloneDeep,
  debounce,
  forEach,
  flatten,
  groupBy,
  map,
  mapValues,
  keyBy,
  range,
  sum,
  isArray,
}