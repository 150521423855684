import React, { Component } from 'react';
import { ButtonGroup } from 'reactstrap';
import classNames from 'classnames';

import ToggleButton from 'components/ToggleButton';

class GraphFilter extends Component {
  handleChangeAxisLabel = (event, value) => {
    let ax = event.target.getAttribute('id');
    this.props.setGraphFilter({
      ...this.props.graphFilter,
      axis: ax,
    });
  };

  render() {
    let { axis } = this.props.graphFilter;

    return (
      <div className="graph-filter">
        <div className="d-flex">
          <div className='d-inline-flex'>
            <div className="m-2">
              <h5>Aggregate to level</h5>
              <ButtonGroup>
                <ToggleButton
                  value="Resource"
                  id="resource"
                  checked={axis === 'resource'}
                  onChange={this.handleChangeAxisLabel}
                />
                {/* TODO Check groupName0 */}
                {this.props.data.defaultLabels?.groupName && (
                  <ToggleButton
                    value={this.props.data.defaultLabels.groupName}
                    id="groupName0"
                    checked={axis === 'groupName0'}
                    onChange={this.handleChangeAxisLabel}
                  />
                )}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GraphFilter;
