import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { exportSurveyParticipants } from 'api/survey';
import AgTable from 'components/AgTable';
import Button from 'components/Button';
import { getResponseStatusClassname } from 'lib/helper';

const StatusCell = (props) => {
  return (
    <span className="indicator">
      <span className={getResponseStatusClassname(props.value)}/>
    </span>
  );
};

const SurveyParticipants = (props) => {
  let columns;
  const { surveyId, data, customColumns, hideExport, isSimple } = props;
  const mutation = useMutation({
    mutationFn: (payload) => {
      return exportSurveyParticipants(payload).then((response) => {
        let url = window.URL.createObjectURL(
          new Blob([response.data])
        );
        let filename;
        let a = document.createElement('a');
        a.href = url;
        filename = response.headers['content-disposition'].split('filename=')[1];
        a.download = filename;
        a.click();
      });
    },
  });
  const onExport = () => {
    mutation.mutate({ id: surveyId });
  };
  const additionalColumns = customColumns.map((col, index) => ({
    accessor: `customfield${index + 1}`,
    Header: col.Header,
  }));

  if (isSimple) {
    columns = [
      { accessor: 'first_name', Header: 'First Name' },
      { accessor: 'last_name', Header: 'Last Name' },
      { accessor: 'title', Header: 'Job Title' },
      { accessor: 'department', Header: 'Organization unit' },
      { accessor: 'email_address', Header: 'Email' },
      {
        accessor: 'subscriber_status',
        Header: 'Response status',
        Cell: StatusCell,
      },
    ];
  } else {
    columns = [
      { accessor: 'first_name', Header: 'First Name' },
      { accessor: 'last_name', Header: 'Last Name' },
      { accessor: 'title', Header: 'Job Title' },
      { accessor: 'team', Header: 'FTE'},
      { accessor: 'department', Header: 'Organization unit' },
      { accessor: 'mailing_address_city', Header: 'Location' },
      { accessor: 'email_address', Header: 'Email' },
      ...additionalColumns,
      {
        accessor: 'subscriber_status',
        Header: 'Response status',
        Cell: StatusCell,
      },
    ]
  }

  return (
    <div className="card__content-wrapper">
      <div className="card__header align-items-center d-flex justify-content-between">
        <h3>Participants</h3>
        {
          !hideExport &&
          <Button
            className="button button-ic-bg float-end"
            text="Export participant list"
            onClick={onExport}
            icon="export.svg#share"
            iconWidth={20}
            iconHeight={17}
            position="right"
            iconClassName="button__icon button__icon-blue"
          />
        }
      </div>
      <div className="card-content">
        <AgTable
          data={data}
          columns={columns}
          defaultSort={[{ id: 'subscriber_status', desc: true }]}
          emptyText="No participants"
        />
      </div>
    </div>
  );
};

export default SurveyParticipants;
