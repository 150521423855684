import React, { useEffect, useState } from 'react';
import OrgChartComponent from 'components/OrgChart'; // Ensure correct import path
import Loading from 'components/Loading';
import * as d3 from 'd3';

/*
template: `
          <div>
            <div style="margin-left:70px;margin-top:10px;font-size:20px;font-weight:bold;">${employee.first_name + employee.last_name}</div>
            <div style="margin-left:70px;margin-top:3px;font-size:16px;">${employee.role}</div>
            <div style="margin-left:70px;margin-top:3px;font-size:14px;">${employee.department}</div>
            <div style="margin-left:196px;margin-top:15px;font-size:13px;position:absolute;bottom:5px;">
              <div>${employee.office}</div>
              <div style="margin-top:5px">${employee.location}</div>
            </div>
          </div>
        `,
*/

export default function OrgChartTab({ name, setName, website, setWebsite, businessDetails, setBusinessDetails, businessDescription, setbusinessDescription, activities, setActivities, employees, setEmployees }) {
  const [orgChartData, setOrgChartData] = useState([]); // Use useState for managing data
  const [isLoading, setIsLoading] = useState(true);

  function isInteger(str) {
    return /^-?\d+$/.test(str);
  }

  // Helper function to normalize reportingTo value
  const normalizeReportingTo = (value) => {
    if (typeof value === 'string' && !isNaN(value)) {
      return parseInt(value, 10);
    }
    return value;
  };

  const getReportingTo = (reportingTo) => {
    if (!reportingTo) {
      return null;
    }
  
    const normalizedReportingTo = normalizeReportingTo(reportingTo);
  
    // Use employee_number if reportingTo is a number or a string representation of a number
    if (isInteger(normalizedReportingTo)) {
      const manager = employees.find((employee) => String(employee.employee_number) === String(normalizedReportingTo));
      return getEmployeeId(manager);
    }
  
    // Use email if reportingTo is a string and not a number
    //if (typeof normalizedReportingTo === 'string') {
    if (reportingTo.includes('@')) {
      const manager = employees.find((employee) => employee.email === reportingTo);
      return getEmployeeId(manager);
    }
  
    return '';
  };

  const getEmployeeId = (employee) => {
    if (employee.employee_number) {
      return employee.employee_number;
    }
    return employee.email;
  }
  
  useEffect(() => {
    const buildOrgChartDataset = (employees) => {
      let dataset = [];
      if (!employees) {
        return dataset;
      }
      try {
        employees.forEach((employee) => {
          const node = {
            nodeId: getEmployeeId(employee),
            parentNodeId: getReportingTo(employee.reporting_to),
            employee_number: employee.employee_number,
            name: employee.name || `${employee.first_name} ${employee.last_name}`,
            role: employee.role || employee.title,
            unit: employee.organization_unit || employee.groupName,
            borderWidth:1,
            borderRadius:5,
            borderColor:{
              red:15,
              green:140,
              blue:121,
              alpha:1
            },
            backgroundColor:{
              red:51,
              green:182,
              blue:208,
              alpha:1
            },
            nodeIcon:{
              icon:'https://www.freeiconspng.com/img/1682',
              size:30
            },
            connectorLineColor:{
              red:220,
              green:189,
              blue:207,
              alpha:1
            },
            connectorLineWidth:5,
            dashArray:'',
            expanded:false,
            directSubordinates:getDirectSubordinates(employee),
            totalSubordinates:getTotalSubordinates(employee),
          };
          dataset.push(node);
        });
      } catch (error) {
        //console.error('Error building org chart dataset:', error);
      }
      return dataset;
    }

    setOrgChartData(buildOrgChartDataset(employees));
    setIsLoading(false);
  }, [employees]);

  const getDirectSubordinates = (employee) => {
    if (!employee) {
      return 0;
    }
    const directSubordinates = employees.filter((emp) => String(emp.reporting_to) === String(getEmployeeId(employee)));
    return directSubordinates.length + (employee.fte-1);
  }

  const getTotalSubordinates = (employee) => {
    if (!employee) {
      return 0;
    }
    const directSubordinates = employees.filter((emp) => String(emp.reporting_to) === String(getEmployeeId(employee)));
    let totalSubordinates = directSubordinates.length + (employee.fte-1);
    directSubordinates.forEach((emp) => {
      totalSubordinates += getTotalSubordinates(emp);
    });
    return totalSubordinates;
  }


  return (
    <div className='diagram-area'>
      {isLoading ? (
            <div className="loading-spin">
              <Loading text="Building the organization chart..." />
            </div>
          ) : (
            <OrgChartComponent data={orgChartData} />
      )}
    </div>
  );
}
