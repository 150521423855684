import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Button from 'components/Button';
import _, { isEqual } from 'lodash';
import { generateuniqueIshId, transposeObject, sum } from 'lib/helper';
import { getAutomationPotentialByActivity, getTotalWorkEffort, calcTotalWorkEffortAcrossActivities } from 'lib/metrics';
import { postSearchApi } from 'api/chat';
import { FTE_HOURS_PER_MONTH } from 'lib/constants';
import PercentageCellEditor from 'components/DataTable/PercentageCellEditor';
import InlineTextCellEditor from 'components/InlineTextCellEditor';
import StrategicActivityClassificationEditor from 'components/StrategicActivityClassificationEditor';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import KpiCard from 'components/KpiCard';
import RatioBar from 'components/RatioBar';
import TreeMap from 'components/TreeMap';

const calcTotalWorkEffortAcrossRelationshipActivities = (relationships, activities) => {
  // for each activity, calculate the total work effort across all relationships
  return activities.map((activity) => {
    const relationshipWorkEffortPerActivity = {};
    const totalWorkEffort = relationships.map((relationship) => {
      if(!relationship.relationship.includes(String(activity.id))) {
        return 0;
      }
      return relationship.workEffort / relationship.relationship.length || 0;
    }).reduce((total, effort) => total + effort, 0);
    return {'activityId': activity.id, 'totalRelationshipWorkEffort': totalWorkEffort};
  });
};

const getRelationshipWorkEffortForClassification = (relationships, activities, activityClassification) => {
  const strategicRelationships = relationships.map((relationship) => {
    const workEffortPerActivity = relationship.workEffort / relationship.relationship.length;
    const classifiedRelationships = relationship.relationship.filter((activityId) => activities.find((a) => String(a.id) === String(activityId)).classification === activityClassification);
    return workEffortPerActivity * classifiedRelationships.length || 0;
  });
  return sum(strategicRelationships);
}


const CommunciationMetricsComponent = (props) => {
  const { data, employees, relationships, activities, isMaximized } = props;
  const { time_utilization } = data;
  const [communicationMetrics, setCommunicationMetrics] = useState([]);

  const totalWorkEffortPerRelationshipActivity = useMemo(() => calcTotalWorkEffortAcrossRelationshipActivities(relationships, activities), [relationships, activities, calcTotalWorkEffortAcrossRelationshipActivities]);

  const totalCommunicationHours = useMemo(() => relationships.reduce((total, relationship) => total + relationship.workEffort, 0) || 0, [relationships]);
  const automationPotentialByActivity = useMemo(() => getAutomationPotentialByActivity(activities, time_utilization, employees)?.automation_potential_per_activity, [activities, time_utilization, employees]);
  const totalStrategicRelationshipWorkEffort = useMemo(() => getRelationshipWorkEffortForClassification(relationships, activities, 'Strategic') || 0, [relationships, activities]);
  const totalSupportRelationshipWorkEffort = useMemo(() => getRelationshipWorkEffortForClassification(relationships, activities, 'Support') || 0, [relationships, activities]);
  

  const complexityByActivity = useCallback(() => {
    if (!totalWorkEffortPerRelationshipActivity) {
      return [];
    }
    return activities.map((activity) => {
      const activityWorkEffort = totalWorkEffortPerRelationshipActivity.find((a) => a.activityId === activity.id)?.totalRelationshipWorkEffort || 0;
      return activityWorkEffort / totalWorkEffortPerRelationshipActivity.reduce((total, activity) => total + activity.totalRelationshipWorkEffort, 0);
    });
  }, [activities, totalWorkEffortPerRelationshipActivity]);

  const totalComplexity = useMemo(() => 100 * complexityByActivity().reduce((total, complexity) => total + complexity, 0) / activities.length || 0, [activities, totalWorkEffortPerRelationshipActivity]);
  const totalAmbiguity = useMemo(() => {
    if (!relationships || relationships.length === 0) {
      return 0;
    }
  
    const total = relationships.reduce((total, relationship) => {
      return total + (relationship?.reported_by?.length === 1 ? 1 : 0);
    }, 0);
  
    return (total / relationships.length) * 100;
  }, [relationships]);
  
  const uncertaintyPerEmmployee = employees.map((employee) => employee.incomingLinks?.length - employee.outgoingLinks?.length || 0);
  const totalUncertainty = uncertaintyPerEmmployee.reduce((total, diff) => total + diff, 0) / employees.length * 100 + 50; // Balance the uncertainty to be between 0 and 100 with 50 as the baseline for a balanced communication network
  
  const CalcCommunicationMetrics = useCallback(() => {
    return [
      { title: 'Total monthly communication hours', value: totalCommunicationHours.toFixed(0).toLocaleString('no-NO'), icon: '🕒', unit: 'hrs/mth' },
      { title: 'Complexity', value: totalComplexity.toFixed(0).toLocaleString('no-NO'), icon: '🧩', unit: '%'},
      { title: 'Uncertainty', value: totalUncertainty.toFixed(0).toLocaleString('no-NO'), icon: '🤷', unit: '%' },
      { title: 'Ambiguity', value: totalAmbiguity.toFixed(0).toLocaleString('no-NO'), icon: '⚖️', unit: '%' },
    ];
  }, [totalCommunicationHours, totalComplexity, totalUncertainty, totalAmbiguity]);


  useEffect(() => {
    const UpdatedAutomationMetrics = CalcCommunicationMetrics();
    if (!isEqual(communicationMetrics, UpdatedAutomationMetrics)) {
      setCommunicationMetrics(UpdatedAutomationMetrics);
    }
  }, [CalcCommunicationMetrics, communicationMetrics]);

  return (
    <div className="tab automation-tab">
      <div>
        <div className='automation-tab-header'>
          
        </div>
        <div className='container-wide-row'>
          <h2>Communication Effort and Impact</h2>
          <RatioBar label1='Strategic communication' label2="Support communication" value1={totalStrategicRelationshipWorkEffort} value2={totalSupportRelationshipWorkEffort} unit="hrs/mth" />
          <KpiCard kpiData={communicationMetrics} />
          
        </div>
      </div>

      
    </div>
  );
};

export default CommunciationMetricsComponent;
