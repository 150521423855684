import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { Alert } from 'reactstrap';

import { checkSurveyData } from 'lib/helper';
import toast from 'lib/toast';
import {
  getSurvey,
  getSurveyMeta,
  setSurvey,
  setSurveyError,
  surveyCreate,
  sendSurveys,
  deleteSurvey,
  clearSurvey,
} from 'redux/actions/survey';
import TextInput from 'components/TextInput';
import ConfirmLeave from 'components/ConfirmLeave';
import HTMLEditor from 'components/HTMLEditor';
import Button from 'components/Button';
import Loading from 'components/Loading';
import SurveyParticipantsCreate from './components/SurveyParticipantsCreate';
import SurveyActivitiesCreate from './components/SurveyActivitiesCreate';
import SurveyConfirmModal from './components/SurveyConfirmModal';
import EmailEditor from './components/EmailEditor';
import { createSurvey } from 'api/survey';
import withRouter from 'lib/withRouter';

class SurveyCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      endDate: null,
      name: null,
      error: null,
      changed: false,
      survey: {
        ...props.survey,
        activities: this.props.survey.data?.activities || [], // this.props.history.location.state?.activities || [],
        participants: this.props.history.location.state?.participants || [],
      },
      surveyCreating: false,
    };
    this.endDateRef = React.createRef();
    this.deleteConfirmModalRef = React.createRef();
    this.introductionRef = React.createRef();
    this.inviteEmailRef = React.createRef();
  }

  componentDidMount() {
    this.surveyId = parseInt(this.props.match.params.survey);
    const { survey } = this.state;
    if (this.surveyId) {
      this.setState({
        survey: {
          ...survey,
          loading: true,
        },
      });
      this.props.getSurvey(this.surveyId);
    } else {
      const activities = this.props.history.location.state?.activities;
      const participants = this.props.history.location.state?.participants;
      this.props.clearSurvey();
      this.setState({
        survey: {
          ...survey,
          data: null,
        },
      });

      if(activities && participants) {
        if (activities.length > 0 && participants.length > 0) {
          this.props.setSurvey({
            activities,
            participants,
          })
        }

        if (activities.length > 0 && participants.length === 0) {
          this.props.setSurvey({
            activities,
          })
        }

        if (activities.length === 0 && participants.length > 0) {
          this.props.setSurvey({
            participants,
          })
        }
      }

      if(activities && !participants) {
        if (activities.length > 0) {
          this.props.setSurvey({
            activities,
          })
        }
      }

      this.props.getSurveyMeta();
    }
  }

  deleteSurvey = () => {
    if (!this.surveyId) return;
    this.deleteStart = true;
    this.props.deleteSurvey(this.surveyId);
  };

  sendRequest = () => {
    if (this.checkSurveyData()) {
      let { data } = this.state.survey;

      data.introduction = this.introductionRef.current.getContent();
      data.invite_email = this.inviteEmailRef.current.getContent();
      if (+this.props.currentClient) {
        data.client = this.props.currentClient;
      }
      this.sendSurveyStart = true;
      if (this.surveyId) {
        data.id = this.surveyId;
      }
      this.props.sendSurveys(data);
    } else {
      toast('There should be a validation error somewhere. Please fix the error and try again.');
    }
  };

  onCreate = () => {
    if (this.checkSurveyData()) {
      this.setState({ surveyCreating: true }, () => {
        this.sendSurveyStart = true;
      });
      let { data } = this.state.survey;
      data.introduction = this.introductionRef.current.getContent();
      data.invite_email = this.inviteEmailRef.current.getContent();
      if (+this.props.currentClient) {
        data.client = this.props.currentClient;
      }
      if (this.surveyId) {
        data.id = this.surveyId;
      }
      createSurvey(data).then(res => {

      }).catch(err => {
        console.warn(err);
      }).finally(() => {
        this.setState({ surveyCreating: false });
      });
    } else {
      toast('There should be a validation error somewhere. Please fix the error and try again.');
    }
  };

  onChangeTitle = (e) => {
    this.props.setSurvey({
      ...this.state.survey.data,
      title: e.target.value,
    });
  };

  onChangeEndDate = (e) => {
    this.props.setSurvey({
      ...this.state.survey.data,
      end_date: e.target.value,
    });
  };

  onChangeEmail = (data) => {
    this.props.setSurvey({
      ...this.state.survey.data,
      invite_email: data,
    });
  };

  onChangeIntroduction = (data) => {
    this.props.setSurvey({
      ...this.state.survey.data,
      introduction: data,
    });
  };

  checkSurveyData = () => {
    let { data } = this.state.survey;
    let errors = checkSurveyData(data);
    if (Object.keys(errors).length) {
      this.props.setSurveyError(errors);
      return false;
    } else {
      return true;
    }
  };

  onSave = () => {
    if (!this.checkSurveyData()) {
      toast('There are some invalid settings.');
      return;
    }
    let data = { ...this.state.survey?.data };
    data.introduction = this.introductionRef.current.getContent();
    data.invite_email = this.inviteEmailRef.current.getContent();
    if (+this.props.currentClient) {
      data.client = this.props.currentClient;
    }
    if (data) {
      this.createStart = true;
      this.props.surveyCreate(data);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      survey: nextProps.survey,
    };
  }

  render() {
    const {
      match,
      surveyMeta,
      surveyCreateStatus,
      sendSurveyStatus,
      deleteSurveyStatus,
      profile,
    } = this.props;
    const { survey } = this.state;
    let survey_id = match.params.survey;
    if (survey_id && survey.loading) {
      return <Loading text="Loading survey" />;
    }

    if (surveyCreateStatus.loading) {
      return (
        <Loading text={survey_id ? 'Updating survey' : 'Saving draft survey'} />
      );
    }
    if (sendSurveyStatus.loading) {
      return (
        <Loading
          text={survey_id ? 'Sending survey' : 'Creating and sending survey'}
        />
      );
    }
    if (this.state.surveyCreating) {
      return (
        <Loading text="Creating survey" />
      );
    }
    if (deleteSurveyStatus.loading) {
      return <Loading text="Removing survey" />;
    }

    if (this.createStart && surveyCreateStatus.loading === false) {
      if (!surveyCreateStatus.errors) {
        return <Navigate to="/discover" />;
      }
    }

    if (this.sendSurveyStart && this.state.surveyCreating === false) {
      if (!surveyCreateStatus.errors) {
        return <Navigate to="/discover/" />;
      }
    }

    if (this.sendSurveyStart && sendSurveyStatus.loading === false) {
      if (!sendSurveyStatus.errors) {
        return <Navigate to={'/discover/' + sendSurveyStatus.data.id} />;
      }
    }

    if (this.deleteStart && deleteSurveyStatus.loading === false) {
      if (!deleteSurveyStatus.errors) {
        return <Navigate to="/discover" />;
      }
    }

    if (surveyMeta.loading) {
      return <Loading text="Loading" />;
    }

    let surveyData = survey.data;
    let surveyCreateError = surveyCreateStatus.errors;
    let sendSurveyError = sendSurveyStatus.errors;
    let deleteSurveyError = deleteSurveyStatus.errors;
    let { email, subject, from_email, from_name } = surveyMeta?.data || {};
    let emailData = surveyData?.invite_email ?? {
      subject,
      from_name,
      body: email,
      reply: from_email,
    };
    let introductionData =
      surveyData?.introduction ?? surveyMeta?.data?.introduction;
    let errors =
      (survey && survey.errors) ||
      surveyCreateError ||
      sendSurveyError ||
      deleteSurveyError ||
      {};

    return (
      <div>
        <div className="content__header py-4 d-lg-flex align-items-center">
          <h3 className="ellipsis">
            {surveyData?.id ? surveyData.title : 'New Survey'}
          </h3>
          <Button
            className="button me-3 mb-3 ms-lg-auto"
            text="Save"
            onClick={this.onSave}
            disabled={!survey.changed}
          />
          {
            profile?.data?.is_admin &&
            <Button
              className="button mb-3 me-3"
              text="Create"
              onClick={this.onCreate}
            />
          }
          <Button
            className="button mb-3"
            text="Send"
            onClick={this.sendRequest}
          />
          {(this.surveyId && (
            <Button
              className="button ms-3 mb-3"
              text="Delete"
              onClick={() => this.deleteConfirmModalRef.current.toggle()}
            />
          )) ||
            ''}
        </div>

        {errors && errors.non_field_errors && (
          <Alert color="danger">{errors.non_field_errors}</Alert>
        )}

        <div className="card__group">
          <div className="new-survey-name">
            <TextInput
              type="text"
              id="title"
              name="title"
              placeholder="Enter survey title"
              required
              maxLength="30"
              onChange={this.onChangeTitle}
              defaultValue={surveyData && surveyData.title}
              error={errors.title}
            />
          </div>
          <div className="row mb-2">
            <div className="col-lg-6 mb-2 d-flex flex-column">
              <div className="mb-1">
                When are you planning to end the survey?
              </div>
              <div className="mt-auto">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Survey ends at"
                  defaultValue={surveyData ? surveyData.end_date : null}
                  onChange={this.onChangeEndDate}
                  ref={this.endDateRef}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card__group">
          <div className="survey__ends align-items-center pt-4">
            <div className="card">
              <div className="card__content-wrapper pb-3">
                <div className="card__header row align-items-center">
                  <h3 className="me-3">Email message - Survey invitation</h3>
                </div>
                <EmailEditor
                  ref={this.inviteEmailRef}
                  data={emailData}
                  onChange={this.onChangeEmail}
                />
              </div>
            </div>
          </div>
          <div className="survey__ends align-items-center pt-4">
            <div className="card">
              <div className="card__content-wrapper">
                <h3 className="me-3">
                  Survey introduction - First page when opening survey
                </h3>
                <div className="card-content mt-2 mb-2">
                  <HTMLEditor
                    ref={this.introductionRef}
                    value={introductionData}
                    editorHeight={600}
                    onChange={this.onChangeIntroduction}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <SurveyActivitiesCreate />
        </div>
        <div className="card">
          <SurveyParticipantsCreate props={this.props} addColumn={true} />
        </div>
        <SurveyConfirmModal
          ref={this.deleteConfirmModalRef}
          message="Are you sure to delete this survey?"
          onConfirm={this.deleteSurvey}
        />
        <ConfirmLeave change={survey.changed} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  surveyCreateStatus: state.surveyCreate,
  survey: state.survey,
  surveyMeta: state.surveyMeta,
  sendSurveyStatus: state.sendSurvey,
  deleteSurveyStatus: state.deleteSurvey,
  profile: state.profile,
  currentClient: state.currentClient?.data,
});

const mapDispatchToProps = {
  surveyCreate,
  getSurvey,
  getSurveyMeta,
  sendSurveys,
  deleteSurvey,
  setSurvey,
  setSurveyError,
  clearSurvey,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyCreate));
