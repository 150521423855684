import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';

import { listDiagnoses } from 'api/diagnose';
import LinkButton from 'components/LinkButton';
import Loading from 'components/Loading';
import { SortableComponent } from 'lib/Sortable';
import ItemCard from './ItemCard';

function useDiagnoses(clientId) {
  return useQuery({
    queryKey: ['diagnoses', clientId],
    queryFn: () => listDiagnoses(clientId),
  });
}

const Diagnoses = (props) => {
  const diagnoses = useDiagnoses(props.currentClient);
  const allData = diagnoses.data?.data || [];
  const renderChild = (diagnosis) => <ItemCard data={diagnosis}/>;

  if (diagnoses.isFetching) {
    return <Loading text="Loading data..."/>;
  }

  return (
    <>
    <div className="content__header py-4 d-lg-flex align-items-center">
      <h3 className="ellipsis">Diagnose your organization</h3>
      <div className="ms-lg-auto">
        <LinkButton
          to="/diagnose/new"
          text="Create Diagnosis"
          iconWidth={16}
          iconHeight={16}
          icon="plus.svg#plus"
          iconClassName="button__icon button__icon-blue"
          className="button"
        />
      </div>
    </div>
      <div className="all__surveys row">
        <svg style={{height: 0, width: 0}} className="gradient-pattern">
          <defs>
            <linearGradient id="circle_progressbar_gradient">
              <stop offset="0%" stopColor="#4767F9"/>
              <stop offset="100%" stopColor="#11C684"/>
            </linearGradient>
          </defs>
        </svg>
        <SortableComponent
          data={allData}
          renderChild={renderChild}
          // onSortStart={onSortStart}
          // onSortEnd={orderSurveys}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentClient: state.currentClient?.data,
});

export default connect(mapStateToProps, null)(Diagnoses);
