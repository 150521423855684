import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import useContextMenu from 'lib/useContextMenu';
import SVG from 'components/SVG';

export default function Item({ item, choosen, onClick, linkmap, labels, width, height, onDoubleClick, onCopy, onDelete }) {
  const _choosen = useMemo(() => choosen && item.id === choosen.id, [choosen, item]);
  const incomming = !_choosen && choosen && linkmap[`${choosen.id}-${item.id}`]
  const outgoing = !_choosen && choosen && linkmap[`${item.id}-${choosen.id}`]
  const both = incomming && outgoing;

  const itemRef = useRef();
  const contextMenuRef = useRef();
  const { contextMenu } = useContextMenu({
    menu: contextMenuRef,
    targets: [{ id: 'contextMenu', target: itemRef }]
  })

  const copyItem = () => {
    console.log('copyItem function');
  }

  const onClickItem = (e) => {
    e.preventDefault();
    if (!contextMenuRef.current || !contextMenuRef.current.contains(e.target)) {
      onClick();
    }

  }


  return (
    <>
      <div
        ref={itemRef}
        className={classNames('card-item', { choosen: _choosen, incomming, outgoing, both })}
        style={{
          '--itemColor': item.color,
          minHeight: `${height}px`,
          width: `${width}px`,
        }}
        data-id={item.id}
        onClick={onClickItem}
        onDoubleClick={() => onDoubleClick(item)}
      >
        <div
          className={classNames('card-name')}
        >
          {labels.map(label => <p key={label}>{item[label]}</p>)}
        </div>
        {
          contextMenu.isOpen && (
            <div className="context-menu-wrapper">
              <div ref={contextMenuRef} className="context-menu">
                <div className="context-menu-item d-flex align-items-center" onClick={onDoubleClick}>
                  <span className="me-2">Edit</span>
                  <SVG
                    icon="edit.svg#deselected"
                    iconWidth={20}
                    iconHeight={20}
                    iconClassName="button__icon"
                    iconViewWidth={42}
                    iconViewHeight={42}
                  />
                </div>
                <div className="context-menu-item d-flex align-items-center" onClick={onCopy}>
                  <span className="me-2">Copy</span>
                  <SVG
                    icon="copy1.svg#copy"
                    iconWidth={20}
                    iconHeight={20}
                    iconViewWidth={116}
                    iconViewHeight={123}
                    iconClassName="button__icon"
                  />
                </div>
                <div className="context-menu-item d-flex align-items-center" onClick={onDelete}>
                  <span className="me-2">Delete</span>
                  <SVG
                    icon="delete.svg#delete"
                    iconWidth={20}
                    iconHeight={20}
                    iconClassName="button__icon"
                  />
                </div>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}
