import { apiMiddleware } from 'redux-api-middleware';
import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { apiAuthInjector, apiAuthFailureInjector } from 'app/authApiInjector';
import createRootReducer from '../redux/reducers';

export const history = createBrowserHistory();

const configureAppStore = (preloadedState) => {
  return configureStore({
    reducer: createRootReducer(history),
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: {
          ignoredPaths: ['designOrg', 'survey', 'designMatrixFilter'],
        },
        serializableCheck: {
          ignoredActionPaths: ['payload.nodes', 'payload.links'],
          ignoredPaths: ['designOrg',],
        },
      }).concat([apiAuthInjector, apiMiddleware, apiAuthFailureInjector]),
    preloadedState,
  });
};

const initialState = {
  sidebar: {
    show: true,
    collapse: false,
  },
};

export default configureAppStore(initialState);
