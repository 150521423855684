import React from 'react';

import LinkButton from 'components/LinkButton';

const DesignButtons = (props) => {
  return (
    <div className="design-management-actions">
      <div className="clearfix w-100">
        <LinkButton
          className="button button-ic-bg float-start mb-4"
          to={`/design/org/${props.design.id}`}
          text=" EDIT"
          icon="design-small.svg#pen"
          iconWidth={20}
          iconHeight={17}
          position="right"
          iconClassName="button__icon button__icon-blue"
        />
      </div>
    </div>
  );
};

export default DesignButtons;
