import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function DropdownMenu({ title, items, onChange }) {
  const [inputTitle, setInputTitle] = useState(title);

  const handleSelect = (eventKey) => {
    const selectedItem = items.find(item => item.id.toString() === eventKey);
    if (selectedItem) {
      setInputTitle(selectedItem.label);
      if (onChange) {
        onChange(selectedItem);
      }
    }
  };

  return (
    <DropdownButton title={inputTitle} onSelect={handleSelect}>
      {items.map((item, index) => (
        <Dropdown.Item key={index} eventKey={item.id.toString()}>{item.label || item.name}</Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default DropdownMenu;
