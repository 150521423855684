import React from 'react';
import Card from 'react-bootstrap/Card';

const EmployeeCard = (props) => {
    const { employee, selectedAgent, activity, fte_hour_month, onClick } = props;

    const calcTimeFreedUp = (employee, activity, fte_hour_month) => {
        let activityId = activity.id;
        if (!('time_utilization' in employee) || employee.time_utilization === null) {
            return 0;
        }
        if (!Object.keys(employee.time_utilization).includes(String(activity.id))) {
            activityId = activity.function_id;
        }
        if (!employee.time_utilization || !employee.time_utilization[activityId]) {
            return 0;
        }
        return (parseFloat(employee.time_utilization[activityId]) / 100) * employee.fte * fte_hour_month * (parseFloat(activity.automation_potential) / 100);
    }

    const handleClick = () => {
        if (onClick) {
            onClick(employee);
        }
    }

    return (
        <Card
            className={`${employee.employee_type === 'GenAI Agent' ? 'agent-card' : 'employee-card'} ${employee?.id === selectedAgent?.id ? 'agent-card-selected' : ''}`}
            onClick={handleClick}
        >
            <Card.Img variant="top" src={employee.image} />
            <Card.Body>
                <Card.Title>{employee.name}</Card.Title>
                <Card.Subtitle>{employee.title}</Card.Subtitle>
                {employee.employee_type === 'GenAI Agent' ? (
                    <Card.Text>
                        Automates: {(employee.genAIAgentData.workFreedUp || 0).toLocaleString('no-NO')} h/mth
                    </Card.Text>
                ) : (
                    <Card.Text>
                        Frees up: {calcTimeFreedUp(employee, activity, fte_hour_month).toLocaleString('no-NO')} h/mth
                    </Card.Text>
                )}
            </Card.Body>
        </Card>
    );
};

export default EmployeeCard;
