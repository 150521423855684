import "./Tooltip.css";
import * as React from "react";
import useTooltip from "./useTooltip";
export var Tooltip = React.memo(function (_a) {
    var label = _a.label, value = _a.value;
    var tooltipClassName = useTooltip().tooltipClassName;
    return (React.createElement("div", { className: tooltipClassName },
        React.createElement("div", { className: "TreeMap__tooltip" }, value !== undefined ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "TreeMap__tooltipLabel" },
                label,
                ": "),
            React.createElement("span", { className: "TreeMap__tooltipValue" }, "" + value))) : (label))));
});
