import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(callback, dependencies) {
  // Using the custom hook to memoize the dependencies
  const memoizedDependencies = useDeepCompareMemoize(dependencies);

  useEffect(() => {
    callback();
  }, [memoizedDependencies]); // Only re-run if memoized dependencies change
}
