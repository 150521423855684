import React, { useState, useRef, useEffect } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AutoSuggest from 'react-autosuggest';
import LocaleCurrency from 'locale-currency';
import cc from 'country-code';
import _ from 'lodash';
import 'flag-icon-css/css/flag-icon.css';
const debug = require('debug')('select-currency:info');

const countries = associateCurrencies(countriesWithCurrency(cc.countries));

function associateCurrencies(countries) {
  return _.map(countries, (country) => ({
    ...country,
    currencyCode: LocaleCurrency.getCurrency(country.alpha2),
  }));
}

function countriesWithCurrency(countries) {
  return _.filter(countries, (country) => !!LocaleCurrency.getCurrency(country.alpha2));
}

function stringStartsWith(s, fragment) {
  return s.indexOf(fragment) === 0;
}

function stringContains(s, fragment) {
  return s.indexOf(fragment) !== -1;
}

function getSuggestions(arg = '') {
  const value = arg.trim().toUpperCase();
  const commonCurrencyCountries = ['US', 'DE', 'JP', 'GB', 'CN'];
  
  let filteredCountries = value === ''
    ? countries
    : _.filter(countries, (country) => {
        return stringStartsWith(country.currencyCode, value) || stringContains(country.name.toUpperCase(), value);
      });

  const prioritized = _.partition(filteredCountries, country => commonCurrencyCountries.includes(country.alpha2));

  return [...prioritized[0], ...prioritized[1]];
}

function getSuggestionValue(country) {
  return country.currencyCode;
}

function renderSuggestion({ name, alpha2: countryCode, currencyCode }) {
  const iconClasses = classNames(
    'flag-icon',
    `flag-icon-${countryCode.toLowerCase()}`
  );
  return (
    <div className="inputContainer">
      <span className={iconClasses} />
      &nbsp;<span className="country-name">{name} ({currencyCode})</span>
    </div>
  );
}

const CurrencySelect = ({ onChange, name, value: initialCurrencyCode = '', className, ...passProps }) => {
  const hasRunOnceRef = useRef(false);
  const [country, setCountry] = useState('');
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setSuggestions(getSuggestions(value));
  }, [value]);

  useEffect(() => {
    // Set the initial currency code on screen, but not on evey change since that will make it static
    if (initialCurrencyCode && !hasRunOnceRef.current) {
      if (initialCurrencyCode) {
        setValue(initialCurrencyCode);
      }
      hasRunOnceRef.current = true;
    }
  }, [initialCurrencyCode]);

  const handleChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSuggestionSelected = (event, { suggestion }) => {
    onChange({
      target: {
        currencyCode: suggestion.currencyCode,
        countryCode: suggestion.alpha2,
      },
    });
  };

  const inputProps = {
    ...passProps,
    value,
    placeholder: 'Currency',
    onChange: handleChange,
  };

  return (
    <FormGroup className={className}>
      <AutoSuggest
        value={value}
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionSelected={getSuggestionValue}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        shouldRenderSuggestions={() => true}
        onSuggestionSelected={handleSuggestionSelected}
        className="auto-suggest-dropdown form-control"
      />
    </FormGroup>
  );
};

CurrencySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default CurrencySelect;
