import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';

import TextInput from 'components/TextInput';
import Button from 'components/Button';
import toast from 'lib/toast';
import { logout } from 'redux/actions/auth'
import { passwordReset } from 'api/user';

const PasswordReset = ({ ...props }) => {
  const { errors } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const handleInputChange = event => {
    const value = event.target.value;
    switch (event.target.name) {
      case 'old_password':
        setOldPassword(value);
        break;
      case 'new_password':
        setNewPassword(value);
        break;
      case 'confirm_password':
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    if (newPassword !== confirmPassword) {
      toast('Confirm password doesn\'t match.');
      return;
    }

    passwordReset({ oldPassword, newPassword })
      .then(res => {
        toast('Password is reset successfully! Please login again with new password.');
        props.logout();
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((err) => {
        toast(err.response.data.message);
      });
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div>
        <p className="text-center mt-4 mb-5">
          This is your first login to Reconfig.<br/>
          Please reset your password
        </p>
        <Form onSubmit={onSubmit} className="login-form">
          <div className="fields" style={{width: 400}}>
            <TextInput
              type="password"
              id="old_password"
              name="old_password"
              error={errors?.username}
              placeholder="Old Password"
              onChange={handleInputChange}
            />
            <TextInput
              type="password"
              id="new_password"
              name="new_password"
              error={errors?.username}
              placeholder="New Password"
              onChange={handleInputChange}
            />
            <TextInput
              type="password"
              id="confirm_password"
              name="confirm_password"
              error={errors?.username}
              placeholder="Confirm Password"
              onChange={handleInputChange}
            />
          </div>
          <Button
            text="Reset Password"
            iconClassName="button__icon button__icon-blue"
            type="submit"
            className="button login-button"
          />
        </Form>
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  profile: state.profile.data,
});
const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
