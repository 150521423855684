import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

export default class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  focus = () => {
    this.inputRef.current.focus();
  };

  select = () => {
    this.inputRef.current.select();
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus();
    }
  }

  render() {

    const { name, label, error, type, className, value, ...rest } = this.props;
    const id = `id_${name}`;
    const input_type = type || 'text';

    return (
      <FormGroup className={className}>
        {label && <Label className="ps-0 pe-4 pt-2 pb-2">{label}</Label>}
        <Input
          innerRef={this.inputRef}
          type={input_type}
          name={name}
          id={id}
          className={error && 'is-invalid'}
          value={value}
          {...rest}
          isvalid={error}
          autoFocus={error}
        />
        {
          error
            ? <FormFeedback>{error}</FormFeedback>
            : ''
        }
      </FormGroup>
    );
  }
}
