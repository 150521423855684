import React, { useState, useEffect } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import classNames from 'classnames';

export default function NumberInput(props) {
  const { name, label, error, className, value, min, max, step, placeholder, autoFocus = false, onChange } = props;
  const [inputValue, setInputValue] = useState(value || '');
  const [isFocused, setIsFocused] = useState(autoFocus);
  const [isValid, setIsValid] = useState(true);
  const id = `id_${name}`;
  const regex = /[^\d.-]/g;

  useEffect(() => {
    setInputValue(formatNumber(value));
  }, [value]);

  const formatNumber = (number) => {
    return number ? new Intl.NumberFormat('en-US').format(number) : '';
  };

  const validate = (input) => {
    const numValue = parseFloat(input.replace(regex, ''));
    const isNumberValid = !isNaN(numValue) &&
                          (min == null || numValue >= min) &&
                          (max == null || numValue <= max) &&
                          (step == null || (numValue - (min || 0)) % step === 0);
    if (!isFocused) {
      setIsValid(isNumberValid);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    setInputValue(value || '');
  };

  const handleBlur = () => {
    setIsFocused(false);
    setInputValue(formatNumber(value));
    validate(inputValue);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value.replace(regex, ''));
    if (onChange) {
      onChange({
        ...event,
        target: {
          ...event.target,
          value: event.target.value.replace(regex, '')
        }
      });
    }
  };

  const inputClass = classNames({
    'is-invalid': !isValid && !isFocused,
  });

  return (
    <FormGroup className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Input
        type="text"
        name={name}
        id={id}
        className={`${inputClass} ${error ? 'is-invalid' : ''}`}
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        invalid={!isValid && !isFocused ? true : undefined}
        autoFocus={autoFocus}
      />
      {!isValid && !isFocused && error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
}
