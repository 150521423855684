import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import ApiHelper from 'api/helper';

const ProgressLineChart = (props) => {
  const { id } = props;
  const [data, setData] = useState([]);
  const [generation, setGeneration] = useState(100);
  useEffect(() => {
    if (!id) return;
    const interval = setInterval(() => {
      ApiHelper.get(`/api/design-output/?id=${id}`).then(
        res => {
          setData(res.data.data);
          setGeneration(res.data.generation);
        },
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [id])

  if (data.length < 1) return null;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{ left: 30, bottom: 20 }}
      >
        <XAxis dataKey="generation" type="number" domain={[0, generation]}
               hide></XAxis>
        <XAxis xAxisId="percent" type="number" domain={[0, 100]} unit="%">
          <Label value="Optimization progress" offset={0} position="bottom"/>
        </XAxis>
        <YAxis
          type="number"
          domain={[
            dataMin => isFinite(dataMin) ? Math.floor(dataMin * 0.095) * 10 : 0,
            dataMax => isFinite(dataMax) ? Math.ceil(dataMax * 0.105) * 10 : 1,
          ]}
          label=""
        >
        </YAxis>
        <CartesianGrid strokeDasharray="5 5"/>
        <Tooltip
          formatter={value => parseFloat(Math.round(value * 100) / 100).toFixed(2)}/>
        <Legend verticalAlign="top" layout="vertical" align="right"/>
        <Line name="min" dataKey="min" stroke="#34e39f" dot={false}/>
        <Line name="max" dataKey="max" stroke="#a033ff" dot={false}/>
        <Line name="avg" dataKey="avg" stroke="#4963fe" dot={false}/>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ProgressLineChart;
