var canvas;
export var getTextDimensions = function (text, style) {
    if (style === void 0) { style = {
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: 14,
        fontFamily: "Arial",
    }; }
    if (!canvas) {
        canvas = document.createElement("canvas");
    }
    var context = canvas.getContext("2d");
    var fontVariant = style.fontVariant, fontWeight = style.fontWeight, fontSize = style.fontSize, fontFamily = style.fontFamily;
    var heightSpacingAround = 2;
    var height = Number(fontSize) + heightSpacingAround;
    if (context) {
        context.font = fontVariant + " " + fontWeight + " " + fontSize + "px '" + fontFamily + "'";
        return {
            width: Number(context.measureText(text).width),
            height: height,
        };
    }
    else {
        return { width: 0, height: height };
    }
};
var charWidthCache = {};
export var truncateText = function (text, style, maxWidth, ellipsis) {
    if (ellipsis === void 0) { ellipsis = "..."; }
    var cachedCharWidth = function (char) {
        var cached = charWidthCache[char];
        if (cached !== undefined) {
            return cached;
        }
        var charWidth = getTextDimensions(char, style).width;
        charWidthCache[char] = charWidth;
        return charWidth;
    };
    var truncatedChars = [];
    var charArray = Array.from(text);
    var ellipsisWidth = cachedCharWidth(ellipsis);
    if (maxWidth - ellipsisWidth < 0) {
        return text.charAt(0);
    }
    var currentWidth = ellipsisWidth;
    var didTruncate = false;
    for (var i = 0; i < charArray.length; i++) {
        var charWidth = cachedCharWidth(charArray[i]);
        if (currentWidth + charWidth <= maxWidth) {
            truncatedChars[i] = charArray[i];
            currentWidth += charWidth;
        }
        else {
            truncatedChars[i] = ellipsis;
            didTruncate = true;
            break;
        }
    }
    if (didTruncate) {
        return truncatedChars.join("");
    }
    return text;
};
export var getNumberItemsWidthByNumberOfChars = function (fontSize, numberOfChars) {
    return (fontSize / 2) * numberOfChars + 5;
};
