import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import ForceGraph3D from 'react-force-graph-3d';
import TextSprite from '@seregpie/three.text-sprite';
import {
  collapseOrExpandNode,
  collapseNode,
  expandNode,
  getSize,
  collapseAll,
  removeAllParents,
  filterTermGraphData,
  getGraphLinks,
} from 'lib/helper';
import { Tooltip } from 'components/Tooltip';
import _ from 'lodash';

class Graph extends Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.parentGraphRef = React.createRef();
    this.click = 0;

    this.totalNodes = _.cloneDeep(this.data.nodes);
    this.nodeMap = _.keyBy(this.totalNodes, 'id');
    let { axis } = this.props.graphFilter;

    this.totalNodes.forEach((node) => {
      node.parent = null;
      node.filtered = true
    });
    if (axis && axis !== 'resource' && axis !== 'custom') {
      collapseAll(axis, this.nodeMap);
    }
    this.graphLinks = getGraphLinks(this.data.links, this.totalNodes);

    this.filteredData = filterTermGraphData(
      this.graphLinks,
      this.totalNodes,
      this.props.graphTermFilter,
      this.props.graphFilter,
      this.props.data.survey_type
    );
  }

  renderTooltip = (data) => {
    return ReactDOMServer.renderToStaticMarkup(
      <Tooltip
        data={data}
        threshold={this.data.threshold}
        activityOptions={this.props.activityOptions}
        surveyType={this.props.data.survey_type}
        totalNodes={this.nodeMap}
      />
    );
  };

  collapseOrExpandNode = (clickedNode) => {
    let { axis: ax } = this.props.graphFilter;
    if (ax === 'resource') {
      return;
    }
    collapseOrExpandNode(clickedNode, ax, this.nodeMap);
    this.filteredData = filterTermGraphData(
      this.graphLinks,
      this.totalNodes,
      this.props.graphTermFilter,
      this.props.graphFilter,
      this.props.data.survey_type
    );

    this.props.setGraphFilter({
      ...this.props.graphFilter,
      axisCustom: true,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    let {
      axis,
      // , axisCustom
    } = nextProps.graphFilter;
    if (axis !== this.props.graphFilter.axis) {
      removeAllParents(this.totalNodes, this.nodeMap);
      this.totalNodes.forEach((node) => {
        expandNode(node, this.nodeMap);
      });
      if (axis !== 'resource') {
        collapseAll(axis, this.nodeMap);
      }
    }
    this.filteredData = filterTermGraphData(
      this.graphLinks,
      this.totalNodes,
      nextProps.graphTermFilter,
      nextProps.graphFilter,
      this.props.data.survey_type
    );

    return true;
  }

  onControlChange = (ev) => {
    let changed = false;
    let delta = Math.sign(ev.deltaY);
    let { axis } = this.props.graphFilter;
    if (axis === 'resource') {
      return;
    }

    this.filteredData.nodes.forEach((node) => {
      let camera = this.parentGraphRef.current.cameraPosition();
      let distance = Math.hypot(
        camera.x - node.x,
        camera.y - node.y,
        camera.z - node.z
      );
      if (delta < 0 && distance < 80 && node.ax && node.byzoom) {
        expandNode(node, this.nodeMap);
        changed = true;
      }
      if (
        delta > 0 &&
        distance > 300 &&
        !node.parent &&
        collapseNode(node, axis, this.nodeMap)
      ) {
        node.byzoom = true;
        changed = true;
      }
    });
    if (changed) {
      this.filteredData = filterTermGraphData(
        this.graphLinks,
        this.totalNodes,
        this.props.graphTermFilter,
        this.props.graphFilter,
        this.props.data.survey_type
      );
      this.props.setGraphFilter({
        ...this.props.graphFilter,
        axisCustom: true,
      });
    }
  };

  componentDidMount() {
    this.parentGraphRef.current
      .d3Force('link')
      .distance((link, index, links) =>
        link.source.group === link.target.group ? 1 : 200
      );
    this.parentGraphRef.current
      .d3Force('link')
      .strength((link, index, links) =>
        link.source.group === link.target.group ? 0.8 : 0.1
      );
    this.parentGraphRef.current
      .controls()
      .domElement.addEventListener('wheel', this.onControlChange);
  }

  componentWillUnmount() {
    this.parentGraphRef.current.controls().removeEventListener('wheel');
  }

  renderNodeText = (node) => {
    return `<div style='color:black'>${node.uid}</div>`;
  };

  handleClick = (node, ev) => {
    this.collapseOrExpandNode(node);
  };

  render() {
    const colorOptions = this.props.colorOptions;
    return (
      <ForceGraph3D
        ref={this.parentGraphRef}
        onNodeClick={this.handleClick}
        nodeVal={(node) => getSize(node, this.nodeMap)}
        className="graph"
        backgroundColor="#FDFFFE"
        graphData={this.filteredData}
        nodeLabel={this.renderNodeText}
        linkSource="sourceId"
        linkTarget="targetId"
        linkLabel={this.renderTooltip}
        nodeOpacity={0.9}
        nodeAutoColorBy="group"
        linkColor={(link) => (link.transparent < 1 ? '#000000' : '#000000')}
        linkDirectionalParticles="value"
        linkDirectionalParticleSpeed={(d) => d.value * 0.001}
        width={this.props.size.width}
        height={this.props.size.height}
        linkHoverPrecision={2}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
          node.fz = node.z;
        }}
        onEngineTick={this.onEngineTick}
        nodeColor={(node) => colorOptions[node.group]}
        nodeThreeObject={(node) => {
          return new TextSprite({
            color: colorOptions[node.group],
            fontFamily: 'Arial',
            fontSize: 4 * Math.sqrt(getSize(node, this.nodeMap)),
            text: node.uid,
          });
        }}
        nodeThreeObjectExtend={false}
        rendererConfig={{ preserveDrawingBuffer: true }}
      />
    );
  }
}

export default Graph;
