import React from 'react'
import { FilterTerm } from './FilterValues'

export default function Term({ filter, delFilter, editFilter }) {
  function onDelFilter(ev) {
    ev.stopPropagation();
    delFilter(filter);
  }
  return (
    <div className="header-term" onClick={() => editFilter(filter)}>
      <div className="header-term-label">{filter.label}</div>

      <FilterTerm filter={filter} />
      <div className="header-term-del" onClick={onDelFilter}>✕</div>
    </div>
  )
}
