import React from 'react'
import { Line } from 'react-progress-bar.js'

class LineProgressBar extends React.PureComponent {
  render() {
    var GradientB =
      '<defs><linearGradient id="blue" x1="10%" y1="25%" x2="100%" y2="20%" gradientUnits="userSpaceOnUse"><stop offset="0%" stop-color="#6295FF"/><stop offset="100%" stop-color="#8C19E5"/><stop offset="30%" stop-color="#4963FE"/></linearGradient></defs>'
    const { percent, ...otherProps } = this.props
    var options = {
      strokeWidth: 2,
      color: 'url(#blue)',
      trailColor: '#fff',
      trailWidth: 2,
      easing: 'easeInOut',
      duration: 1400,
      svgStyle: null,
      text: {
        value: '',
        alignToBottom: true
      },
      step: (state, bar) => {
        var value = Math.round(bar.value() * 100)
        if (value === 0) {
          bar.setText('')
        } else {
          bar.setText('<span>' + value + '%</span>')
        }

        bar.text.style.color = state.color
        bar.svg.insertAdjacentHTML('afterbegin', GradientB)
        bar.text.style.fontFamily = '"Open Sans", sans-serif'
      }
    }
    return (
      <Line
        progress={percent / 100}
        text={'test'}
        options={options}
        initialAnimate={true}
        {...otherProps}
      />
    )
  }
}

export default LineProgressBar
