import { RSAA } from 'redux-api-middleware'
import * as constants from 'redux/constants/design'
import { arrayMoveImmutable } from 'array-move'

export const getDesigns = (client) => ({
  type: 'GET_DESIGNS',
  [RSAA]: {
    endpoint: `/api/design/?client=${client}`,
    method: 'GET',
    types: [
      constants.DESIGNS_GET_REQUEST,
      constants.DESIGNS_GET_SUCCESS,
      constants.DESIGNS_GET_FAILURE
    ]
  }
})

export const setMatrixFilter = filter => ({
  type: constants.SET_MATRIX_FILTER,
  filter
})


export const getDesignOrg = (design_index) => ({
  type: 'GET_DESIGNS',
  [RSAA]: {
    endpoint: `/api/design/${design_index}/`,
    method: 'GET',
    types: [
      constants.DESIGN_ORG_GET_REQUEST,
      constants.DESIGN_ORG_GET_SUCCESS,
      constants.DESIGN_ORG_GET_FAILURE
    ]
  }
})

export const saveDesignOrg = (design_index, data, run, update = true) => {
  const error = new Error();
  const stackTrace = error.stack;

  return {
    type: 'SAVE_DESIGN',
    [RSAA]: {
      endpoint: `/api/design/${design_index}/`,
      method: 'PATCH',
      body: JSON.stringify({
        ...data,
        optimize: run,
        stackTrace,
      }),
      headers: { 'Content-Type': 'application/json' },
      types: [
        constants.DESIGN_ORG_SAVE_REQUEST,
        update ? constants.DESIGN_ORG_SAVE_SUCCESS : constants.DESIGN_ORG_SAVE_SUCCESS_NO_UPDATE,
        {
          type: constants.DESIGN_ORG_SAVE_FAILURE,
          payload: { ...data, stackTrace },
        }
      ]
    }
  };
};


export const deleteDesign = (design_index) => ({
  type: 'DELETE_DESIGN',
  [RSAA]: {
    endpoint: `/api/design/${design_index}/`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    types: [
      constants.DESIGN_DELETE_REQUEST,
      {
        type: constants.DESIGN_DELETE_SUCCESS,
        payload: {
          id: design_index,
        }
      },
      constants.DESIGN_DELETE_FAILURE
    ]
  }
})

export const exportDesign = (design_id, design_name) => ({
  type: 'DESIGN_EXPORT',
  [RSAA]: {
    endpoint: '/api/design-export/',
    method: 'POST',
    body: JSON.stringify({ id: design_id }),
    headers: { 'Content-Type': 'application/json' },
    types: [
      constants.DESIGN_EXPORT_REQUEST,
      {
        type: constants.DESIGN_EXPORT_SUCCESS,
        payload: (action, state, response) => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = url
            const filename = response.headers.get('Content-Disposition').split('filename=')[1];
            a.download = filename
            a.click()
          })
        }

      },
      constants.DESIGN_EXPORT_FAILURE
    ]
  }
})

export const sortSurveyDesigns = (oldIndex, newIndex) => ({
  type: constants.SORT_SURVEY_DESIGN,
  oldIndex,
  newIndex
})

export const sortSurveyDesignsApi = (surveyIds, oldIndex, newIndex) => ({
  type: 'SORT_SURVEY_DESIGN_API',
  [RSAA]: {
    endpoint: '/api/designs_order/',
    method: 'POST',
    body: JSON.stringify(arrayMoveImmutable(surveyIds, oldIndex, newIndex)),
    headers: { 'Content-Type': 'application/json' },
    types: [
      constants.SORT_SURVEY_DESIGN_API_REQUEST,
      constants.SORT_SURVEY_DESIGN_API_SUCCESS,
      constants.SORT_SURVEY_DESIGN_API_FAILURE
    ]
  }
})

export const addDesignMatrixFilter = payload => ({
  type: constants.ADD_MATRIX_FILTER,
  payload
})

export const delDesignMatrixFilter = payload => ({
  type: constants.DEL_MATRIX_FILTER,
  payload
})

export const resetDesignMatrixFilter = {
  type: constants.RESET_MATRIX_FILTER,
}

export const setGraphFilter = filter => ({
  type: constants.SET_GRAPH_FILTER,
  filter,
})

export const setEfficiencyCoefficient = (newValue) => ({
  type: constants.SET_DESIGN_EFFICIENCY_COEFFICIENT,
  newValue,
})
