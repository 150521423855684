import React, { Component } from 'react';
import { Alert, Form } from 'reactstrap';
import TextInput from 'components/TextInput';
import Button from 'components/Button';

export default class LoginForm extends Component {
  state = {
    username: '',
    password: '',
  };
  handleInputChange = event => {
    const target = event.target,
      value = target.type === 'checkbox' ? target.checked : target.value,
      name = target.name
    this.setState({
      [name]: value
    })
  }
  onSubmit = event => {
    event.preventDefault()
    this.props.onSubmit(this.state.username, this.state.password)
  }

  render() {
    const errors = this.props.errors || {}

    return (
      <div className="login-content">
        <img className="logo" src={require('../../img/logo.svg').default} alt="logo" />
        <p className="text-center mt-4 mb-5">Next generation organization design tools</p>
        {
          document.documentMode ?
            <div style={{
              textAlign: 'center',
              height: '100%',
              marginTop: '25%',
            }}>
              <h4>
                Internet Explorer is unfortunately not able to run Reconfig
              </h4>
              <h4>Please use a different browser such as
                <a
                  href='https://www.google.com/intl/no/chrome/'
                  style={{ color: '#4963fe' }}
                >
                  Chrome
                </a>
              </h4>
            </div>
            :
            <Form onSubmit={this.onSubmit} className="login-form">
              {errors.non_field_errors && <Alert color="danger">{errors.non_field_errors}</Alert>}
              <div className="fields">
                <TextInput
                  type="text"
                  id="username"
                  name="username"
                  error={errors.username}
                  placeholder="Email"
                  onChange={this.handleInputChange}
                />
                <TextInput
                  name="password"
                  id="pass"
                  type="password"
                  error={errors.password}
                  onChange={this.handleInputChange}
                  placeholder="Password"
                />
              </div>
              {/*<p className="helper-text text-center mt-3 mb-4">
            Don't have an account?
            <Link to="/signup" className="sign-up">
              Sign up!
            </Link>
          </p>*/}
              <Button
                text="Log in"
                iconWidth={18}
                iconHeight={14}
                icon="arrow_next.svg#Arrow_next"
                iconClassName="button__icon button__icon-blue"
                type="submit"
                className="button login-button"
              />
            </Form>

        }


      </div>
    )
  }
}
