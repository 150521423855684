export const COLOR_LIGHT_BLUE = '#6295FF'; // CORE - Light Blue
export const COLOR_BLUE = '#4963FE'; // CORE - Blue - Deprecated
export const COLOR_WHITE = '#FDFFFC'; // CORE - White
export const COLOR_GREEN = '#34E39F';  // CORE - Green
export const COLOR_DARK_GREEN = '#3FA3CF';
export const COLOR_PINK = '#A033FF'; // CORE - Ultraviolet - Deprecated
export const COLOR_VIOLET = '#754BFF';

// Blue -> Purple gradient colors
export const COLOR_SOFT_BLUE = '#4963FE'; // CORE - Soft Blue
export const COLOR_PERIWINKLE_BLUE = '#4E60FE';
export const COLOR_MEDIUM_SLATE_BLUE = '#575BFE';
export const COLOR_HAN_BLUE = '#6056FE';
export const COLOR_IRIS_BLUE = '#6454FE';
export const COLOR_MAJORELLE_BLUE = '#6951FE';
export const COLOR_ELECTRIC_INDIGO = '#6E4FFE';
export const COLOR_BLUE_VIOLET = '#724CFE';
export const COLOR_VIVID_INDIGO = '#7B47FF';
export const COLOR_PURPLE = '#8045FF';
export const COLOR_ELECTRIC_PURPLE = '#8542FF';
export const COLOR_VIVID_PURPLE = '#8940FF';
export const COLOR_DEEP_PURPLE = '#8E3DFF';
export const COLOR_DARK_VIOLET = '#923BFF';
export const COLOR_ELECTRIC_VIOLET = '#9738FF';
export const COLOR_NEON_PURPLE = '#9B36FF';
export const COLOR_ULTRAVIOLET = '#A033FF'; // CORE - Ultraviolet

// Green -> Blue gradient colors
export const COLOR_TURQUOISE = '#34E39F';  // CORE - Turquoise
export const COLOR_MEDIUM_TURQUOISE = '#35DCA4';
export const COLOR_LIGHT_SEA_GREEN = '#36D6A9';
export const COLOR_MEDIUM_AQUAMARINE = '#37CFAE';
export const COLOR_AQUAMARINE = '#38C8B3';
export const COLOR_PALE_TURQUOISE = '#3AC1B8';
export const COLOR_POWDER_BLUE = '#3BBBBD';
export const COLOR_CADET_BLUE = '#3CB4C2';
export const COLOR_SKY_BLUE = '#3DADC7';
export const COLOR_LIGHT_SKY_BLUE = '#3EA6CC';
export const COLOR_DEEP_SKY_BLUE = '#3FA0D1';
export const COLOR_DODGER_BLUE = '#4099D6';
export const COLOR_PURE_BLUE = '#4385E5';
export const COLOR_MEDIUM_BLUE = '#457EEA';
export const COLOR_DARK_BLUE = '#4677EF';
export const COLOR_NAVY = '#4770F4';
export const COLOR_MIDNIGHT_BLUE = '#486AF9';


//Gradient overlap colors
export const COLOR_CORNFLOWER_BLUE = '#4192DB';
export const COLOR_ROYAL_BLUE = '#428BE0';
export const COLOR_INDIGO = '#4963FE';

export const GREEN_BLUE_GRADIENT_FULL = [ 
    COLOR_TURQUOISE, 
    COLOR_MEDIUM_TURQUOISE, 
    COLOR_LIGHT_SEA_GREEN, 
    COLOR_MEDIUM_AQUAMARINE, 
    COLOR_AQUAMARINE, 
    COLOR_PALE_TURQUOISE, 
    COLOR_POWDER_BLUE, 
    COLOR_CADET_BLUE, 
    COLOR_SKY_BLUE, 
    COLOR_LIGHT_SKY_BLUE, 
    COLOR_DEEP_SKY_BLUE, 
    COLOR_DODGER_BLUE, 
    COLOR_PURE_BLUE, 
    COLOR_MEDIUM_BLUE, 
    COLOR_DARK_BLUE, 
    COLOR_NAVY, 
    COLOR_MIDNIGHT_BLUE
    ];
export const GREEN_BLUE_GRADIENT_SHORT = [ 
    COLOR_TURQUOISE, 
    COLOR_MEDIUM_AQUAMARINE, 
    COLOR_POWDER_BLUE, 
    COLOR_LIGHT_SKY_BLUE, 
    COLOR_PURE_BLUE, 
    COLOR_MIDNIGHT_BLUE
    ];
export const ORGCHART_LAYER_COLORS = [ 
    COLOR_PURE_BLUE, 
    COLOR_TURQUOISE, 
    COLOR_DEEP_PURPLE, 
    COLOR_LIGHT_SKY_BLUE, 
    COLOR_AQUAMARINE, 
    COLOR_VIOLET
    ];

export const FTE_HOURS_PER_MONTH = 160;
