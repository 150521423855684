import React from 'react';

class PercentageCellEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.parseValue(props.value)
    };
  }

  // Parse value to remove '%' if present
  parseValue(value) {
    return value && typeof value === 'string' ? value.replace('%', '') : value;
  }

  getValue() {
    // Append '%' to the value if it's a valid number
    const number = parseFloat(this.state.value);
    return !isNaN(number) ? `${number}%` : '';
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  render() {
    return (
      <input 
        type="text"
        value={this.state.value}
        onChange={this.handleChange}
        style={{ width: '100%' }}
        autoFocus
      />
    );
  }
}

export default PercentageCellEditor;
