import React from 'react';
import SVG from './SVG';
import { useNavigate } from 'react-router-dom';

const LinkButton = ({
  text,
  to,
  position,
  icon,
  iconWidth,
  iconHeight,
  iconClassName,
  className,
  disabled,
  ...otherProps
}) => {
  let navigate = useNavigate();
  let onClick = () => {
    navigate(to);
  };

  if (disabled === true) className += ' disabled';

  return position === 'right' ? (
    <button {...otherProps} onClick={onClick} className={className}
      disabled={disabled}>
      <SVG
        icon={icon}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
        iconClassName={iconClassName}
        disabled={disabled}
      />
      {text}
    </button>
  ) : (
    <button {...otherProps} onClick={onClick} className={className}
      disabled={disabled}>
      {text}
      <SVG
        icon={icon}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
        iconClassName={iconClassName}
        disabled={disabled}
      />
    </button>
  );
};

export default LinkButton;
