import React, { useState, useEffect } from 'react';
import withRouter from 'lib/withRouter'
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { confirmEmail } from 'api/user';
import Loading from 'components/Loading';
import toast from 'lib/toast';
import { getProfileData } from './profileSlice';

const EmailVerification = ({ ...props }) => {
  const { search } = props.location;
  const token = (new URLSearchParams(search)).get('token');
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    confirmEmail(token)
      .then((e) => {
        toast('Email verification is completed!');
        props.getProfileData();
      })
      .catch((e) => {
        toast('Email verification is failed!');
      })
      .finally(() => {
        setLoading(false);
        navigate('/profile');
      });
  }, []);

  return (
    <>
      <div className="content__header py-3">
      </div>
      <div className="card__group">
        <div className="card">
          <div className="card__content-wrapper">
            <div className="card-content">
              {isLoading && <Loading />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.profile.data,
});

const mapDispatchToProps = {
  getProfileData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmailVerification));
