import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from 'components/Button';
import classNames from 'classnames';
import { getOptions } from './util';
import FilterValues from './FilterValues';

const RelationshipsColumns = [
  { label: 'Activity', key: 'activity' },
  { label: 'Relationship Work Effort', key: 'weight_new' },
  { label: 'Relationship importance', key: 'weight' },
  { label: 'Relationship stability', key: 'permanence' },
  { label: 'Interaction frequency', key: 'frequency' },
  // 'Role complexity',
  { label: 'Reported by', key: 'reported_by' },
  { label: 'Interaction duration', key: 'duration' },
  { label: 'Span of control', key: 'group_length' },
];

export default function FilterModal({
  filter,
  modal,
  toggle,
  data,
  addFilter,
}) {
  const [column, setColumn] = useState(filter);
  const [flag, setFlag] = useState(filter?.flag || 'include');
  const [valueMap, setValueMap] = useState(filter ? { [filter.key]: filter.value } : {});
  const [valid, setValid] = useState(false);

  const labelColumnsWithValues = useMemo(
    () =>
      [
        ...Object.keys(data.defaultLabels).map((key) => ({
          label: data.defaultLabels[key],
          key,
          resource: key !== 'groupName' && key !== 'name',
        })).filter(u => u.label),
        ...(data.custom_columns || []).map((c) => (
          { label: c.Header, key: c.accessor, custom: true }),
        ),
        ...(data.scenarios.map((item, index) => (
          { label: item.name, key: 'groupName' + index, custom: true }
        ))),
      ].map((u) => ({
        label: u.label,
        key: u.key,
        customLabel: u.custom,
        resource: u.resource,
        values: getOptions(u.key, data, u.resource),
      })),
    [data],
  );

  const relationshipColumnsWithValues = useMemo(
    () =>
      RelationshipsColumns.map((u) => ({
        label: u.label,
        key: u.key,
        values: getOptions(u.key, data),
      })),
    [data],
  );

  const createFilter = () => {
    toggle();
    addFilter({
      ...column,
      id: filter?.id,
      value: valueMap[column.key],
      flag,
    });
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-dialog"
      contentClassName="matrix-filter-modal"
    >
      <ModalHeader>{filter ? 'Update filter' : 'Create new filter'}</ModalHeader>
      <ModalBody className="row filter-modal-body">
        <div className="col-md-4 overflow-auto mh-100">
          <div className="filter-label-group">
            <h4 className="title">Resources</h4>
            <ul className="list-group">
              {labelColumnsWithValues.map(col =>
                col.values.length > 1 ? (
                  <li
                    className={classNames('list-group-item', {
                      active: col.key === column?.key,
                    })}
                    key={col.key}
                    onClick={() => setColumn(col)}
                  >
                    {data.defaultLabels[col.key] || col.label}
                  </li>
                ) : null,
              )}
            </ul>
          </div>
          <div className="filter-label-group">
            <h4 className="title">Relationships</h4>
            <ul className="list-group">
              {relationshipColumnsWithValues.map(col =>
                col.values.length > 1 ? (
                  <li
                    className={classNames('list-group-item', {
                      active: col.key === column?.key,
                    })}
                    key={col.key}
                    onClick={() => setColumn(col)}
                  >
                    {col.label}
                  </li>
                ) : null,
              )}
            </ul>
          </div>
        </div>
        <div className="col-md-8 side-content fm-column">
          <div className="row col-md-12">
            <h4>Values</h4>
            <div
              className="btn-group btn-group-toggle ms-auto"
              data-toggle="buttons"
            >
              <button
                className={classNames('btn btn-primary flag', {
                  'btn-selected': flag === 'include',
                  'btn-unselected': flag !== 'include',
                })}
                onClick={() => setFlag('include')}
              >
                Include
              </button>
              <button
                className={classNames('btn btn-primary flag', {
                  'btn-selected': flag === 'exclude',
                  'btn-unselected': flag !== 'exclude',
                })}
                onClick={() => setFlag('exclude')}
              >
                Exclude
              </button>
            </div>
          </div>
          {column && (
            <FilterValues
              column={column}
              data={data}
              value={valueMap[column.key]}
              setValue={(v) => setValueMap({
                ...valueMap,
                [column.key]: v,
              })}
              setValid={setValid}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={toggle}
          text="Cancel"
          className="button"
        />
        <Button
          color="primary"
          onClick={createFilter}
          text={filter ? 'Update' : 'Create'}
          className="button"
          disabled={!column || !valid}
        />
      </ModalFooter>
    </Modal>
  );
}
