import React, { useEffect, useRef } from 'react';

export default function Checkbox(props) {
  const ref = useRef();
  useEffect(() => {
    ref.current.indeterminate = props.indeterminate;
  }, [props.indeterminate]);

  const { indeterminate, ...otherProps } = props;

  return <input {...otherProps} ref={ref} type="checkbox" />;
}
