import { arrayMoveImmutable } from 'array-move';
import { RSAA } from 'redux-api-middleware';
import * as constant from 'redux/constants/survey';

export const surveyCreate = (payload, token) => ({
  type: 'SURVEY_CREATE',
  [RSAA]: {
    endpoint: '/api/survey/' + (payload.id ? `${payload.id}/` : ''),
    method: payload.id ? 'PATCH' : 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [
      constant.SURVEY_CREATE_REQUEST,
      constant.SURVEY_CREATE_SUCCESS,
      constant.SURVEY_CREATE_FAILURE,
    ],
  },
});

export const getSurvey = index => ({
  type: 'GET_SURVEY',
  [RSAA]: {
    endpoint: `/api/survey/${index}/`,
    method: 'GET',
    types: [
      constant.SURVEY_GET_REQUEST,
      constant.SURVEY_GET_SUCCESS,
      constant.SURVEY_GET_FAILURE,
    ],
  },
});

export const getSurveyMeta = () => ({
  type: 'GET_SURVEY_META',
  [RSAA]: {
    endpoint: '/api/sample_meta/',
    method: 'GET',
    types: [
      constant.SURVEY_META_GET_REQUEST,
      constant.SURVEY_META_GET_SUCCESS,
      constant.SURVEY_META_GET_FAILURE,
    ],
  },
});

export const closeSurvey = index => ({
  type: 'CLOSE_SURVEY',
  [RSAA]: {
    endpoint: '/api/survey_info/close/',
    method: 'POST',
    body: JSON.stringify({ survey: index }),
    headers: { 'Content-Type': 'application/json' },
    types: [
      constant.CLOSE_REQUEST,
      constant.CLOSE_SUCCESS,
      constant.CLOSE_FAILURE,
    ],
  },
});

export const closeDiagnoseSurvey = (survey_id) => ({
  type: 'DIAGNOSE_CLOSE_SURVEY',
  [RSAA]: {
    endpoint: '/api/diagnose_info/close/',
    method: 'POST',
    body: JSON.stringify({ survey_id: survey_id}),
    headers: { 'Content-Type': 'application/json' },
    types: [
      constant.DIAGNOSE_CLOSE_REQUEST,
      constant.DIAGNOSE_CLOSE_SUCCESS,
      constant.DIAGNOSE_CLOSE_FAILURE,
    ],
  },
});


export const deleteSurvey = index => ({
  type: 'DELETE_SURVEY',
  [RSAA]: {
    endpoint: `/api/survey/${index}/`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    types: [
      constant.DELETE_REQUEST,
      constant.DELETE_SUCCESS,
      constant.DELETE_FAILURE,
    ],
  },
});


export const deleteDiagnoseSurvey = (id, clientId) => ({
  type: 'DELETE_DIAGNOSE_SURVEY',
  [RSAA]: {
    endpoint: `/api/diagnose/${id}/?client=${clientId}`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    types: [
      constant.DELETE_DIAGNOSE_REQUEST,
      constant.DELETE_DIAGNOSE_SUCCESS,
      constant.DELETE_DIAGNOSE_FAILURE,
    ],
  },
});


export const sendSurveys = payload => ({
  type: 'SEND_SURVEYS',
  [RSAA]: {
    endpoint: '/api/survey_info/send/',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [
      constant.SURVEY_SEND_REQUEST,
      constant.SURVEY_SEND_SUCCESS,
      constant.SURVEY_SEND_FAILURE,
    ],
  },
});

export const setSurvey = survey => ({
  type: constant.SET_SURVEY_LOCAL,
  payload: survey,
});

export const setSurveyError = errors => ({
  type: constant.SET_SURVEY_ERROR,
  payload: errors,
});

export const sortSurveysApi = (surveyIds, oldIndex, newIndex) => ({
  type: 'SORT_SURVEY_API',
  [RSAA]: {
    endpoint: '/api/surveys_order/',
    method: 'POST',
    body: JSON.stringify(arrayMoveImmutable(surveyIds, oldIndex, newIndex)),
    headers: { 'Content-Type': 'application/json' },
    types: [
      constant.SORT_SURVEY_API_REQUEST,
      constant.SORT_SURVEY_API_SUCCESS,
      constant.SORT_SURVEY_API_FAILURE,
    ],
  },
});

export const clearSurvey = () => ({
  type: constant.CLEAR_SURVEY,
});