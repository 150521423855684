import React from 'react';

const RatioBar = ({label1, label2, value1, value2, unit }) => {
  const total = value1 + value2;
  const ratio1 = (value1 / total) * 100;
  const ratio2 = (value2 / total) * 100;

  return (
    <div className="RatioBar">
      <div className="wrapper">
        <div className="bar-container">
          <div className="bar">
            <div className="bar-section bar-section1" style={{ width: `${ratio1}%` }}></div>
            <div className="bar-section bar-section2" style={{ width: `${ratio2}%` }}></div>
          </div>
        </div>
        <div className="labels">
            <div className="label">{`${label1}`}</div>
            <div className="label">{`${label2}`}</div>
        </div>
        <div className="labels">
          <div className="label">{`${Number(value1.toFixed(0)).toLocaleString('no-NO') || 0} ${unit} ( ${ratio1 ? ratio1.toFixed(0) : 0}% )`}</div>
          <div className="label">{`${Number(value2.toFixed(0)).toLocaleString('no-NO') || 0} ${unit} ( ${ratio2 ? ratio2.toFixed(0) : 0}% )`}</div>
        </div>
      </div>
    </div>
  );
};

export default RatioBar;
