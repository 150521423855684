import React from 'react';

const Explanation = () => {
  return (
    <div className="row">
      <div className="card">
        <h4 className="diagnose-table-title">The diagnostic assessments</h4>
        <div className="diagnose-table-wrapper">
          <table className="diagnose-table">
            <tbody>
            <tr>
              <td className="text-center" colSpan={4}>
                <div className="body-head">PURPOSE</div>
                <div className="body-content">How we formulate and fulfil our
                  mission or “reason for being”
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-center">
                <div className="body-head">STRATEGY</div>
                <div className="body-content px-3">How we formulate strategic
                  goals to exploit opportunities and neutralize threats
                </div>
              </td>
              <td className="text-center">
                <div className="body-head">GOVERNANCE</div>
                <div className="body-content px-3">How we manage a system of
                  rules and practices to direct and control activities
                </div>
              </td>
              <td className="text-center">
                <div className="body-head">PROCESSES</div>
                <div className="body-content px-3">How we work processes to
                  achieve efficiency and effectiveness in our operations
                </div>
              </td>
              <td className="text-center">
                <div className="body-head">TEAM COMPOSITION</div>
                <div className="body-content px-3">How we ensure that the
                  right combination of roles on each team
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-center">
                <div className="body-head">ALIGNMENT</div>
                <div className="body-content px-3">How we align the
                  organization with the overall purpose and strategy
                </div>
              </td>
              <td className="text-center">
                <div className="body-head">ACCOUNTABILITIES</div>
                <div className="body-content px-3">How we allocate decision
                  rights to individuals at different levels
                </div>
              </td>
              <td className="text-center">
                <div className="body-head">MANAGEMENT LAYERS</div>
                <div className="body-content px-3">How we create a structure
                  with the appropriate number of management layers
                </div>
              </td>
              <td className="text-center">
                <div className="body-head">COORDINATION</div>
                <div className="body-content px-3">How we ensure the right
                  amount of coordination within and across units
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-center" colSpan={4}>
                <div className="body-head">CAPABILITIES</div>
                <div className="body-content">How we develop and mobilize
                  resources to fulfill our purpose and realize our strategy
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default Explanation;