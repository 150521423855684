import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as d3 from 'd3';
import { Tooltip, TooltipAxis } from 'components/Tooltip';
import {
  getMatrixAxisLabel,
  collapseOrExpandNode,
  collapseAll,
  removeAllParents,
  strokeWidth,
  expandNode,
  collapseNode,
  getFontSize,
  getSize,
  getSelection,
  setSelection,
  getColorOpacityCell,
  filterTermGraphData,
} from 'lib/helper';

import ReactFloatingScroll from 'components/FloatingScroll.js';
import _ from 'lodash';

export default class Matrix extends Component {
  constructor(props) {
    super(props);
    this.matrixRef = React.createRef();
    this.scrollRef = React.createRef();
    this.state = {
      cursor: props.cursor,
    }
  }

  setSelection = (i, rowOrCol, force, dragging) => {
    if (this.props.tool !== 'highlight') {
      return;
    }
    let currentDiv = this.matrixRef.current;
    let u;
    if (rowOrCol === 'row')
      u = d3.select(currentDiv).selectAll('.matrixrow').nodes();
    else u = d3.select(currentDiv).selectAll('.column').nodes();
    const ele = d3.select(u[i]);
    let selected;
    if (force === undefined) selected = !ele.classed('selection');
    else selected = force;

    if (selected !== null) {
      d3.select(u[i]).classed('selection', selected);
      ele.selectAll('.selection').remove();

      if (selected) {
        ele
          .insert('rect', ':first-child')
          .attr('class', 'selection')
          .attr('fill-opacity', '0.1')
          .attr('width', this.svgWidth)
          .attr('height', this.nodes[i].size)
          .attr('y', 0)
          .attr('x', rowOrCol === 'row' ? -this.text_margin : -this.width);
      }
    }
    if (dragging !== undefined) {
      d3.select(u[i]).classed('dragging', dragging);
      ele.selectAll('.dragging').remove();
      if (dragging) {
        ele
          .insert('rect', ':first-child')
          .attr('class', 'dragging')
          .attr('fill-opacity', '0.1')
          .attr('width', this.svgWidth)
          .attr('height', this.nodes[i].size)
          .attr('y', 0)
          .attr('x', rowOrCol === 'row' ? -this.text_margin : -this.width);
      }
    }
    if (selected !== null) {
      setSelection(this.nodes[i], rowOrCol, selected);
    }
  };

  mouseDownEvent = (ev) => {
    const rightclick = ev.which === 3 || ev.ctrlKey;
    if (rightclick) {
      ev.preventDefault();
      if (
        this.props.tool === 'highlight' &&
        !this.svg.node().contains(ev.target) &&
        !ev.target.classList.contains('matrix-inner')
      ) {
        for (const node of this.totalNodes) {
          setSelection(node, 'row', false);
          setSelection(node, 'col', false);
        }
        d3.select(this.matrixRef.current).selectAll('rect.selection').remove();
        d3.select(this.matrixRef.current).selectAll('rect.dragging').remove();
        d3.select(this.matrixRef.current)
          .selectAll('.selection')
          .classed('selection', false);
        d3.select(this.matrixRef.current)
          .selectAll('.dragging')
          .classed('dragging', false);
      }
    }
    // clear selection

  };

  contextMenu = (ev) => {
    if (this.props.tool === 'highlight') {
      ev.preventDefault();
    } else if (this.props.tool === 'stamp') {
      ev.preventDefault();
      this.setState({
        cursor: {
          url: require('../../img/icons/stamp-unfilled.svg').default,
          x: 18,
          y: 35
        },
        stamp: null,
      });
    }

  }

  onClickAxis = (ax, rowOrCol) => {
    if (this.props.notCollapse) {
      return null;
    }
    return (p, i) => {
      let clickedNode = this.nodes[i];
      if (this.props.tool === 'search') {
        if (collapseOrExpandNode(clickedNode, this.collapseAllBy || this.collapseByDefault, this.keyedTotalNodes)) {
          this.drawMatrix();
        }
      }
    };
  };

  axisHeaderClick = (ax) => {
    if (this.props.notCollapse) {
      return null;
    }
    return () => {
      if (ax === 'name') return;
      if (this.collapseAllBy === ax) {
        this.collapseAllBy = null;
        removeAllParents(this.totalNodes, this.keyedTotalNodes);
        this.drawMatrix();
      } else {
        this.collapseAllBy = ax;
        removeAllParents(this.totalNodes, this.keyedTotalNodes);
        collapseAll(ax, this.totalNodes);
        this.drawMatrix();
      }
    };
  };

  onclickCell = (d, i) => {
    const that = this;
    if (this.nodes[d.x].ax || this.nodes[d.y].ax) {
      if (this.props.tool === 'search') {
        expandNode(this.nodes[d.x], this.keyedTotalNodes);
        expandNode(this.nodes[d.y], this.keyedTotalNodes);
        this.drawMatrix();
      }
    } else {
      if (this.props.tool === 'edit' && d.x !== d.y) {
        this.props.showEditModal(this.nodes[d.x], this.nodes[d.y], d.link);
      } else if (this.props.tool === 'stamp' && d.x !== d.y) {
        if (this.state.stamp) {
          let { links } = this.props.data;
          let tmp = {
            ...this.state.stamp,
            sourceId: this.nodes[d.x].id,
            source: this.nodes[d.x],
            targetId: this.nodes[d.y].id,
            target: this.nodes[d.y],
            reported_by: ['stamp'],
          };
          if (!links.find(item => item.sourceId === tmp.sourceId && item.targetId === tmp.targetId)) {
            links.forEach(link => link.edited = undefined);
            tmp.edited = 'added';
            links.push(tmp);
            that.props.saveDesign();
            this.drawMatrix();
          }
        } else if (d.link) {
          this.setState({
            cursor: {
              url: require('../../img/icons/stamp-filled.svg').default,
              x: 18,
              y: 35
            },
            stamp: d.link,
          });
        }
      } else if (this.props.tool === 'search') {
        this.collapseAllBy = this.collapseAllBy || this.collapseByDefault;
        collapseNode(this.nodes[d.x], this.collapseAllBy, this.totalNodes);
        collapseNode(this.nodes[d.y], this.collapseAllBy, this.totalNodes);
        this.drawMatrix();
      }
    }
    if (this.props.tool === 'select') {
      if ((this.nodes[d.x].ax || this.nodes[d.y].ax || d.x !== d.y) && d.count > 0) {
        this.props.showEditModal(this.nodes[d.x], this.nodes[d.y], d.link, {
          ...d.link,
          value: d.count ? d.value / d.count : 0,
          frequency: d.count ? d.frequency / d.count : 0,
          permanence: d.count ? d.permanence / d.count : 0,
          duration: d.count ? d.duration / d.count : 0,
          value_new: d.count ? d.value_new / d.count : 0,
          count: d.count,
          relationship: d.relationship,
        });
      }
    }
  };

  getMargins = (nodes) => {
    let firstColumn = true;

    return this.axis.map((ax, i) => {
      let margin = d3.max(nodes, (node) => {
        let f = this.textFontSize + 'px Open Sans';
        let element = document.createElement('canvas');
        let context = element.getContext('2d');
        context.font = f;
        let text = getMatrixAxisLabel(node, ax, this.keyedTotalNodes);
        return context.measureText(text).width;
      });

      if (margin > 0) {
        let f = this.textFontSize + 'px Open Sans';
        let element = document.createElement('canvas');
        let context = element.getContext('2d');
        context.font = f;
        let axisTextWidth =
          context.measureText(this.axisTexts[i]).width + this.textFontSize * 2;
        if (margin < axisTextWidth) {
          margin = axisTextWidth;
        }
        if (firstColumn) {
          margin += this.textFontSize;
          firstColumn = false;
        }
        margin += 10;
      }
      return margin;
    });
  };

  drawMatrix = (first = false) => {
    const {
      isModal,
      matrixFilter,
      data: initData,
      activityOptions,
      coefficient,
      colorOptions,
    } = this.props;
    const {
      threshold,
      survey_type: surveyType,
      optimize_type: optimizeType,
      base_result: baseResult,
      scenarios,
    } = initData;
    let { nodes: totalNodes } = initData;
    this.keyedTotalNodes = _.keyBy(totalNodes, n => n.id)
    totalNodes.forEach((node) => {
      node.count = 0;
      node.sourceRaw = [];
    });

    if (first && !this.props.notCollapse) {
      let ax = 'groupName0';
      this.collapseByDefault = 'groupName0';

      this.collapseAllBy = ax;
      totalNodes.forEach((node) => {
        node.filtered = true
        node.parent = null;
        node.childNodes = null;
      });
      collapseAll(ax, this.keyedTotalNodes);
    }

    let { axis } = matrixFilter;
    this.axisTexts = axis.map(c => this.props.axisLabels[c]);

    const matrixData = filterTermGraphData(
      initData.links,
      totalNodes,
      this.props.matrixTermFilter,
      matrixFilter,
      surveyType,
      scenarios
    );

    let { nodes, matrix, filteredNodes, groupOpacity, maxOpacity } = matrixData;

    let n = nodes.length;
    let width = this.props.width || 1200;
    let height = this.props.height || 1200;
    this.totalNodes = filteredNodes;

    let textFontSize = Math.max(
      Math.min(width / this.totalNodes.length - 2, 20),
      10
    );
    let defaultSize = textFontSize + 2;
    this.defaultSize = defaultSize;
    let firstColumn = true;
    this.axis = axis;
    this.textFontSize = textFontSize;
    let margins = this.getMargins(nodes);

    let text_margin = d3.sum(margins);
    let margin = { top: text_margin, right: 10, bottom: 10, left: text_margin };
    this.text_margin = text_margin;

    let that = this;

    // Compute index per node.
    this.nodes = nodes;
    this.matrix = matrix;

    nodes.forEach((node) => {
      node.size = this.defaultSize * getSize(node, this.keyedTotalNodes);
    });

    this.order = this.getOrder(matrixFilter.order);

    let sz = this.order.map((id) => nodes[id].size);
    let x = new Array(n);
    this.x = x;
    for (let i = 0; i < n; i++) {
      x[this.order[i]] = d3.sum(sz.slice(0, i));
    }

    width = d3.sum(sz);
    height = d3.sum(sz);

    let svgWidth = width + margin.left + margin.right;
    let svgHeight = height + margin.top + margin.bottom;

    this.svgWidth = svgWidth;
    this.width = width;
    this.startPos = null;

    let svgOuter = d3
      .select(this.matrixRef.current)
      .html('')
      .append('svg')
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .attr('viewBox', `0 0 ${svgWidth - 10} ${svgHeight - 10}`);

    let svg = svgOuter
      .append('g')
      .attr('class', 'matrix-inner')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .on('mousedown', function () {
        if (that.props.notCollapse && that.props.tool !== 'highlight') {
          return;
        }
        that.startPos = d3.mouse(this);
        that.rightclick = d3.event.which === 3 || d3.event.ctrlKey;
      })
      .on('mousemove', function () {
        if (that.startPos) {
          let [sx, sy] = that.startPos;
          let [ex, ey] = d3.mouse(this);
          if (sx > ex) {
            let tmp = sx;
            sx = ex;
            ex = tmp;
          }
          if (sy > ey) {
            let tmp = sy;
            sy = ey;
            ey = tmp;
          }

          if (ex - sx < 20 && ey - sy < 20) return;
          for (let i = 0; i < that.x.length; i++) {
            if (sx >= 0 && ex >= 0) {
              if (that.x[i] + that.nodes[i].size >= sx && that.x[i] < ex) {
                that.setSelection(i, 'col', null, true);
              } else {
                that.setSelection(i, 'col', null, false);
              }
            }
            if (sy >= 0 && ey >= 0) {
              if (that.x[i] + that.nodes[i].size >= sy && that.x[i] < ey) {
                that.setSelection(i, 'row', null, true);
              } else {
                that.setSelection(i, 'row', null, false);
              }
            }
          }
        }
      })
      .on('mouseup', function () {
        if (that.startPos && that.props.tool === 'highlight') {
          let [sx, sy] = that.startPos;
          let [ex, ey] = d3.mouse(this);
          if (sx > ex) {
            let tmp = sx;
            sx = ex;
            ex = tmp;
          }
          if (sy > ey) {
            let tmp = sy;
            sy = ey;
            ey = tmp;
          }

          d3.select(that.matrixRef.current).selectAll('rect.dragging').remove();
          d3.select(that.matrixRef.current)
            .selectAll('.dragging')
            .classed('dragging', false);

          const [ax, ay] = that.startPos;
          let row = -1,
            col = -1;

          for (let i = 0; i < that.x.length; i++) {
            if (that.x[i] + that.nodes[i].size >= ax && that.x[i] < ax) {
              col = i;
            }
            if (that.x[i] + that.nodes[i].size >= ay && that.x[i] < ay) {
              row = i;
            }
          }

          const forceSelect = !that.rightclick;

          for (let i = 0; i < that.x.length; i++) {
            if (sx >= 0 && ex >= 0) {
              if (that.x[i] + that.nodes[i].size >= sx && that.x[i] < ex) {
                that.setSelection(i, 'col', forceSelect);
              }
            }
            if (sy >= 0 && ey >= 0) {
              if (that.x[i] + that.nodes[i].size >= sy && that.x[i] < ey) {
                that.setSelection(i, 'row', forceSelect);
              }
            }
          }
        }
        that.startPos = null;
      })
      .on('mouseleave', function () {
        that.startPos = null;
      })

    svgOuter
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .append('rect')
      .attr('fill', '#FDFFFC')
      .attr('class', 'corner')
      .attr('width', text_margin - this.textFontSize - 10)
      .attr('height', text_margin - this.textFontSize - 10)
      .attr('x', -text_margin)
      .attr('y', -text_margin);

    this.svg = svg;

    svg
      .append('rect')
      .attr('class', 'background')
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .attr('x', -text_margin)
      .attr('y', -text_margin)
      .attr('fill', '#FDFFFC')
      .on('mousemove', mousemovebackground)
      .on('mouseleave', mouseleave)
      .on('click', mouseclickbackground);

    function getmousepositiononbackground(elem) {
      const [mx, my] = d3.mouse(elem);

      let px, py;
      for (let i = 0; i < x.length; i++) {
        if (mx < that.x[i] + nodes[i].size && mx > that.x[i]) {
          px = i;
          break;
        }
      }
      for (let i = 0; i < x.length; i++) {
        if (my < that.x[i] + nodes[i].size && my > that.x[i]) {
          py = i;
          break;
        }
      }
      return { px, py };
    }

    function mouseclickbackground(d) {
      const { px, py } = getmousepositiononbackground(this);
      if (px !== undefined && py !== undefined) {
        that.onclickCell({ x: px, y: py });
      }
    }

    function mousemovebackground(d) {
      const { px, py } = getmousepositiononbackground(this);
      mouseleave();
      if (px !== undefined && py !== undefined) {
        mouseover({ x: px, y: py });
      }
    }
    firstColumn = true;

    axis.forEach((ax, i) => {
      svg
        .append('line')
        .attr('x1', -d3.sum(margins.slice(0, i)))
        .attr('x2', -d3.sum(margins.slice(0, i)))
        .attr('y1', 0)
        .attr('y2', height)
        .attr('stroke', '#e7e9ee');
      svg
        .append('line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', -d3.sum(margins.slice(0, i)))
        .attr('y2', -d3.sum(margins.slice(0, i)))
        .attr('stroke', '#e7e9ee');
      if (margins[i] > 0) {
        let firstMargin = firstColumn ? textFontSize : 3;
        firstColumn = false;
        svg
          .append('rect')
          .attr('x', -firstMargin - d3.sum(margins.slice(0, i + 1)))
          .attr('y', -defaultSize - 10)
          .attr('height', defaultSize + 10)
          .attr('width', margins[i] + firstMargin)
          .attr('fill-opacity', 0)
          .on('click', that.axisHeaderClick(ax));
        svg
          .append('text')
          .attr('x', -firstMargin - d3.sum(margins.slice(0, i)))
          .attr('y', 0)
          .attr('dy', '-0.3em')
          .attr('font-family', 'Open Sans')
          .attr('font-weight', 'bold')
          .attr('text-anchor', 'end')
          .attr('font-size', defaultSize - 2)
          .attr('fill', '#A033FF')
          .text(this.axisTexts[i])
          .on('click', that.axisHeaderClick(ax));
        svg
          .append('rect')
          .attr('x', - d3.sum(margins.slice(0, i)))
          .attr('y', -defaultSize - 10)
          .attr('height', defaultSize + 10)
          .attr('width', margins[i] + firstMargin)
          .attr('fill-opacity', 0)
          .attr('transform', 'rotate(-90)')
          .on('click', that.axisHeaderClick(ax));
        svg
          .append('text')
          .attr('x', firstMargin + d3.sum(margins.slice(0, i)))
          .attr('y', 0)
          .attr('dy', '-0.3em')
          .attr('font-family', 'Open Sans')
          .attr('font-weight', 'bold')
          .attr('text-anchor', 'start')
          .attr('font-size', defaultSize - 2)
          .attr('fill', '#A033FF')
          .attr('transform', 'rotate(-90)')
          .text(this.axisTexts[i])
          .on('click', that.axisHeaderClick(ax));
      }
    });

    let column = svg
      .selectAll('.column')
      .data(matrix)
      .enter()
      .append('g')
      .attr('class', 'column')
      .classed('selection', (d, i) => getSelection(that.nodes[i], 'col'))
      .attr('transform', (d, i) => {
        return 'translate(' + x[i] + ')rotate(-90)';
      });

    column
      .append('line')
      .attr('x1', -height)
      .attr('x2', text_margin)
      .attr('stroke', '#e7e9ee');

    firstColumn = true;
    axis.forEach((ax, i) => {
      if (margins[i] > 0) {
        let firstMargin = firstColumn ? textFontSize : 3;
        firstColumn = false;
        column
          .append('rect')
          .attr('x', d3.sum(margins.slice(0, i)))
          .attr('y', 1)
          .attr('height', (d, i) => nodes[i].size - 2)
          .attr('width', margins[i])
          .attr('class', 'axis-rect')
          .attr('fill-opacity', 0)
          .on('click', that.onClickAxis(ax, 'col'))
          .on('mousemove', function (d, i) {
            let that = this;
            mousemoveaxis(that, nodes[i]);
          })
          .on('mouseleave', mouseleave);
        column
          .append('text')
          .attr('x', d3.sum(margins.slice(0, i)) + firstMargin)
          .attr('y', (d, i) => (nodes[i].size + defaultSize) / 2 - 1)
          .attr('class', 'axis-text')
          .attr('dy', '-0.3em')
          .attr('text-anchor', 'start')
          .attr('font-family', 'Open Sans')
          .attr('font-size', defaultSize - 2)
          .text((d, i) => {
            return getMatrixAxisLabel(nodes[i], ax, that.keyedTotalNodes);
          })
          .on('click', that.onClickAxis(ax, 'col'))
          .on('mousemove', function (d, i) {
            let that = this;
            mousemoveaxis(that, nodes[i]);
          })
          .on('mouseleave', mouseleave);
      }
    });

    const rows = svg
      .selectAll('.matrixrow')
      .data(matrix)
      .enter()
      .append('g')
      .attr('class', 'matrixrow')
      .classed('selection', (d, i) => getSelection(that.nodes[i], 'row'))
      .attr('transform', (d, i) => {
        return 'translate(0,' + x[i] + ')';
      })
      .each(row);
    rows
      .append('line')
      .attr('x2', width)
      .attr('x1', -text_margin)
      .attr('stroke', '#e7e9ee');

    firstColumn = true;
    axis.forEach((ax, i) => {
      if (margins[i] > 0) {
        let firstMargin = firstColumn ? textFontSize : 3;
        firstColumn = false;
        rows
          .append('rect')
          .attr('x', -d3.sum(margins.slice(0, i + 1)))
          .attr('y', 1)
          .attr('height', (d, i) => nodes[i].size - 2)
          .attr('width', margins[i])
          .attr('class', 'axis-rect')
          .attr('fill-opacity', 0)
          .on('click', that.onClickAxis(ax, 'row'))
          .on('mousemove', function (d, i) {
            let that = this;
            mousemoveaxis(that, nodes[i]);
          })
          .on('mouseleave', mouseleave);
        rows
          .append('text')
          .attr('x', -d3.sum(margins.slice(0, i)) - firstMargin)
          .attr('y', (d, i) => (nodes[i].size + defaultSize) / 2 - 1)
          .attr('class', 'axis-text')
          .attr('dy', '-0.3em')
          .attr('text-anchor', 'end')
          .attr('font-family', 'Open Sans')
          .attr('font-size', defaultSize - 2)
          .text((d, i) => {
            return getMatrixAxisLabel(nodes[i], ax, that.keyedTotalNodes);
          })
          .on('click', that.onClickAxis(ax, 'row'))
          .on('mousemove', function (d, i) {
            let that = this;
            mousemoveaxis(that, nodes[i]);
          })
          .on('mouseleave', mouseleave);
      }
    });

    let oppositeHighlight = svg
      .append('rect')
      .attr('class', 'opposite-highlight')
      .attr('width', 0)
      .attr('height', 0)
      .style('display', 'none');
    let hoverHighlight = svg
      .append('rect')
      .attr('class', 'hover-highlight')
      .attr('width', 0)
      .attr('height', 0)
      .style('display', 'none');

    function row(row) {
      let r = d3
        .select(this)
        .selectAll('.cell')
        .data(
          row.filter((d) => {
            return (that.props.notCollapse || d.z || d.border);
          })
        )
        .enter();
      r.append('rect')
        .attr('class', 'cell')
        .attr('x', (d) => {
          return x[d.x] + 1 + strokeWidth(d) / 2;
        })
        .attr('y', (d) => 1 + strokeWidth(d) / 2)
        .attr('width', (d) => nodes[d.x].size - 2 - strokeWidth(d))
        .attr('height', (d) => nodes[d.y].size - 2 - strokeWidth(d))
        .style('fill-opacity', (d) => {
          return getColorOpacityCell(
            d,
            maxOpacity,
            groupOpacity,
            threshold,
            matrixFilter.colorRelationshipOption?.value || (surveyType === 0 ? 'value' : 'value_new'),
            matrixFilter.colorIntensityOption?.value || 'global'
          );
        })
        .style('fill', (d) => {
          return (
            nodes[d.x].group === nodes[d.y].group &&
            colorOptions[nodes[d.x].group]
          );
        })
        .style('stroke', (d) => {
          if (d.edited === 'deleted') return '#4963FE';
          else if (d.edited === 'added') return '#34E39F';
          else if (d.edited === 'edited') return '#A033FF';
          else return (
            nodes[d.x].group === nodes[d.y].group &&
            colorOptions[nodes[d.x].group]
          );
        })
        .style('stroke-width', (d) => {
          return strokeWidth(d);
        })
        .style('stroke-opacity', 1)
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave)
        .on('click', that.onclickCell);
      let fontSize = defaultSize;
      r.append('text')
        .filter((d) => (nodes[d.x].ax || nodes[d.y].ax) && d.percent)
        .attr('x', (d) => {
          return x[d.x] + nodes[d.x].size / 2;
        })
        .attr('y', (d) => {
          return (nodes[d.y].size + getFontSize(fontSize, nodes, d, that.keyedTotalNodes)) / 2;
        })
        .attr('dy', '-0.3em')
        .attr('text-anchor', 'middle')
        .attr('class', 'cell-text')
        .style('fill', 'white')
        .attr('font-size', (d) => getFontSize(fontSize, nodes, d, that.keyedTotalNodes) - 2)
        .text((d) => d.percent.toFixed(0) + '%')
        .attr('transform', (d) => {
          if (
            nodes[d.x].size < defaultSize * 2 &&
            nodes[d.x].size < nodes[d.y].size
          )
            return `rotate(-90, ${x[d.x] + nodes[d.x].size / 2 + 1}, ${nodes[d.y].size / 2
              })`;
        })
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave)
        .on('click', that.onclickCell);
    }

    let currentDiv = this.matrixRef.current;

    d3.select(currentDiv)
      .selectAll('.matrixrow.selection')
      .insert('rect', ':first-child')
      .attr('class', 'selection')
      .attr('fill-opacity', '0.1')
      .attr('width', width + text_margin)
      .attr('height', function (d, i) {
        return that.nodes[d[i].y].size;
      })
      .attr('y', 0)
      .attr('x', -text_margin);
    d3.select(currentDiv)
      .selectAll('.column.selection')
      .insert('rect', ':first-child')
      .attr('class', 'selection')
      .attr('width', width + text_margin)
      .attr('height', function (d, i) {
        return that.nodes[d[i].y].size;
      })
      .attr('fill-opacity', '0.1')
      .attr('y', 0)
      .attr('x', -width);

    function mouseover(p) {
      if (p.x !== p.y) {
        oppositeHighlight
          .style('display', 'block')
          .attr('x', that.x[p.y])
          .attr('y', that.x[p.x])
          .attr('width', nodes[p.y].size)
          .attr('height', nodes[p.x].size)
          .attr('stroke', (d, i) => {
            return nodes[p.x].group === nodes[p.y].group
              ? colorOptions[nodes[p.x].group]
              : 'black';
          })
          .attr('fill-opacity', 0)
          .attr('stroke-width', 4);
      } else {
        oppositeHighlight.style('display', 'none');
      }
      hoverHighlight
        .style('display', 'block')
        .attr('x', that.x[p.x])
        .attr('y', that.x[p.y])
        .attr('width', nodes[p.x].size)
        .attr('height', nodes[p.y].size)
        .attr('stroke', (d, i) => {
          return nodes[p.x].group === nodes[p.y].group
            ? colorOptions[nodes[p.x].group]
            : 'black';
        })
        .attr('fill-opacity', 0)
        .attr('stroke-width', 4);
      d3.select(currentDiv)
        .selectAll('.matrixrow')
        .classed('active', (d, i) => {
          return p.y === i;
        });
      d3.select(currentDiv)
        .selectAll('.column')
        .classed('active', (d, i) => {
          return p.x === i;
        });
      d3.select(currentDiv)
        .selectAll('.matrixrow.active')
        .insert('rect', ':first-child')
        .attr('class', 'highlight')
        .attr('fill-opacity', '0.05')
        .attr('width', width + text_margin)
        .attr('height', nodes[p.y].size - 2)
        .attr('y', 1)
        .attr('x', -text_margin);
      d3.select(currentDiv)
        .selectAll('.column.active')
        .insert('rect', ':first-child')
        .attr('class', 'highlight')
        .attr('width', width + text_margin)
        .attr('height', nodes[p.x].size - 2)
        .attr('fill-opacity', '0.05')
        .attr('y', 1)
        .attr('x', -width);
    }

    function mouseleave(d) {
      tooltip.style('display', 'none');
      d3.selectAll('.highlight').remove();
      d3.select(currentDiv)
        .selectAll('.column.active')
        .classed('active', false);
      d3.select(currentDiv)
        .selectAll('.matrixrow.active')
        .classed('active', false);
      oppositeHighlight.style('display', 'none');
      hoverHighlight.style('display', 'none');
    }

    function mouseposition(element) {
      let offsetX = isModal ? 50 : currentDiv.offsetParent.offsetLeft;
      let offsetY = isModal
        ? 75 - document.getElementsByClassName('modal')[0].scrollTop
        : currentDiv.offsetParent.offsetTop - window.pageYOffset;
      let that = element;
      if (element.tagName === 'text') {
        that = element.parentNode;
      }
      let matrix = element
        .getScreenCTM()
        .translate(+element.getAttribute('cx'), +that.getAttribute('cy'));

      let dpos = 10;

      const [mx, my] = d3.mouse(that);

      let posX = matrix.e + matrix.a * mx - matrix.b * my - offsetX;
      let posY = matrix.f - matrix.c * mx + matrix.d * my - offsetY;

      let position = {};
      if (matrix.e + d3.mouse(that)[0] + 400 > window.innerWidth) {
        position.right = 0;
        posX -= dpos;
      } else {
        posX += dpos;
      }

      if (matrix.f + 400 > window.innerHeight) {
        position.bottom = 0;
      } else {
        posY += dpos;
      }
      return { position, posX, posY };
    }

    function mousemove(d) {
      if (that.startPos || !d.z || that.props.notCollapse) return;
      let target = nodes[d.y];
      let source = nodes[d.x];

      const { position, posX, posY } = mouseposition(this);

      let tooltipData = ReactDOMServer.renderToStaticMarkup(
        <Tooltip
          data={{
            ...d,
            source,
            target,
            optimizeType,
            baseResult,
            coefficient,
            value: d.count ? d.value / d.count : 0,
            frequency: d.count ? d.frequency / d.count : 0,
            permanence: d.count ? d.permanence / d.count : 0,
            duration: d.count ? d.duration / d.count : 0,
            value_new: d.count ? d.value_new / d.count : 0,
          }}
          position={position}
          threshold={threshold}
          activityOptions={activityOptions}
          surveyType={surveyType}
          filters={that.props.matrixTermFilter}
          totalNodes={that.keyedTotalNodes}
          scenarios={that.props.data.scenarios}
          links={that.props.data.links}
        />
      );
      tooltip
        .html(tooltipData)
        .style('left', posX + 'px')
        .style('top', posY + 'px');

      tooltip.style('display', 'flex');
    }

    function mousemoveaxis(element, node) {
      if (that.props.notCollapse) {
        return;
      }
      const { position, posX, posY } = mouseposition(element);

      let tooltipData = ReactDOMServer.renderToStaticMarkup(
        <TooltipAxis data={node} position={position} />
      );
      tooltip
        .html(tooltipData)
        .style('left', posX + 'px')
        .style('top', posY + 'px');

      tooltip.style('display', 'flex');
    }

    let tooltip = d3
      .select(this.matrixRef.current)
      .append('div')
      .style('opacity', 1)
      .attr('class', 'tooltip')
      .attr('width', 0)
      .attr('height', 0);

    this.scrollRef.current.onUpdateContent();
  };

  getOrder = (options) => {
    let nodes = this.nodes;
    let n = nodes.length;

    options = options || [];

    return d3.range(n).sort((a, b) => {
      for (let i = 0; i < options.length; i++) {
        let { value, asc } = options[i];
        let propA = nodes[a][value];
        let propB = nodes[b][value];
        try {
          if (propA !== propB) {
            if (typeof propA === 'number') {
              return (propA - propB) * (asc || 1);
            } else {
              return (propA || '').localeCompare(propB || '');
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
      return 0;
    });
  };

  orderByOption = (value) => {
    let nodes = this.nodes;
    let n = nodes.length;
    let defaultSize = this.defaultSize;
    this.order = this.getOrder(value);

    let sz = this.order.map((id) => nodes[id].size);
    let x = new Array(n);
    this.x = x;
    for (let i = 0; i < n; i++) {
      x[this.order[i]] = d3.sum(sz.slice(0, i));
    }
    let svg = this.svg;

    let t = svg.transition().duration(2500);
    let r = t
      .selectAll('.matrixrow')
      .delay((d, i) => {
        return (x[i] / this.width) * 1500;
      })
      .attr('transform', (d, i) => {
        return 'translate(0,' + x[i] + ')';
      });

    r.selectAll('.cell')
      .delay((d) => {
        return (x[d.x] / this.width) * 1500;
      })
      .attr('x', (d) => {
        return x[d.x] + 1 + strokeWidth(d) / 2;
      });
    r.selectAll('.cell-text')
      .delay((d) => {
        return (x[d.x] / this.width) * 1500;
      })
      .attr('x', (d) => {
        return x[d.x] + nodes[d.x].size / 2;
      })
      .attr('transform', (d) => {
        if (
          nodes[d.x].size < defaultSize * 2 &&
          nodes[d.x].size < nodes[d.y].size
        )
          return `rotate(-90, ${x[d.x] + nodes[d.x].size / 2 + 1}, ${nodes[d.y].size / 2
            })`;
      });
    t.selectAll('.column')
      .delay((d, i) => {
        return (x[i] / this.width) * 1500;
      })
      .attr('transform', (d, i) => {
        return 'translate(' + x[i] + ')rotate(-90)';
      });
  };

  componentDidMount = () => {
    this.drawMatrix(true);
    window.addEventListener('mousedown', this.mouseDownEvent);
    window.addEventListener('contextmenu', this.contextMenu);
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { cursor } = this.props;
    const { cursor: nextCursor } = nextProps;
    if (cursor !== nextCursor) {
      this.setState({ cursor: nextCursor });
    }
  }


  shouldComponentUpdate(nextProps, nextState) {
    let {
      matrixFilter: { order },
      cursor,
    } = this.props;
    let {
      matrixFilter: { order: orderNext },
      cursor: nextCursor,
    } = nextProps;

    if (order !== orderNext) {
      this.orderByOption(orderNext);
      return false;
    }

    if (cursor !== nextCursor) {
      this.matrixRef.current.style.cursor = nextCursor
        ? `url(${nextCursor.url})  ${nextCursor.x} ${nextCursor.y}, pointer`
        : 'arrow';
      return false;
    }

    return true;
  }

  componentDidUpdate = () => {
    this.drawMatrix();
    if (this.setNodes) this.setNodes(this.totalNodes);
  };

  componentWillUnmount = () => {
    window.removeEventListener('mousedown', this.mouseDownEvent);
    window.removeEventListener('contextmenu', this.contextMenu);
  };

  render() {
    const { cursor } = this.state;
    return (
      <ReactFloatingScroll
        contentRef={this.matrixRef}
        ref={this.scrollRef}
        innerScrollableClass="matrixview"
      >
        <div
          ref={this.matrixRef}
          className="matrixview"
          id="matrix-view"
          style={{
            cursor: cursor
              ? `url(${cursor.url})  ${cursor.x} ${cursor.y}, pointer`
              : 'arrow',
          }}
        />
      </ReactFloatingScroll>
    );
  }
}
