import React, { useRef, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Tooltip } from 'react-tippy';
import SVG from 'components/SVG';
import { useDoubleClick } from 'lib/hooks';
import _ from 'lib/lodashFunctions';
import EditableText from './EditableText';

function ActionCell(props) {
  return (
    <button
      className="grid-button"
      onClick={() => {
        props.api.updateRowData({ remove: [props.node.data] });
        props.colDef.cellRendererParams.onUpdated();
      }}
    >
      <SVG
        icon="delete.svg#delete"
        iconWidth={24}
        iconHeight={24}
        iconClassName="button__icon button__icon-red"
      />
    </button>
  );
}

function AddColumnHeader({ addNewColumn }) {
  return (
    <button className="grid-button" onClick={addNewColumn}>
      <SVG
        icon="plus.svg#plus"
        iconWidth={24}
        iconHeight={24}
        iconClassName="button__icon button__icon-blue"
      />
    </button>
  );
}

function CustomColumnHeader(props) {
  const thRef = useRef();
  const editRef = useRef();
  const [header, setHeader] = useState(props.displayName);

  useDoubleClick({
    onSingleClick: (e) => {
      if (e.target.className === 'remove_column') return;
      if (editRef.current && editRef.current.state.disabled === false) return;
    },
    ref: thRef,
    latency: 250,
  });

  const onRemoveClick = (e) => {
    e.stopPropagation();
    props.removeColumn(props.column.colId);
  };

  return (
    <div ref={thRef} className="ag-grid-custom-header">
      <div onClick={(e) => onRemoveClick(e)} className="remove_column">
        <SVG
          icon="delete.svg#delete"
          iconWidth={24}
          iconHeight={24}
          iconClassName="button__icon button__icon-red"
        />{' '}
      </div>
      <EditableText
        value={header}
        setValue={(v, confirm) => {
          if (confirm) {
            return props.setHeader(props.column.colId, v, confirm);
          }
          setHeader(v);
        }}
        ref={editRef}
      />
    </div>
  );
}

function DefaultCell(props) {
  const { colDef, value, data } = props;
  const { validation } = colDef.cellRendererParams;
  let error;

  if (!validation) return value || null;
  

  error = validation(colDef.field, value, data);
  return error
    ? (
      <Tooltip title={error} position="bottom" followCursor arrow>
        <div className="ellipsis alert-danger">{value || '___'}</div>
      </Tooltip>
    )
    : value || null;
}

export default function AgTable({
  data,
  columns,
  editable,
  onUpdate,
  validation,
  addColumn,
  maxCustomColumnCount,
  setColumns,
  emptyText,
  pagination = true,
}) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [columnState, setColumnState] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onUpdated = (params) => {
    const data = [];
    gridApi.forEachNode((rowNode, index) => {
      data.push(rowNode.data);
    });
    setColumnState(gridColumnApi.getColumnState());
    onUpdate(data);
  };

  const onUpdateStart = (params) => {
    params.api.refreshCells({
      columns: ['action'],
      rowNodes: [params.node],
      force: true,
    });
  };

  const components = {
    ..._.mapValues(
      _.keyBy(
        columns.filter((c) => c.Cell),
        (c) => `Render${c.accessor}`
      ),
      (v) => v.Cell
    ),
    defaultCell: DefaultCell,
    actionCell: ActionCell,
    addColumnHeader: AddColumnHeader,
    customColumnHeader: CustomColumnHeader,
  };

  const ableToAddNewColumn =
    addColumn &&
    columns.filter((col) => col.custom).length < maxCustomColumnCount;

    const isUnique = (header, columns) => {
      return !columns.find(
        (column) =>
          column.Header.toLowerCase() === header.toLowerCase() ||
          column.accessor.toLowerCase() === header.toLowerCase()
      );
    };
    
  const addNewColumn = () => {
    if (columns.filter((col) => col.custom).length >= maxCustomColumnCount) {
      return;
    }
  
    let header = '';
    for (let i = 1; i < 1000; i++) {
      header = `Column${i}`;
      if (isUnique(header, columns)) {
        break;
      }
    }
  
    setColumnState(gridColumnApi.getColumnState());
    setColumns([
      ...columns,
      { accessor: header.toLowerCase(), Header: header, custom: true },
    ]);
  };
    

  const removeColumn = (colId) => {
    const newColumns = columns.filter((col) => col.accessor !== colId);
    setColumnState(gridColumnApi.getColumnState());
    setColumns(newColumns);
  };

  const setHeader = (colId, header, confirm) => {
    const newColumns = columns.map((col) =>
      col.accessor === colId
        ? {
          ...col,
          Header: header,
        }
        : col
    );
    setColumns(newColumns);
    if (confirm) {
      if (newColumns.filter((col) => col.Header === header).length > 1)
        return 'Duplicate header';
      return null;
    }
  };

  const colState = columnState ? _.keyBy(columnState, (c) => c.colId) : {};

  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        rowData={data || []}
        gridOptions={{
          pagination: pagination,
          paginationPageSize: pagination ? 10 : undefined,
          domLayout: 'autoHeight',
          localeText: {
            noRowsToShow: emptyText,
          },
        }}
        components={components}
        defaultColDef={{
          flex: 1,
          editable,
          sortable: true,
        }}
        stopEditingWhenCellsLoseFocus={true}
        onCellEditingStarted={onUpdateStart}
        onCellValueChanged={onUpdated}
        onGridReady={onGridReady}
        rowSelection={editable ? 'multiple' : undefined}
      >
        {columns.map((c, idx) => {
          const width = colState[c.accessor]?.width;
          return (
            <AgGridColumn
              key={c.accessor}
              field={c.accessor}
              resizable
              flex={width ? 0 : 1}
              width={width}
              minWidth={140}
              headerName={c.Header}
              cellRenderer={c.Cell ? `Render${c.accessor}` : 'defaultCell'}
              headerComponent={c.custom ? 'customColumnHeader' : undefined}
              cellRendererParams={{
                validation,
              }}
              headerComponentParams={{
                removeColumn,
                setHeader,
              }}
              suppressMovable={true}
            />
          );
        })}
        {editable && (
          <AgGridColumn
            resizable={false}
            headerName=""
            headerComponent={ableToAddNewColumn ? 'addColumnHeader' : undefined}
            cellRenderer="actionCell"
            colId="action"
            editable={false}
            width={50}
            flex="auto"
            cellRendererParams={{
              onUpdated,
            }}
            headerComponentParams={{
              addNewColumn,
            }}
            sortable={false}
            pinned="right"
          />
        )}
      </AgGridReact>
    </div>
  );
}
