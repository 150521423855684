export const SURVEY_CREATE_REQUEST = 'SURVEY/SURVEY_CREATE_REQUEST'
export const SURVEY_CREATE_SUCCESS = 'SURVEY/SURVEY_CREATE_SUCCESS'
export const SURVEY_CREATE_FAILURE = 'SURVEY/SURVEY_CREATE_FAILURE'

export const SURVEY_GET_REQUEST = 'SURVEY/SURVEY_GET_REQUEST'
export const SURVEY_GET_SUCCESS = 'SURVEY/SURVEY_GET_SUCCESS'
export const SURVEY_GET_FAILURE = 'SURVEY/SURVEY_GET_FAILURE'

export const SURVEY_META_GET_REQUEST = 'SURVEY/SURVEY_META_GET_REQUEST'
export const SURVEY_META_GET_SUCCESS = 'SURVEY/SURVEY_META_GET_SUCCESS'
export const SURVEY_META_GET_FAILURE = 'SURVEY/SURVEY_META_GET_FAILURE'

export const CLOSE_REQUEST = 'SURVEY/CLOSE_REQUEST'
export const CLOSE_SUCCESS = 'SURVEY/CLOSE_SUCCESS'
export const CLOSE_FAILURE = 'SURVEY/CLOSE_FAILURE'

export const DIAGNOSE_CLOSE_REQUEST = 'SURVEY/DIAGNOSE_CLOSE_REQUEST'
export const DIAGNOSE_CLOSE_SUCCESS = 'SURVEY/DIAGNOSE_CLOSE_SUCCESS'
export const DIAGNOSE_CLOSE_FAILURE = 'SURVEY/DIAGNOSE_CLOSE_FAILURE'

export const DELETE_REQUEST = 'SURVEY/DELETE_REQUEST'
export const DELETE_SUCCESS = 'SURVEY/DELETE_SUCCESS'
export const DELETE_FAILURE = 'SURVEY/DELETE_FAILURE'

export const DELETE_DIAGNOSE_REQUEST = 'SURVEY/DELETE_DIAGNOSE_REQUEST'
export const DELETE_DIAGNOSE_SUCCESS = 'SURVEY/DELETE_DIAGNOSE_SUCCESS'
export const DELETE_DIAGNOSE_FAILURE = 'SURVEY/DELETE_DIAGNOSE_FAILURE'

export const SURVEY_SEND_REQUEST = 'SURVEY/SURVEY_SEND_REQUEST'
export const SURVEY_SEND_SUCCESS = 'SURVEY/SURVEY_SEND_SUCCESS'
export const SURVEY_SEND_FAILURE = 'SURVEY/SURVEY_SEND_FAILURE'

export const SET_SURVEY_LOCAL = 'SURVEY/SET_SURVEY_LOCAL'
export const SET_SURVEY_ERROR = 'SURVEY/SET_SURVEY_ERROR'

export const SORT_SURVEY = 'SURVEY/SORT_SURVEY';
export const SORT_SURVEY_API_REQUEST = 'SURVEY/SORT_SURVEY_API_REQUEST';
export const SORT_SURVEY_API_SUCCESS = 'SURVEY/SORT_SURVEY_API_SUCCESS';
export const SORT_SURVEY_API_FAILURE = 'SURVEY/SORT_SURVEY_API_FAILURE';

export const CLEAR_SURVEY = 'SURVEY/CLEAR_SURVEY';