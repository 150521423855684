import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export const Portalable = (props) => {
  const el = useRef(document.createElement('div'));
  useEffect(() => {
    const portal = document.getElementById('portal');
    portal.appendChild(el.current);

    return () => {
      portal.removeChild(el.current);
    };

  }, [props.children]);

  useEffect(() => {
    if (el.current) {
      if (props.portal) {
        el.current.className = props.className;
      } else {
        el.current.className = '';
      }
    }
  }, [el, props.className, props.portal])

  return props.portal ? ReactDOM.createPortal(props.children, el.current) : <div className={props.className}>{props.children}</div>;
}

export const PortalDiv = () => <div id='portal'></div>;