import * as constants from 'redux/constants/design';
import toast from 'lib/toast.js';
import { assignDesignResources, reorder } from 'lib/helper';
import { arrayMoveImmutable } from 'array-move';

export const getDesignsInitialState = {
  loading: true,
  data: null,
  errors: {},
};

export const getDesigns = (state = getDesignsInitialState, action) => {
  let changedList;
  switch (action.type) {
    case constants.DESIGNS_GET_REQUEST:
      return {
        loading: true,
      };
    case constants.DESIGNS_GET_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case constants.DESIGN_DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(d => d.id !== action.payload.id)
      }
    case constants.SORT_SURVEY_DESIGN:
      changedList = arrayMoveImmutable(state.data, action.oldIndex, action.newIndex);
      return {
        ...state,
        data: changedList,
      };
    case constants.SORT_SURVEY_DESIGN_API_REQUEST:
      return {
        ...state,
        onSort: true,
      };
    case constants.SORT_SURVEY_DESIGN_API_FAILURE:
      return {
        ...state,
        onSort: false,
      };
    case constants.SORT_SURVEY_DESIGN_API_SUCCESS:
      return {
        ...state,
        onSort: false,
      };
    case constants.DESIGNS_GET_FAILURE:
      toast(
        'Failed to get designs:',
        action.payload.response || {
          Error: action.payload.statusText,
        }
      );
      return {
        loading: false,
        errors: action.payload.response || {
          Error: action.payload.statusText,
        },
      };
    default:
      return state;
  }
};

export const getDesignInitialState = {
  loading: true,
  data: null,
  errors: {},
};

export const getDesign = (state = getDesignInitialState, action) => {
  let changedList;
  switch (action.type) {
    case constants.DESIGN_GET_REQUEST:
      return {
        loading: true,
      };
    case constants.DESIGN_GET_SUCCESS:
    case constants.DESIGN_UPDATE_SUCCESS:
      reorder(action.payload.designs, action.payload.designs_order);
      return {
        loading: false,
        data: action.payload,
      };
    case constants.DESIGN_GET_FAILURE:
      toast(
        'Failed to get design:',
        action.payload.response || {
          Error: action.payload.statusText,
        }
      );
      return {
        loading: false,
        errors: action.payload.response || {
          Error: action.payload.statusText,
        },
      };
    case constants.SORT_DESIGN:
      changedList = arrayMoveImmutable(
        state.data.designs,
        action.oldIndex,
        action.newIndex
      );
      return {
        ...state,
        data: {
          ...state.data,
          designs: changedList,
        },
      };
    case constants.SORT_DESIGN_API_REQUEST:
      return {
        ...state,
        onSort: true,
      };
    case constants.SORT_DESIGN_API_FAILURE:
      return {
        ...state,
        onSort: false,
      };
    case constants.SORT_DESIGN_API_SUCCESS:
      return {
        ...state,
        onSort: false,
      };

    default:
      return state;
  }
};

export const getDesignOrg = (state = getDesignInitialState, action) => {
  let valid, data;
  switch (action.type) {
    case constants.DESIGN_ORG_GET_REQUEST:
      return {
        loading: true,
      };
    case constants.DESIGN_ORG_GET_SUCCESS:
      valid = assignDesignResources(action.payload);
      return valid
        ? { loading: false, data: action.payload }
        : { loading: false, errors: 'Invalid graph data from server' };
    case constants.DESIGN_ORG_GET_FAILURE:
      toast(
        'Failed to get organization data:',
        action.payload.response || {
          Error: action.payload.statusText,
        }
      );
      return {
        loading: false,
        errors: action.payload.response || {
          Error: action.payload.statusText,
        },
      };
    case constants.DESIGN_ORG_SAVE_SUCCESS:
      if (action.run) {
        toast('Succesfully saved design changes');
      }
      data = { ...state.data, ...action.payload };
      assignDesignResources(data);
      return { loading: false, data };
    case constants.DESIGN_ORG_SAVE_FAILURE:
      toast('Failed to save design changes');
      data = {
        ...state.data,
        ...action.payload,
        result: {
          ...state.data.result,
          ...action.payload.result,
        },
      };
      assignDesignResources(data, false);
      return {
        loading: false,
        data,
      }
    default:
      return state;
  }
};

let filterGraphInitialData = {
  filterActivities: [],
};

export const graphFilter = (state = filterGraphInitialData, action) => {
  let filter_role, activities, ax;
  switch (action.type) {
    case constants.SET_GRAPH_FILTER:
      return {
        ...action.filter,
      };
    case constants.DESIGN_ORG_GET_SUCCESS:
      filter_role = action.payload.filter_role || [];
      activities = action.payload.activities || [];
      if (activities.length <= filter_role.length) filter_role = [];
      ax = 'groupName0';
      return {
        filterActivities: filter_role.map((role) => ({
          label: activities.find((a) => a.id === role)?.activity,
          value: role,
        })).filter(u => u.label),
        axis: ax,
      };
    default:
      return state;
  }
};


let filterMatrixInitialData = {
  filterActivities: [],
};

export const matrixFilter = (state = filterMatrixInitialData, action) => {
  let filter_role, activities, ax;

  switch (action.type) {
    case constants.SET_MATRIX_FILTER:
      return {
        ...action.filter,
      };

    case constants.DESIGN_ORG_GET_SUCCESS:
      filter_role = action.payload.filter_role || [];
      activities = action.payload.activities || [];
      if (activities.length <= filter_role.length) filter_role = [];
      ax = 'groupName0';

      return {
        filterActivities: filter_role.map((role) => ({
          label: activities.find((a) => a.id === role)?.activity,
          value: role,
        })).filter(u => u.label),
        axis: [ax],
        order: [
          { value: 'groupName0' },
          { value: 'division' },
          { value: 'name' },
        ],
      };

    default:
      return state;
  }
};

const getRandomString = () => (Math.random() + 1).toString(36).substring(4);
const matrixTermFilterInitData = [];

export const matrixTermFilter = (state = matrixTermFilterInitData, action) => {
  switch (action.type) {
    case constants.ADD_MATRIX_FILTER: {
      if (!action.payload.id) {
        return [
          ...state,
          {
            ...action.payload,
            id: getRandomString(),
          },
        ];
      } else {
        const idx = state.findIndex((f) => f.id === action.payload.id);
        const newState = [...state];
        newState[idx] = action.payload;
        return newState;
      }
    }
    case constants.DEL_MATRIX_FILTER:
      return state.filter((s) => s.id !== action.payload.id);

    case constants.RESET_MATRIX_FILTER:
      return [];
    default:
      return state;
  }
};

const designExportInitialData = {
  loading: false,
};

export const designExport = (state = designExportInitialData, action) => {
  switch (action.type) {
    case constants.DESIGN_EXPORT_REQUEST:
      return {
        loading: true,
      };
    case constants.DESIGN_EXPORT_FAILURE:
      toast('Failed to Export');
      return {
        loading: false,
        error: true,
      };
    case constants.DESIGN_EXPORT_SUCCESS:
      toast('Export succeeded!');
      return {
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export const savedDesign = (state = {}, action) => {
  switch (action.type) {
    case constants.SAVE_DESIGN:
      return {
        ...state,
        [action.id]: action.data,
      };
    default:
      return state;
  }
};

export const efficiencyCoefficient = (state = {data: 30}, action) => {
  switch (action.type) {
    case constants.SET_DESIGN_EFFICIENCY_COEFFICIENT:
      return {
        data: parseFloat(action.newValue)
      }
    default:
      return state
  }
}
