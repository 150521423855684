import React from 'react';
import HeaderSidebar from './HeaderSidebar';
import HeaderActions from './HeaderActions';
import DesignsTermFilter from 'components/DesignsTermFilter';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import {
  addDesignMatrixFilter,
  delDesignMatrixFilter,
  resetDesignMatrixFilter,
} from 'redux/actions/design';

export default function Header() {
  const designData = useSelector((s) => s.designOrg);
  const isDesignOrg = useMatch('/design/org/:design');

  const state = useSelector((state) => state);

  const addFilterAction = addDesignMatrixFilter;
  const delFilterAction = delDesignMatrixFilter;
  const resetFilterAction = resetDesignMatrixFilter;
  const filters = state.designMatrixTermFilter;
  const data = designData;

  const dispatch = useDispatch();

  const showFilter = isDesignOrg;

  const addFilter = (payload) => {
    dispatch(addFilterAction(payload));
  };

  const delFilter = (payload) => {
    dispatch(delFilterAction(payload));
  };

  const resetFilter = () => {
    dispatch(resetFilterAction);
  };

  return (
    <header className="plat-header">
      <HeaderSidebar />
      {showFilter && (
        <DesignsTermFilter
          data={data.data}
          addFilter={addFilter}
          delFilter={delFilter}
          filters={filters || []}
          resetFilter={resetFilter}
        />
      )}
      <HeaderActions />
    </header>
  );
}
