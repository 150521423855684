import React from 'react';

const PapaParse = require('papaparse/papaparse.min.js');

// https://www.papaparse.com/docs#config Parser options
export default class CSVReader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.uploadBtn = React.createRef();
  }

  onButtonClick = () => {
    this.uploadBtn.current.click();
  };

  handleChangeFile = (e, props) => {
    let reader = new FileReader();
    const filename = e.target.files[0].name;
    reader.onload = (event) => {
      const csvData = PapaParse.parse(
        event.target.result,
        Object.assign(props.parserOptions, {
          skipEmptyLines: true,
          error: props.onError,
          transform: (v) => v.trim(),
        })
      );
      this.uploadBtn.current.value = null;
      props.onFileLoaded(csvData.data, filename);
    };

    reader.readAsText(e.target.files[0]);
  };

  render() {
    return (
      <button
        onClick={this.onButtonClick}
        className="button"
        disabled={this.props.disabled}
      >
        <input
          type="file"
          ref={this.uploadBtn}
          style={{ display: 'none' }}
          accept=".csv, text/csv"
          onChange={(e) => this.handleChangeFile(e, this.props)}
        />
        {this.props.label}
      </button>
    );
  }
}
