export const DESIGNS_GET_REQUEST = 'DESIGN/DESIGNS_GET_REQUEST'
export const DESIGNS_GET_SUCCESS = 'DESIGN/DESIGNS_GET_SUCCESS'
export const DESIGNS_GET_FAILURE = 'DESIGN/DESIGNS_GET_FAILURE'

export const DESIGN_GET_REQUEST = 'DESIGN/DESIGN_GET_REQUEST'
export const DESIGN_GET_SUCCESS = 'DESIGN/DESIGN_GET_SUCCESS'
export const DESIGN_GET_FAILURE = 'DESIGN/DESIGN_GET_FAILURE'

export const DESIGN_UPDATE_REQUEST = 'DESIGN/DESIGN_UPDATE_REQUEST'
export const DESIGN_UPDATE_SUCCESS = 'DESIGN/DESIGN_UPDATE_SUCCESS'
export const DESIGN_UPDATE_FAILURE = 'DESIGN/DESIGN_UPDATE_FAILURE'


export const SET_GRAPH_FILTER = 'DESIGN/SET_GRAPH_FILTER'

export const DESIGN_ORG_GET_REQUEST = 'DESIGN/DESIGN_ORG_GET_REQUEST'
export const DESIGN_ORG_GET_SUCCESS = 'DESIGN/DESIGN_ORG_GET_SUCCESS'
export const DESIGN_ORG_GET_FAILURE = 'DESIGN/DESIGN_ORG_GET_FAILURE'

export const DESIGN_ORG_SAVE_REQUEST = 'DESIGN/DESIGN_ORG_SAVE_REQUEST'
export const DESIGN_ORG_SAVE_SUCCESS = 'DESIGN/DESIGN_ORG_SAVE_SUCCESS'
export const DESIGN_ORG_SAVE_SUCCESS_NO_UPDATE = 'DESIGN/DESIGN_ORG_SAVE_SUCCESS_NO_UPDATE'
export const DESIGN_ORG_SAVE_FAILURE = 'DESIGN/DESIGN_ORG_SAVE_FAILURE'

export const DESIGN_DELETE_REQUEST = 'DESIGN/DESIGN_DELETE_REQUEST'
export const DESIGN_DELETE_SUCCESS = 'DESIGN/DESIGN_DELETE_SUCCESS'
export const DESIGN_DELETE_FAILURE = 'DESIGN/DESIGN_DELETE_FAILURE'


export const DESIGN_EXPORT_REQUEST = 'DESIGN/DESIGN_EXPORT_REQUEST'
export const DESIGN_EXPORT_SUCCESS = 'DESIGN/DESIGN_EXPORT_SUCCESS'
export const DESIGN_EXPORT_FAILURE = 'DESIGN/DESIGN_EXPORT_FAILURE'

export const SORT_DESIGN = 'DESIGN/SORT_DESIGN'

export const SORT_DESIGN_API_REQUEST = 'DESIGN/SORT_DESIGN_API_REQUEST'
export const SORT_DESIGN_API_SUCCESS = 'DESIGN/SORT_DESIGN_API_SUCCESS'
export const SORT_DESIGN_API_FAILURE = 'DESIGN/SORT_DESIGN_API_FAILURE'

export const SORT_SURVEY_DESIGN = 'DESIGN/SORT_SURVEY_DESIGN'

export const SORT_SURVEY_DESIGN_API_REQUEST = 'DESIGN/SORT_SURVEY_DESIGN_API_REQUEST'
export const SORT_SURVEY_DESIGN_API_SUCCESS = 'DESIGN/SORT_SURVEY_DESIGN_API_SUCCESS'
export const SORT_SURVEY_DESIGN_API_FAILURE = 'DESIGN/SORT_SURVEY_DESIGN_API_FAILURE'

export const SAVE_DESIGN = 'DESIGN/SAVE_DESIGN'

export const SET_MATRIX_FILTER = 'DESIGN/SET_MATRIX_FILTER'

export const ADD_MATRIX_FILTER = 'DESIGN/ADD_MATRIX_FILTER'
export const DEL_MATRIX_FILTER = 'DESIGN/DEL_MATRIX_FILTER'
export const RESET_MATRIX_FILTER = 'DESIGN/RESET_GRAPH_FILTER'
export const SET_DESIGN_EFFICIENCY_COEFFICIENT = 'DESIGN/EFFICIENCY_COEFFICIENT'