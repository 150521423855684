import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';

import { createClient } from 'api/user';
import Loading from 'components/Loading';
import TextInput from 'components/TextInput';
import profileIcon from 'img/icons/profile.svg';
import withRouter from 'lib/withRouter';
import { getProfileData } from 'pages/Profile/profileSlice';
import { getClientsData, setCurrentClient } from 'pages/Client/clientSlice';

const NewClientModal = (props) => {
  const { showModal, toggle } = props;
  const [clientName, setClientName] = useState('');
  const [loading, setLoading] = useState(false);

  function onCreate() {
    setLoading(true);
    createClient(clientName)
      .then((res) => {
        props.setClient(res.id);
        props.getClientsData();
        props.changeClient({
          value: res.id,
          label: res.name,
        })
        setClientName('');
        toggle();
      })
      .catch()
      .finally(() => setLoading(false));
  }

  return (
    <Modal isOpen={showModal} toggle={toggle} style={{ width: 600 }}>
      <ModalHeader toggle={toggle}>Add New Client</ModalHeader>
      <ModalBody>
        {
          loading
            ? <Loading size="sm" />
            : <TextInput
              type="text"
              placeholder="Enter client name"
              onChange={(e) => setClientName(e.target.value)}
              name="client_name"
              value={clientName}
              autoFocus
            />
        }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onCreate} disabled={loading} className="normal">
          Create
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const UserProfile = (props) => {
  useEffect(() => {
    props.getProfileData();
    props.getClientsData();
    const currentClient = +localStorage.getItem('selectedClient');
    if (!currentClient) {
      props.setCurrentClient('all');
    } else {
      props.setCurrentClient(currentClient);
    }
  }, []);
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(!showModal);
  const { profile, clients } = props;
  let navigate = useNavigate();
  let options = (clients?.data || []).map(client => ({
    value: client.id,
    label: client.name,
  }));
  options.push({
    value: 'all',
    label: 'All',
  }, {
    value: 0,
    label: '+ Add New Client',
  });
  const currentOption = options.filter(
    option => option.value === props.currentClient?.data,
  )[0];

  function changeClient(option) {
    if (option.value === 0) {
      toggle();
    } else {
      const { pathname } = props.history.location;
      localStorage.setItem('selectedClient', option.value);
      props.setCurrentClient(option.value);
      if (pathname.startsWith('/discover') && pathname !== '/discover') {
        navigate('/discover');
      } else if (pathname.startsWith('/design') && pathname !== '/design') {
        navigate('/design');
      } else if (pathname.startsWith('/diagnose') && pathname !== '/diagnose') {
        navigate('/diagnose');
      }
    }
  }


  return (
    <div className="user-profile">
      <div style={{ width: 200 }}>
        <Select
          name="clients"
          placeholder="Select client"
          options={options}
          onChange={changeClient}
          value={currentOption}
        />
      </div>
      <div className="avatar" style={{ marginLeft: 20 }}>
        <img src={profileIcon} alt="Profile" />
      </div>
      <h4 className="username">
        <Link to="/profile">
          {profile?.data?.first_name || profile?.data?.username || ''}
        </Link>
      </h4>
      <NewClientModal
        toggle={toggle}
        showModal={showModal}
        setClient={props.setCurrentClient}
        changeClient={changeClient}
        {...props}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  clients: state.client,
  currentClient: state.currentClient,
});

const mapDispatchToProps = {
  getProfileData,
  getClientsData,
  setCurrentClient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile));
