import React from 'react';

// TODO replace with react svg
const SVG = props => {
  let {
    iconWidth,
    iconHeight,
    iconViewWidth,
    iconViewHeight,
    icon,
    iconClassName,
    disabled,
    ...otherProps
  } = props
  let iconCls = 'button__icon'
  if (!iconWidth) iconWidth = 16
  if (!iconHeight) iconHeight = 16
  if (!disabled) {
    if (!iconClassName) iconCls += ' button__icon-blue'
    else iconCls += ' ' + iconClassName
  }

  if (!icon) return '';
  let svg_link = '';
  try {
    const [icon_file, icon_id] = icon.split('#')
    svg_link = require(`../img/icons/${icon_file}`) + `#${icon_id}`
  } catch (e) {
    console.warn('Cannot find file:' + icon + e)
  }
  return (
    <svg
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
      viewBox={`0 0  ${iconViewWidth || iconWidth} ${iconViewHeight || iconHeight}`}
      {...otherProps}
    >
      <use
        xlinkHref={svg_link}
        preserveAspectRatio="xMinYMid"
        className={iconCls}
      />
    </svg>
  );
};

export default SVG;
