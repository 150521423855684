import React, { PureComponent } from 'react';
import { Circle } from 'react-progress-bar.js';

class CircleProgressBar extends PureComponent {
  render() {
    let Gradient = '<defs></defs>';
    const percent = this.props.percent;
    let progress = percent > 100 ? 100 : percent < 0 ? 0 : percent;
    let options = {
      strokeWidth: 10,
      color: 'url(#circle_progressbar_gradient)',
      trailColor: '#fff',
      trailWidth: 10,
      easing: 'easeInOut',
      duration: 1400,
      svgStyle: null,
      text: {
        value: '',
        alignToBottom: false,
      },
      step: (state, bar) => {
        if (!this.props.hideProgress) {
          var value = Math.round(bar.value() * 100);
          bar.setText(
            '<p class="progress-bar-title">Progress</p>' +
            Math.abs(value) +
            '<span>%</span>',
          );
          bar.text.style.color = state.color;
          bar.text.style.fontFamily = '"Open Sans", sans-serif';
        }
        bar.svg.insertAdjacentHTML('afterbegin', Gradient);
      },
    };
    return (
      <Circle
        progress={-progress / 100 - 0.00001}
        text={this.props.text}
        options={options}
        initialAnimate={true}
        containerClassName={'circular-progressbar'}
      />
    );
  }
}

export default CircleProgressBar;
