import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import _ from 'lib/lodashFunctions';

export default function MappingFilters(
  {
    allFilters,
    selectedFilters,
    onMappingFilterChange,
    disabled,
  },
) {
  const selectedItems = selectedFilters && allFilters.filter(f => selectedFilters.indexOf(f.id) > -1).map(v => ({
    label: v.title,
    value: v.id,
    group_name: v.group_name,
  }));
  const groupedSelectedItems = selectedItems && _.groupBy(selectedItems, filter => filter.group_name);
  const [selected, setSelected] = useState(groupedSelectedItems || {});
  const groupedFilters = _.map(_.groupBy(allFilters, filter => filter.group_name), (value, key) => ({
    title: key,
    items: value.map(v => ({
      label: v.title,
      value: v.id,
    })),
  }));
  const onChangeFilter = (category, v) => {
    const newFilters = {
      ...selected,
      [category]: v || [],
    };
    setSelected(newFilters);
    const ids = _.flatten(Object.values(newFilters)).map(v => v.value);
    if (onMappingFilterChange) {
      onMappingFilterChange(ids);
    }
  };

  useEffect(() => {
    setSelected(groupedSelectedItems);
  }, [selectedFilters]);

  return (
    <div className="card__content-wrapper pb-2">
      {groupedFilters.map((category) => (
        <div key={category.title}>
          <div className="card__header align-items-center pt-2 pb-0">
            <h5>{category.title}</h5>
          </div>
          <div className="card-content">
            <Select
              name={category.title}
              placeholder="All"
              options={category.items}
              onChange={v => onChangeFilter(category.title, v)}
              value={selected?.[category.title]}
              classNamePrefix="item-filter"
              isDisabled={disabled}
              isMulti
            />
          </div>
        </div>
      ))}
    </div>
  );
}
