import React, { useState } from 'react';
import { Tooltip } from 'react-tippy';
import { useDispatch } from 'react-redux';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';

import ApiHelper from 'api/helper';
import ConfirmModal from 'components/ConfirmModal';
import EditableText from 'components/EditableText';
import SVG from 'components/SVG';
import { ReactComponent as NavbarToggler } from 'img/icons/menu.svg';
import {
  exportDesign,
  saveDesignOrg,
  deleteDesign,
} from 'redux/actions/design';
import DesignContent from './DesignOrganizationContent';
import DesignTitleModal from './DesignTitleModal';

export default function DesignOrganizationCard(props) {
  const {
    data,
    survey_id,
    currentScore,
  } = props;

  const [title, setTitle] = useState(props.data.title);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validateAndSetTitle = (v, confirm) => {
    let ret = null;
    if (confirm && !v) {
      ret = 'Title can\'t be empty';
    }
    setTitle(v);
    if (confirm && !ret) {
      dispatch(saveDesignOrg(data.id, { title: v }, false, false));
    }
    return ret;
  };
  const onCopy = () => {
    setShowCopyModal(true);
  };
  const onDelete = () => {
    setShowDeleteModal(false);
    dispatch(deleteDesign(data.id));
  };

  function onConfirmCopy(title) {
    ApiHelper.post('/api/v2/design/copy/', {
      data: {
        title,
        id: data.id,
      },
    }).then(res => {
      navigate('/design/org/' + res.data.id);
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <div className="card col-12 col-xl-6 col-lg-6">
      <div className="card__content-wrapper">
        <div className="card__header row pb-4 d-table">
          <div className="d-table-cell w-100">
            <Tooltip
              title={data.title}
              position="bottom"
              followCursor
              arrow
            >
              <EditableText className="ellipsis ellipsis-card" value={title}
                            setValue={validateAndSetTitle}/>
            </Tooltip>
          </div>
          {
            <UncontrolledDropdown group>
              <DropdownToggle color="white">
                <NavbarToggler style={{ display: 'flex' }}/>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="DropdownItem" onClick={onCopy}>
                  <SVG
                    icon="copy.svg#copy"
                    iconWidth={20}
                    iconHeight={20}
                    iconClassName="icon-blue"
                  />
                  <span>COPY</span>
                </DropdownItem>
                <DropdownItem
                  className="DropdownItem"
                  onClick={() => dispatch(exportDesign(data.id))}
                >
                  <SVG
                    icon="export.svg#share"
                    iconWidth={20}
                    iconHeight={17}
                    iconClassName="icon-blue"
                  />
                  <span>EXPORT</span>
                </DropdownItem>
                <DropdownItem
                  className="DropdownItem"
                  onClick={() => {setShowDeleteModal(true)}}
                >
                  <SVG
                    icon="delete.svg#delete"
                    iconWidth={20}
                    iconHeight={20}
                    iconClassName="icon-blue"
                  />
                  <span>DELETE</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        </div>
        <DesignContent
          design={data}
          survey_id={survey_id}
          currentScore={currentScore}
        />
      </div>
      {
        showCopyModal && (
          <DesignTitleModal
            showModal={showCopyModal}
            defaultTitle={data.title + ' - Copy'}
            onConfirm={onConfirmCopy}
            onToggle={() => {
              setShowCopyModal(!showCopyModal);
            }}
          />
        )
      }
      {
        showDeleteModal &&
        <ConfirmModal
          message="Are you sure to delete this design?"
          onConfirm={onDelete}
          onToggle={() => setShowDeleteModal(false)}
          showModal={showDeleteModal}
        />
      }
    </div>
  );
}
