import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import Button from 'components/Button';

const ConfirmModal = (props) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setModal(props.showModal);
  }, [props.showModal])

  function toggle() {
    setModal(!modal);
    if (modal && props.onToggle) {
      props.onToggle();
    }
  }

  function onConfirm() {
    props.onConfirm();
  }

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-dialog-mini modal-dialog-centered "
      >
        <ModalBody className="text-center p-5">
          <p>{props.message}</p>
          <Button text="Yes" onClick={onConfirm} className="button me-3"/>
          <Button text="No" className="button ms-3" onClick={toggle}/>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConfirmModal;