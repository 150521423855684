import React from 'react';
import Treemap, { ColorModel } from 'react-d3-treemap';
import * as d3 from 'd3'; // Import the 'd3' library
import { COLOR_WHITE, COLOR_SOFT_BLUE, COLOR_GREEN, COLOR_AQUAMARINE, COLOR_BLUE, COLOR_NAVY, COLOR_VIOLET, COLOR_BLUE_VIOLET, COLOR_LIGHT_SEA_GREEN, COLOR_DARK_BLUE, COLOR_LIGHT_SKY_BLUE } from 'lib/constants';

//const customColorScheme = [COLOR_SOFT_BLUE, COLOR_BLUE_VIOLET, COLOR_GREEN, COLOR_BLUE, COLOR_NAVY, COLOR_VIOLET, COLOR_LIGHT_SEA_GREEN, COLOR_DARK_BLUE, COLOR_AQUAMARINE, COLOR_LIGHT_SKY_BLUE];
const customColorScheme = [COLOR_WHITE, COLOR_GREEN, COLOR_SOFT_BLUE, COLOR_GREEN, COLOR_SOFT_BLUE, COLOR_GREEN, COLOR_SOFT_BLUE, COLOR_GREEN, COLOR_SOFT_BLUE, COLOR_GREEN];

const TreeMap = ({ hierarchyData }) => {
  return (
    hierarchyData ? (
      <div className="treemap-container">
        <Treemap
          data={hierarchyData}
          width={960}
          height={500}
          valueUnit="hrs/mth"
          customD3ColorScale={d3.scaleOrdinal(customColorScheme)}
          colorModel={ ColorModel.Depth }
          paddingInner={10}
          tile={d3.treemapSquarify}
          levelsToDisplay={3}
          lightNodeBorderColor={COLOR_WHITE}
        />
      </div>
    ) : null
  );
};

export default TreeMap;
