import ApiHelper from './helper';

export async function createSurvey(formData) {
  const url = '/api/survey_info/create/';

  const { data } = await ApiHelper.post(url, { data: formData });
  return data;
}

export async function listSurveys(client) {
  return await ApiHelper.get(`/api/survey/?client=${client}`);
}

export async function getSurvey(surveyId) {
  return await ApiHelper.get(`/api/survey/${surveyId}/`);
}

export async function remindSurvey(data) {
  return await ApiHelper.post('/api/survey_info/remind/', {data});
}

export async function getDownloadUrl(dataId) {
  return await ApiHelper.get(`/api/diagnose_download_report/?id=${dataId}`);
}

export async function exportSurveyParticipants(payload) {
  return await ApiHelper.post(
    '/api/export_participants/',
    {
      data: payload,
      responseType: 'arraybuffer',
    },
  );
}

export async function createDesign(survey, title) {
  return await ApiHelper.post('/api/survey_info/design/', {
    data: { id: survey, title },
  });
}
