import * as React from "react";
import { getTextDimensions, truncateText } from "../helpers";
var LabelNewLine = function (_a) {
    var label = _a.label, textColor = _a.textColor, value = _a.value, hasChildren = _a.hasChildren, containerWidth = _a.containerWidth, containerHeight = _a.containerHeight, style = _a.style, _b = _a.splitRegExp, splitRegExp = _b === void 0 ? /(?=[A-Z/a-z0-9.][^A-Z/a-z0-9. -])/g : _b;
    if (!label) {
        return null;
    }
    var fullLabel = value ? label + "\u00A0" + value : label;
    var _c = getTextDimensions(fullLabel, style), width = _c.width, height = _c.height;
    if (containerHeight < height) {
        return null;
    }
    var maxTextRows = Math.floor(containerHeight / height);
    var splitLabel = width >= containerWidth || !hasChildren
        ? label.split(splitRegExp).concat(value).slice(0, maxTextRows)
        : [fullLabel];
    return (React.createElement(React.Fragment, null, splitLabel.map(function (item, index) {
        return (React.createElement("tspan", { fontSize: style.fontSize, fill: textColor, key: index, x: 0, dy: style.fontSize }, truncateText(item, style, containerWidth)));
    })));
};
export default LabelNewLine;
