import { RSAA } from 'redux-api-middleware';
import * as constants from 'redux/constants/auth';

export const login = (username, password) => ({
  type: 'LOG_IN',
  [RSAA]: {
    endpoint: '/api/token/',
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: { 'Content-Type': 'application/json' },
    types: [
      constants.LOGIN_REQUEST,
      constants.LOGIN_SUCCESS,
      constants.LOGIN_FAILURE,
    ],
  },
});

export const logout = () => ({
  type: constants.LOGOUT,
});
