import React, { useState } from 'react';
import Select from 'react-select';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import LodashFunctions from 'lib/lodashFunctions';

import {
  COLOR_LIGHT_BLUE,
  COLOR_BLUE,
  COLOR_PINK,
  COLOR_GREEN,
  COLOR_DARK_GREEN,
  COLOR_VIOLET,
  FTE_HOURS_PER_MONTH,
} from 'lib/constants';
import { ButtonGroup } from 'reactstrap';
import ToggleButton from '../../../components/ToggleButton';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  ChartDataLabels
);

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');
  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'column';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;
    listContainer.style.justifyContent = 'center';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);
    var automation_potential_added_to_legend = false;

    items.forEach((item, idx) => {
      if (item.text.includes('Automation potential')) {
        if (automation_potential_added_to_legend) {
          return;
        }
        automation_potential_added_to_legend = true;
      }
      
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      if (idx > 0) {
        li.style.marginTop = '4px';
      }

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '18px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '45px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
      if (item.text.includes('Automation potential')) {
        automation_potential_added_to_legend = true;
      }
    });
  }
};

function chunks(str, n) {
  const maxLength = Math.ceil(str.length / n);
  let output = [];
  let tmpWord = '';
  const words = str.split(' ');
  words.forEach(word => {
    if (tmpWord) {
      tmpWord += ` ${word}`
    } else {
      tmpWord = word;
    }
    if (tmpWord.length > maxLength) {
      output.push(tmpWord);
      tmpWord = '';
    }
  });
  if (tmpWord) output.push(tmpWord);
  return output;
}

const chartOptions = {
  plugins: {
    title: {
      display: false,
      text: 'Time Utilization',
      position: 'Top',
    },
    htmlLegend: {
      // ID of the container to put the legend in
      containerID: 'statistics-legend-container',
    },
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      position: 'average',
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ' + context.raw.toLocaleString();

            if (context.dataset.xAxisID === 'x') {
              label += ' h/mth';
            }
          }
          return label;
        },
        // title: function () {}
      },
    },
    datalabels: {
      labels: {
        title: {
          font: {
            weight: 'bold',
          },
        },
      },
      color: 'black',
      formatter: function (value, context) {
        return '';
        // return context.dataset.label;
      },
    },
  },
  indexAxis: 'y',
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: true,
      },
      ticks: {
        maxTicksLimit: 5,
      },
      title: {
        text: 'Hours per Month',
        display: true,
        padding: {
          top: 20,
          bottom: -10,
        }
      },
      position: 'top',
    },
    x1: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 5,
      },
      title: {
        text: 'Count',
        display: true
      },
      position: 'top',
    },
    y: {
      position: 'left',
      gridLines: {
        display: false,
        drawBorder: false,
      },
      stacked: true,
      title: {
        text: 'Activity',
        display: true,
      },
    },
    y2: {
      position: 'left',
      gridLines: {
        display: false,
        drawBorder: false,
      },
      stacked: true,
      title: {
        text: 'Function',
        display: true,
      },
      ticks: {
        callback: function (label) {
          let realLabel = this.getLabelForValue(label);
          return realLabel[0];
        }
      }
    },
  },
};
const directionOptions = [
  {
    label: 'Ascending',
    value: 1,
  },
  {
    label: 'Descending',
    value: -1,
  },
];
const basicSortingOptions = [
  {
    label: 'Total Work',
    value: 'total',
  },
  {
    label: 'FTE',
    value: 'FTE',
  },
  {
    label: 'Coordination',
    value: 'coordination',
  },
  {
    label: 'Relationships',
    value: 'relationships',
  },
];
const groupByOptions = [
  {
    label: 'Function - Activity',
    value: '',
  },
  {
    label: 'Function',
    value: 'group',
  },
  {
    label: 'Activity',
    value: 'activity',
  },
];

function getAggregatedData(
  activityMap, activities, links, originalUtilization, resourcesFTE, sortKey, sortDirection, groupBy, nodes
) {
  const aggregatedData = {};
  const activitiesByRelationship = {};
  activities.forEach((activity) => {
    let key, label;
    
    const activityFteTotal = (originalUtilization[activity.groupId]?.value || [])
      .reduce((a, b) => a + b, 0) / 100; // pct number, FTE is calculated at print time
    if (groupBy === 'activity') {
      key = activity.name;
      label = key;
    } else if (groupBy === 'group') {
      key = activity.group;
      label = key;
    } else {
      key = activity.id;
      label = [activity.group, activity.name];
    }

    if (!aggregatedData[key]) {
      aggregatedData[key] = {
        label,
        total: 0,
        coordinationPair: {},
        fullTimeEquivalent: 0,
        relationships: 0,
      };
    }

    aggregatedData[key].total += activityFteTotal;
    
    aggregatedData[key].automation_potential = parseFloat(activity.automation_potential)/100 || 0;
  });
  

  // aggregate resource count based on aggregation level
  const seenIds = {}; // To keep track of unique IDs
  
  Object.entries(resourcesFTE).forEach(([resourceGroupId, resource]) => {
    const currentResource = resource || [];
    let key;
 
    if (groupBy === 'activity') {
      key = activityMap[resourceGroupId];
    } else if (groupBy === 'group') {
      key = activities.find(item => String(item.id) === String(resourceGroupId))?.group;
    } else {
      key = activities.find(item => String(item.id) === String(resourceGroupId))?.id;
    }
    if (!key) {
      return;
    }

    if(!seenIds[key]){
      seenIds[key] = new Set();
    }

    currentResource.forEach(item => {
      if (!seenIds[key].has(item.id)) {
        seenIds[key].add(item.id);
        if (aggregatedData[key]) {
          aggregatedData[key].fullTimeEquivalent += item.fullTimeEquivalent;
        }
      }
    });
  });

  
  links.forEach((link) => {
    if (!nodes[link.sourceId] && !nodes[link.targetId]) {
      return;
    }
    const workEffort = link.value_new;
    const numberOfFteInvolved = link.num_fte_involved || 2;
    let totalWorkEffort = workEffort * numberOfFteInvolved;
    
    let relationshipKey = link.sourceId > link.targetId
      ? `${link.targetId}-${link.sourceId}`
      : `${link.sourceId}-${link.targetId}`;
    if (!activitiesByRelationship[relationshipKey]) {
      activitiesByRelationship[relationshipKey] = [];
    }
    if (link.relationship.length > 1) {
      totalWorkEffort /= link.relationship.length;
    }
    link.relationship.forEach((activity) => {
      let key;
      if (groupBy === 'activity') {
        key = activityMap[activity];
      } else if (groupBy === 'group') {
        key = activities.find(item => String(item.id) === String(activity))?.group;
      } else {
        key = activity;
      }
      if (!key) {
        return;
      }
      if (aggregatedData[key]) {
        if (activitiesByRelationship[relationshipKey].indexOf(activity) === -1) {
          activitiesByRelationship[relationshipKey].push(activity);
          aggregatedData[key].relationships += 1;
        }
        aggregatedData[key].coordinationPair[relationshipKey] = Math.max(
          totalWorkEffort,
          aggregatedData[key].coordinationPair?.[relationshipKey] || 0,
        );
      }
    });
  });

  Object.keys(aggregatedData).forEach((key) => {
    aggregatedData[key].coordination = Object.values(
      aggregatedData[key].coordinationPair
    ).reduce((a, b) => a + b, 0);
  });

  const sortedIds = Object.keys(aggregatedData).sort((a, b) => {
    let diff;
    if (sortKey === 'FTE') {
      diff = aggregatedData[a].fullTimeEquivalent > aggregatedData[b].fullTimeEquivalent ? 1 : -1;
    } else if (sortKey === 'group') {
      if (!groupBy) {
        diff = aggregatedData[a].label[0] > aggregatedData[b].label[0] ? 1 : -1;
      } else {
        diff = aggregatedData[a].label > aggregatedData[b].label ? 1 : -1;
      }
    } else if (sortKey === 'activity') {
      if (!groupBy) {
        diff = aggregatedData[a].label[1] > aggregatedData[b].label[1] ? 1 : -1;
      } else {
        diff = aggregatedData[a].label > aggregatedData[b].label ? 1 : -1;
      }
    } else {
      diff = aggregatedData[a][sortKey] - aggregatedData[b][sortKey];
    }
    return sortDirection * diff;
  });
  const labels = sortedIds.map((id) => aggregatedData[id].label);
  return {
    ids: sortedIds,
    aggregatedData,
    labels,
  }
}

const TimeUtilizationTab = (props) => {
  const [selectedSorting, setSorting] = useState(basicSortingOptions[0]);
  const [selectedGroupBy, setGroupBy] = useState(groupByOptions[0]);
  const [selectedDirection, setDirection] = useState(directionOptions[1]);
  const {
    links,
    nodes,
    activityMap,
    activities,
    timeUtilization,
  } = props;
  const originalUtilization = {};
  const resourcesFTE = {};

  if (!timeUtilization || Object.keys(timeUtilization).length < 1) {
    return <></>;
  } else {
    let aggregatedTimeUtilization = {};
    Object.values(nodes).forEach(node => {
      const activityUtilization = node['activityUtilization'];
      if (!activityUtilization) {
        return;
      }
      const item = timeUtilization[node.id];
      if (!item) {
        return;
      }

      // Resources calculation
      Object.keys(activityUtilization).forEach(activity => {
        if (!resourcesFTE[activity]) {
          resourcesFTE[activity] = [];
        }
        const fullTimeEquivalent = {
          'id': node.id,
          'fullTimeEquivalent': +node.fte || 1
        };
        
        resourcesFTE[activity].push(fullTimeEquivalent);
      });
     
      
      // Total Work calculation
      Object.keys(item).forEach(activity => {
        if (!aggregatedTimeUtilization[activity]) {
          aggregatedTimeUtilization[activity] = [];
        }
        const fullTimeEquivalent = +node.fte || 1;
        
        // Multiply the time utilization per activity percentage 
        // by the FTE value of the resource to scale Total work
        // by the number of FTE represented by the resource
        const totalWork = +item[activity].toString().replace('%', '').trim() * fullTimeEquivalent;
        aggregatedTimeUtilization[activity].push(totalWork);
      });
    });

    Object.keys(aggregatedTimeUtilization).forEach((key) => {
      if (activities.find((item) => key.toString() === String(item?.groupId))) {
        originalUtilization[key] = {
          value: aggregatedTimeUtilization[key],
        };
      }
    });
  }

  const { aggregatedData, labels, ids } = getAggregatedData(
    activityMap,
    activities,
    links,
    originalUtilization,
    resourcesFTE,
    selectedSorting.value,
    selectedDirection.value,
    selectedGroupBy.value,
    nodes,
  );


  const barOptions = LodashFunctions.cloneDeep(chartOptions);
  switch (selectedGroupBy.value) {
    case 'group':
      delete barOptions.scales.y2;
      barOptions.scales.y.title = {
        text: 'Function',
        display: true,
      }
      break;
    case 'activity':
      delete barOptions.scales.y2;
      barOptions.scales.y.title = {
        text: 'Activity',
        display: true,
      }
      break;
    default:
      barOptions.scales.y.ticks = {
        callback: function (label) {
          let realLabel = this.getLabelForValue(label)[1];
          return chunks(realLabel || '', 4);
        },
      };
      break;
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Coordination',
        stack: 'Coordination',
        data: (ids.map((id) => Math.round(aggregatedData[id].coordination * (1 - aggregatedData[id].automation_potential)))),
        datalabels: {
          display: 'auto',
        },
        backgroundColor: COLOR_BLUE,
        xAxisID: 'x',
      },
      {
        label: 'Total work',
        stack: 'total_work',
        data: ids.map((id) => Math.round(aggregatedData[id].total * FTE_HOURS_PER_MONTH * (1 - aggregatedData[id].automation_potential))),
        datalabels: {
          display: 'auto',
        },
        backgroundColor: COLOR_LIGHT_BLUE,
        xAxisID: 'x',
      },
      {
        label: 'Resources',
        stack: 'resources',
        data: ids.map(
          (id) => aggregatedData[id].fullTimeEquivalent * (1 - aggregatedData[id].automation_potential)
        ),
        datalabels: {
          display: 'auto',
        },
        backgroundColor: COLOR_PINK,
        xAxisID: 'x1',
      },
      {
        label: 'Relationships',
        stack: 'relationships',
        data: ids.map((id) => aggregatedData[id].relationships * (1 - aggregatedData[id].automation_potential)),
        datalabels: {
          display: 'auto',
        },
        backgroundColor: COLOR_VIOLET,
        xAxisID: 'x1',
      },
      {
        label: 'Automation potential',
        stack: 'Coordination',
        data: ids.map((id) => Math.round(aggregatedData[id].coordination * aggregatedData[id].automation_potential)),
        datalabels: {
          display: 'auto',
        },
        backgroundColor: COLOR_GREEN,
        xAxisID: 'x',
      },
      {
        label: 'Automation potential',
        stack: 'total_work',
        data: ids.map((id) => Math.round(aggregatedData[id].total * FTE_HOURS_PER_MONTH * aggregatedData[id].automation_potential)),
        datalabels: {
          display: 'auto',
        },
        backgroundColor: COLOR_GREEN,
        xAxisID: 'x',
      },
      {
        label: 'Automation potential',
        stack: 'resources',
        data: ids.map(
          (id) => aggregatedData[id].fullTimeEquivalent * aggregatedData[id].automation_potential
        ),
        datalabels: {
          display: 'auto',
        },
        backgroundColor: COLOR_GREEN,
        xAxisID: 'x1',
      },
      {
        label: 'Automation potential',
        stack: 'relationships',
        data: ids.map((id) => aggregatedData[id].relationships * aggregatedData[id].automation_potential),
        datalabels: {
          display: 'auto',
        },
        backgroundColor: COLOR_GREEN,
        xAxisID: 'x1',
      },
    ],
  };

  let sortingOptions = [...basicSortingOptions];
  if (selectedGroupBy.value === 'group') {
    sortingOptions.push({
      label: 'Function',
      value: 'group',
    });
  } else if (selectedGroupBy.value === 'activity') {
    sortingOptions.push({
      label: 'Activity',
      value: 'activity',
    });
  } else {
    sortingOptions.push({
      label: 'Function',
      value: 'group',
    });
    sortingOptions.push({
      label: 'Activity',
      value: 'activity',
    });
  }
  return (
    <>
      <div className="design-statistics d-flex flex-row justify-content-between">
        <div className="filter-select filter">
          <h5>Legend</h5>
          <div id="statistics-legend-container" />
        </div>
        <div className="filter-select filter design-label-selector">
          <h5>Group by</h5>
          <ButtonGroup>
            {groupByOptions.map(o => (
              <ToggleButton
                key={o.value}
                value={o.label}
                id={o.value}
                checked={o.value === selectedGroupBy.value}
                onChange={() => setGroupBy(o)}
              />
            ))}
          </ButtonGroup>
        </div>
        <div className="filter-select filter">
          <h5>Order By</h5>
          <ButtonGroup>
            {sortingOptions.map(o => (
              <ToggleButton
                key={o.value}
                value={o.label}
                id={o.value}
                checked={o.value === selectedSorting.value}
                onChange={() => setSorting(o)}
              />
            ))}
          </ButtonGroup>
        </div>
        <div className="filter-select filter">
          <h5>Order by direction</h5>
          <ButtonGroup>
            {directionOptions.map(o => (
              <ToggleButton
                key={o.value}
                value={o.label}
                id={o.value}
                checked={o.value === selectedDirection.value}
                onChange={() => setDirection(o)}
              />
            ))}
          </ButtonGroup>
        </div>
      </div>
      <div style={{
        height: (labels.length * 80 + 80) > 32500
          ? 32500
          : labels.length * 80 + 80,
        position: 'relative',
      }}>
        <Bar options={barOptions} plugins={[htmlLegendPlugin]} data={data} className="time-utilization" />
      </div>
    </>
  );
};

export default TimeUtilizationTab;
