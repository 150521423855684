import axios from 'axios';
import ls from 'local-storage';

import store from '../app/store';
import { TOKEN_FAILURE } from '../redux/constants/auth';

const withAuth = (headers = {}) => {
  return {
    ...headers,
    Authorization: ls.get('accessToken') && `Bearer ${ls.get('accessToken')}`,
  };
};

const base = (method, url, props = {}, secure = true) => {
  const { data = {}, headers = {}, params = {}, cancelToken, ...rest } = props;
  if (secure) {
    let tmp = {
      method,
      url,
      data,
      params,
      headers: withAuth(headers),
      ...rest,
    };
    if (cancelToken) {
      tmp.cancelToken = cancelToken;
    }
    return axios(tmp).catch(err => {
      if (err.response.status === 401) {
        store.dispatch({
          type: TOKEN_FAILURE,
          payload: { response: err.response.data },
        });
        return err;
      }
      throw err;
    });
  } else {
    return axios({
      method,
      url,
      headers,
      data,
      params,
    });
  }
};

const ApiHelper = {};

['get', 'post', 'put', 'patch', 'delete'].forEach((method) => {
  ApiHelper[method] = base.bind(null, method);
});

export default ApiHelper;
